<template>
<div>
  <div class="coupon-items">
    <div :class="isHas <= 0  || content.overdue? 'gray' : (content.type != 4 ? 'yuan' : 'discount')">
      <img src="../images/coup.png" alt="" class="coupImg" v-show="content.is_receive === 1">
      <div class="header">
        <div class="title">
          <span>{{ content.amount }}</span>
          <span v-if="content.type != 4">元</span>
          <span v-else>折</span>
        </div>
        <p>{{ typeList[content.type - 1] }}</p>
        <p>{{content.use_end_at}}截止</p>
      </div>
      <div class="footer">
        <p>{{ content.condition > 0 ? '满'+content.condition+'可用' : '无门槛' }}</p>
        <p class="mt-8">{{content.admin.name}}</p>
        <a
          href="javascript:;"
          class="mt-16"
          v-if="isHas > 0 && content.is_receive !== 1 && !content.overdue"
          @click="getCoupon(content.hashid)"
        >
          立即领取
        </a>

        <a href="javascript:;" class="mt-16" v-else-if="content.is_receive === 1 && !content.overdue" @click="toHousePage(content.admin.code)">立即使用</a>
        <a href="javascript:;" class="mt-16" v-else-if="content.overdue">已过期</a>
        <a href="javascript:;" class="mt-16" v-else>已领完</a>
      </div>
    </div>
  </div>
  <div class="con_bottom">
    <div>剩余{{isHas}}张 {{content.explain}}</div>
    <div class="mt-8">{{content.use_end_at}}截止</div>
  </div>
</div>
  
  
</template>

<script>
import { receiveCoupon } from '@/http/api'
import { mapState } from 'vuex'
export default {
  name: "couponItems",
  props: {
    content: Object,
    action: Boolean,
  },
  computed: {
    ...mapState(["isLogin"])
  },
  created() {
    this.isHas = this.content.created_num - this.content.send_num;
  },
  data() {
    return {
      canIUse: false,
      typeList: ['满减券', '现金券', '运费券', '折扣券'],
      isHas: 0
    };
  },
  methods: {
    // 立即使用
    toHousePage(code) {
      this.$store.commit('saveKeyword', code);
      this.$router.push(`/house?keyword=${code}`);
    },
    //领取优惠券
    async getCoupon(id) {
      console.log(this.isLogin);
      if(this.isLogin) {
        let res = await receiveCoupon({couponId: id});
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success(res.msg);
          this.$store.commit('changeCoupNumber', 1);
          this.$emit("get-coupon");
        }else {
          this.$message.error(res.msg);
        }
      }else {
        this.$message.warning('还没登录，即将跳转到登录页面');
        setTimeout(() => {
          this.$router.push('/login');
        }, 1000);
  
      }
    },
  },
};
</script>

<style>
/* 优惠券组件 */
.coupon-items {
  position: relative;
  text-align: center;
  width: 220px;
  height: 292px;
  line-height: 16px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
  cursor: default;
}
.coupon-items > div {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.coupon-items .yuan {
  background-image: url("../images/coup-red.png");
}
.coupon-items .discount {
  background-image: url("../images/coup-yellow.png");
}
.coupon-items .gray {
  background-image: url("../images/coup_gray.png");
}
.coupon-items .header,
.coupon-items .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
}
.coupon-items .title span {
  font-weight: bold;
  color: #ffffff;
}
.coupon-items .title span:first-child {
  font-size: 50px;
  line-height: 85px;
  margin-right: 8px;
}
.coupon-items .yuan .title span:nth-child(2) {
  line-height: 31px;
  font-size: 24px;
}
.coupon-items .discount .title {
  display: flex;
  align-items: center;
}
.coupon-items .discount .title span:nth-child(2) {
  font-size: 48px;
}
.coupon-items > div .header p:nth-child(2) {
  color: #ffffff;
  line-height: 24px;
  font-size: 18px;
}
.coupon-items .footer a {
  display: inline-block;
  font-size: 16px;
  border-radius: 19px;
  background: #ffffff;
  padding: 8px 32px;
}
.coupon-items .yuan .footer a {
  color: #fe5a74;
}
.coupon-items .discount .footer a {
  color: #fea918;
}
.coupon-items .gray .footer a {
  color: #999999;
}
.con_bottom {
  text-align: center;
  color: #999;
  font-size: 14px;
  padding: 20px 0;
}
.coupImg {
  z-index: 9;
  position: absolute;
  right: 0;
  top: 0;
}
</style>