<template>
  <div class="myHeader" id="header">
    <header>
      <div class="c-box flex fs-12">
        <span>您好，欢迎来到印爆网！</span>
        <ul class="flex">
          <li class="ml-16" v-show="isLogin">
            <router-link :to="{path: '/user/my'}">{{ tel }}</router-link>
          </li>
          <li class="ml-16" v-show="isLogin">
            <a href="javascript:;" @click="out">退出</a>
          </li>
          <li class="ml-16" v-show="!isLogin">
            <router-link to="/login">登录</router-link>
          </li>
          <li class="ml-16" v-show="!isLogin">
            <router-link to="/login/reg">注册</router-link>
          </li>
          <li class="ml-16">
            <router-link :to="'/user/my-coupon'" class="i-coup" style="position: relative;">
            <em v-show="coupNumber != 0">{{ coupNumber }}</em>
              我的优惠券
            </router-link>
          </li>
          <li class="ml-32">
            <router-link :to="'/user/follow'">
              我的订单
            </router-link>
          </li>
          <li v-for="(value, index) in headerList" :key="index" class="ml-16">
            <router-link :to="value.path">{{ value.value }}</router-link>
          </li>
        </ul>
      </div>
    </header>
    <div class="header-search" v-show="showSearch">
      <div class="c-box flex mb-16">
        <router-link to="/" class="logo-box">
          <img src="../images/LOGO.png" alt="印爆网" id="logo" />
        </router-link>
        <form action="" class="flex" style="position: relative;">
          <input
            type="search"
            name="keyword"
            placeholder="搜索 成衣/品牌/款式/款号/面料"
            v-model="search"
          />
          <!-- <el-upload
            action="https://yinbaole.com/admin/uploadQiniu"
            :on-success="handleUpSuccess"
            :show-file-list="false"
          >
            <img src="../images/phoneIcon.png" alt="" class="phoneIcon">
          </el-upload> -->
          <img src="../images/phoneIcon.png" alt="" class="phoneIcon pointer" @click="showUploadBox">

          <input type="submit" value="搜索" class="bg-r-btn" @click="handleSearch($event)" />
        </form>
        <div class="btn-inline my-btn">
          <router-link to="/shopping-cart" class="i-shopping mr-16">
            <i></i>
            我的购物车
            <em v-show="cartNumber != 0">{{ cartNumber }}</em>
          </router-link>
          <router-link :to="'/user/my-star'" class="i-star">
            <i></i>
            我的收藏
            <em v-show="collectNumber != 0">{{ collectNumber }}</em>
          </router-link>
        </div>
      </div>
      <nav class="c-box myheader">
        <router-link
          v-for="value in navList"
          :to="value.path"
          :key="value.id"
          :class="{ active: value.active }"
          
        >
          {{ value.value }}
        </router-link>
      </nav>
    </div>
    <transition name="mask">
      <div class="imgMask" v-show="showUpload">
        <div class="searchBox bg_white" >
          <div class="flex fs-16 f-j-s">
            <span class="black">识图搜索</span>
            <span class="iconfont w_line-close" @click="showUploadBox"></span>
          </div>
          <div class="flex mt-16 f-j-s">
            <el-upload
              action="https://yinbaole.com/admin/uploadQiniu"
              :on-success="handleUpSuccess"
              :show-file-list="false"
              drag
            >
              <div class="searchItem t-c">
                <img src="../images/s-phone.png" alt="" style="wisth:56px; height:56px;">
                <div class="fs-14 bold mt-8">
                  <span class="black ">以图</span><span class="c-red">搜款</span>
                </div>
                <div class="mt-8 c-9 fs-12">可将图片拖拽到此处进行上传</div>
                <div class="bg_red dis_block white fs-12 t-c uploadBtn mt-16">本地上传</div>
              </div>
            </el-upload>
             <el-upload
              action="https://yinbaole.com/admin/uploadQiniu"
              :on-success="handleUpIconSuccess"
              :show-file-list="false"
              drag
            >
              <div class="searchItem t-c">
                <img src="../images/s-icon.png" alt="" style="wisth:56px; height:56px;">
                <div class="fs-14 bold mt-8">
                  <span class="black ">以图</span><span class="c-red">搜标</span>
                </div>
                <div class="mt-8 c-9 fs-12">可将图片拖拽到此处进行上传</div>
                <div class="bg_red dis_block white fs-12 t-c uploadBtn mt-16">本地上传</div>
              </div>
            </el-upload>
            
          </div>
        </div>
      </div>
    </transition> 
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
// import { imageSearch } from '@/http/api';
export default {
  name: "myHeader",
  data() {
    return {
      headerList: [
        {
          value: "商家中心",
          path: "/", // merchantHome
        },
        {
          value: "印爆小程序",
          path: "/", // mShow
        },
      ],
      navList: [
        {
          id: 1,
          value: "首页",
          path: "/",
          active: true,
        },
        {
          id: 2,
          value: "服饰云仓",
          path: "/house",
          active: false,
        },
        {
          id: 3,
          value: "优惠券",
          path: "/coupon",
          active: false,
        },
        {
          id: 4,
          value: "活动专场",
          path: "/activity",
          active: false,
        },
        {
          id: 5,
          value: "排行榜",
          path: "/rank",
          active: false,
        },
        {
          id: 6,
          value: "新闻中心",
          path: "/news",
          active: false,
        },
        {
          id: 7,
          value: "供应链合作",
          path: "/supplyChain",
          active: false,
        },
        {
          id: 8,
          value: "领标中心",
          path: "/leadLabel",
          active: false
        }
      ],
      search: '',
      image: '',
      showUpload: false,
    };
  },
  watch: {
    $route: "changeNav",
    keyword: function () {
      this.search = this.keyword;
    }
  },
  computed: {
    ...mapState(["keyword", "isLogin", "coupNumber", "cartNumber", "collectNumber", "searchImg"]),
    //用户账号
     tel() {
      let str = this.$store.state.tel || "not-login";
      return str;
    },
    //搜索显示
    showSearch() {
      return this.$store.state.showSearch;
    },
    
  },
  methods: {
    //给nav样式
    changeNav() {
      for (let i = 0; i < this.navList.length; i++) {
        let path = this.navList[i].path.split("/")[1];
        if(path === this.$route.path.split('/')[1]) {
          this.navList[i].active = true;
        }else {
          this.navList[i].active = false;
        }
      }
    },
    //退出
    out() {
      this.$confirm('是否退出登录','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.localStorage.setItem('token', '');
        this.$store.commit("changeLogin", false);
        this.$store.commit("saveTel", '');
        this.$router.replace('/');
      }).catch(() => {})
    },
    // 搜索商品
    handleSearch(e) {
      e.preventDefault();
      console.log(this.$route);
      this.$router.push({path: '/house'});
      this.$store.commit('changeSearchFlag');
      // this.$store.commit('saveSearchImg', '');
      this.$store.commit('saveKeyword', this.search);
      // this.keyword = '';
    },
    // 显示上传窗口
    showUploadBox() {
      // this.$store.commit('changeMask');
      this.showUpload = !this.showUpload;
      if(this.showUpload) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    // 上传图片
    handleUpSuccess(file) {
      // this.image = file.data.src;
      this.$store.commit('saveSearchImg', file.data.src);
      this.showUpload = false;
      document.body.style.overflow = 'auto';
      this.$router.push('/house');

    },
    // 上传领标
    handleUpIconSuccess(file) {
      console.log(file);
      this.$store.commit('saveSearchLabel', file.data.src);
      this.showUpload = false;
      document.body.style.overflow = 'auto';
      this.$router.push('/leadLabel');
    }
  },
  
  created() {
    this.changeNav();
    // if(this.keyword) {
    //   this.search = this.keyword;
    // }
  },
  mounted() {
    // if(this.keyword) {
    //   this.search = this.keyword;
    // }
  }
};
</script>

<style>
#header {
  z-index: 1500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
header {
  background: #000;
  padding: 10px 0;
  color: #fff;
}
header a:hover {
  color: #CCC;
}
header span:first-child {
  margin-right: auto;
}
.header-search {
  padding-top: 10px;
  border-bottom: 1px solid #f5222d;
  background: #fff;
}
.header-search .flex {
  align-items: center;
  justify-content: space-between;
}
#logo {
  width: 156px;
}
.header-search .flex form {
  justify-content: flex-start;
  border-radius: 2px;
  overflow: hidden;
}
.header-search input[type="search"] {
  width: 517px;
  height: 40px;
  border: none;
  font-size: 14px;
  border: 2px solid #f5222d;
  padding: 5px 40px 5px 12px;
}
.header-search input[type="submit"] {
  height: 40px;
  line-height: 16px;
  padding: 0.75em 2em;
}
nav a {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 1.75em;
  color: rgba(0, 0, 0, 0.85);
}
nav a:hover,
nav a.active {
  background: #f5222d;
  color: #fff;
}
.myHeader .i-shopping,
.myHeader .i-star {
  position: relative;
}
.myHeader .i-shopping em,
.myHeader .i-star em {
  width: max-content;
  height: 21px;
  line-height: 19px;
  font-size: 12px;
  text-align: center;
  font-style: normal;
  color: #fff;
  background: #fa5151;
  border-radius: 16px;
  padding: 0 4px;
  margin-left: 5px;
}
.my-btn.btn-inline a {
  width: auto;
  padding: 0 12px;
}
.i-coup em {
  position: absolute;
  right: -15px;
  top: 0;
  border-radius: 50%;
  background: #fa5151;
  font-size: 12px;
  padding: 0 4px;
  font-style: normal;
}
.phoneIcon {
  position: absolute;
  right: 105px;
  top: 50%;
  transform: translateY(-50%);
}
.myheader a {
  margin-right: 4px;
}
.myHeader .searchBox {
  z-index: 9999;
  width: 448px;
  position: fixed;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  padding: 16px;
}
.searchBox .searchItem {
  width: 200px;
  height: 203px;
  padding: 24px 0;
  border: 1px dashed #DDDDDD;
  border-radius: 8px;
}
.searchBox .searchItem:hover {
  border-color: #6495ED;
}
.searchItem .uploadBtn {
  padding: 8px;
  width: 80px;
  border-radius: 16px;
}
.searchBox .w_line-close {
  color: #BBBBBB;
  cursor: pointer;
}
.imgMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.searchBox .el-upload-dragger {
  border: 0;
  width: auto;
  height: auto;
}
.searchBox .el-upload-dragger.is-dragover {
  border: 1px dashed #6495ED!important;
  box-sizing: border-box;
}
</style>