<template>
  <footer>
    <div class="flex c-box outBox">
      <img src="../images/LOGO.png" alt="" class="logoImg">
      <div class="fs-14 flex">
        <div class="flex f_col">
          <div class="title">购物须知</div>
          <div @click="toagree(0)" class="pointer">注册协议</div>
          <div @click="toagree(1)" class="pointer">政策法规</div>
          <div @click="toagree(2)" class="pointer">隐私政策</div>
        </div>
        <div class="flex f_col">
          <div class="title">支付方式</div>
          <div>支付宝支付</div>
          <div>微信支付</div>
          <div>余额支付</div>
        </div>
        <div class="flex f_col">
          <div class="title">售后服务</div>
          <div @click="toagree(3)" class="pointer">售后政策</div>
          <div @click="toagree(4)" class="pointer">退款说明</div>
          <div @click="toagree(5)" class="pointer">联系客服</div>
        </div>
        <div class="flex f_col">
          <div class="title">联系印爆</div>
          <div>{{configData.length ? configData[18].value : ''}}</div>
          <div>WX: {{configData.length ? configData[19].value:''}}</div>
          <div @click="toagree(5)" class="pointer">关于我们</div>
        </div>
      </div>
      <div class="t-center">
        <img :src="configData.length?configData[17].value:''" alt="" style="width: 100px; height:100px;">
        <div class="fs-14 mt-8">欢迎关注印爆网</div>
      </div>
    </div>
    <div class="f_bottom col_fff mt-40">
      <div>
        {{configData.length?configData[21].desc:''}}：{{configData.length?configData[21].value:''}}
        <!-- 地址中国广东省广州市白云区新百佳市场二层201-208 -->
        <span class="ml-16">{{configData.length?configData[20].desc:''}}：{{configData.length?configData[20].value:''}}</span>
      </div>
      <div class="mt-16">
        Copyright 2022 广州苏铁信息技术有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="ahover">粤ICP备16017870号</a>
        <span class="ml-16"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011102002742" target="_blank" class="ahover">粵公网安备11010802029085号</a></span>
      </div>
    </div>
  </footer>
</template>

<script>
import { configData } from '@/http/api';
export default {
  name: "myFooter",
  data() {
    return {
      configData: []
    }
  },
  created() {
    this.getConfigData();
  },
  methods: {
    toagree(index) {
      let routeUrl = this.$router.resolve({
        path: "/agree?index=" + index,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({path: '/agree', query: {index: index}})
    },
    getConfigData() {
      configData().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.configData = data;
          this.$store.commit('saveRecharge', data[23].value);
        }
      })
    }
  }
};
</script>

<style>
footer {
  background: #fff;
  border-top: 2px solid #F5222D;
  padding-top: 42px;
}
.outBox {
  align-items: center;
  justify-content: space-between;
}
.outBox >.flex {
  flex: 1;
  justify-content: space-around;
}
.f_col {
  flex-direction: column;
}
.f_col div,
.f_col a {
  margin-bottom: 16px;
}

.title {
  font-weight: bold;
}
.f_bottom {
  background: #000;
  padding: 18px 0 14px;
  text-align: center;
}
footer .pointer:hover {
  color: #fa5151;
}
.ahover:hover {
  color: #999999;
}
</style>