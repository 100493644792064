<template>
  <div class="news">
     <cNav :nav="navList" />
    <div class="c-box mt-40">
      <div class="example bg_white flex pointer" @click="toNewsDetail(firstNews.hashid)" v-if="firstNews">
        <img :src="firstNews.image" alt="" class="leftImg">
        <div class="rightPart">
          <div class="gray9">
            <span class="bg_f5 fs-12 mr-12" 
            v-for="(item, index) in firstNews.label_name" :key="'tag' + index">
              {{item}}
            </span>
          </div>
          <div class="title gray3 mt-20">{{firstNews.title}}</div>
          <div class="mt-32 gray9">{{firstNews.abstract}}</div>
        </div>
      </div>
      <div class="gray3 fs-30 mt-80">新闻中心</div>
      <div class="newsNav flex mt-32 gray6 fs-16">
        <div :class="{NavActive: newActive === -1}" @click="changeNav(-1)">全部</div>
        <div v-for="item in newNav" :key="item.id" :class="{NavActive: newActive === item.id}" @click="changeNav(item.id)">{{item.name}}</div>
      </div>
      <div class="newCon mt-32 mb-40 flex ">
        <div class="new_conItem pointer" v-for="item in newsList" :key="item.id" @click="toNewsDetail(item.hashid)">
          <img :src="item.image" alt="">
          <div class="textCon">
            <div>
              <span class="bg_f5 fs-12 mr-12" 
              v-for="(tag, index) in item.label_name" :key="'tags' + index">
                {{tag}}
              </span>
            </div>
            <div class="fs-20 gray3 mt-20 bold">{{item.title}}</div>
            <div class="fs-16 gray9 mt-8 abstract" :title="item.abstract">{{item.abstract}}</div>
          </div>
        </div>
        <div v-if="!newsList.length">暂无数据~</div>
      </div>
      <div class="t-c">
        <div class="more t-c fs-14 ver_mid" v-show="total > newsList.length" @click="seeMore">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { firstData, getNewsList } from '@/http/api'
export default {
  name: "news",
  data() {
    return {
      navList: [
        {
          id: 1,
          path: "",
          text: "新闻中心",
        },
      ],
      newNav: [],
      newActive: -1,
      firstNews: null,
      newsList: [],
      page: 1,
      total: 0,
      per_page: 0,
    }
  },
  created() {
    this.getFirstNews();
    this.getNewsList(1);
  },
  methods: {
    // 选择分类
    changeNav(id) {
      this.newActive = id;
      this.newsList = [];
      this.getNewsList(1);
    },
    // 新闻详情
    toNewsDetail(id) {
      this.$router.push({path: '/news/newsDetail', query:{newsId: id}});
    },
    seeMore() {
      this.getNewsList(++this.page);
    },
    //------------------------获取数据-------------------
    // 获取首条数据
    getFirstNews(){
      firstData().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.firstNews = data.data;
          this.newNav = data.typeData
        }
      })
    },
    // 获取新闻列表
    getNewsList(page) {
      getNewsList({
        type_id: this.newActive === -1 ? '' : this.newActive,
        page: page,
        limt: 6
      }).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.newsList.push(...data.data);
          this.total = data.total;
          this.per_page = data.per_page;
        }
      })
    }
  }
};
</script>

<style scoped>
.news {
  margin-bottom: 80px;
}
.example {
  max-height: 400px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  overflow: hidden;
}
.leftImg {
  width: 640px;
}
.rightPart {
  width: 50%;
  padding: 78px 36px 68px;
}
.bg_f5 {
  border-radius: 4px;
  padding: 4px 12px;
}
.title {
  font-size: 30px;
}
.newsNav {
  border-bottom: 1px solid #DDDDDD;
}
.newsNav div {
  padding-bottom: 22px;
  margin-right: 64px;
  cursor: pointer;
}
.newsNav div:nth-child(1) {
  margin-left: 20px;
}
.NavActive {
  border-bottom: 1px solid #F5222D;
  color: #F5222D;
}
.newCon {
  flex-wrap: wrap;
}
.new_conItem {
  width: 376px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin-right: 26px;
  margin-bottom: 32px;
}
.new_conItem:nth-child(3n) {
  margin-right: 0;
}
.new_conItem img {
  height: 236px;
  width: 100%;
}
.abstract {
  height: 45px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.textCon {
  background: #fff;
  padding: 20px 20px 40px;
}
.more {
  border: 1px solid #000;
  height: 48px;
  width: 200px;
  line-height: 48px;
}
</style>