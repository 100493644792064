<template>
<div class="bg_f5">
  <div class="activity c-box">
    <ul class="flex">
      <li v-for="(value, index) in activityList" :key="index">
        <div class="img-box">
          <img class="acImg" :src="value.image" alt="活动图片" v-if="value.image"/>
        </div>
        <div class="footer">
          <p>{{ value.title }}</p>
          <p>{{ value.time }}</p>
        </div>
      </li>
    </ul>
  </div>
</div>
  
</template>

<script>
import { activityList } from '@/http/api';
export default {
  name: "activity",
  data() {
    return {
      activityList: [],
    };
  },
  created() {
    this.getActivityList();
  },
  methods: {
    // 获取活动列表
    getActivityList() {
      activityList().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.activityList = data.data
        }
      })
    }
  }
};
</script>

<style>
/* 活动专场 */
.activity ul.flex {
  width: 1206px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.activity li {
  width: 48%;
  background: #dddddd;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 2px;
  overflow: hidden;
}
.activity .img-box {
  border-radius: 2px 2px 0px 0px;
  width: 580px;
  min-height: 320px;
  overflow: hidden;
}
.acImg {
  height: 320px;
  
}
.activity .footer {
  padding: 24px;
  background: #fff;
}
.activity .footer p:first-child {
  margin-bottom: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.85);
}
.activity .footer p:last-child {
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.45);
}
</style>