<template>
  <div class="my-star">
    <c-nav :nav="navList"></c-nav>
    <p class="card p-20 mb-20">我的收藏</p>
    <div class="card p-20" style="min-height: 462px;">
      <a
        href="javascript:;"
        class="mr-8"
        
        @click="chooseAll"
        v-show="!isManage"
      >
        批量管理
      </a>
      <a href="javascript:;" @click="notStar" v-show="isManage" class="bg_red white">取消收藏</a>
      <div class="white bg_black finishBtn t-center fs-12" v-show="isManage" @click="handleCancel">完成</div>
      <ul class="flex stargoodsLlist mt-20" v-show="starGoods.length > 0">
        <li v-for="(value, index) in starGoods" :key="'star'+index" class="mb-20" @click="toDetailPage(value.products.hashid)">
          <a
            v-show="isManage"
            href="javascript:;"
            class="checkbox"
            :class="{ 'w_checkbox-selectednormal': value.active }"
            @click="chooseGoods($event,index)"
          >
            <i></i>
          </a>
          <div class="starItem t-center pointer">
            <div style="width:180px; height:180px;">
              <img :src="value.products ? value.products.image + '?imageMogr2/thumbnail/180x': ''" alt="" style="width:100%; height:100%;"/>
            </div>
            <div class="fs-14 col_85 itemTitle mt-12">
             {{value.products ? value.products.full_name : ''}}
            </div> 
            <div class="mt-12 fs-18 col_f52">￥{{value.products ? value.products.price : ''}}</div>
          </div>
          <!-- <goods-box :data="value"></goods-box> -->
        </li>
      </ul>
      <p v-show="starGoods.length == 0" class="t-c">暂时没有哦~</p>
    </div>
  </div>
</template>

<script>
import { collectionProduct, delProduct } from '@/http/api';

export default {
  name: "myStar",

  data() {
    return {
      navList: [
        {
          path: `/user/my-star`,
          text: "我的收藏",
        },
      ],
      //收藏商品列表
      starGoods: [],
      //批量管理状态
      isAll: false,
      isManage: false
    };
  },
  computed: {
    //选择删除的商品ID列表
    deletList() {
      let arr = [];
      for (const iterator of this.starGoods) {
        if (iterator.active) arr.push(iterator.hashid);
      }
      return arr;
    },
  },
  methods: {
    //选择商品
    chooseGoods(e,index) {
      e.stopPropagation();
      console.log(index);
      this.starGoods[index].active = !this.starGoods[index].active;
    },
    //批量管理
    chooseAll() {
      this.isManage = true;
      this.isAll = true;
      for (const iterator of this.starGoods) {
        iterator.active = this.isAll;
      }
    },
    // 点击完成
    handleCancel() {
      this.isManage = false;
      this.isAll = false;
      for (const iterator of this.starGoods) {
        iterator.active = this.isAll;
      }
    },
    //点击取消收藏
    notStar() {
      if (this.deletList.length > 0) {
        console.log(this.deletList);
        this.delProduct();
      } else {
        this.$message.warning("尚未选择商品");
      }
    },
    // 前往详情
    toDetailPage(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
    //-----------------------获取数据-----------------
    // 获取收藏产品
    getCollectionProduct() {
      collectionProduct().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          let newArr = JSON.parse(JSON.stringify(data.productData));
          newArr.forEach(item => {
            item.active = false;
          })
          this.starGoods = newArr;
          this.$store.commit('saveCollectNumber', newArr.length);
          console.log(this.starGoods);
        }
      })
    },
    // 取消收藏
    delProduct() {
      delProduct({collectionIdArr: this.deletList}).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.getCollectionProduct();
        }else {
          this.$message.error(msg);
        }
      })
    }
  },
  created() {
    //获取数据
    this.getCollectionProduct();
  },
};
</script>

<style>
.my-star li {
  position: relative;
}
.my-star .checkbox {
  position: absolute;
  top: 4px;
  right: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #f5f5f5;
  overflow: hidden;
  z-index: 999;
}
.my-star .checkbox.w_checkbox-selectednormal {
  border: none;
}
.my-star .checkbox i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}
.starItem {
  width: 220px;
  height: 302px;
  border: 1px solid #E8E8E8;
  padding: 20px 20px 16px;
}
.finishBtn {
  display: inline-block;
  width: 56px;
  height: 26px;
  line-height: 26px;
  vertical-align: initial;
  margin-left: 8px;
  cursor: pointer;
}
.stargoodsLlist {
  flex-wrap: wrap;
}
.stargoodsLlist li {
  margin-right: 20px;
}
.stargoodsLlist li:nth-child(4n) {
  margin-right: 0;
}
li:hover .starItem img {
  transform: translateY(-6px);
}
.stargoodsLlist li .starItem img {
  transition: all 0.3s;
}
.itemTitle {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 38px;
}
</style>