import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //登录状态
    isLogin: false,
    //用户账号
    tel: "",
    // 用户余额
    surplusMoney: '',
    //购物车商品数量
    cartNumber: 0,
    //收藏商品数量
    collectNumber: 0,
    //优惠券数量
    coupNumber: 0,
    //结算数据
    settleData: null,
    // 结算类型
    settleType: null,
    //支付数据
    payData: null,
    //支付成功数据
    paySucData: null,
    //header显示
    showHeader: true,
    //搜素显示
    showSearch: true,
    //footer显示
    showFooter: true,
    //遮罩显示
    showMask: false,
    //token
    token: '',
    // wx信息
    wxInfo: {},
    // 搜索信息
    keyword: '',
    // 详情商品id
    goodId: '', 
    // 搜索条件图片
    searchImg: '',
    // 搜索条件领标
    label: '',
    // 限制金额
    recharge: '',
    // 监听搜索
    sFlag: false,
    // 领标
    labelId: ''
  },
  mutations: {
    // 保存搜索信息
    saveKeyword(state, data) {
      state.keyword = data;
    },
    // 保存微信信息
    saveWxInfo(state, status) {
      state.wxInfo = status;
    },
    // 设置余额
    savesurplusMoney(state, data) {
      state.surplusMoney = data;
    },
    // 保存token
    saveToken(state, status) {
      state.token = status;
      window.localStorage.setItem('token', status);
    },
    //改变登录状态
    changeLogin(state, status) {
      state.isLogin = status;
    },
    //保存用户账号
    saveTel(state, tel) {
      state.tel = tel;
    },
    //保存购物车商品数量
    saveCartNumber(state, num) {
      state.cartNumber = num;
    },
    //保存收藏商品数量
    saveCollectNumber(state, num) {
      state.collectNumber = num;
    },
    // 保存优惠券数量
    saveCoupNumber(state, num) {
      state.coupNumber = num;
    },
    // 保存结算类型
    saveSettleType(state, type) {
      state.settleType = type;
    },
    //保存结算数据
    saveSettleData(state, data) {
      state.settleData = data;
    },
    //保存支付数据
    savePayData(state, data) {
      state.payData = data;
    },
    // 支付成功清除订单数据
    clearPayData(state) {
      state.payData = null;
    },
    //保存支付成功数据
    savePaySucData(state, data) {
      state.paySucData = data;
    },
    //改变header显示
    changeHeader(state) {
      state.showHeader = !state.showHeader;
    },
    //改变搜素显示
    changeSearch(state) {
      state.showSearch = !state.showSearch;
    },
    //改变footer显示
    changeFooter(state) {
      state.showFooter = !state.showFooter;
    },
    //改变遮罩显示
    changeMask(state) {
      state.showMask = !state.showMask;
      if (state.showMask) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    //修改优惠券数量
    changeCoupNumber(state, num) {
      state.coupNumber += num;
    },
    // 修改购物车数量
    changeCartNumber(state, num) {
      state.cartNumber += num;
    },
    // 修改收藏商品数量
    changeCollectNumber(state, num) {
      state.collectNumber += num;
    },
    // 保存商品id
    saveGoodId(state, gid) {
      state.goodId = gid
    },
    // 保存搜索条件图片
    saveSearchImg(state, img) {
      state.searchImg = img
    },
    // 保存搜索条件领标
    saveSearchLabel(state, label) {
      state.label = label;
    },
    // 限制充值金额
    saveRecharge(state, money) {
      state.recharge = money
    },
    // 监听搜索
    changeSearchFlag(state) {
      state.sFlag = !state.sFlag;
    },
    // 保存领标
    saveLabelId(state, labelId) {
      state.labelId = labelId;
    }
  },
  actions: {},
  modules: {},
});
