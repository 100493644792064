<template>
  <div class="bg">

  </div>
</template>


<script>
// import { mapState } from 'vuex'
import { scanBinding } from '@/http/api';
export default {
  data() {
    return {
      code: ''
    }
  },
  created() {
    this.code = this.$route.query.code ? this.$route.query.code : '';
    console.log(this.code);
    this.scanBinding();
  },
  // mounted() {
  //   this.scanBinding();
  // },
  computed: {
    // ...mapState(["tel"])
  },
  methods: {
     scanBinding() {
       scanBinding({code: this.code}).then(res => {
         const { statusCode, msg } = res;
         if(statusCode === 200) {
           this.$message.success(msg);
         }else {
           this.$message.error(msg);
         }
         this.$router.replace(`/user/setting`);
       })
     }
  }
}
</script>

<style scoped>

</style>