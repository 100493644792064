<template>
  <div class="after-sale">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "afterSale",
};
</script>

<style>
.after-sale .input-box {
  padding-bottom: 0;
}
.after-sale .input-box input {
  width: 200px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 5px 12px;
}
.after-sale table td:nth-child(3) p {
  text-align: left;
  padding-left: 20px;
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.after-sale table td a:first-child {
  display: block;
  width: 72px;
  height: 28px;
  line-height: 28px;
  background: #f9f9f9;
  border: 1px solid #dddddd;
  margin: 0 auto;
  margin-bottom: 8px;
  border-radius: 2px;
}
</style>