<template>
  <div class="my-add">
    <c-nav :nav="navList"></c-nav>
    <p class="card p-20 mb-20">收货地址</p>
    <div class="card p-20 t-r">
      <a href="javascript:;" class="bg-r-btn mb-20" @click="changeNewAdd"
        >新建地址</a
      >
      <div class="table-box">
        <table>
          <thead>
            <th>
              <p>收货人</p>
            </th>
            <th>
              <p>所在地区</p>
            </th>
            <th>
              <p>详细地址</p>
            </th>
            <th>
              <p>邮编</p>
            </th>
            <th>
              <p>电话/手机</p>
            </th>
            <th>
              <p>操作</p>
            </th>
            <th>
              <p>默认</p>
            </th>
          </thead>
          <tbody>
            <tr class="bb1" v-for="(value, index) in addList" :key="index">
              <td>{{ value.name }}</td>
              <td>{{ value.county }}{{ value.province }}{{ value.city }}{{ value.district }}</td>
              <td>{{ value.detail }}</td>
              <td>{{ value.code }}</td>
              <td>{{ value.phone }}</td>
              <td>
                <a href="javascript:;" @click="changeAdd(index)">修改</a>
                <a href="javascript:;" @click="deletAdd(index)">删除</a>
              </td>
              <td>
                <a
                  href="javascript:;"
                  v-if="value.is_default == 0"
                  @click="defaultAdd(index)"
                >
                  设为默认
                </a>
                <span v-if="value.is_default == 1" class="c-red"> 默认地址 </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-show="addList.length == 0" class="t-c mt-16 fs-14">尚未添加地址</p>
      </div>
    </div>
    <div class="new-alert card" v-show="newAddShow">
      <div class="n-a-header">
        <span>新建地址</span>
        <a href="javascript:;" class="x_line-close" @click="changeNewAdd"
          ><i></i
        ></a>
      </div>
      <div class="n-a-body bb1">
        <div class="flex">
          <span class="required">收货人姓名：</span>
          <input type="text" v-model="name" placeholder="请输入收货人姓名" />
        </div>
        <div class="flex">
          <span class="required">手机号码：</span>
          <input type="tel" v-model="tel" placeholder="请输入手机号码" />
        </div>
        <div class="flex">
          <span class="required">地址信息：</span>
          <el-cascader
            v-model="addressValue"
            :options="options"
            @change="handleChange">
          </el-cascader>
          <!-- <div class="select-box">
            <div class="select">
              <a
                href="javascript:;"
                class="header w_down"
                @click="changeIsPro(true)"
              >
                <span>{{ province == null ? "请选择省" : province.name }}</span>
                <i></i>
              </a>
              <div
                class="option"
                :style="{ height: isProvince ? '296px' : '0px' }"
              >
                <a
                  href="javascript:;"
                  v-for="value in provinceList"
                  :key="value.id"
                  @click="choosePro(value.id, value.name)"
                >
                  {{ value.name }}
                </a>
              </div>
            </div>
            <div class="select">
              <a
                href="javascript:;"
                class="header w_down"
                @click="changeIsCity(true)"
              >
                <span>{{ city == null ? "请选择市" : city.name }}</span> <i></i>
              </a>
              <div class="option" :style="{ height: cityHeight }">
                <a
                  href="javascript:;"
                  v-for="value in cityList"
                  :key="value.id"
                  @click="chooseCity(value.id, value.name)"
                >
                  {{ value.name }}
                </a>
                <span v-show="cityList.length == 0" style="font-size: 12px">
                  请先选择省
                </span>
              </div>
            </div>
            <div class="select">
              <a
                href="javascript:;"
                class="header w_down"
                @click="changeIsCoun(true)"
              >
                <span>{{ country == null ? "请选择区" : country.name }}</span>
                <i></i>
              </a>
              <div class="option" :style="{ height: counHeight }">
                <a
                  href="javascript:;"
                  v-for="value in countryList"
                  :key="value.id"
                  @click="chooseCoun(value.id, value.name)"
                >
                  {{ value.name }}
                </a>
                <span
                  v-show="countryList.length == 0 && hasCoun"
                  style="font-size: 12px"
                >
                  请先选择市
                </span>
                <span v-show="!hasCoun" style="font-size: 12px">
                  暂无下属区域信息，请手动输入详细地址
                </span>
              </div>
            </div>
          </div> -->
        </div>
        <div class="flex">
          <span class="required">详细地址：</span>
          <input
            type="text"
            v-model="address"
            placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"
          />
        </div>
        <div class="flex">
          <span>邮政编码：</span>
          <input type="text" v-model="postcode" placeholder="请填写邮政编码" />
        </div>
      </div>
      <div class="n-a-footer">
        <a href="javascript:;" class="bg-w-btn mr-8" @click="changeNewAdd">
          取 消
        </a>
        <a href="javascript:;" class="bg-r-btn" @click="newAdd">确 定</a>
      </div>
    </div>
  </div>
</template>

<script>
import { addressData, addAddress, saveAddress, delAddress, saveDefault } from '@/http/api';
import { options } from '../../assets/location.js';
export default {
  name: "myAdd",
  data() {
    return {
      navList: [
        {
          path: `/user/my-add`,
          text: "收货地址",
        },
      ],
      //地址列表
      addList: [],
      //--------------------新增地址------------
      //新增地址显示状态
      newAddShow: false,
      // 地址id
      addressId: '',
      //收货人姓名
      name: "",
      //手机号
      tel: "",
      //选择的省
      province: null,
      //选择的市
      city: null,
      //选择的区
      country: null,
      //详细地址
      address: "",
      //邮政编码
      postcode: "",
      //选择省
      // isProvince: false,
      //省列表
      // provinceList: [],
      //选择市
      // isCity: false,
      //市列表
      // cityList: [],
      //选择区
      // isCountry: false,
      //区列表
      // countryList: [],
      //是否有区
      // hasCoun: false,
      //新建还是修改地址 0是新建 1是修改
      addType: 0,
      //选择修改的地址索引
      addId: 0,

      addressValue: null,
      options: null
    };
  },
  computed: {
  },
  methods: {
    //初始化数据
    start() {
      this.name = "";
      this.tel = "";
      this.province = null;
      this.city = null;
      this.country = null;
      this.address = "";
      this.postcode = "";
      this.addressValue = null;
    },
    //---------------------------获取数据-----------------
    // 获取收货地址列表
    getAddressData() {
      addressData().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.addList = data;
        }
      })
    },
    // 新增收货地址
    addAddress() {
      let params = {
        name: this.name,
        phone: this.tel,
        province: this.province,
        city: this.city, 
        district: this.country,
        detail: this.address,
        code: this.postcode,
      }
      addAddress(params).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('添加成功');
          this.getAddressData();
          this.changeNewAdd();
        }else {
          this.$message.error('添加失败');
        }
      })
    },
    // 保存地址
    saveAddress() {
      let params = {
        addressId: this.addressId,
        name: this.name,
        phone: this.tel,
        province: this.province,
        city: this.city, 
        district: this.country,
        detail: this.address,
        code: this.postcode,
      }
      saveAddress(params).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.getAddressData();
          this.changeNewAdd();
        }else {
          this.$message.error(msg);
        }
      })
    },
    // 删除地址
    delAddress() {
      delAddress({addressId: this.addressId}).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.addressId = '';
          this.getAddressData();
        }else {
          this.$message.error(msg);
        }
      })
    },
    // 设置默认地址
    saveDefault() {
      saveDefault({addressId: this.addressId}).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.addressId = '';
          this.getAddressData();
        }else {
          this.$message.error(msg);
        }
      })
    },
    //-------------------------------------------

    // 监听选择地址
    handleChange() {
      console.log(this.addressValue);
      this.province = this.addressValue[0];
      this.city = this.addressValue[1];
      this.country = this.addressValue[2];
    },
    //改变新建地址显示状态
    changeNewAdd() {
      this.newAddShow = !this.newAddShow;
      this.start();
      this.addType = 0;
      this.$store.commit("changeMask");
    },
    //新建地址
    newAdd() {
      if (this.name == "") {
        //检查是否输入姓名
        this.$message.warning("请输入姓名");
      }else if(this.addressValue == null) {
        this.$message.warning("请选择地址信息");
      }else if (this.address == "") {
        //是否输入详细地址
        this.$message.warning("请输入详细地址");
      } else if (this.testTel(this.tel)) {
        //以上信息全输入了 并且通过手机号格式测试
        switch (this.addType) {
          //新增地址
          case 0: {
            this.addAddress();
            break;
          }
          //修改地址
          case 1: {
            this.saveAddress();
            break;
          }
        }
      }
    },
    //点击修改地址
    changeAdd(index) {
      this.addId = index;
      const data = this.addList[this.addId];
      console.log(data);
      this.changeNewAdd();
      this.addType = 1;
      // 设置数据
      this.addressId = data.hashid;
      this.name = data.name;
      this.tel = data.phone;
      this.addressValue = [data.province, data.city, data.district];
      this.province = data.province;
      this.city = data.city;
      this.country = data.district;
      this.address = data.detail;
      this.postcode = data.code ? data.code : '';
    },
    //删除地址
    deletAdd(index) {
      this.addressId = this.addList[index].hashid;
      this.$confirm('是否删除该地址信息','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delAddress();
      }).catch(() => {})
    },
    //设为默认
    defaultAdd(index) {
      this.addressId = this.addList[index].hashid;
      this.saveDefault();
    },
  },
  created() {
    //获取地址列表
    this.getAddressData();
    this.options = options;
  },
};
</script>

<style>
.user .select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user .select-box .select {
  flex-shrink: 0;
  width: 110px;
}
.user .select-box .select:not(:last-child) {
  margin-right: 8px;
}
.my-add a:hover {
  color: #f5222d;
}
</style>