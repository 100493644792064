<template>
  <div class="pay-suc c-box">
    <div class="flex f-a-c mb-24">
      <router-link to="/" class="logo-box mr-24">
        <img src="../../images/LOGO.png" alt="印爆网" id="logo" />
      </router-link>
      <h2>支付中心</h2>
    </div>
    <div class="card">
      <div class="pay-suc-header">
        <span class="w_line-check_white"><i></i></span>
        <h2 class="mb-8" v-if="paySucData.isRecharge">充值成功</h2>
        <h2 class="mb-8" v-else>支付成功，我们尽快为您处理</h2>
        <p class="fs-12 mb-8">订单号：{{ orderData.order ? orderData.order.sn : ''}}</p>
        <p class="fs-14" v-show="orderData.pay_type === 3">余额支付：{{ orderData.user ? orderData.user.surplus_money : '' }}元</p>
        <div class="psh-btn-box mt-24">
          <router-link class="bg-r-btn mr-8" to="/">继续逛逛</router-link>
          <router-link class="bg-w-btn" :to="'/user/follow'" v-if="!paySucData.isRecharge">
            订单详情
          </router-link>
        </div>
      </div>
      <p class="mb-16">可能还感兴趣</p>
      <div class="flex gms-box">
        <router-link
          v-for="value in goodsList"
          :to="'/house/' + value.hashid"
          class="mr-20"
          :key="value.id"
        >
          <div class="gmsb-img-box mb-8">
            <img
              :src="value.image"
              alt="可能感兴趣"
              v-if="value.image"
            />
          </div>
          <p class="ellipsis mb-8">{{ value.full_name }}</p>
          <p>
            <span class="fs-12">￥</span>
            <span class="mr-8">{{ value.price }}</span>
            <span class="fs-12">销量{{ value.sales_num }}件</span>
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getPayIndex, randomProductList, rechargeIndex } from '@/http/api';
export default {
  name: "paySuccess",
  data() {
    return {
      orderData: {},
      goodsList: [],
      orderId: '',
    };
  },
  created() {
    this.orderId = this.paySucData.orderId
    this.randomProductList();
  },
  watch: {
   orderId: function() {
     if(this.paySucData.isRecharge) {
      this.rechargeIndex();
    }else {
      this.getPayIndex();
    }   
   }
  },
  computed: {
    ...mapState(["paySucData"]),
    //用户账号
    tel() {
      return this.$store.state.tel;
    },
  },
  methods: {
    // 获取订单信息
    getPayIndex() {
      getPayIndex({orderId: this.orderId}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.orderData = data;
          this.$store.commit('savesurplusMoney', data.user.surplus_money);
        }
      })
    },
    // 获取充值订单信息
    rechargeIndex() {
      rechargeIndex({orderId: this.orderId}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.orderData = data;
          this.$store.commit('savesurplusMoney', data.user.surplus_money);
        }
      })
    },
    // 获取产品列表
    randomProductList() {
      randomProductList({limit: 6}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.goodsList = data;
        }
      })
    }
  },
  mounted() {
    this.$store.commit("changeSearch");
  },
  destroyed() {
    this.$store.commit("changeSearch");
  },
};
</script>

<style>
.pay-suc {
  padding-top: 36px;
  margin-bottom: 80px;
}
.pay-suc .card {
  padding: 52px 55px;
}
.pay-suc-header {
  text-align: center;
}
.pay-suc-header .w_line-check_white {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #52c41a;
  margin-bottom: 28px;
}
.pay-suc-header .w_line-check_white i {
  width: 46px;
  height: 46px;
}
.pay-suc-header h2 {
  font-weight: 400;
}
.pay-suc-header .fs-14 {
  color: rgba(0, 0, 0, 0.45);
}
.pay-suc-header a {
  width: 88px;
  height: 32px;
  line-height: 30px;
  font-size: 14px;
}
.pay-suc .card > p {
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.pay-suc .gms-box {
  justify-content: space-between;
}
</style>