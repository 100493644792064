<template>
  <div class="login">
    <router-link to="/">
      <img src="../../images/LOGO.png" alt="印爆网" />
    </router-link>
    <router-view :code="code" :tel="tel" @initData="handleInit">
      <template v-slot:tel>
        <div class="input-box">
          <!-- <i class="iconfont mr-8">&#xe66d;</i> -->
          <input type="tel" v-model="tel" placeholder="请输入手机号" />
        </div>
      </template>
      <template v-slot:code>
        <div class="code-box">
          <div class="input-box">
            <!-- <i class="iconfont mr-8">&#xe661;</i> -->
            <input type="text" v-model="code" placeholder="请输入手机验证码" />
          </div>
          <a href="javascript:;" class="has-get-code" v-if="hasGetCode">
            {{ time }}
          </a>
          <a href="javascript:;" class="get-code" @click="getTelCode" v-else>
            获取验证码
          </a>
        </div>
      </template>
    </router-view>
  </div>
</template>

<script>
import { sendCode } from '@/http/api'
export default {
  name: "login",
  data() {
    return {
      //手机号
      tel: "",
      //验证码
      code: "",
      //是否发送验证码
      hasGetCode: false,
      //倒计时
      time: 60,
      //计数器
      timer: null,
    };
  },
  methods: {
    // 初始化数据
    handleInit() {
      this.tel = '';
      this.code = '';
    },
    //显示遮罩
    changeMask() {
      this.$emit("change-mask");
    },
    //获取手机验证码
    getTelCode() {
      //检查输入的手机号格式是否正确
      if (this.testTel(this.tel)) {
        sendCode({phone: this.tel}).then(res => {
          const { statusCode, msg } = res;
          if(statusCode === 200) {
            this.hasGetCode = true;
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        }).catch(err => {
          console.log(err);
        })
        
        // this.axios("serve/sendCode?phone=" + this.tel).then(
        //   res => {
        //     console.log(res);
        //     const {statusCode, msg} = res.data;
        //     if(statusCode === 200) {
        //       this.hasGetCode = true;
        //     } else {
        //       alert(msg);
        //     }
        //   }
          // (res) => {
          //   //判断服务器返回状态是否为成功
          //   if (res.data.code == 0) {
          //     //发送成功
          //     this.hasGetCode = true;
          //   } else {
          //     //发送失败
          //     console.log(res);
          //     alert(res.data.msg);
          //   }
          // }
        // );
      }
    },
  },
  created() {
    //隐藏header footer
    this.$store.commit("changeHeader");
    this.$store.commit("changeFooter");
  },
  beforeDestroy() {
    //显示header footer
    this.$store.commit("changeHeader");
    this.$store.commit("changeFooter");
  },
  watch: {
    //监听是否发送过验证码
    hasGetCode: function (newValue) {
      //如果已发送则开启倒计时
      if (newValue) {
        this.timer = setInterval(() => {
          this.time--;
          //倒计时结束才能再次获取验证码
          if (this.time == 0) {
            clearInterval(this.timer);
            this.hasGetCode = false;
            this.time = 60;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style>
.login {
  width: 100vw;
  height: 100vh;
  /* background: url("../../images/login-bg.svg") no-repeat; */
  background: #fff;
  background-size: contain;
  padding-top: 102px;
  text-align: center;
}
.login i {
  height: 16px;
}
</style>