<template>
  <div style="text-align:center; height:600px;">
    <div style="padding-top: 150px;"> 暂无数据~</div>
   
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>