<template>
  <div class="over-pay">
    <a href="javascript:;" class="bg-r-btn" @click="overpay">立即支付</a>
  </div>
</template>

<script>
export default {
  name: "overPay",
  methods: {
    //触发余额支付事件
    overpay() {
      this.$emit("overpay");
    },
  },
};
</script>

<style>
.over-pay .bg-r-btn {
  width: 216px;
  height: 48px;
  line-height: 46px;
}
</style>