<template>
  <div class="house">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "house",
};
</script>

<style>
.house {
  background: #fff;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 80px;
  overflow: auto;
}
</style>