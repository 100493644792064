import http from '@/http/axios'
/* 首页 */
const getIndexData = function (params) {
  const url = 'index/index';
  return http(url, params, 'get');
}
// 协议列表
const agreementData = function (params) {
  const url = 'index/agreementData';
  return http(url, params, 'get');
}
// 协议详情
const agreementDetails = function (params) {
  const url = 'index/agreementDetails';
  return http(url, params, 'get');
}
// 配置信息
const configData = function (params) {
  const url = 'index/configData';
  return http(url, params, 'get');
}

/* 服饰仓库 */
// 获取分类
const productCat = function (params) {
  const url = 'product/productCat';
  return http(url, params, 'get');
}
// 获取商品列表
const productList = function (params) {
  const url = 'product/productList';
  return http(url, params, 'get');
}
// 获取商品详情
const productDetails = function (params) {
  const url = 'product/productDetails';
  return http(url, params, 'get');
}
// 获取榜单列表
const productRanking = function (params) {
  const url = 'product/productRanking';
  return http(url, params, 'get');
}
// 随机产品列表数据
const randomProductList = function (params) {
  const url = 'product/randomProductList';
  return http(url, params, 'get');
}
// 收藏产品
const collection = function (params) {
  const url = 'product/collection';
  return http(url, params, 'post');
}
// 产品列表数据-上传图片搜索
const imageSearch = function (params) {
  const url = 'index/imageSearch';
  return http(url, params, 'post');
}

/* 登录&注册 */
// 发送验证码
const sendCode = function(params) {
  const url = 'serve/sendCode';
  return http(url, params, 'get');
}
// 登录注册
const auth = function (params) {
  const url = 'serve/auth';
  return http(url, params, 'post');
}
// 判断微信是否注册
const getToken = function (params) {
  const url = 'serve/getToken';
  return http(url, params, 'post');
}

/* 优惠券相关 */
// 获取优惠券列表
const getCouponList = function (params) {
  const url = 'coupon/index';
  return http(url, params, 'get');
}
// 领取优惠券
const receiveCoupon = function (params) {
  const url = 'coupon/receive';
  return http(url, params, 'post');
}


/* 活动专场相关 */
// 活动列表
const activityList = function (params) {
  const url = 'activity/index';
  return http(url, params, 'get');
}


/* 排行榜相关 */
// 销量排行榜
const salesRank = function (params) {
  const url = 'rankingList/salesList';
  return http(url, params, 'get');
}
// 热度排行榜
const browseRank = function (params) {
  const url = 'rankingList/browseList';
  return http(url, params, 'get');
}

/* 新闻相关 */
// 首条数据
const firstData = function (params) {
  const url = 'news/firstData';
  return http(url, params, 'get');
}
// 新闻列表数据
const getNewsList = function (params) {
  const url = 'news/index';
  return http(url, params, 'get');
}
// 新闻详情
const getNewsDetail = function (params) {
  const url = 'news/details';
  return http(url, params, 'get');
}

/* 工作台相关 */
// 我的工作台
const myInfo = function (params) {
  const url = 'my/index';
  return http(url, params, 'get');
}
// 跟单工作台
const documentary = function (params) {
  const url = 'my/documentary';
  return http(url, params, 'get');
}
// 订单详情
const orderDetails = function (params) {
  const url = 'order/orderDetails';
  return http(url, params, 'get');
}
// 确认收货
const confirmReceipt = function (params) {
  const url = 'order/confirmReceipt';
  return http(url, params, 'post');
}
// 申请售后页面数据
const reOrderIndex = function (params) {
  const url = 'return_order/index';
  return http(url, params, 'get');
}
// 创建售后订单数据
const createReOrder = function (params) {
  const url = 'return_order/createOrder';
  return http(url, params, 'post');
}
// 售后工作台
const afterOrderList = function (params) {
  const url = 'return_order/orderList';
  return http(url, params, 'get');
}
// 售后订单详情
const reOrderDetail = function (params) {
  const url = 'return_order/orderDetails';
  return http(url, params, 'get');
}
// 撤销售后申请
const revoke = function (params) {
  const url = 'return_order/revoke';
  return http(url, params, 'post');
}
// 我的优惠券
const myCoupon = function (params) {
  const url = 'my/couponData';
  return http(url, params, 'get');
}
// 保存寄回快递单号
const saveDelivery = function (params) {
  const url = 'return_order/saveDelivery';
  return http(url, params, 'post');
}
// 资金流水
const wallet = function (params) {
  const url = 'my/wallet';
  return http(url, params, 'get');
}
// 收藏产品列表
const collectionProduct = function (params) {
  const url = 'my/collectionProduct';
  return http(url, params, 'get');
}
// 取消收藏
const delProduct = function (params) {
  const url = 'my/delProduct';
  return http(url, params, 'post');
}
// 收货地址
const addressData = function (params) {
  const url = 'my/addressData';
  return http(url, params, 'get');
}
// 新增收货地址
const addAddress = function (params) {
  const url = 'my/addAddress';
  return http(url, params, 'post');
}
// 保存收货地址
const saveAddress = function (params) {
  const url = 'my/saveAddress';
  return http(url, params, 'post');
}
// 删除收货地址
const delAddress = function (params) {
  const url = 'my/delAddress';
  return http(url, params, 'post');
}
// 设置默认地址
const saveDefault = function (params) {
  const url = 'my/saveDefault';
  return http(url, params, 'post');
}
// 我的信息数据-基本设置
const getUserInfo = function (params) {
  const url = 'my/userInfo';
  return http(url, params, 'get');
}
// 修改信息-基础设置
const editInfo = function (params) {
  const url = 'my/editInfo';
  return http(url, params, 'post');
}
// 上传文件
const upload = function (params) {
  const url = 'admin/uploadQiniu';
  return http(url, params, 'post');
}
// 编辑账号密码
const editPassword = function (params) {
  const url = 'my/editPassword';
  return http(url, params, 'post');
}
// 修改手机号
const editPhone = function (params) {
  const url = 'my/editPhone';
  return http(url, params, 'post');
}
// 扫码绑定微信
const scanBinding = function (params) {
  const url = 'my/scanBinding';
  return http(url, params, 'post');
}
// 解绑微信
const unbound = function (params) {
  const url = 'my/unbound';
  return http(url, params, 'post');
}

/* 购物车相关 */
// 加入购物车
const addCart = function (params) {
  const url = 'shop_cart/addCart';
  return http(url, params, 'post');
}
// 购物车列表
const getCartList = function (params) {
  const url = 'shop_cart/list';
  return http(url, params, 'get');
}
// 修改购物车数量
const updateCartNum = function (params) {
  const url = 'shop_cart/updateCartNum';
  return http(url, params, 'post');
}
// 删除购物车订单
const delCartOrder = function (params) {
  const url = 'shop_cart/delCartOrder';
  return http(url, params, 'post');
}

/* 订单相关 */
// 确认订单页面信息
const confirmData = function (params) {
  const url = 'order/confirmData';
  return http(url, params, 'post');
}
// 创建订单
const createOrder = function (params) {
  const url = 'order/createOrder';
  return http(url, params, 'post');
}
// 取消订单
const delOrder = function (params) {
  const url = 'order/delOrder';
  return http(url, params, 'post');
}
// 创建充值订单
const createRechargeOrder = function (params) {
  const url = 'order/createRechargeOrder';
  return http(url, params, 'post');
}
// 计算运费
const getFreight = function (params) {
  const url = 'order/getFreight';
  return http(url, params, 'get');
}

/* 支付相关 */
// 支付页面数据
const getPayIndex = function (params) {
  const url = 'pay/index';
  return http(url, params, 'get');
}
// 充值支付页面数据
const rechargeIndex = function (params) {
  const url = 'pay/rechargeIndex';
  return http(url, params, 'get');
}
// 余额支付
const balancePay = function (params) {
  const url = 'pay/balancePay';
  return http(url, params, 'post');
}
// 调起支付(支付宝)
const zfbPay = function (params) {
  const url = 'pay/zfbPay';
  return http(url, params, 'post');
}
// 查询订单支付宝支付状态
const queryZfbPayStatus = function (params) {
  const url = 'pay/queryZfbPayStatus';
  return http(url, params, 'get');
}
// 调起充值支付宝支付
const zfbRechargePay = function (params) {
  const url = 'pay/zfbRechargePay';
  return http(url, params, 'post');
}
// 调起充值支付宝支付
const queryZfbRechargeStatus = function (params) {
  const url = 'pay/queryZfbRechargeStatus';
  return http(url, params, 'get');
}

// 调起支付(微信)
const wechatPay = function (params) {
  const url = 'pay/wxPay';
  return http(url, params, 'post');
}
// 调起充值微信支付
const wxRechargePay = function (params) {
  const url = 'pay/wxRechargePay';
  return http(url, params, 'post');
}
// 查询订单支付状态
const queryPayStatus = function (params) {
  const url = 'pay/queryPayStatus';
  return http(url, params, 'get');
}
// 查询充值订单支付状态
const queryRechargeStatus = function (params) {
  const url = 'pay/queryRechargeStatus';
  return http(url, params, 'get');
}

/* 领标中心 */
// 商品分类
const catDate = function (params) {
  const url = 'product/catDate';
  return http(url, params, 'get');
}
// 领标图片搜索-检索
const searchLogo = function (params) {
  const url = 'index/searchLogo';
  return http(url, params, 'post');
}
// 领标图片搜索-检索
const supplierLogo = function (params) {
  const url = 'product/supplierLogo';
  return http(url, params, 'get');
}


// 获取购物车数量&优惠券
const getCartNum = function (params) {
  const url = 'shop_cart/getCartNum';
  return http(url, params, 'get');
}
// 获取产品名称
const getProductName = function (params) {
  const url = 'product/getProductName';
  return http(url, params, 'get');
}


export {
  getIndexData,
  agreementData,
  agreementDetails,
  configData,
  sendCode,
  auth,
  getToken,
  productCat,
  productList,
  productDetails,
  productRanking,
  randomProductList,
  collection,
  imageSearch,
  getCouponList,
  receiveCoupon,
  myCoupon,
  activityList,
  salesRank,
  browseRank,
  firstData,
  getNewsList,
  getNewsDetail,
  myInfo,
  documentary,
  orderDetails,
  confirmReceipt,
  reOrderIndex,
  createReOrder,
  afterOrderList,
  reOrderDetail,
  revoke,
  saveDelivery,
  wallet,
  collectionProduct,
  delProduct,
  addressData,
  addAddress,
  saveAddress,
  delAddress,
  saveDefault,
  getUserInfo,
  editInfo,
  upload,
  editPassword,
  editPhone,
  scanBinding,
  unbound,
  addCart,
  getCartList,
  updateCartNum,
  delCartOrder,
  confirmData,
  createOrder,
  delOrder,
  createRechargeOrder,
  getFreight,
  getPayIndex,
  rechargeIndex,
  balancePay,
  zfbPay,
  queryZfbPayStatus,
  zfbRechargePay,
  queryZfbRechargeStatus,
  wechatPay,
  wxRechargePay,
  queryPayStatus,
  queryRechargeStatus,
  catDate,
  searchLogo,
  supplierLogo,
  getCartNum,
  getProductName
}