<template>
  <div class="newsDetail">
    <cNav :nav="navList" />
    <div class="detailBox">
      <div class="main">
        <div class="tagBox">
          <span class="bg_f5 fs-12 mr-12" v-for="(item,index) in newsDetail.label_name" :key="'tag'+index">
          {{item}}
          </span>
        </div>
        <div class="mt-20 fs-36 bold">{{newsDetail.title}}</div>
        <div class="mt-32 flex articleTop">
          <div>
            <img :src="author.headimgurl" alt="" class="avatar">
            <span class="fs-20 gray3 ml-12">{{author.name}}</span>
          </div>
          <div class="fs-14 c-6">阅读量：{{newsDetail.browse_num}}</div>
          <div class="fs-14 gray9">更新日期：{{newsDetail.created_at}}</div>
        </div>
        <div class="p-16 bg_f5 fs-16 mt-24 c-6">
          摘要：{{newsDetail.abstract}} 
        </div>
        <div class="mt-24" v-html="newsDetail.content"></div>
      </div>
    </div>
    <div class="c-box" style="margin-bottom: 75px;">
      <div class="gray3 fs-30 t-c" style="margin-top: 79px;">更多新闻</div>
      <div class="newsCon mt-40 flex">
        <div class="newd_conItem pointer" v-for="item in newsList" :key="item.id" @click="toNewsDetail(item.hashid)">
          <img :src="item.image" alt="">
          <div class="textCon">
            <div>
              <span class="bg_f5 fs-12 mr-12 itemTag" 
              v-for="(tag, index) in item.label_name" :key="'tags' + index">
                {{tag}}
              </span>
            </div>
            <div class="fs-20 gray3 mt-20 bold">{{item.title}}</div>
            <div class="fs-16 gray9 mt-8 abstract" :title="item.abstract">{{item.abstract}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail, getNewsList } from '@/http/api';
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          path: "/news",
          text: "新闻中心",
        },
        {
          id: 2,
          path: "newsDetail",
          text: "文章详情"
        }
      ],
      newsDetail: {},
      author: {},
      newsList: []
    }
  },
  created() {
    const { newsId } = this.$route.query;
    this.getNewsDetail(newsId);
    this.getNewsList();
    console.log(this.$route.query);
  },
  watch: {
    $route: function() {
      const { newsId } = this.$route.query;
      this.getNewsDetail(newsId);
    }
  },
  methods: {
    // 跳转详情
    toNewsDetail(id) {
      if(this.$route.query.newsId === id) {
        window.scrollTo(0,0);
      }else {
        this.$router.replace({path: '/news/newsDetail', query: {newsId: id}})
      }
      
    },
    // 获取新闻详情
    getNewsDetail(id) {
      getNewsDetail({newsId: id}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.newsDetail = data;
          this.author = data.author;
        }
      })
    },
    // 获取新闻列表
    getNewsList() {
      getNewsList({
        type_id: this.newActive === -1 ? '' : this.newActive
      }).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.newsList = data.data.splice(0, 3);
        }
      })
    }
  } 
}
</script>

<style>
.newsDetail {
  background: #fff;
}
.detailBox {
  padding-bottom: 78px;
  border-bottom: 1px solid #EEEEEE;
  background: #fff;
}
.newsDetail .main {
  width: 788px;
  margin: 40px auto;
}
.newsDetail .main img {
  width: 100%;
}

.newsDetail .tagBox span {
  padding: 4px 12px;
  border-radius: 4px;
}
.newsDetail .avatar {
  width: 60px!important;
  height: 60px;
  border-radius: 50%;
  vertical-align: middle;
}
.newsDetail .articleTop {
  padding-bottom: 16px;
  border-bottom: 1px solid #DDDDDD;
  justify-content: space-between;
  align-items: flex-end;
}
.newsDetail .newd_conItem {
  width: 376px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin-right: 26px;
}
.newsDetail .newd_conItem img {
  height: 236px;
  width: 100%;
}
.newsDetail .abstract {
  height: 45px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.newsDetail .textCon {
  padding: 20px 20px 40px;
}
.newsDetail .itemTag {
  border-radius: 4px;
  padding: 4px 12px;
}
.newsDetail .newsCon {
  padding-bottom: 5px;
}
</style>