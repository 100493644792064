<template>
  <div class="c-box" style="margin-bottom: 80px;">
    <div class="mt-24 flex" style="align-items: flex-start;">
      <div class="bg_white flex leftNav fs-18 gray6">
        <div v-for="(item, index) in navlist" :key="item.id" @click="changeNav(item.hashid, index)">
          <span :class="{active: aIndex === index}">{{item.title}}</span>  
        </div>
      </div>
      <div class="ml-20 bg_white flex1" style="padding:24px;">
        <div v-html="agreeData ? agreeData : ''"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { agreementData, agreementDetails } from '@/http/api';
export default {
  data() {
    return {
      navlist: [],
      agreeData: '',
      aIndex: 0
    }
  },
  created() {
    console.log(this.$route.query.index);
    this.aIndex = parseInt(this.$route.query.index);
    this.getAgree();
    
  },
  watch: {
    $route: function() {
      this.aIndex = parseInt(this.$route.query.index);
      this.getDetail(this.navlist[this.aIndex].hashid);
    }
  },
  methods: {
    changeNav(id, index) {
      this.aIndex = index;
      this.getDetail(id);
      document.title = this.navlist[index].title + '-印爆网';
    },
    // 获取协议列表
    getAgree() {
      agreementData().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.navlist = data;
          let id = this.navlist[this.aIndex].hashid;
          document.title = this.navlist[this.aIndex].title + '-印爆网';
          this.getDetail(id);
        }
      })
    },
    // 获取详情
    getDetail(id) {
      agreementDetails({agreementId: id}).then (res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.agreeData = data.content;
        }
      })
    }
  }
}
</script>

<style>
.leftNav {
  padding: 8px 0;
  flex-direction: column;
}
.leftNav div {
  cursor: pointer;
  padding: 16px 0;
  box-sizing: border-box;
}
.leftNav div span {
  padding: 0 63px 0 45px;
  box-sizing: border-box;
}
.leftNav .active {
  color: #333333;
  font-weight: bold;
  border-left: 4px solid #ED1C24;
}
</style>