<template>
  <div class="leadLabel t-c">
    <div class="c-box" style="overflow: visible;">
      <img src="../../images/label-title.png" alt="" class="logo">
      <div class="flex f-j-c mt-40 f-a-c">
        <div class="labelSelBox ">
          <div class="dis_block text">数量：<span class="c-red">{{total}}</span>个</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option value="" key="" label="全部"></el-option>
            <el-option
              v-for="item in options"
              :key="item.hashid"
              :label="item.name"
              :value="item.hashid">
            </el-option>
          </el-select>
        </div>
        
        <el-upload
          class="upload-demo"
          action="https://yinbaole.com/admin/uploadQiniu"
          :on-success="handleSuccess"
          >
          <div class="upBtn white fs-14 t-c pointer ml-16">上传领标识别</div>
        </el-upload>
        
        <div class="fs-12 col_46 ml-16 pointer seeTips" style="position: relative;">
          查看示例
          <div class="labelTips">
            <img src="../../images/label1.jpg" alt="">
            <div class="fs-12 black">上传的领标图片必须清晰可见，领标占图片面积至少60%或以上</div>
          </div>
        </div>
      </div>
      <div class="mt-20">
        <el-alert
          title="领标持续更新中...如未能找到匹配领标，可反馈至商城客服，谢谢！"
          type="warning"
          show-icon>
        </el-alert>
      </div>
      <div class=" mt-20 t-c list" id="listBox">
        <div v-if="labelList.length" class="flex labelList">
          <div class="labelItem bg_white mr-20 mb-20" v-for="item in labelList" :key="item.id">
            <div class="imgBox">
              <img :src="item.image + '?imageMogr2/thumbnail/204x'" alt="">
              <div class="labelMask f-j-c f-a-c pointer" @click="handlePreview(item.image)">
                <span class="white fs-16" >点击查看大图</span>
              </div>
            </div>
            <div class="mt-16 white bold fs-16 viewBtn pointer" @click="toHousePage(item.hashid)">查看款式</div>
          </div>
        </div>
        <div class="t-c" v-if="labelList.length === 0" style="padding:60px 0;">暂时没有哦~</div>
      </div>
      <div v-show="labelList.length">
        <pages
        :pageCount="page_count"
        :nowPage="nowPagination"
        @change-page="changePage"
        style="justify-content: flex-end;"
        >
          <a href="javascript:;">{{ goods_page_count }}条/页</a>
          <span>
            跳至
            <input
              type="text"
              name="to-pages"
              v-model="pagesTo"
              @keyup.enter="changePages(pagesTo)"
            />页
          </span>
        </pages>
      </div>
      
    </div>
    
    <transition name="mask">
      <div class="pmask" v-show="isShow">
        <div class="previewImg">
          <img src="../../images/pre-del.png" alt="" class="delBtn pointer" @click="handlePreview">
          <img :src="preImg" alt="">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { catDate, supplierLogo, searchLogo } from '@/http/api';
import pages from "../../components/pages";
import { mapState } from 'vuex'
export default {
  components: {
    pages
  },
  data() {
    return {
      options: [],
      value: '',
      isShow: false,
      index: 0,
      labelList: [],
      preImg: '',
      page: 1,
      total: 0,

      //当前页
      nowPagination: 0,
       //跳转指定分页
      pagesTo: "",
      //分页总页数
      page_count: 0,
      //每页条数
      goods_page_count: 0,
    }
  },
  computed: {
    ...mapState(["label"])
  },
  watch: {
    label: function(newVal) {
      if(newVal != '') {
        this.getLogoListByImg(1, this.label);
      }
    }
  },
  created() {
    this.getCatDate();
    if(this.label) {
      this.getLogoListByImg(1, this.label);
    }else {
      this.getLogoList(1);
    }
   
    
  },
  methods: {
    // 查看款式
    toHousePage(id) {
      this.$store.commit('saveLabelId', id);
      this.$router.push(`/house`);
    },
    //---------------分页----------------
    // 预览
    handlePreview(img) {
      this.isShow = !this.isShow;
      if(this.isShow) {
        this.preImg = img
        document.body.style.overflow='hidden';
      }else {
        document.body.style.overflow='auto';
      }
    },
    // 跳转至
    changePages(i) {
      i = parseInt(i);
      if(this.nowPagination === i) {
        return;
      }
      if (i > this.page_count || i <= 0) {
        return this.$message.error('你输入的分页不存在！');
      }
      this.nowPagination = i;
      this.getLogoList(this.nowPagination);
      window.scrollTo(0, 0);
      this.pagesTo = "";
    },
    // 切换分页
    changePage(page) {
      if(this.nowPagination === page) {
        return;
      }
      // this.nowPagination = page;
      window.scrollTo(0, 0);
      this.getLogoList(page);
    },
    // 监听上传成功
    handleSuccess(file) {
      console.log(file);
      this.$store.commit('saveSearchLabel', file.data.src);
    },
    topChangePage(type, page) {
      let pages = page;
      if(type == 0) {
        if(--pages >= 1) {
          this.getLogoList(pages);
        }else return;
      }else {
        if(++pages <= this.page_count) {
          this.getLogoList(pages);
        }else return;
      }
      
    },
    // --------------------请求数据 &api-----------------------
    // 获取分类数据
    getCatDate() {
      catDate().then(res => {
        const { statusCode,data } = res;
        if(statusCode === 200) {
          this.options = data;
        }
      })
    },
    // 获取领标列表
    getLogoList(page) {
      let params = {
        page: page,
        limit: 15
      }
      
      supplierLogo(params).then(res=> {
        const loading = this.openLoading('#listBox');
        const { statusCode,data } = res;
        if(statusCode === 200) {
          loading.close();
          this.labelList = data.data;
          this.nowPagination = data.current_page;
          this.page_count = data.last_page;
          this.goods_page_count = data.per_page;
          this.total = data.total;
        }else {
          loading.close();
        }
      }).catch(() => {
        // loading.close();
      })
    },
    // 图片检索 列表
    getLogoListByImg(page, img) {
      let params = {
        image: img,
        page: page,
        limit: 15
      }
      searchLogo(params).then(res => {
        const loading = this.openLoading('#listBox');
        const { statusCode, data } = res;
        if(statusCode === 200) {
          loading.close();
          this.labelList = data.data;
          this.nowPagination = data.current_page;
          this.page_count = data.last_page;
          this.goods_page_count = data.per_page;
        }else {
          loading.close();
        }
      })
    }
  }
}
</script>

<style>
.leadLabel {
  background: url('../../images/label-bg.png') no-repeat;
  background-size: 100%;
  margin-bottom: 103px;
}
.leadLabel .logo {
  margin-top: 43px;
  width: 290px;
  height: 94px;
}
.leadLabel .upBtn {
  padding: 11px 20px ;
  border-radius: 4px;
  background: #F5222D;
}
.leadLabel .upBtn:hover {
  background: #F08080;
}
.leadLabel .el-alert {
  padding: 10px 16px!important;
}
.leadLabel .el-alert__title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px!important;
}
.leadLabel .el-alert__closebtn {
  top: 16px;
}
.labelList {
  flex-wrap: wrap;
}
.labelItem {
  width: 220px;
  padding: 8px 8px 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
}
.labelItem:nth-child(5n) {
  margin-right: 0;
}
.labelItem .imgBox {
  position: relative;
  width: 204px;
  height: 204px;
}
.labelItem .imgBox:hover .labelMask{
  display: flex;
}
.labelItem img {
  width: 100%;
  height: 100%;
}
.viewBtn {
  display: inline-block;
  background: #F5222D;
  width: 128px;
  padding: 10px 0;
  border-radius: 21px;
}
.labelMask {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.pmask {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .7);
}
.previewImg {
  z-index: 9999;
  position: absolute;
  top: 211px;
  left: 50%;
  transform: translateX(-50%);
  width: 516px;
  height: 516px;
  background: #fff;
  padding: 8px;
  box-sizing: border-box;
}
.previewImg img {
  width: 100%;
  height: 100%;
}
.previewImg .delBtn {
  position: fixed;
  width: 48px;
  height: 48px;
  right: -78px;
  top: -71px;
}
.labelSelBox {
  background: #fff;
  border-radius: 4px;
}
.labelSelBox .text {
  padding: 11px 15px;
  font-size: 14px;
  color: #000;
}
.labelSelBox .el-input__inner {
  border: 0!important;
  text-align: right!important;
}
.labelTips {
  display: none;
  z-index: 1600;
  top: -255px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  padding: 15px;
  width: 210px;
  height: 255px;
  background: url('../../images/labelTips.png') no-repeat;
  background-size: 100% 100%;
}
.labelTips img {
  width: 100%;
}
.seeTips:hover .labelTips {
  display: block;
}
</style>