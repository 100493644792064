<template>
  <div class="pagination flex">
    <a
      href="javascript:;"
      class="w_turn-left flex f-a-c"
      @click="changePage(2, 'before')"
    >
      <i></i>
    </a>
    <a
      href="javascript:;"
      v-for="(value, index) in pageList"
      :key="index"
      :class="{ active: nowPage == value }"
      @click="changePage(1, value)"
    >
      {{ value }}
    </a>
    <a
      href="javascript:;"
      class="w_turn-right flex f-a-c"
      @click="changePage(2, 'next')"
    >
      <i></i>
    </a>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "pages",
  props: {
    //总页数
    pageCount: {
      type: Number,
    },
    //当前页数
    nowPage: {
      type: Number,
    },
  },
  computed: {
    //渲染的页码列表
    pageList() {
      let arr = [];
      if (this.pageCount < 5) {
        for (let i = 0; i < this.pageCount; i++) {
          arr.push(i + 1);
        }
      } else if (this.nowPage < 5) {
        arr = [1, 2, 3, 4, 5, "...", this.pageCount];
      } else if (this.nowPage >= 5) {
        if (this.pageCount - this.nowPage > 2) {
          arr = [
            1,
            "...",
            this.nowPage - 2,
            this.nowPage - 1,
            this.nowPage,
            this.nowPage + 1,
            this.nowPage + 2,
            this.pageCount,
          ];
        } else {
          arr = [
            1,
            "...",
            this.pageCount - 4,
            this.pageCount - 3,
            this.pageCount - 2,
            this.pageCount - 1,
            this.pageCount,
          ];
        }

        if (arr[arr.length - 2] <= this.pageCount - 2) {
          arr.splice(arr.length - 1, 0, "...");
        }
      }
      return arr;
    },
  },
  methods: {
    //切换分页事件
    changePage(type, index) {
      //点击...则不触发切换分页事件
      if (index == "...") return false;
      switch (type) {
        //type为1时 正常切换分页
        case 1: {
          this.$emit("change-page", index);
          break;
        }
        //type为2时 切换上下页
        case 2: {
          let i = this.nowPage;
          if (index == "before") {
            //上一页
            if (i > 1) {
              i--;
            }
          } else {
            //下一页
            if (i < this.pageCount) {
              i++;
            }
          }
          this.$emit("change-page", i);
          break;
        }
      }
    },
  },
};
</script>

<style>
</style>