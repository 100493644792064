<template>
  <div class="flex sideNav">
    <div class="navItem">
      <img src="../images/side_icon1.png" alt="">
      <div class="navTitle">在线客服</div>
    </div>
    <div class="navItem">
      <img src="../images/side_icon2.png" alt="">
      <div class="navTitle">QQ咨询</div>
    </div>
    <div class="navItem wxItem">
      <img src="../images/side_icon3.png" alt="">
      <div class="navTitle">微信咨询</div>
      <img src="../images/wxcode.png" alt="" class="wxImg">
    </div>
    <div class="navItem" @click="backTop" v-if="btnFlag">
      <img src="../images/side_icon4.png" alt="">
      <div class="navTitle">回到顶部</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnFlag: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollTop)
  },
  methods: {
    // 回到顶部
    backTop() {
      let timer = setInterval(() => {
        let ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = this.scrollTop + ispeed
        if(this.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16);
    },
    // 控制按钮显示
    scrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
      if(this.scrollTop > 100) {
        this.btnFlag = true
      }else {
        this.btnFlag = false
      }
    }
  }
}
</script>

<style>
.sideNav {
  z-index: 99999;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: column;
  
  text-align: center;
}
.navItem {
  position: relative;
  background: rgba(0, 0, 0, 0.65);
  margin-top: 8px;
  width: 48px;
  padding: 4px 8px 6px;
  cursor: pointer;
}
.navTitle {
  font-size: 14px;
  display: inline-block;
  margin: 0 auto;
  width: 32px;
  color: rgba(255, 255, 255, 0.85);
}
.wxImg {
  display: none;
  position: absolute;
  left: -136px;
  top: -28px;
}
.wxItem:hover .wxImg {
  display: block;
}
</style>