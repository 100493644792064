import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { getProductName } from "@/http/api";

import Home from '../views/home.vue';
import House from '../views/house/house.vue';
import HouseDefault from '../views/house/houseDefault.vue';
import Goods from '../views/goods.vue';
import Coupon from '../views/coupon';
import Activity from '../views/activity';
import Rank from '../views/rank/rank';
import News from '../views/news/news';
import NewsDefault from '../views/news/newsDefault';
import LeadLabel from '../views/leadLabel/leadLabel';
import SupplyChain from '../views/supplyChain/supplyChain';

import ShoppingCart from '../views/shoppingCart';
import Settle from '../views/settle';
import Pay from '../views/pay/pay';
import OverPay from '../views/pay/overPay';
import Alipay from '../views/pay/alipay';
import WechatPay from '../views/pay/wechatpay';
import PaySuccess from '../views/pay/paySuccess';

import User from'../views/user/user';
import My from'../views/user/my';
import Follow from'../views/user/follow/follow';
import FollowDefault from'../views/user/follow/followDefault';
import FollowItem from'../views/user/follow/followItem';
import ApplySale from'../views/user/follow/applyAfterSale';
import AfterSale from'../views/user/afterSale/afterSale';
import AfterSaleDefault from'../views/user/afterSale/afterSaleDefault';
import AfterSaleItem from'../views/user/afterSale/afterSaleItem';
import MyCoupon from'../views/user/myCoupon';
import MyFlow from'../views/user/myFlow/myFlow';
import MyFlowDefault from'../views/user/myFlow/myFlowDefault';
import Recharge from'../views/user/myFlow/recharge';
import MyStar from'../views/user/myStar';
import MyAdd from'../views/user/myAdd';
import Setting from'../views/user/setting';

import Login from '../views/login/login';
import LoginDefault from '../views/login/loginDefault';
import Forgetpwd from '../views/login/forgetpwd';
import Reg from '../views/login/reg';
import LoginSuccess from '../views/login/loginSuccess';
import NewsDetail from '../views/news/newsDetail';
import Wechat from '../views/wechat/code';
import Agreement from '../views/agreement/agreement';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

//检查是否已登录
function testIsLogin(next) {
  let token = window.localStorage.getItem('token');
  if (token) {
    next();
  } else {
    Vue.prototype.$message.error('尚未登录');
    setTimeout(() => {
      router.push("/login");
    }, 1000);
  }
}

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: '印爆网-基础服饰批发平台-让广告衫印爆了！',
      content: {
        keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发、吉尔丹批发、公司文化衫、班服、工服定做、T恤定制、莫代尔广告衫',
        description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
      }
    }
  },
  {
    path: '/agree',
    component: Agreement
  },
  {
    path: "/login",
    component: Login,
    children: [
      {
        path: "",
        component: LoginDefault,
        meta: {
          title: '登录注册-印爆网让广告衫印爆了！'
        }
      },
      {
        path: "forgetpwd",
        component: Forgetpwd,
      },
      {
        path: "reg",
        component: Reg,
      },
    ],
  },
  {
    path: "/loginSuccess",
    component: LoginSuccess,
    meta: {
      title: '登录注册-印爆网让广告衫印爆了！',
      content: {
        keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发',
        description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
      }
    }
  },
  {
    path: "/house",
    
    component: House,
    children: [
      {
        path: "",
        name: 'house',
        component: HouseDefault,
        meta: {
          title: '胚杉服饰批发全球云仓-印爆网',
          content: {
            keywords: '印爆网、胚杉批发、空白衫批发、文化衫批发、polo定制、班服定制、短袖定制logo、纯棉T恤、工厂批发',
            description: '基础服饰批发全球云仓中心，印爆网广州设中心仓，全国各地设有分仓，国外设有海外仓，印爆胚杉批发平台'
          }
        },
        //允许带参跳转
        props: (route) => ({ query: route.query})
      },
      {
        path: "*",
        meta: {
          good: true
        },
        component: Goods,
      },
    ],
  },
  {
    path: "/coupon",
    component: Coupon,
    meta: {
      title: '优惠券-印爆网让广告衫印爆了',
      content: {
        keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发',
        description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
      }
    }
  },
  {
    path: "/activity",
    component: Activity,
    meta: {
      title: '活动专场-印爆网让广告衫印爆了！',
      content: {
        keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发',
        description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
      }
    }
  },
  {
    path: "/news",
    component: News,
    children: [
      {
        path: "",
        component: NewsDefault,
        meta: {
          title: '新闻中心-印爆网让广告衫印爆了！',
          content: {
            keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发',
            description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
          }
        }
      },
      {
        path: "newsDetail",
        component: NewsDetail
      }
    ],
  },
  {
    path: "/rank",
    component: Rank,
    meta: {
      title: '排行榜-印爆网让广告衫印爆了！',
      content: {
        keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发',
        description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
      }
    }
  },
  {
    path: "/shopping-cart",
    component: ShoppingCart,
    //检查登录状态 已登录则通过 未登录则跳至登录页面
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      testIsLogin(next);
    },
  },
  {
    path: "/settle",
    component: Settle,
    //检查是否是有保存结算信息
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (store.state.settleData != null) {
        testIsLogin(next);
      } else {
        router.push(from.path);
      }
    },
  },
  {
    path: "/pay",
    component: Pay,
    redirect: '/pay/over-pay',
    children: [
      {
        path: "over-pay",
        component: OverPay,
      },
      {
        path: "alipay",
        component: Alipay,
      },

      {
        path: "wechatpay",
        component: WechatPay,
      },
    ],
    //检查是否有支付数据 如果无 则返回结算界面
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      if (store.state.payData != null) {
        testIsLogin(next);
      } else {
        router.push("/");
      }
    },
  },
  {
    path: "/pay-success",
    component: PaySuccess,
  },
  {
    name: 'user',
    path: "/user",
    component: User,
    children: [
      {
        path: "my",
        component: My,
      },
      {
        path: "follow",
        component: Follow,
        children: [
          {
            path: "",
            component: FollowDefault,
            //允许带参跳转
            props: (route) => ({ query: route.query.query }),
          },
          {
            path: 'applySale',
            component: ApplySale
          },
          {
            path: "*",
            component: FollowItem,
          },
          
        ],
      },
      {
        path: "after-sale",
        component: AfterSale,
        children: [
          {
            path: "",
            component: AfterSaleDefault,
            //允许带参跳转
            props: (route) => ({ query: route.query.query }),
          },
          {
            path: "*",
            component: AfterSaleItem,
          },
        ],
      },
      {
        path: "my-coupon",
        component: MyCoupon,
      },
      {
        path: "my-flow",
        component: MyFlow,
        children: [
          {
            path: '',
            component: MyFlowDefault
          },
          {
            path: "recharge",
            component: Recharge
          }
        ]
      },
      {
        path: "my-star",
        component: MyStar,
      },
      {
        path: "my-add",
        component: MyAdd,
      },
      {
        path: "setting",
        component: Setting,
      },
    ],
    //检查登录状态 已登录则通过 未登录则跳至登录页面
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      testIsLogin(next);
    },
  },
  {
    path: '/wechat',
    component: Wechat
  },
  {
    path: '/leadLabel',
    component: LeadLabel,
    meta: {
      title: '领标中心-品牌中心-领标搜索产品-图搜产品-印爆网',
      content: {
        keywords: '领标搜索、服装领标、印爆网、胚杉批发、空白衫批发、文化衫批发、polo定制、班服定制、短袖定制logo、纯棉T恤、工厂批发',
        description: '汇聚胚杉品牌领标中心，找领标找产品、图搜领标找产品，全品牌汇聚印爆网'
      }
    }
  },
  {
    path: '/supplyChain',
    component: SupplyChain,
    meta: {
      title: '供应链合作-诚邀胚杉厂家-供应商-加工企业入驻印爆网',
      content: {
        keywords: '印爆网、印爆、印爆官网、新百佳、文化衫市场、广告衫市场、空白T、空白衣服、广告衫、活动衫、文化衫、文化衫定制、胚杉批发',
        description: '印爆网，基础服饰批发平台，让广告衫印爆了！汇聚全球基础服饰供应商，涵盖定制服饰全品类供应，印爆网基础服饰供应链领导品牌、胚杉批发平台。小程序搜索“印爆网”，官网：www.yinbaole.com'
      }
    }
  }
];



const router = new VueRouter({
  mode: "history",
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.good) {
    let id = to.params.pathMatch;
    const { data } = await getProductName({ productId: id });
    document.title = data.name + '-印爆网'
  }
  else if (to.meta.title){
    // document.title = '印爆网-让广告衫印爆了！'
    document.title = to.meta.title;
  } else {
    document.title = '印爆网-让广告衫印爆了！'
  }
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  next();
})

export default router;
