import Vue from 'vue'
import { Button, Icon, Message, Checkbox, Cascader, MessageBox, Upload, Select, Option, InputNumber, Loading, Image, Alert } from 'element-ui'


Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Cascader)
Vue.use(Upload)
Vue.use(Icon)
Vue.use(Select)
Vue.use(Option)
Vue.use(InputNumber)
Vue.use(Loading)
Vue.use(Image)
Vue.use(Alert)

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.openLoading = function (id) {
  const loading = this.$loading({
    lock: true,
    text: '加载中',
    target: id,
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, .8)'
  });
  return loading;
};