<template>
  <div class="recharge">
    <c-nav :nav="navList"></c-nav>
    <div class="card" style="padding: 24px 24px 39px;">
      <div class="fs-20 col_85">充值</div>
      <div class="fs-14 gray9 mt-24">当前余额(元)</div>
      <div class="col_85 fs-28 mt-8">{{surplusMoney}}</div>
      <div class="bt_line mt-16"></div>
      <div class="mt-16 tips">
        <img src="../../../images/warning.png" alt="" class="ver_top">
        <div class="dis_block ml-16" style="width: calc(100% - 40px);">
          <div class="fs-16 col_85">温馨提示</div>
          <div class="mt-4 fs-14 col_65">充值完成后系统自动处理。如遇充值未生效的情况，请及时联系客服处理。充值部分的余额不能用于提现。</div>
        </div>
      </div>
      <div class="mt-16">
        <div class="fs-14">充值金额</div>
        <div class="flex mt-16 moneyBox">
          <a href="javascript:;" class="momeyItem fs-12 mr-16 mb-16" 
          :class="{'active': item.id === active}"
          @click="handleSel(item.id, item.amount)"
          v-for="item in moneyList" :key="item.id">{{item.amount}}元</a>
          <div class="inputBox fs-12">
            <input type="text"
            @click="selInput"
            @input="checkNum(amountInput)"
            class="moneyInput ver_top fs-12" 
            v-model="amountInput"
            :maxlength="moneyMaxLeng"
            :readonly="active !== 0"
            placeholder="其他金额">元
          </div>
          <span class="fs-12 gray9 ml-8">不低于0.01元 最高{{recharge}}元</span>
        </div>
      </div>
      <div class="mt-32 flex fs-14">
        <div class="rechargeBtn t-center black bg_f9" @click="goBack">返回</div>
        <div class="ml-16 rechargeBtn t-center white bg_red" @click="handleSubmit">确认充值</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRechargeOrder } from '@/http/api';
import { mapState } from 'vuex'
export default {
  data() {
    return {
      navList: [
        {
          path: `/user/my-flow`,
          text: "资金流水",
        },
        {
          path: '',
          text: '充值'
        }
      ],
      moneyList: [
        { id: 1, amount: 100 },
        { id: 2, amount: 500 },
        { id: 3, amount: 1000 },
        { id: 4, amount: 5000 },
        { id: 5, amount: 10000 },
        { id: 6, amount: 20000 },
      ],
      active: 0,
      amount: 0,
      amountInput: null,
      moneyMaxLeng: 7
    }
  },
  computed: {
    ...mapState(["surplusMoney", "recharge"])
  },
  methods: {
    // 返回
    goBack() {
      this.$router.back();
    },
    // 选择金额
    handleSel(id, amount) {
      this.active = id;
      this.amount = amount;
      this.amountInput = '';
    },
    selInput() {
      this.active = 0;
    },
  
    // 确认充值
    handleSubmit() {
      let money;
      if(this.active === 0) {
        if(this.amountInput < 0.01) {
          return this.$message.warning('最小充值金额不能小于0.01!');
        }
        money = this.amountInput;
      }else {
        money = this.amount;
      }
      this.createRechargeOrder(money);
    },

    // 创建充值订单
    createRechargeOrder(money) {
      createRechargeOrder({amount: money}).then(res => {
        const { statusCode, msg, orderId } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.amountInput = '';
          let data = {
            orderId: orderId,
            isRecharge: true
          }
          this.$store.commit('savePayData', data);
          this.$router.push({path: '/pay/wechatpay'});
        }else {
          this.$message.error(msg);
        }
      })
    },
    // 验证金额
    checkNum(val) {
      val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(/^0+\./g, '0.');
      val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val;
      val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || '';
      val = val >= parseFloat(this.recharge) ?  this.recharge : val;

      if(val.includes('.')) {
        let numDian = val.toString().split('.')[1].length;
        if(numDian === 2) {
          this.moneyMaxLeng = val.length;
        }
      }else {
        this.moneyMaxLeng = 7;
      }
      this.amountInput = val;
    }

  }
}
</script>

<style>
.bt_line {
  width: 272px;
  height: 1px;
  background: #EEEEEE;
}
.recharge .tips {
  width: 580px;
  padding: 16px 24px;
  background: #E6F7FF;
  border: 1px solid #91D5FF;
  border-radius: 2px;
}
.momeyItem {
  overflow: hidden;
  position: relative;
  width: 80px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  /* padding: 10px 23px; */
  border: 1px solid #D9D9D9;
}
.moneyBox {
  flex-wrap: wrap;
  width: 288px;
  align-items: center;
}
.moneyBox .active {
  border: 1px solid #f5222d;
}
.moneyBox .active::before,
.moneyBox .active::after {
  content: "";
  display: inline-block;
  position: absolute;
}
.moneyBox .active::before {
  width: 24px;
  height: 24px;
  background: #f5222d;
  transform: rotate(45deg);
  bottom: -12px;
  right: -12px;
}
.moneyBox .active::after {
  width: 12px;
  height: 12px;
  background: url("../../../images/line-check-white.png") no-repeat;
  background-size: contain;
  background-position: center;
  bottom: -1px;
  right: -1px;
}
.inputBox {
  width: 80px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}
.moneyInput {
  width: 60px;
  border: none;
  padding: 10px 2px 6px 5px;
  height: 100%;
  text-align: center;
}
.rechargeBtn {
  width: 88px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
}
</style>