<template>
  <div class="settle fs-12">
    <div class="c-box">
      <router-link to="/" class="logo-box mb-48">
        <img src="../images/LOGO.png" alt="印爆网" id="logo" />
      </router-link>
      <p class="title fs-16 mb-16">填写并核对订单信息</p>
      <div class="settle-add-box ">
        <div class="flex" style="justify-content: space-between; align-items: center;">
          <div class="bold fs-14 black">收货人信息</div>
          <div class="fs-12 col_005 pointer" @click="handleNewAdd">新建收货地址</div>
        </div>
        
        <div class="flex">
          <div class="settle-add mt-24">
            <div class="settle-add-item" v-show="IsSelAddress && this.areaList.length">
                <div>
                  <span
                    class="x_line-check_red mr-16 x_completed"
                  >
                    <i></i>
                  </span>
                  <span class="mr-24">{{ choosedArea.name }}</span>
                  <span class="mr-24">
                    {{ choosedArea.province }} {{ choosedArea.city }} {{ choosedArea.district }}
                    {{ choosedArea.detail }}
                  </span>
                  <span class="mr-24">{{ choosedArea.code }}</span>
                  <span>{{ choosedArea.phone }}</span>
                </div>
              </div>
            <div class="settle-add-item-box" :style="{ height: areaHeight }">
              <div
                class="settle-add-item"
                v-for="value in areaList"
                :key="value.id"
              >
                <div>
                  <span
                    class="x_line-check_red mr-16"
                    :class="{ x_completed: value.active }"
                    @click="chooseArea(value.id)"
                  >
                    <i></i>
                  </span>
                  <span class="mr-24">{{ value.name }}</span>
                  <span class="mr-16">
                    {{ value.province }} {{ value.city }} {{ value.district }} {{ value.detail }}
                  </span>
                  <span class="mr-16">{{ value.code }}</span>
                  <span>{{ value.phone }}</span>
                </div>
                <div class="fs-12 col_005 setting">
                  <span class="mr-24 c-red" v-show="value.is_default === 1">默认地址</span>
                  <span class="mr-24 pointer" v-show="value.is_default === 0" @click="handleSaveDefault(value.hashid)">设为默认地址</span>
                  <span class="mr-24 pointer" @click="handleEditAdd(value)">编辑</span>
                  <span class="pointer" @click="handleDelAdd(value.hashid)">删除</span>
                </div>

              </div>
            </div>
            <a
              href="javascript:;"
              class="w_up"
              :class="{ active: isOpen }"
              @click="changeOpen"
              v-if="action.type != 1"
            >
              {{ action.text }}
              <i class="ml-4"></i>
            </a>
            <a href="javascript:;" v-if="action.type == 1">
              {{ action.text }}
            </a>
          </div>
          <!-- <router-link
            :to="`/user/my-add`"
            tag="a"
            target="_blank"
            class="right"
          >
            管理地址
          </router-link> -->
        </div>
        
      </div>
      <div
        class="settle-order mt-20"
        v-for="(value, index) in order_list"
        :key="index"
      >
        <p class="so-title">{{ value.name }}</p>
        <div class="flex" style="">
          <div class="so-footer mt-32 mb-24 f-a-c" style="width: 35%;">
            <div class="flex" style="align-items: center;">
              <span class="c-9 mr-8">配送方式：</span>
              <a
                v-for="(item, typeIndex) in deliveryType" :key="item.id"
                href="javascript:;"
                :class="{ active: value.distribution.type === typeIndex+1}"
                @click="chooseType(index, typeIndex + 1, value)"
              >
                {{item.name}}
              </a>
            </div>
            <div class="ml-68" v-show="value.distribution.type === 1">
              <div class="fs-14 gray9 mt-16">提货时间：{{time}}可提货</div>
                <div class=" mt-16 pick">
                  <el-select v-model="value.pickPointId" placeholder="请选择">
                    <el-option
                      v-for="item in pickPoint"
                      :key="item.id"
                      :label="item.label"
                      :value="item.hashid">
                    </el-option>
                  </el-select>
                </div>
              <!-- <div class="fs-12 gray3 mt-16">{{value.warehouse.province}}
                {{value.warehouse.city}} {{value.warehouse.county}} {{value.warehouse.detail}}
                {{value.warehouse.name}}</div> -->
              <!-- <div class="fs-12 mt-18">运费总计：<span class="black fs-14">{{value.freight}}</span>元</div> -->
            </div>
            <div class="ml-68" v-show="value.distribution.type === 2 || value.distribution.type === 3">
              <div class="fs-14 gray9 mt-16">寄出时间：{{time}}寄出</div>
              <div class="fs-12 gray9 mt-10">收到快递后有异常，请及时联系客服，请勿擅自寄回</div>
              <div class=" mt-16">
                <el-select v-model="value.company" placeholder="请选择" @change="handleChange(value.company, value)">
                  <el-option
                    v-for="item in expressList"
                    :key="item.id"
                    :label="item.company"
                    :value="item.hashid">
                  </el-option>
                </el-select>
              </div>
              <div class="fs-12 mt-18">运费总计：<span class="black fs-14">{{value.freight}}</span>元</div>
            </div>
            <div class="remark mt-24">
              <div class="gray9 fs-14">订单备注：</div>
              <input type="text" class="remarkInput mt-8" v-model="value.remarks" placeholder="可以填写您的特殊要求">
            </div>
          </div>
          <div class="so-header mt-32" style="flex: 1;">
            <div class="goodTitle flex gray9 fs-12">
              <span style="width:35%;">商品信息</span>
              <span style="width:13%;">颜色</span>
              <span style="width:13%;">尺码</span>
              <span style="width:13%;">数量</span>
              <span style="width:13%;">单价</span>
              <span style="width:13%;">金额</span>
            </div>
            <div class="flex goodBox" v-for="(goods) in value.productData" :key="goods.id">
              <div style="width: 200px;" class="t-center">
                <img :src="goods.image" alt="" class="goodImg">
                <div class="mt-8px fs-12 mt-8">{{goods.full_name}}</div>
              </div>
              <div style="flex: 1;" >
                <div class="flex fs-12 colorBox" v-for="color in goods.colourArr" :key="color.id">
                  <div style="width: 80px;" class="t-center">{{color.name}}</div>
                  <div style="flex: 1;">
                    <div class="flex sizeRow" v-for="size in color.sizeArr" :key="size.id">
                      <span>{{size.name}}</span>
                      <span>{{size.number}}</span>
                      <span>￥{{size.price}}</span>
                      <span>￥{{size.total_price ? size.total_price.toFixed(2) : ''}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomBox mt-12 mb-32">
              <div class="bg_f9f totalBox fs-12 flex">
                <span style="width:8%;">小计：</span>
                <span style="width: 18%;" class="t-center">{{value.totalNum}}件</span>
                <span style="width: 22%; padding-right:5px;" class="t-right">￥{{value.totalPrice ? value.totalPrice.toFixed(2) : ''}}</span>
              </div>
              <div class="fs-12 mt-16 t-right">
                <div>总重量：<span class="fs-14">{{value.weight}}</span>kg</div>
                <div class="mt-8">运费：￥{{value.freight}}</div>
                <div class="mt-8">总金额：<span class="fs-14 bold col_f52">{{(parseFloat(value.totalPrice) + parseFloat(value.freight)).toFixed(2) }}</span><span class="col_f52">元</span></div>
              </div> 
            </div>
            <!-- <div class=" flex">
              <span class="flex1 t-center c-9">订单{{ index + 1 }}：</span>
              <div class="so-table-box" style="width: calc(100% - 50px);">
                <table>
                  <thead>
                    <th>产品信息</th>
                    <th>单价（元）</th>
                    <th>数量</th>
                    <th>金额（元）</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="height: 0; border: none"></td>
                      <td style="height: 0; border: none"></td>
                      <td style="height: 0; border: none"></td>
                      <td style="height: 0; border: none"></td>
                    </tr>
                    <tr
                      v-for="(goods, goodsIndex) in value.productData"
                      :key="goodsIndex"
                    >
                      <td class="flex table-goods-box f-a-c">
                        <router-link
                          :to="'/house/' + goods.hashid"
                          class="order-img-box mr-8"
                        >
                          <img
                            :src="goods.image"
                            :alt="goods.full_name"
                          />
                        </router-link>
                        <div>
                          <router-link
                            :to="'/house/' + goods.hashid"
                            class="title mb-8"
                          >
                            {{ goods.full_name }}
                          </router-link>
                          <p>
                            颜色:<span class="mr-8">{{ goods.sku.colour.name }}</span>
                            <span class="mr-8">尺码：{{ goods.sku.size.name }}</span>
                          </p>
                        </div>
                      </td>
                      <td>{{ goods.sku.price }}</td>
                      <td>{{ goods.number }}</td>
                      <td class="fw-bold fs-14">
                        {{ goods.total_price.toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="sotb-footer flex">
                  <div class="input-box">
                    <span>订单留言备注：</span>
                    <input
                      type="text"
                      v-model="value.remarks"
                      placeholder="可以填写您的特殊要求"
                    />
                  </div>
                  <div class="right">
                    <p>
                      <span>总金额：</span>
                      <span class="c-red mr-4">
                        {{ orderSum(value.productData).toFixed(2) }}
                      
                      </span>
                      <span>元</span>
                    </p>
                    <p class="mt-8">
                      <span>总重量：</span>
                      <span class="fs-14">{{ WeightSum(value.productData) }}</span>
                      kg
                      
                    </p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="settle-coupon-box mt-20">
        <p class="scb-title">使用优惠券</p>
        <div class="settle-coupon">
          <div class="sc-header">
            <a
              href="javascript:;"
              :class="{ active: seeCoupon == 1 }"
              @click="seeOthCoupon(1)"
            >
              可用
            </a>
            <a
              href="javascript:;"
              :class="{ active: seeCoupon == 2 }"
              @click="seeOthCoupon(2)"
            >
              不可用
            </a>
          </div>
          <div class="sc-body">
            <div
              class="s-coupon"
              :class="{ active: value.active }"
              v-for="value in can_use"
              :key="value.id"
              v-show="seeCoupon == 1"
              @click="chooseCoupon(value.id)"
            >
              <div class="s-coupon-header">
                <span class="fs-22">{{ value.name }}</span>
                <!-- <span class="fs-22" v-if="value.type != 4">
                  ￥{{ value.money }}
                </span>
                <span class="fs-22" v-if="value.type == 4">
                  {{ value.money }}折
                </span>
                <span class="sill" v-if="value.is_sill == 1">
                  {{ value.sill }}
                </span> -->
                <p>{{ value.use_end_at }}</p>
              </div>
              <div class="s-coupon-footer">
                <span>[{{ couponType[value.type-1] }}]</span>
              </div>
            </div>
            <div
              class="s-coupon fade"
              v-for="value in not_use"
              :key="value.id"
              v-show="seeCoupon == 2"
            >
              <div class="s-coupon-header">
                <span class="fs-22">{{ value.name.split('券')[0] }}</span>
                <span class="fs-12 ml-8">满{{value.condition == 0 ? '' : value.condition}}可用</span>
                <!-- <span class="fs-22" v-if="value.type != 4">
                  ￥{{ value.money }}
                </span>
                <span class="fs-22" v-if="value.type == 4">
                  {{ value.money }}折
                </span>
                <span class="sill" v-if="value.is_sill == 1">
                  {{ value.sill }}
                </span> -->
                <p>有效期至:{{ value.use_end_at }}</p>
              </div>
              <div class="s-coupon-footer">
                <span>[{{ couponType[value.type-1] }}]</span>
              </div>
            </div>
            <p v-show="!hasCoupon">暂时没有哦~</p>
          </div>
        </div>
        <div class="scb-footer">
          <span class="mr-8 fw-bold">金额抵用</span>
          <span class="c-red mr-8 fw-bold">￥{{ discoun }}</span>
          <span class="c-6">
            使用优惠券{{ discoun == 0 ? 0 : 1 }}张，优惠{{
              discoun
            }}元
          </span>
        </div>
      </div>
      <div class="settle-sum mt-20 flex">
        <div class="ss-left">
          <span>总计：</span>
          <span class="mr-16">货品种类：{{ productTypeNum }}种</span>
          <span>数量总计：{{ productNum }}件</span>
        </div>
        <div class="flex1 t-r">
          <p class="mb-8">
            <span>总金额：</span>
            <span class="fs-14 fw-bold">{{ totalAmount.toFixed(2) }}</span>
            <span class="ml-4">元</span>
          </p>
          <p class="mb-8">
            <span>运费总计：</span>
            <span class="fs-14 fw-bold">{{ dhlSum }}</span>
            <span class="ml-4">元</span>
          </p>
          <p class="mb-4">
            <span>优惠：</span>
            <span class="fs-14 fw-bold">-{{ discoun }}</span>
            <span class="ml-4">元</span>
          </p>
          <p>
            <span>应付总额（含运费）：</span>
            <span class="c-red fw-bold">{{ sumAddDHL.toFixed(2) }}</span>
            <span class="ml-4">元</span>
          </p>
        </div>
      </div>
      <div class="settle-submit mt-20 flex">
        <router-link to="/shopping-cart" class="fs-14">返回购物车</router-link>
        <a href="javascript:;" class="bg-r-btn right" @click="submit">
          提交订单
        </a>
      </div>
    </div>
    <new-add
      v-show="newAddShow"
      @close="handleNewAdd"
      @add-suc="getAddressAgain"
      :addressData="addData"
    >
    </new-add>
  </div>
</template>

<script>
import newAdd from "../components/newAdd";
import { confirmData, addressData, delAddress, saveDefault, createOrder, getCartNum, getFreight } from '@/http/api';
import { mapState } from 'vuex';
export default {
  name: "settle",
  components: {
    newAdd,
  },
  data() {
    return {
      // //寄付可选列表
      // sendCom: [],
      // //到付可选列表
      // arriveCom: [],
      
      //是否显示新建地址
      newAddShow: false,
      //新建地址数据
      addData: {
        //新建还是修改地址 0是新建 1是修改
        addType: 0,
      },
      //地址列表
      areaList: [],
      //是否展开显示更多地址
      isOpen: false,
      //可用优惠券列表
      can_use: [],
      //不可用优惠券列表
      not_use: [],
      //查看的是可用优惠券还是不可用 1是可用 2是不可用
      seeCoupon: 1,
      order_list: [], //订单数据列表
      addressId: '', // 地址id
      couponType: ["满减券", "现金券", "运费券", "折扣券"],
      productTypeNum: 0, // 产品类型总数
      productNum: 0, // 产品总数
      totalAmount: 0, //订单总价
      time: '', //配送&提货时间
      expressList: [], //快递公司
      company: '', // 所选的快递公司
      deliveryType: [
        { id: 1, name: '自提' },
        { id: 2, name: '寄付' },
        { id: 3, name: '到付' },
      ],
      // 自提点信息
      pickPoint: [],
      pickPointId: '',
      couponId: '',
      IsSelAddress: true,
      timer: null,
      freight: '',// 运费
      changeAll: false
    };
  },
  computed: {
    ...mapState(["settleType", "settleData"]),
    //地址列表高度
    areaHeight() {
      let num = this.action.type == 1 ? 0 : 0;
      if (this.isOpen) num = 46 * this.areaList.length;
      return num + "px";
    },
    //货品种类
    goodsSortSum() {
      let num = 0,
        goodsList = [];
      if (this.order_list.length > 0) {
        for (const item of this.order_list) {
          for (const goods of item.productData) {
            goodsList.push(goods.id);
          }
        }
      }
      num = new Set(goodsList).size;
      return num;
    },
    //总运费
    dhlSum() {
      let num = 0;
      if (this.order_list.length > 0) {
        this.order_list.forEach(item => {
          console.log(item.freight);
          num += item.freight;
        })
      }
      return +num;
    },
    //优惠金额
    discoun() {
      let num = 0;
      if(this.can_use.length > 0) {
        this.can_use.forEach(item => {
          if(item.active) {
            num = item.amount;
            this.couponId = item.hashid;
          }else {
            this.couponId = '';
          }
        });
      }
      return num;
    },
    //总金额（含运费）
    sumAddDHL() {
      let num = 0;
      num = this.totalAmount + this.dhlSum - this.discoun;
      return +num;
    },
    //是否有可用优惠券
    hasCoupon() {
      if (this.seeCoupon == 1 && this.can_use.length == 0) {
        return false;
      } else if (this.seeCoupon == 2 && this.not_use.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    //当前选择的地址
    choosedArea() {
      let obj = {};
      if (this.areaList.length > 0) {
        for (const iterator of this.areaList) {
          if (iterator.active) obj = iterator;
        }
      }
      return obj;
    },
    //收货地址显示操作字段
    action() {
      //type 1是没有收货地址 2是有收货地址,但是未展开显示
      //3是有收货地址 展开显示
      let obj = {
        text: "",
        type: 1,
      };
      if (this.areaList.length == 0) {
        //没有收货地址
        // obj.text = "暂无收货地址，点击此处添加";
      } else if (this.areaList.length > 0 && !this.isOpen) {
        obj.text = "选择其他地址";
        obj.type = 2;
      } else if (this.areaList.length > 0 && this.isOpen) {
        obj.text = "收起";
        obj.type = 3;
      }
      return obj;
    },
    //提交订单的数据
    submitData() {
      let orderData = [];
      let flag = true;
      this.order_list.forEach(item => {
        let ordObj = {
          warehouseId: item.hashid,
          type: item.distribution.type,
          remarks: item.remarks,
          pickPointId: item.distribution.type === 1 ?  item.pickPointId : '',
          expressId: item.distribution.type !== 1 ? item.company : '',
          productData: []
        }
        if(item.distribution.type === 1 && item.pickPointId === '') {
          this.$message.warning('请先选择自提点');
          flag =  false;
        }
        if(item.distribution.type !== 1 && item.company === '') {
          this.$message.warning('尚未选择快递公司');
          flag =  false;
        }
        item.productData.forEach(pro => {
          pro.colourArr.forEach(col => {
            col.sizeArr.forEach(size => {
              let proObj = {
                productId: pro.hashid,
                number: size.number,
                skuId: size.skuId
              }
              ordObj.productData.push(proObj);
            })
          })
        })
        orderData.push(ordObj);
      });
      if(flag) {return orderData}
      else { return [] }
    },
  },
  methods: {
    //保存可选列表
    saveList(list, type) {
      let arr = [];
      if (list) {
        for (const iterator of list) {
          let obj = {
            active: false,
          };
          if (iterator.default == 1 && type) obj.active = true;
          arr.push(Object.assign(iterator, obj));
        }
        if (!type) arr[0].active = true;
      }
      return arr;
    },
    //选择配送方式
    chooseType(index, typeIndex, value) {
      console.log(value);
      //type 1是自提 2是寄付 3是到付 
      this.order_list[index].distribution.type = typeIndex;
      if(typeIndex === 1) {
        this.order_list.forEach(item => {
          if(item.hashid === value.hashid) {
            item.freight = 0;
          }
        })
      }else if(typeIndex !== 1){
        if(value.company) {
          this.getFreight(value.templateId, value.weight, value);
        }else {
          this.order_list.forEach(item => {
            if(item.hashid === value.hashid) {
              item.freight = 0;
              item.templateId = value.templateId;
            }
          })
          return;
        }
      }
    },
    //选择快递公司
    chooseCom(index) {
      this.company = this.expressList[index].company;
    },
    //切换查看优惠券
    seeOthCoupon(type) {
      this.seeCoupon = type;
    },
    //选择使用的优惠券
    chooseCoupon(id) {
      for (const item of this.can_use) {
        if (item.id == id) {
          item.active = !item.active;
        } else {
          item.active = false;
        }
      }
    },
    //展开显示更多地址
    changeOpen() {
      this.IsSelAddress = !this.IsSelAddress;
      this.isOpen = !this.isOpen;
    },
    //选择其他收货地址
    chooseArea(id) {
      for (const iterator of this.areaList) {
        if (iterator.id == id) {
          iterator.active = true;
          this.addressId = iterator.hashid;
        } else {
          iterator.active = false;
        }
      }
      this.isOpen = false;
      this.IsSelAddress = true;
      this.order_list.forEach(async item => {
        if(item.distribution.type !== 1 && item.company) {
          item.freight = item.distribution.type === 3 ? 0 : await this.getFreightAll(item.templateId, item.weight);
        }else {return;}
      })
    },
    // 新建地址
    handleNewAdd() {
      this.newAddShow = !this.newAddShow;
      this.$store.commit("changeMask");
      this.addData = {addType: 0};
    },
    //提交订单
    submit() {
      if(this.submitData.length) {
        console.log(this.submitData);
        this.createOrder(this.submitData);
      }
    },
    // 编辑地址
    handleEditAdd(item) {
      console.log(item);
      this.newAddShow = !this.newAddShow;
      this.$store.commit('changeMask');
      this.addData = {
        addType: 1,
        ...item
      }
    },
    // 删除地址
    handleDelAdd(id) {
      this.$confirm('是否删除该地址信息','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delAddress(id);
      }).catch(() => {})
    },
    // 设置默认地址
    handleSaveDefault(id) {
      this.saveDefault(id);
    },
    // 重新获取地址信息
    getAddressAgain() {
      this.timer = setTimeout(() => {
        this.addressData();
      },1000);
    },
    // 监听快递方式改变
    handleChange(ex, value) {
      console.log(ex, value);
      let tid = '';
      this.expressList.forEach(item => {
        if(item.hashid === ex) {
          tid = item.template_id;
        }
      })
      if(value.distribution.type !== 1) {
        // 计算运费
        this.getFreight(tid, value.weight, value);
      }
    },
    //------------------获取数据 &api-------------------
    // 获取页面数据
    confirmData() {
      let params = {
        orderData: this.settleData,
        // type: this.settleType,
        addressId: ''
      };
      console.log(params);
      confirmData(params).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          console.log(data);
          // 地址设置
          if(data.address) {
            this.selAddressId = data.address.hashid
            this.addressData();
          }
          // 优惠券设置
          if(data.couponList.length) {
            let newCoupon = JSON.parse(JSON.stringify(data.couponList));
            newCoupon.forEach(item => {
              item.active = false;
            })
            this.can_use = newCoupon.filter(item => {return item.is_use === 1});
            this.not_use = newCoupon.filter(item => {return item.is_use === 2});
          }
          // 订单数据
          let newOrderList = JSON.parse(JSON.stringify(data.orderData.warehouse));
          newOrderList.forEach(item => {
            item.distribution = {
              type: 1
            }
            item.pickPointId = '' //自提点id
            item.company = ''; //寄付 到付
            item.remarks= ''; // 备注
            item.freight = 0;
            item.totalNum = 0;
            item.totalPrice = 0;
            item.productData.forEach(pro => {
              item.totalNum += pro.number;
              item.totalPrice += pro.total_price;
            })
          });
          this.order_list = newOrderList;
          // this.order_list = newOrderList;


          this.productTypeNum = data.productTypeNum;
          this.productNum = data.productNum;
          this.totalAmount = data.totalAmount;
          this.time = data.time;
          this.expressList = data.expressList;
          this.addressId
          data.pickPoint.forEach(item => {
            let obj = {
              hashid: item.hashid,
              label: item.province + item.city + item.district + item.detail,
              id: item.id,
              phone: item.phone
            }
            this.pickPoint.push(obj);
          })
        }
      })
    },
    // 获取地址信息
    addressData() {
      addressData().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          if(this.timer) {
            clearTimeout(this.timer);
          }
          let resList = JSON.parse(JSON.stringify(data));
          resList.forEach(item => {
            if(item.hashid === this.selAddressId) {
              item.active = true;
              this.addressId = item.hashid;
            }else {
              item.active = false;
            }
          });
          this.areaList = resList;
        }
      });
    },
    // 删除地址信息
    delAddress(id) {
      delAddress({addressId: id}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('删除成功');
          this.addressData();
        }else {
          this.$message.error('删除失败');
        }
      })
    },
    // 设置默认地址
    saveDefault(id) {
      saveDefault({addressId: id}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('设置成功');
          this.addressData();
        }else {
          this.$message.error('设置失败');
        }
      })
    },
    // 创建订单
    createOrder(data) {
      let params = {
        orderData: data,
        addressId: this.addressId,
        couponId: this.couponId
      }
      createOrder(params).then(res => {
        const { statusCode, msg, orderId } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          let payData = {
            orderId: orderId,
          }
          this.$store.commit('savePayData', payData);
          this.$router.push({path: '/pay'});
          this.getCartNum()
        }else {
          return this.$message.error(msg);
        }
      })
    },
    // 重新获取购物车数量
    getCartNum() {
      getCartNum().then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$store.commit('saveCartNumber', res.orderCartNum);
        }
      });
    },
    // 计算运费
    getFreight(templateId, weight, value) {
      let params = {
        addressId: this.addressId,
        templateId: templateId,
        weight: weight
      }
      if(value.distribution.type === 3) {
        this.order_list.forEach(item => {
          if(item.hashid === value.hashid) {
            item.templateId = templateId;
            item.freight = 0;
          } 
        })
      }else {
        getFreight(params).then(res => {
          const { statusCode, data } = res;
          if(statusCode === 200) {
            this.order_list.forEach(item => {
              if(item.hashid === value.hashid) {
                item.templateId = templateId;
                item.freight = data.freight;
              } 
            })
          }
        })
      }
    },
    //
    async getFreightAll(templateId, weight) {
      let params = {
        addressId: this.addressId,
        templateId: templateId,
        weight: weight
      }
      try {
        let res = await getFreight(params);
        const { data } = res;
        if(data.freight) {
          return data.freight;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //--------------------------------------
    // 计算每/订单总金额
    orderSum(list) {
      let num = 0;
      list.forEach(item => {
        num += item.total_price;
      }, 0);
      return num;
    },
    // 计算每/订单总重量
    WeightSum(list) {
      let weight = 0;
      list.forEach(item => {
        weight += item.weight ? parseFloat(item.weight) : 0;
      }, 0);
      return weight;
    },
    
  },
  created() {
    console.log(this.settleType,this.settleData);
    //发送请求 获取数据
    this.confirmData();
  },
  mounted() {
    this.$store.commit("changeSearch");
  },
  destroyed() {
    this.$store.commit("changeSearch");
  },
};
</script>

<style>
/* 结算 */
.settle {
  padding-top: 68px;
  background: #fff;
  padding-bottom: 120px;
}
.settle .title {
  color: #666;
}
.settle .c-box > div {
  border: 1px solid #f0f0f0;
}
.settle-add {
  width: 100%;
}
.settle-add-box {
  padding: 16px 24px;
}
.settle-add-box > span:first-child {
  margin-right: 48px;
  color: #999999;
}
.settle-add-item-box {
  overflow: auto;
  max-height: 184px;
}
.settle-add-item-box::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
.settle-add-item-box::-webkit-scrollbar-thumb {
  border-radius:4px;
  background:#DDDDDD;
}
.settle-add-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 32px;
  padding: 7px 8px;
  justify-content: space-between;
}
.settle-add-item .setting {
  display: none;
}
.settle-add-item:hover {
  background: #FFF3F3;
}
.settle-add-item:hover .setting {
  display: block;
}
.settle-add-item .x_line-check_red {
  display: inline-block;
  /* margin-left: 8px; */
}
.settle-add-item .x_line-check_red.x_completed {
  border: 1px solid #F5222D;
  border-radius: 50%;
}
.settle-add-item .x_line-check_red {
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid #BBBBBB;
  border-radius: 50%;
}
.settle-add .x_completed i {
  background-image: none;
  background: #F5222D;
}
.settle-add-item .x_line-check_red i {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.settle-add-box a {
  display: inline-block;
  color: #005ea7;
}
.settle-add-box a.w_up {
  display: flex;
  align-items: center;
}
.settle-add-box a.w_up i {
  transform: rotate(180deg);
}
.settle-add-item-box,
.settle-add-box a.w_up i {
  transition: all 0.3s;
}
.settle-add-box a.w_up.active i {
  transform: rotate(0deg);
}
.so-title {
  line-height: 40px;
  height: 40px;
  padding: 0 24px;
  background: #e2edf8;
}
.so-header {
  padding: 0 16px;
  margin-left: 154px;
}
.goodTitle {
  padding-bottom: 8px;
  border-bottom: 1px solid #EEEEEE;
}
.goodTitle span {
  text-align: center;
}
.goodBox {
  padding: 16px 0 24px;
  border-bottom: 1px solid #EEEEEE;
}
.goodBox:nth-child(n-1) {
  border-bottom: 0;
}
.goodImg {
  border: 1px solid #DDDDDD;
  width: 64px;
  height: 64px;
}
.colorBox {
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
}
.colorBox:last-child {
  border-bottom: 0;
}
.sizeRow {
  padding: 8px 0;
  border-bottom: 1px dashed #DDDDDD;
}
.sizeRow:last-child {
  border-bottom: 0;
}
.sizeRow span {
  width: 25%;
  text-align: center;
}


.so-table-box {
  flex-shrink: 0;
  width: 1026px;
  background: #f9fbff;
  border: 1px solid #e2edf8;
  margin-bottom: 24px;
}
.so-table-box table {
  width: 100%;
  table-layout: fixed;
}
.so-table-box th {
  height: 40px;
}
.so-table-box table th,
.so-table-box table td {
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #e2edf8;
}
.so-table-box table th:first-child {
  width: 30%;
}
.so-table-box table th:first-child,
.so-table-box table td:first-child {
  padding-left: 16px;
  text-align: left;
}
/* .so-table-box table th:last-child,
.so-table-box table tr:first-child td:last-child {
  border-left: 1px solid #e2edf8;
} */
.so-table-box table td {
  height: 90px;
}
.so-table-box table .order-img-box {
  width: 60px;
  height: 60px;
}
.table-goods-box .title {
  display: inline-block;
  max-width: 207px;
  max-height: 32px;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333;
}
.table-goods-box p {
  color: #999999;
}
.sotb-footer {
  padding-bottom: 24px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 24px;
}
.sotb-footer input {
  width: 320px;
  height: 24px;
  padding: 0 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 12px;
}
.sotb-footer .c-red {
  font-size: 14px;
  font-weight: bold;
}
.so-footer {
  padding-left: 16px;
  position: relative;
}
.so-footer a {
  display: inline-block;
  width: 80px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-right: 16px;
}
.so-footer a.active {
  border: 1px solid #f5222d;
  position: relative;
  overflow: hidden;
}
.so-footer a.active::before,
.so-footer a.active::after,
.s-coupon.active::before,
.s-coupon.active::after {
  content: "";
  display: inline-block;
  position: absolute;
}
.s-coupon.active::before,
.so-footer a.active::before {
  width: 24px;
  height: 24px;
  background: #f5222d;
  transform: rotate(45deg);
  bottom: -12px;
  right: -12px;
}
.s-coupon.active::after,
.so-footer a.active::after {
  width: 12px;
  height: 12px;
  background: url("../images/line-check-white.png") no-repeat;
  background-size: contain;
  background-position: center;
  bottom: -1px;
  right: -1px;
}
.so-footer .select {
  height: 38px;
  line-height: 36px;
}
.so-footer .select a {
  display: flex;
  width: 100%;
}
.so-footer .select .header {
  border: none;
}
.so-footer .select .option {
  top: 36px;
}
.scb-title {
  padding: 16px 24px;
  font-size: 14px;
}
.settle-coupon {
  padding: 0 24px;
}
.sc-header {
  height: 32px;
  line-height: 32px;
  background: #f6f6f6;
}
.sc-header a {
  display: inline-block;
  height: 100%;
  padding: 0 24px;
  transition: all 0.3s;
}
.sc-header a:hover,
.sc-header a.active {
  background: #fff;
}
.sc-body {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  padding-bottom: 16px;
}
.s-coupon {
  position: relative;
  width: 200px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 2px solid;
  border-color: #fe637c;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
}
.s-coupon.fade {
  cursor: default;
  border: none;
}
.s-coupon.active {
  border-color: #f5222d;
}
.s-coupon-header,
.s-coupon-footer {
  padding: 10px;
}
.s-coupon-header {
  /* height: 70px; */
  color: #fff;
  background: #fe637c;
}
.s-coupon.fade .s-coupon-header {
  background: url("../images/s-coupon.png") no-repeat;
  background-size: cover;
}
.sill {
  color: rgba(255, 255, 255, 0.85);
}
.s-coupon-header p {
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.65);
}
.s-coupon.fade .s-coupon-header p {
  color: rgba(0, 0, 0, 0.25);
}
.s-coupon-footer {
  color: #fe637c;
}
.s-coupon.fade .s-coupon-footer {
  color: #bbb;
  border: 1px solid;
  border-top: none;
  border-color: #f0f0f0;
}
.scb-footer {
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  background: #f6f6f6;
}
.settle-sum {
  padding: 24px 32px;
}
.ss-left span:first-child {
  margin-right: 52px;
}
.settle-sum .t-r .c-red {
  font-size: 22px;
}
.settle-submit {
  border: none !important;
  padding-left: 32px;
}
.settle-submit .bg-r-btn {
  width: 180px;
  height: 60px;
  line-height: 58px;
  font-size: 18px;
}
a.logo-box {
  display: inline-block;
}
.pick .el-select>.el-input {
  width: 272px!important;
}
.totalBox {
  padding: 8px 0;
 border: 1px solid #E2EDF8;
 justify-content: flex-end;
}
.remarkInput {
  width: 342px;
  padding: 10px 12px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}

</style>