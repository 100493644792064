<template>
<div>
  <div class="coupon-items">
    <div :class="content.status !== 1 ? 'gray' : (content.type != 4 ? 'yuan' : 'discount')">
      <div class="header">
        <div class="title">
          <span>{{ content.amount }}</span>
          <span v-if="content.type != 4">元</span>
          <span v-else>折</span>
        </div>
        <p>{{ typeList[content.type - 1] }}</p>
        <p>{{content.use_end_at}}截止</p>
      </div>
      <div class="footer">
        <p>{{ content.condition > 0 ? '满'+content.condition+'可用' : '无门槛' }}</p>
        <p class="mt-8">{{content.admin.name}}</p>
        <p class="mt-8">
          {{content.admin.code}}
          <span>{{ content.amount }}</span>
          <span v-if="content.type != 4">元</span>
          <span v-else>折</span>
          券
        </p>
        <a href="javascript:;" v-if="content.status === 1" class="mt-16" @click="toUse(content.admin.code)">立即使用</a>
        <a href="javascript:;" v-else-if="content.status === 2" class="mt-16">已使用</a>
        <a href="javascript:;" v-else class="mt-16">已过期</a>
      </div>
    </div>
  </div>
  <div class="con_bottom">
    <div>券编号：{{content.code}}</div>
    <div class="mt-8">有效期至 {{content.use_end_at}}</div>
  </div>
</div>
  
  
</template>

<script>
// import { receiveCoupon } from '@/http/api'
// import { mapState } from 'vuex'
export default {
  name: "mycouponItems",
  props: {
    content: Object,
    action: Boolean,
  },
  computed: {
    // ...mapState(["isLogin"])
  },
  created() {
    console.log(this.action);
  },
  data() {
    return {
      canIUse: false,
      typeList: ['满减券', '现金券', '运费券', '折扣券']
    };
  },
  methods: {
    // 使用优惠券
    toUse(code) {
      this.$store.commit('saveKeyword', code);
      this.$router.push(`/house?keyword=${code}`);
    },
  },
};
</script>

<style>
/* 优惠券组件 */
.coupon-items {
  position: relative;
  text-align: center;
  width: 220px;
  height: 292px;
  line-height: 16px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
  cursor: default;
}
.coupon-items > div {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.coupon-items .yuan {
  background-image: url("../images/coup-red.png");
}
.coupon-items .discount {
  background-image: url("../images/coup-yellow.png");
}
.coupon-items .gray {
  background-image: url("../images/coup_gray.png");
}
.coupon-items .header,
.coupon-items .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
}
.coupon-items .title span {
  font-weight: bold;
  color: #ffffff;
}
.coupon-items .title span:first-child {
  font-size: 50px;
  line-height: 85px;
  margin-right: 8px;
}
.coupon-items .yuan .title span:nth-child(2) {
  line-height: 31px;
  font-size: 24px;
}
.coupon-items .discount .title {
  display: flex;
  align-items: center;
}
.coupon-items .discount .title span:nth-child(2) {
  font-size: 48px;
}
.coupon-items > div .header p:nth-child(2) {
  color: #ffffff;
  line-height: 24px;
  font-size: 18px;
}
.coupon-items .footer a {
  display: inline-block;
  font-size: 16px;
  border-radius: 19px;
  background: #ffffff;
  padding: 8px 32px;
}
.coupon-items .yuan .footer a {
  color: #fe5a74;
}
.coupon-items .discount .footer a {
  color: #fea918;
}
.coupon-items .gray .footer a {
  color: #999999;
}
.con_bottom {
  text-align: center;
  color: #999;
  font-size: 14px;
  padding: 20px 0;
}

</style>