<template>
  <div class="user flex c-box mt-20 mb-80">
    <ul class="user-left">
      <li
        v-for="(value, index) in userLeft"
        class="card"
        :class="{ active: value.active }"
        :key="index"
      >
        <router-link :to="value.path">{{ value.name }}</router-link>
      </li>
    </ul>
    <router-view @change-mask="changeMask"></router-view>
  </div>
</template>

<script>
export default {
  name: "user",
  data() {
    return {
      userLeft: [
        {
          name: "我的工作台",
          path: `/user/my`,
          active: true,
        },
        {
          name: "跟单工作台",
          path: `/user/follow`,
          active: false,
        },
        {
          name: "售后工作台",
          path: `/user/after-sale`,
          active: false,
        },
        {
          name: "我的优惠券",
          path: `/user/my-coupon`,
          active: false,
        },
        {
          name: "资金流水",
          path: `/user/my-flow`,
          active: false,
        },
        {
          name: "我的收藏",
          path: `/user/my-star`,
          active: false,
        },
        {
          name: "收货地址",
          path: `/user/my-add`,
          active: false,
        },
        {
          name: "账号设置",
          path: `/user/setting`,
          active: false,
        },
      ],
    };
  },
  watch: {
    $route: "changeNav",
  },
  methods: {
    //给nav样式
    changeNav() {
      for (let i = 0; i < this.userLeft.length; i++) {
        this.userLeft[i].active = false;
        let path = this.$route.path.split("/")[2];
        console.log(path);
        
        for (let n = 0; n < this.userLeft[i].path.split("/").length; n++) {
          if (path == this.userLeft[i].path.split("/")[n]) {
            this.userLeft[i].active = true;
          }
        }
      }
    },
    changeMask() {
      this.$emit("change-mask");
    },
  },
  created() {
    this.changeNav();
  },
};
</script>

<style>
.user {
  padding-top: 20px;
  justify-content: space-between;
}
.user-left li {
  width: 180px;
  padding: 16px 0;
  padding-left: 45px;
  font-size: 18px;
  color: #666666;
  position: relative;
  transition: all 0.3s;
}
.user-left li.active {
  color: #333333;
  font-weight: bold;
}
.user-left li.active::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translateY(-9px);
  width: 4px;
  height: 18px;
  background: #ed1c24;
}
.user-left li a:hover {
  color: #333333;
  font-weight: bold;
}
.user-left li a:hover::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translateY(-9px);
  width: 4px;
  height: 18px;
  background: #ed1c24;
}
.user .c-nav .c-box {
  width: 100%;
  padding-top: 0;
}
.user-left + div {
  width: 980px;
  min-height: 600px;
}
.user .pagination {
  margin: 0;
  margin-left: auto;
  font-size: 14px;
}
.user .pagination .w_turn-right {
  margin: 0;
}
.user label[for="all"] {
  font-size: 12px;
  color: #999999;
  margin-left: 8px;
}
.user a.bg-r-btn,
.user a.bg-w-btn {
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
}
.user a.bg-w-btn {
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.user table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  font-size: 12px;
  border-collapse: collapse;
}
.user table thead {
  background: #f8f8f8;
  height: 40px;
}
.user table thead th {
  height: 40px;
  font-weight: 400;
  color: #999999;
  padding: 12px 0;
}
.user table thead th:nth-child(3) {
  max-width: 10%;
}
.user table td {
  padding: 20px 0;
}

/* 流程状态组件 */
.process-status {
  position: relative;
  flex: 1;
  margin-bottom: 40px;
}
.process-status li {
  flex: 1;
  text-align: center;
  position: relative;
}
.process-status li::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #ddd;
  border-radius: 50%;
  position: absolute;
  top: 39px;
  left: 50%;
  transform: translateX(-6px);
  z-index: 100;
}
.process-status .line {
  position: absolute;
  top: 44px;
  left: 69px;
  width: 142px;
  height: 2px;
  background: #ddd;
  z-index: 99;
}
.process-status .line:last-child {
  left: 492px;
}
.process-status .line:nth-last-child(3) {
  left: 210px;
}
.process-status .line:nth-last-child(2) {
  left: 350px;
}
.process-status li.active::after,
.process-status .line.active {
  background: #f5222d;
}
.after-sale-item .courier-status {
  text-align: center;
  font-size: 12px;
}
/* card 0号组件 */
.card-0 {
  border-radius: 2px;
  border: 1px solid #eeeeee;
  height: 113px;
}
.card-0 .left {
  width: 274px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #eee;
  flex-direction: column;
  justify-content: center;
}
.card-0 .left p:last-child {
  font-weight: bold;
  font-size: 20px;
}

/* 物流状态组件 */
.courier-status {
  flex: 1;
}
.courier-status li .child {
  margin-bottom: 24px;
  position: relative;
}
.courier-status span {
  position: relative;
  display: inline-block;
  height: 100%;
  max-width: 200px;
  word-break: break-all;
}
.courier-status span:first-child {
  width: 40%;
  text-align: right;
  word-break: keep-all;
  margin-right: 45px;
}
.courier-status span:nth-child(2) {
  width: 400px;
  text-align: left;
}
/* .courier-status span:nth-child(2)::before,
.courier-status span:nth-child(3)::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 1px;
  height: calc(100% + 24px);
  background: #e9e9e9;
  transform: translateX(1px);
  z-index: 99; */
/* } */
/* .courier-status span:nth-child(2)::before {
  top: -7px;
  left: -25px;
}
.courier-status span:nth-child(3)::before {
  top: -24px;
  left: -21px;
} */
/* 清除第一个的样式 */
/* .courier-status li:first-child .child:first-child span:nth-child(3)::before,
.courier-status li:first-child .child:first-child span:nth-child(2)::before {
  height: 0;
}
.courier-status li:first-child .child:last-child span:nth-child(3)::before,
.courier-status li:first-child .child:last-child span:nth-child(2)::before {
  height: 100%;
} */
/* .courier-status span:nth-child(2)::after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #c2c2c2;
  border-radius: 50%;
  position: absolute;
  left: -26px;
  bottom: 5px;
  z-index: 100;
} */

.my-coupon .card .flex:nth-child(2) {
  font-size: 14px;
}

.my-coupon .card .flex:nth-child(3) {
  /* width: 1000px; */
  flex-wrap: wrap;
  padding-top: 0;
  /* padding-bottom: 80px; */
  overflow: hidden;
}
.my-flow .flow-header > div {
  flex: 1;
  text-align: center;
  position: relative;
}
.my-flow .flow-header > div p:first-child {
  font-size: 24px;
  line-height: 31px;
  color: #333333;
}
.my-flow .flow-header > div p:nth-child(2) {
  line-height: 21px;
}
.my-flow .flow-header > div a {
  position: absolute;
  bottom: -2px;
  left: calc(50% + 70px);
  transform: translateX(-50%);
  width: 48px;
  height: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 2px;
  font-size: 12px;
  color: #f5222d;
}
.user .table-box {
  min-height: 480px;
  padding-bottom: 60px;
}
.my-flow table {
  text-align: left;
}
.my-flow table th p,
.my-flow table td {
  padding-left: 20px;
}
/* .my-flow table th:last-child {
  width: 40%;
} */
.my-flow table td:last-child {
  word-break: keep-all;
}
.my-star .card:nth-child(3) {
  text-align: right;
}
.my-star .card > a {
  display: inline-block;
  width: 72px;
  height: 28px;
  line-height: 26px;
  background: #ffffff;
  border: 1px solid;
  border-color: #dcdcdc;
  font-size: 12px;
  color: #666;
  text-align: center;
  transition: all 0.3s;
}
.my-star .card > a:hover,
.my-star .card > a.active {
  border-color: #f5222d;
  color: #f5222d;
}
.my-star .goods-list {
  width: 1000px;
}
.my-star .goods-list li {
  width: 22%;
}
.my-add table {
  text-align: left;
}
.my-add table td,
.my-add table th p {
  padding-left: 20px;
}
.my-add table td:nth-last-child(2) a:first-child {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #eee;
}
.setting > .flex {
  padding: 20px 0;
}
.setting .left {
  height: 438px;
  border-right: 1px solid #e9e9e9;
  font-size: 14px;
  line-height: 19px;
}
.setting .left a {
  display: block;
  width: 228px;
  padding: 10px 24px;
  transition: all 0.3s;
}
.setting .left a.active {
  color: #f5222d;
  background: #ffe7e8;
  border-right: 3px solid #f5222d;
}
.setting .left a:hover {
  color: #f5222d;
  background: #ffe7e8;
  border-right: 3px solid #f5222d;
}
.setting .set-right {
  margin-left: 40px;
}
.setting .set-right > p {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  line-height: 26px;
}
.setting .set-right .flex {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.setting .set-right .r-left {
  margin-right: 120px;
}
.setting .set-right input {
  width: 310px;
  height: 32px;
  padding: 5px 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.setting .set-right p span {
  margin: 0 16px;
  color: rgba(0, 0, 0, 0.65);
}
.setting .set-right p a {
  color: #1890ff;
}
.setting .set-right .img-box {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  background: #dddddd;
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
}
.setting .safe-right .bb1 {
  width: 680px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.65);
}
.setting .safe-right .bb1 p {
  margin-bottom: 8px;
}
.setting .safe-right .bb1 a {
  color: #1890ff;
}
</style>