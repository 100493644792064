<template>
  <div class="coupon mb-80">
    <div class="c-nav">
      <div class="c-box flex">
        <router-link to="/">首页</router-link>
        <router-link to="coupon" class="flex"><i></i>优惠券</router-link>
        <span class="right pointer" @click="showExplain">使用规则</span>
        <!-- <router-link to="rule" class="right">使用规则</router-link> -->
      </div>
    </div>
    <div class="c-box coupon-banner">
      <img :src="bannerSrc" alt="coupon-banner" v-if="bannerSrc" />
    </div>
    <div class="c-box couponBox">
      <ul class="c-box flex">
        <li v-for="(value, index) in couponList" :key="index">
          <coupon-items
            :content="value"
            :action="true"
            @get-coupon="getData"
          ></coupon-items>
        </li>
      </ul>
    </div>

    <div class="coupBox bg_white" v-show="coupShow">
      <div class="coupTop flex">
        <span class="fs-16">优惠券说明</span>
        <span class="col_45 pointer" @click="showExplain">X</span>
      </div>
      <div class="coupBody gray6 fs-14">
        <div>优惠券类型分别为现金券，折扣券，减免券，运费券。</div>
        <div class="mt-16">现金券：满足使用条件后，可以抵扣部分金额；</div>
        <div class="mt-16">折扣券：满足使用条件后 可以在订单原价的基础上享受折扣；</div>
        <div class="mt-16">减免券：满足使用条件后 可以减免相应金额；</div>
        <div class="mt-16">运费券：满足使用条件后 可以减免相应运费；</div>
      </div>
      <div class="coupBom flex">
        <div class="col_65 fs-14 coupBtn pointer" @click="showExplain">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCouponList } from '@/http/api'
import couponItems from "../components/couponItems";

export default {
  name: "coupon",
  components: {
    couponItems,
  },
  data() {
    return {
      bannerSrc: "",
      couponList: [],
      coupShow: false
    };
  },
  methods: {
    // 使用规则
    showExplain() {
      this.$store.commit('changeMask');
      this.coupShow = !this.coupShow;
    },
    //获取数据
    async getData() {
      let res = await getCouponList({});
      const { statusCode, data } = res;
      console.log(res);
      data.couponList.data.forEach(item => {
        item.overdue = this.calculateDate(item.use_end_at);
      });
      if(statusCode === 200) {
        this.bannerSrc = data.bannerImg;
        this.couponList = data.couponList.data;
      }
    },
    // 计算过期事件
    calculateDate(endData) {
      let time  = (new Date(endData).getTime() - new Date().getTime()) / 1000;
      if(time > 0) {
        return false;
      }else {
        return true;
      }
    }
  },
  created() {
    //发送请求获取数据
    this.getData();
  },
};
</script>

<style>
.coupon ul {
  width: 1220px;
  flex-wrap: wrap;
}
.coupon li {
  width: 18%;
  margin-bottom: 20px;
  margin-right: 20px;
}
.couponBox {
  margin-top: 20px;
}
</style>