<template>
  <div class="goods">
    <c-nav :nav="navList"></c-nav>
    <div class="goods-header flex c-box mt-16" >
      <div class="g-h-left">
        <div
          class="magnifier-main"
          v-if="magnifierImg"
          v-show="magnifierStyle.display == 'block'"
        >
          <img
            :src=" magnifierImg.main_image"
            :alt="goods_name"
            :style="magnifierMainStyle"
            v-show="magnifierImg.isFacade"
          />
          <img
            :src=" magnifierImg.back_image"
            :alt="goods_name"
            :style="magnifierMainStyle"
            v-show="!magnifierImg.isFacade"
          />
        </div>
        <div class="c-img-box">
          <div
            class="cib"
            @mousemove="magnifierMove($event)"
            @mouseout="magnifierHide"
          ></div>
          <div class="magnifier" :style="magnifierStyle"></div>
          <div class="c-img flex" :style="'right:' + pictureRight + 'px'">
            <div class="ci-main" v-for="(value, index) in picture" :key="index">
              <div class="cim-a" @mouseover="magnifierStyle.display = 'block'" v-if="value.back_image">
                <a
                  href="javascript:;"
                  @click="switchMainImg(index)"
                  class="mb-8"
                  :class="{ active: value.isFacade }"
                >
                  主图
                </a>
                <br />
                <a
                  href="javascript:;"
                  @click="switchMainImg(index)"
                  :class="{ active: !value.isFacade }"
                >
                  背图
                </a>
              </div>
              <img
                :src=" value.main_image + '?imageMogr2/thumbnail/480x'"
                :alt="goods_name"
                v-show="value.isFacade"
              />
              <img
                :src=" value.back_image + '?imageMogr2/thumbnail/480x'"
                :alt="goods_name"
                v-show="!value.isFacade"
              />
            </div>
          </div>
        </div>
        <div class="flex f-a-c small-img-box">
          <a href="javascript:;" class="w_turn-left" @click="changeIndex(0)">
            <i></i>
          </a>
          <div class="flex imgList" style="flex: 1; width: 100%; overflow-x:auto;">
            <div
              class="img-box"
              :class= "{img_boxActive: imgIndex === index}"
              v-for="(value, index) in picture"
              @click="imgIndex = index"
              :key="index"
            >
              <el-image :src="value.main_image + '?imageMogr2/thumbnail/80x'" v-show="value.main_image" :alt="goods_name" lazy></el-image>
            </div>
          </div>
          
          <a href="javascript:;" class="w_turn-right" @click="changeIndex(1)">
            <i></i>
          </a>
        </div>
        <div class="mt-24 flex" style="justify-content: space-between;">
          <a
            href="javascript:;"
            :class="productDetails.isCollection === 2 ? 'x_fill-star-selected' : 'w_star-selected'"
            @click="star()"
          >
            <i class="mr-8"></i>{{productDetails.isCollection === 2 ? '收藏产品' : '已收藏'}}
          </a>
        </div>
        
      </div>
      <div class="g-h-right mt-8">
        <span class="col_f52 fs-18 topPrice">
          ￥<span class="fs-30" v-if="productDetails.minPrice < productDetails.maxPrice">{{productDetails.minPrice ? productDetails.minPrice.toFixed(2) : ''}}~{{productDetails.maxPrice ? productDetails.maxPrice.toFixed(2) : ''}}</span>
            <span class="fs-30" v-else>{{productDetails.minPrice ? productDetails.minPrice.toFixed(2) : ''}}</span>
        </span>
        <p class="g-title ver_mid bold">
          {{ productDetails.full_name}}
        </p>
        <div class="flex mt-16" style="justify-content: space-between; align-items: center;">
          <div class="">
            <div class="col_f52 noBox fs-12 dis_block t-center">款号:{{productDetails.code}}</div>
            <div class="dis_block ver_mid pointer" id="qrcodeBox">
              <img src="../images/icon_code.png" alt="" class="ml-8 ver_mid">
              <img :src="productDetails.qrcode" alt="" class="qrcodeImg">
            </div>
            <img src="../images/wxg.png" alt="" class="ml-5 ver_mid wxgImg">
          </div>
          <div class="asTag fs-12 col_f52" v-if="productDetails.label_data && productDetails.label_data.length">{{productDetails.label_data[0].name}}</div>
        </div>
        <div class="fs-14 mt-16">
          <span class="col_45">所在仓库</span>
          <div class="dis_block ver_top" style="width:calc(100% - 56px);">
            <span class="col_03 ml-12 wareItem" :class="{'wareAct': index === wareIndex}"
            v-for="(item, index) in warehouse" :key="item.id"
            @click="selWarehouse(index)"
            >{{item.name}}</span>
          </div>
        </div>
        <div class="fs-14 mt-16">
          <span class="col_45">标准寄出</span>
          <span class="black ml-12">{{productDetails.message}}</span>
        </div>
        <div class="fs-14 mt-16">
          <span class="col_45">发货速度</span>
          <span class="black ml-12">快</span>
        </div>
        <div class="ver_mid mt-16 rankTag" v-if="productDetails.salesRanking <= productDetails.browseRanking">
          <span class="fs-12">{{productDetails.cat ? productDetails.cat.name + '销量排行榜' : ''}}</span>
          <span class="fs-12">第{{productDetails.salesRanking}}名</span>
        </div>
        <div class="ver_mid mt-16 rankTag" v-else>
          <span class="fs-12">{{productDetails.cat ? productDetails.cat.name + '热度排行榜' : ''}}</span>
          <span class="fs-12">第{{productDetails.browseRanking}}名</span>
        </div>
        <div class="flex mt-24 bb1-d">
          <span class="g-r-title">选择颜色</span>
          <div class="flex g-color-box">
            <a
              href="javascript:;"
              :class="{'active': value.id === colorActive }"
              v-for="(value, index) in productDetails.colour"
              :key="index"
              @click="chooseColor(value.id, index)"
            >
              <i :style="{backgroundImage:'url(' + value.main_image +'?imageMogr2/thumbnail/24x)'}"></i>
              {{ value.spec.name }}
            </a>
          </div>
        </div>
        <div class="flex mt-16 pr-0">
          <span class="g-r-title mt-7">选择尺码</span>
          <div class="g-size-box">
            <div
              class="flex bb1-d f-a-c mb-16"
              v-for="(value, index) in skuList" :key="value.id"
            >
              <span>{{ value.size ? value.size.name : '' }}</span>
              <span>{{ value.price }}元</span>
              <span>{{ value.surplus_stock }}件可售</span>
              <span class="flex">
                <a href="javascript:;" class="iconfont x_reduce" @click="changeNum(0, index)">
                  <i></i>
                </a>
                <input type="text" v-model="value.num" @input="inputNum(value.num, index)"/>
                <a href="javascript:;" class="iconfont x_add active" @click="changeNum(1, index)">
                  <i></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="flex g-count f-a-c mb-16">
          <div class="g-c-j">
            <span class="c-red fs-18">{{ selectedNum }}</span>
            <span class="c-red">件</span>
          </div>
          <div class="g-c-y">
            <span class="c-red fs-18">{{ sum }}</span>
            <span class="c-red">元</span>
          </div>
          <div class="g-selected" :class="selectedShow && 'active'">
            <a href="javascript:;" class="w_up" @click="changeSelectedShow">
              已选清单
              <i class="ml-4"></i>
            </a>
          </div>
          <div class="g-selected-box" :class="selectedShow && 'active'">
            <div
              class="g-selected-item"
              v-for="(value, index) in selectedList"
              :key="index"
            >
              <span class="selected-color">{{ value.color }}</span>
              <span class="selected-num">{{ value.num }}件</span>
              <div class="selected-size-box">
                <span
                  class="selected-size"
                  v-for="size in value.size"
                  :key="size.id"
                >
                  {{ size.size }} ({{ size.num }})
                </span>
              </div>
            </div>
            <div class="g-selected-item" v-show="selectedList.length == 0">
              <span>暂时没有哦~</span>
            </div>
          </div>
        </div>
        <div class="flex">
          <a href="javascript:;" class="bg-w-btn mr-16 white bg_black" @click="toSettle"
            >立即下单</a
          >
          <a href="javascript:;" class="bg-r-btn" @click="toCart">加入购物车</a>
        </div>
      </div>
    </div>
    <div class="g-maybe-star c-box">
      <p class="mb-16">可能感兴趣</p>
      <div class="flex gms-box">
        <a
          v-for="value in maybeStar"
          :key="value.id"
          href="javascript:;"
          class="mr-20"
          @click="toGoodsDetail(value.hashid)"
        >
          <div class="gmsb-img-box mb-8">
            <el-image :src=" value.image + '?imageMogr2/thumbnail/130x'" :alt="value.full_name" lazy></el-image>
          </div>
          <p class="ellipsis mb-8">{{ value.full_name }}</p>
          <p>
            <span class="fs-12">￥</span>
            <span class="mr-8">{{ value.price }}</span>
            <span class="fs-12">销量{{ value.sales_num }}件</span>
          </p>
        </a>
      </div>
    </div>
    <div class="g-body c-box flex">
      <div class="g-watch">
        <p class="mb-16">看了又看</p>
        <a
          v-for="value in gWatch"
          :key="value.id"
          class="mb-16 gw-link"
          href="javascript:;"
          @click="toGoodsDetail(value.hashid)"
        >
          <div class="gwl-img-box mb-8">
            <el-image :src=" value.image + '?imageMogr2/thumbnail/160x'" :alt="value.image" lazy></el-image>
          </div>
          <p class="ellipsis mb-8">{{ value.full_name }}</p>
          <p class="c-red">￥{{ value.price }}</p>
        </a>
      </div>
      <div class="gb-item">
        <div class="gbi-link flex">
          <div
            class="gbil-a-box"
            :class="{ active: watchType == 1 }"
            @click="watchType = 1"
          >
            <a href="javascript:;">商品详情</a>
          </div>
          <!-- <div
            class="gbil-a-box"
            :class="{ active: watchType == 2 }"
            @click="watchType = 2"
          >
            <a href="javascript:;">商品参数</a>
          </div> -->
        </div>
        <div class="gbi-body" v-show="watchType == 1">
          <div class="flex gbi_information">
            <div v-for="(item, index) in additional" :key="'msg' + index" class="infor_row">
              <span>{{item.key}}</span>
              <span :class="{size36: item.key === '特殊说明' }">{{item.value}}</span>
            </div>
          </div>
          <div class="attributeBox mt-40 dis_block">
            <div class="flex" v-for="item in attributeList" :key="item.id">
              <div class="fs-14 bold" style="padding: 0 30px 20px;">{{item.title}}</div>
              <div class="attrLine mt-10">
                <div v-for="attr in item.children" :key="attr.id">
                  <span class="attrActive" v-if="attr.active"></span> 
                  {{attr.name}}
                </div>
              </div>
            </div>
          </div>
          <img :src="styleImg" alt="款式图" style="width: 30%; vertical-align: top;" class="ml-32">
          <el-image lazy :src="item.src" alt="商品详情图" v-for="item in detailImgList" :key="item.id"></el-image>
          <div class="flex mt-20" style="flex-wrap:wrap;">
            <div class="randomBox" v-for="item in randomList" :key="item.id" @click="toGoodsDetail(item.hashid)">
              <img :src="item.image" alt="" style="width:160px; height:160px;">
              <div class="ellipsis mt-10 t-c">{{item.full_name}}</div>
              <div class="t-c mt-10 c-red bold">￥{{item.price}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productDetails, productRanking, addCart, randomProductList, collection, delProduct, getCartNum } from '@/http/api'
export default {
  name: "goods",
  data() {
    return {
      navList: [
        {
          path: "/house",
          text: "服饰仓库",
        },
      ],
      //商品id
      id: "",
      //产品主图
      picture: [],
      //当前显示的主图索引
      imgIndex: 0,
      //放大镜style
      magnifierStyle: {
        top: 0,
        left: 0,
        display: "none",
      },
      //放大镜图片style
      magnifierMainStyle: {
        top: 0,
        left: 0,
      },
      //商品名称
      goods_name: "",
      //是否未收藏(0未收藏,1已收藏)
      is_collect: "",
      //仓库
      warehouse: null,
      //仓库ID
      warehouseId: "",
      wareIndex: 0,
      //最低价格
      min_price: "",
      //最高价格
      max_price: "",
      //价格
      price: "",
      //起售件
      initial_sale: "",
      //销量
      sales: "",
      //商品详细信息
      goods_list: [],
      //当前显示的颜色列表索引
      colorIndex: 0,
      //已选清单显示
      selectedShow: false,
      //可能感兴趣
      maybeStar: [],
      //看了又看
      gWatch: [],
      //富文本 详情
      goods_desc: "",
      //查看的类型 1是商品详情 2是商品参数
      watchType: 1,

      // 分类id
      catId: '',
      // 商品详情数据
      productDetails: [],
      // 选择颜色
      colorActive: -1,
      // 颜色数据
      colorList: [],
      // 颜色sku数据
      skuList: [],
      // 商品详情图
      detailImgList: [],
      // 商品款式图
      styleImg: '',
      // 商品详情信息
      additional: [],
      attributeList: [
        { id: 1, title: '厚度指数', 
          children: [
            { id: '11', name: '偏薄', active: false},
            { id: '12', name: '常规', active: false},
            { id: '13',name: '厚', active: false},
            { id: '14',name: '加厚', active: false},
          ]
        },
        {
          id: 2, title: '弹力指数',
          children: [
            { id: '21', name: '无弹', active: false},
            { id: '22', name: '微弹', active: false},
            { id: '23', name: '常规', active: false},
            { id: '24', name: '高弹', active: false},
          ]
        },
        {
          id: 3, title: '面料指数',
          children: [
            { id: '31', name: '柔软', active: false},
            { id: '32', name: '常规', active: false},
            { id: '33', name: '硬', active: false},
            { id: '34', name: '偏硬', active: false},
          ]
        },
        {
          id: 4, title: '版型指数',
          children: [
            { id: '41', name: '紧身', active: false},
            { id: '42', name: '修身', active: false},
            { id: '43', name: '常规', active: false},
            { id: '44', name: '宽松', active: false},
          ]
        }
      ],
      collectionIdArr: [],
      // 随机列表
      randomList: []
    };
  },
  watch: {
    $route: function(newVal) {
      console.log(newVal);
      this.id = newVal.params.pathMatch;
      this.getProdctDetail();
    }
  },
  computed: {
    //主图偏移量
    pictureRight() {
      return this.imgIndex * 580;
    },
    //放大镜显示的图片
    magnifierImg() {
      if (this.picture.length > 0) {
        return this.picture[this.imgIndex] || [];
      } else {
        return null;
      }
    },
    //用户已选择的商品
    selected() {
      let arr = [];
      this.colorList.forEach(item => {
        if(item.sku) {
          item.sku.forEach(skuItem => {
            if(skuItem.num > 0) {
              arr.push({
                pId: item.id,
                id: skuItem.id,
                color: item.spec.name,
                size: skuItem.size.name,
                price: skuItem.price,
                num: skuItem.num
              })
            }
          });
        }
      });
      return arr;
    },
    //选择商品的总数量
    selectedNum() {
      let num = 0;
      for (const iterator of this.selected) {
        num += iterator.num;
      }
      return num;
    },
    //选择商品的总价格
    sum() {
      let num = 0;
      for (const iterator of this.selected) {
        num += iterator.num * iterator.price;
      }
      return num.toFixed(2);
    },
    //已选清单
    selectedList() {
      let arr = [],
      colorArr = [];
      // console.log(this.colorList);
      //保存选择的颜色
      for (const iterator of this.selected) {
        colorArr.push(iterator.color);
      }
      //去重
      colorArr = new Set(colorArr);
      //保存初始化的已选清单列表
      for (const color of colorArr) {
        arr.push({
          id: "",
          color: color,
          size: [],
          num: 0,
        });
      }
      //给已选清单添加尺寸和id
      for (const iterator of this.selected) {
        for (const item of arr) {
          if (iterator.color == item.color) {
            item.size.push({
              size: iterator.size,
              num: iterator.num,
              id: iterator.id,
            });
          }
        }
      }
      //计算数量
      for (const iterator of arr) {
        for (const size of iterator.size) {
          iterator.num += size.num;
        }
      }

      return arr;
    },
    //已选清单高度
    selectedHeight() {
      let num;
      if (this.selectedShow && this.selectedList.length > 0) {
        num = this.selectedList.length * 37;
      } else if (this.selectedShow) {
        num = 37;
      } else {
        num = 0;
      }
      return num + "px";
    },
    //跳至结算界面需要发送的数据
    settleData() {
      let orderData = [];
      let proObj = {
        warehouseId: this.warehouseId,
        productData: []
      }
      let productData = {
          productId: this.productDetails.hashid,
          colourArr: []
      }
      this.colorList.forEach(item => {
        let colourObj = {
          colourId: item.spec.hashid,
          sizeArr: []
        }
        item.sku.forEach(skuItem => {
          if(skuItem.num > 0) {
            colourObj.sizeArr.push({
              number: skuItem.num,
              sizeId: skuItem.size.hashid
            })
          }
        })
        if(colourObj.sizeArr.length) {
          productData.colourArr.push(colourObj);
        }
      })
      proObj.productData.push(productData);
      orderData.push(proObj);
      return orderData;
    },
  },
  methods: {
    
    //改变获取的图片数据
    newPicture(list) {
      let arr = [];
      for (const iterator of list) {
        arr.push({
          ...iterator,
          isFacade: true,
        });
      }
      return arr;
    },
    //改变获取的颜色数据
    newColor(list) {
      let arr = [];
      for (const iterator of list) {
        arr.push({
          ...iterator,
          isChoose: false,
        });
        for (const color_stock of iterator.color_stock) {
          color_stock.num = 0;
        }
      }
      if (arr.length > 0) arr[0].isChoose = true;
      return arr;
    },
    newSku(list) {
      list.forEach(item => {
        if(item.sku) {
          item.sku.forEach(skuItem => {
            skuItem.num = 0;
          })
        }
      });
    },
    //主背图切换
    switchMainImg(index) {
      this.picture[index].isFacade = !this.picture[index].isFacade;
    },
    //放大镜
    magnifierMove(e) {
      // let magnifier = e.path[3].firstChild;
      if (e.offsetY <= 100) {
        this.magnifierStyle.top = 0;
        this.magnifierMainStyle.top = 0;
      } else if (e.offsetY >= 480) {
        this.magnifierStyle.top = 380 + "px";
        this.magnifierMainStyle.top = -742 + "px";
      } else {
        this.magnifierStyle.top = e.offsetY - 100 + "px";
        this.magnifierMainStyle.top = -(((e.offsetY - 100) / 380) * 742) + "px";
      }

      if (e.offsetX <= 100) {
        this.magnifierStyle.left = 0;
        this.magnifierMainStyle.left = 0;
      } else if (e.offsetX >= 480) {
        this.magnifierStyle.left = 380 + "px";
        this.magnifierMainStyle.left = -742 + "px";
      } else {
        this.magnifierStyle.left = e.offsetX - 100 + "px";
        this.magnifierMainStyle.left =
          -(((e.offsetX - 100) / 380) * 742) + "px";
      }
      // console.log(e.offsetX);
      this.magnifierStyle.display = "block";
    },
    //隐藏放大镜
    magnifierHide() {
      this.magnifierStyle.display = "none";
    },
    //切换图片显示
    changeIndex(type) {
      if (type == 0 && this.imgIndex > 0) {
        this.imgIndex--;
      } else if (type == 1 && this.imgIndex < this.picture.length - 1) {
        this.imgIndex++;
      }
    },
    // 选择仓库
    selWarehouse(index) {
      this.wareIndex = index;
      this.warehouseId = this.warehouse[index].hashid;
    },
    //选择颜色
    chooseColor(id, index) {
      this.colorActive = id;
      this.colorList.forEach(item => {
        if(item.id === id) {
          this.skuList = item.sku ? JSON.parse(JSON.stringify(item.sku)) : [];
        }
      });
      this.colorIndex = index;
      this.imgIndex = index;
    },
    //修改选择的颜色数量
    changeNum(type, index) {
      let num = this.skuList[index].num;
      let stock = this.skuList[index].surplus_stock;
      if(type == 0 && num > 0) {
        this.skuList[index].num--;
        this.colorList[this.colorIndex].sku[index].num = this.skuList[index].num;
      }else if (type == 1 && num < stock) {
        //点击加号 当前数量小于库存
        this.skuList[index].num++;
        this.colorList[this.colorIndex].sku[index].num = this.skuList[index].num;
      }
    },
    //用户自行输入数量
    inputNum(value, index) {
      //当前数量和库存
      let stock = this.skuList[index].surplus_stock;
      if (isNaN(value) || value > stock) {
          this.skuList[index].num = this.colorList[this.colorIndex].sku[index].num = 0;
          this.$message.warning('请输入正确的数量');
      } else {
        this.skuList[index].num = this.colorList[this.colorIndex].sku[index].num = +value;
      }
    },
    //查看已选清单
    changeSelectedShow() {
      this.selectedShow = !this.selectedShow;
    },
    //收藏商品
    star() {
      if(this.productDetails.isCollection === 1) {
        this.delProduct();
      }else {
        this.collection();
      }
    },
    //加入购物车
    toCart() {
      if(!window.localStorage.getItem('token')) {
        this.$message.error('尚未登录');
        this.$router.push('/login');
        return;
      }
      if(this.selected.length === 0) {
        this.$message.warning('请选择商品尺码');
      }else {
        this.addCart();
      }
      
    },
    //立即下单
    toSettle() {
      let flag = false;
      this.settleData.forEach(item => {
        item.productData.forEach(pro => {
          pro.colourArr.forEach((col,index) => {
            if(col.sizeArr.length === 0) {
              pro.colourArr.splice(index, 1);
              // delete col.sizeArr;
            }
            else {
              col.sizeArr.forEach(size => {
                if(size.number > 0) {
                  flag = true;
                  return;
                }
              })
            }
          })
        })
      })
      console.log(this.settleData);
      // console.log(flag);
      if (!flag) {
        return this.$message.warning('请选择商品');
      } else {
        this.$store.commit("saveSettleData", this.settleData);
        // this.$store.commit("saveSettleType", 1);
        this.$router.push("/settle");
      }
    },
    // 初始化商品属性
    initAttributeList() {
      this.attributeList.forEach(item => {
        item.children.forEach(child => {
          child.active = false;
        })
      })
    },
    // 跳转到详情页面
    toGoodsDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
    //--------------获取数据 &api--------------------------------
    // 获取商品详情数据
    async getProdctDetail() {
      let res = await productDetails({productId: this.id});
      const { statusCode } = res;
      if(statusCode === 200) {
        const { productDetails } = res.data;
        this.catId = productDetails.cat.id;
        this.getProductRanking(5);
        console.log(this.catId);
        this.productDetails = productDetails;
        this.navList.push({
          path: "",
          text: productDetails.full_name,
        },)
        // 设置数量
        this.newSku(this.productDetails.colour);
        this.colorList = JSON.parse(JSON.stringify(this.productDetails.colour));
        // this.skuList = productDetails.colour;
        // console.log(this.skuList[this.colorIndex]);
        this.picture = this.newPicture(productDetails.colour);
        this.warehouse = productDetails.warehouse_data;
        this.warehouseId = productDetails.warehouse_data.length ? productDetails.warehouse_data[this.wareIndex].hashid : '';
        this.is_collect = productDetails.isCollection;
        this.detailImgList = productDetails.detail_image;
        this.styleImg = productDetails.style_image;
        this.additional = productDetails.additional;
        
        if(productDetails.collectionId) {
          this.collectionIdArr.push(productDetails.collectionId);
        }
        
        // 产品属性
        this.initAttributeList();
        this.attributeList[0].children[productDetails.thickness -1].active = true;
        productDetails.elasticity ? this.attributeList[1].children[productDetails.elasticity -1].active = true : '';
        this.attributeList[2].children[productDetails.fabric -1].active = true;
        this.attributeList[3].children[productDetails.plate -1].active = true;
        // 默认选择第一个颜色
        this.chooseColor(this.productDetails.colour[0].id, 0);

      }
    },
    // 获取榜单列表
    async getProductRanking(type) {
      console.log(this.catId);
      let params = {};
      if(type == 3) {
        params = {
          type: type,
          catId: '',
          limit: 5
        }
      }else if(type ==5){
        params = {
          type: type,
          catId: this.catId,
          limit: 20
        }
      }
      let res = await productRanking(params);
      const { statusCode } = res;
      if(statusCode === 200) {
        if(type == 3) {
          const { productList } = res.data;
          this.gWatch = productList.data;
        }else if(type == 5) {
          const { productList } = res.data;
          this.randomList = productList.data;
        }
        
      }
    },
    // 获取随机产品列表数据
    randomProductList() {
      randomProductList({limit: 8}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.maybeStar = data;
        }
      })
    },
    // 加入购物车
    addCart() {
      let resList = [];
      this.selected.forEach(item =>{
        let obj = {
          productId: this.productDetails.hashid,
          number: item.num,
          sku_id: item.id
        }
        resList.push(obj);
      })
      let params = {
       productData: resList,
       warehouseId: this.warehouseId
      }
      addCart(params).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('加入购物车成功');
          this.getCartNum();
        }else {
          this.$message.error('加入购物车失败');
        }
      })
    },
    // 收藏产品
    collection() {
      collection({productId: this.id}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('收藏成功');
          this.$store.commit('changeCollectNumber' , 1);
          this.getProdctDetail();
        }else {
          this.$message.error('收藏失败');
        }
      })
    },
    // 取消收藏
    delProduct() {
      delProduct({collectionIdArr: this.collectionIdArr}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('已取消收藏');
          this.$store.commit('changeCollectNumber' , -1);
          this.getProdctDetail();
        }else {
          this.$message.error('取消收藏失败');
        }
      })
    },
    // 获取购物车数量
    getCartNum() {
      getCartNum().then(res => {
      const { statusCode } = res;
      if(statusCode === 200) {
        this.$store.commit('saveCartNumber', res.orderCartNum);
      }
    });
    }
  },
  created() {
    //保存跳转进入的商品ID
    this.id = this.$router.currentRoute.params.pathMatch;
    this.getProdctDetail();
    this.randomProductList();
    this.getProductRanking(3);
    // this.getProductRanking(5);
  },
};
</script>

<style>
/* 产品详情 */
.goods-header {
  margin-bottom: 32px;
}

.g-h-left {
  width: 480px;
  margin-right: 32px;
  position: relative;
}
.goods-header a i {
  transform: translateY(2px);
  opacity: 0.25;
}
.goods-header a.w_star-fill i {
  opacity: 1;
}
.goods-header .c-img-box {
  width: 480px;
  height: 480px;
  overflow: hidden;
  border: 1px solid #eee;
  position: relative;
}
.goods-header .c-img {
  width: 10000px;
  position: relative;
  transition: all 0.6s;
  right: 0;
}
.cib {
  width: 480px;
  height: 480px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  cursor: move;
}
.magnifier {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.magnifier-main {
  position: absolute;
  top: 0;
  left: 480px;
  width: 420px;
  height: 420px;
  overflow: hidden;
  border: 1px solid #eee;
  z-index: 90;
}
.magnifier-main img {
  position: relative;
  width: 1160px;
}
.ci-main {
  position: relative;
  width: 580px;
}
.cim-a {
  z-index: 91;
  position: absolute;
  top: 10px;
  left: 10px;
}
.cim-a a {
  display: inline-block;
  padding: 0 16px;
  text-align: center;
  height: 32px;
  line-height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  transition: all 0.3s;
  background-color: #fff;
}
.cim-a a.active,
.cim-a a:hover {
  background-color: #f5222d;
  color: #fff;
  border: 1px solid #f5222d;
}
.small-img-box {
  width: 480px;
  justify-content: space-between;
  margin-top: 20px;
}
.goods-header .c-img img {
  width: 480px;
  height: 480px;
}
.goods-header .img-box {
  width: 80px;
  height: 80px;
  background-color: #ddd;
  margin-right: 8px;
  cursor: pointer;
  flex-shrink: 0;
}
.goods-header .x_fill-star-selected,
.goods-header .w_star-selected {
  display: inline-block;
}
.goods-header .x_fill-star-selected:hover i {
  opacity: 1;
  background-image: url("../images/star-fill.png");
}
.goods-header .w_star-selected i {
  opacity: 1;
}
.g-title {
  max-width: 547px;
  color: #000;
  line-height: 26px;
  font-size: 18px;
}
.g-h-right > .flex {
  padding: 0 0 12px;
}

.g-r-title {
  width: 88px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 19px;
  flex-shrink: 0;
}
.g-r-title_red {
  color: #f5222d!important;
}
.g-p-b-left .flex {
  align-items: flex-end;
}
.g-p-b-left .flex:first-child .g-r-title {
  transform: translateY(-6px);
}
.g-p-b-left span:nth-child(4) {
  margin: 0 8px;
  color: #f5222d;
  transform: translateY(-6px);
}
.p-yuan {
  color: #f5222d;
  font-size: 18px;
  transform: translateY(-3px);
}
.p-yuan + span {
  color: #f5222d;
  font-size: 30px;
}

.g-h-right .z {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.g-h-right .z + span {
  padding-right: 8px;
  border-right: 1px solid #ddd;
  margin-right: 8px;
}
.g-color-box {
  width: 480px;
  flex-wrap: wrap;
  flex-shrink: 0;
  overflow: hidden;
}
.g-color-box a {
  display: flex;
  align-items: center;
  /* width: 72px; */
  height: 34px;
  padding: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
  border: 1px solid;
  border-color: #ccc;
  flex-shrink: 0;
  transition: all 0.3s;
}
.g-color-box a:hover,
.g-color-box a.active {
  border-color: #f5222d;
}
.g-color-box a i {
  opacity: 1;
  width: 24px;
  height: 24px;
  background-color: #eee;
  margin-right: 8px;
  transform: translateY(0);
}
.g-size-box {
  width: 100%;
  height: 205px;
  overflow-y: scroll;
}
.g-size-box > div {
  padding-bottom: 8px;
}
.g-size-box span {
  flex: 1;
}
.g-size-box span:first-child {
  font-weight: bold;
}
.g-size-box span input {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: none;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 6px 8px;
}
a.x_reduce,
a.x_add {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 1px solid #cccccc;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}
a.x_reduce.active i,
a.x_add.active i {
  opacity: 1;
}
.g-h-right {
  width: calc(100% - 512px);
}
.g-h-right > .flex.g-count {
  position: relative;
  width: 100%;
  height: 48px;
  background: #f9f9f9;
  padding: 0;
}
.g-c-j {
  width: 148px;
  padding-right: 16px;
  text-align: right;
  border-right: 1px solid #ddd;
}
.g-c-y {
  width: 260px;
  padding-left: 16px;
  border-right: 1px solid #ddd;
}
.g-c-j span:first-child,
.g-c-y span:first-child {
  font-weight: bold;
}
/* 已选清单 */
.g-selected {
  width: 168px;
  flex-shrink: 0;
  text-align: center;
  overflow: hidden;
}
.g-selected i {
  transition: all 0.3s;
  transform: rotate(0deg);
}
.g-selected.active i {
  transform: rotate(180deg);
}
.g-selected-box {
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
  max-height: 0px;
  transition: all 0.3s;
  overflow: hidden;
  background: #fff;
}
.g-selected-box.active {
  max-height: 999px;
}
.g-selected-item {
  border: 1px solid #ddd;
}
.g-selected-item:not(:last-child) {
  border-bottom: none;
}
.selected-size-box,
.g-selected-item {
  display: flex;
}
.g-selected-item span {
  display: inline-block;
}
.selected-color,
.selected-num {
  width: 148px;
  padding: 8px;
  flex-shrink: 0;
  border-right: 1px solid #ddd;
}
.selected-size-box {
  flex-wrap: wrap;
  padding: 0 8px;
  padding-top: 8px;
}
.selected-size {
  margin-right: 8px;
  margin-bottom: 8px;
}

.g-h-right > .flex:last-child {
  justify-content: flex-end;
}
.g-h-right .bg-w-btn,
.g-h-right .bg-r-btn {
  width: 160px;
  height: 48px;
  line-height: 46px;
}
.g-maybe-star {
  margin-bottom: 80px;
}
.g-maybe-star > p {
  font-size: 18px;
  line-height: 24px;
}
.gms-box > a {
  width: 130px;
}
.gms-box > a .gmsb-img-box {
  width: 130px;
  height: 130px;
  background-color: #ddd;
}
.gms-box > a .gmsb-img-box img {
  width: 100%;
  height: 100%;
}
.gms-box > a span:last-child {
  color: rgba(0, 0, 0, 0.45);
}
.g-watch {
  width: 180px;
  margin-right: 20px;
  text-align: center;
}
.g-watch > p {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
.g-watch > p::before,
.g-watch > p::after {
  content: "";
  display: inline-block;
  width: 44px;
  height: 1px;
  background: #eeeeee;
  transform: translateY(-4px);
}
.g-watch > p::before {
  margin-right: 18px;
}
.g-watch > p::after {
  margin-left: 18px;
}
.g-watch a {
  display: inline-block;
  width: 160px;
}
.gwl-img-box {
  width: 100%;
}
.gwl-img-box img {
  width: 160px;
  height: 160px;
}
.gb-item {
  flex: 1;
}
.gb-item .gbi-link {
  border: 1px solid #e5e5e5;
}
.gb-item .gbi-link .gbil-a-box {
  flex: 1;
  text-align: center;
  font-size: 18px;
  padding: 24px 0;
}
.gb-item .gbi-link .gbil-a-box a {
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 4px solid;
  border-color: rgba(0, 0, 0, 0);
  transition: all 0.3s;
}
.gb-item .gbi-link a:hover,
.gb-item .gbi-link .active a {
  border-color: #f5222d!important;
}
.gbi-body img {
  width: 100%;
}
.gbi-item {
  border: 1px solid #e5e5e5;
  margin-top: -1px;
}
.gbi-item p,
.gbi-item .flex span {
  height: 30px;
  line-height: 29px;
}
.gbi-item p {
  color: #404040;
  font-weight: 700;
  background-color: #f7f7f7;
  padding-left: 16px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  margin-top: -1px;
}
.gbi-item .flex:not(:last-child) {
  border-bottom: 1px solid #f7f7f7;
}
.gbi-item .flex span:first-child {
  width: 150px;
  text-align: right;
  margin-right: 8px;
}
.gbi-item .flex span:last-child {
  flex: 1;
}
.img_boxActive {
  border: 1px solid #4273b0;
}
.blueActive {
  border: 1px solid rgb(55, 163, 255);
}
.gbi-body {
  border: 1px solid #e5e5e5;
  padding: 40px 20px;
}
.gbi_information {
  flex-direction: column;
}
.infor_row {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #e5e5e5;
  padding: 10px 20px;
  font-size: 14px;
}
.infor_row span {
  padding: 10px 20px;
}
.infor_row span:nth-child(1) {
  width: 120px;
  font-weight: bold;
  text-align: center;
}
.size36 {
  font-size: 36px;
  color: rgb(255, 0, 0)
}
.rankBox {
  border: 1px solid #DDDDDD;
}
.rankTitle {
  display: flex;
  background: #F7F7F7;
  font-size: 16px;
}
.rankTitle_item {
  padding: 14px;
  margin-right: 5px;
  cursor: pointer;
}
.rankTitle_item:hover {
  color: #fff;
  background: #37a5ff;
}
.r_active {
  color: #fff;
  background: #37a5ff;
}
.rank_content_item {
  width: 20%;
  padding: 15px 30px;
  text-align: center;
  cursor: pointer;
}
.rank_content_item img {
  width: 100%;
}
.rank_content_item .title {
  margin: 10px 0;
}
.rank_content {
  display: flex;
  padding: 40px 20px;
}
.rank_content_item .price {
  color: red;
  font-weight: bold;
}
.rankTag {
  font-size: 0;
}
.rankTag span{
  padding: 4px 8px;
}
.rankTag span:nth-child(1) {
  background: #000;
  color: #fff;
}
.rankTag span:nth-child(2) {
  background: #FFD500;
}
.attrLine {
  flex: 1;
  display: flex;
  text-align: center;
}
.attrLine div {
  position: relative;
  width: 25%;
  padding-top: 12px;
  border-top: 1px solid #ccc;
}
.attrActive {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -6px;
  width: 10px;
  height: 10px;
  background: #ccc;
}
.noBox {
  height: 32px;
  line-height: 32px;
  border: 1px solid #F5222D;
  padding: 0 10px;
  vertical-align: middle;
}
.topPrice {
  float: right;
}
#qrcodeBox {
  position: relative;
}
#qrcodeBox:hover .qrcodeImg {
  display: block;
}
.qrcodeImg {
  z-index: 9;
  position: absolute;
  top: 34px;
  left: 0;
  display: none;
  width: 160px;
  height: 160px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 50%);
  border-radius: 10px;
}
.wxgImg {
  position: relative;
  animation: move 0.5s infinite alternate linear;
}
.attributeBox {
  width: 65%;
}
.g-size-box::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
.g-size-box::-webkit-scrollbar-thumb {
  border-radius:4px;
  background:#DDDDDD;
}
.g-size-box::-webkit-scrollbar-track {
  background: #EEEEEE;
}
.imgList::-webkit-scrollbar {
  height: 8px;
  border-radius: 4px;
}
.imgList::-webkit-scrollbar-thumb {
  border-radius:4px;
  background:#DDDDDD;
}
.imgList::-webkit-scrollbar-track {
  background: #EEEEEE;
}
.wareItem {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #DDDDDD;
  cursor: pointer;
  margin-bottom: 10px;
}
.wareAct {
  border: 1px solid #f5222d;
}
.el-image {
  width: 100%;
  height: 100%;
}
.randomBox {
  width: 160px;
  margin-right: 34px;
  margin-bottom: 30px;
  cursor: pointer;
}
.randomBox:nth-child(5n) {
  margin-right: 0;
}

@keyframes move {
  0% {
    left: 0px;
  }
  50% {
    left: 8px;
  }
  100% {
    left: 0px;
  }
}
</style>