<template>
  <div class="wechatpay flex">
    <div class="pay-left">
      <div class="pay-QRcode-box">
        <img :src="code_url" :alt="errorMsg" v-if="code_url"/>
      </div>
      <div class="x_scanning flex f-a-c">
        <i></i>
        <span>请使用微信扫一扫扫描二维码支付</span>
      </div>
    </div>
    <div class="pay-right">
      <img src="../../images/wxScan.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { wechatPay, wxRechargePay } from '@/http/api';
export default {
  name: "wechatpay",
  data() {
    return {
      code_url: '',
      errorMsg: ''
    };
  },
  computed: {
    ...mapState(["payData"])
  },
  created() {
    if(this.payData.isRecharge) {
      this.wxRechargePay();
    }else {
      this.wechatPay();
    }
  },
  methods: {
    // 调起微信支付
    wechatPay() {
      wechatPay({ orderId: this.payData.orderId }).then(res => {
        const { statusCode, code_url, msg } = res;
        if(statusCode === 200) {
          this.code_url = code_url;
        }else {
          this.errorMsg= msg
        }
      });
    },
    // 调起充值微信支付
    wxRechargePay() {
      wxRechargePay({ orderId: this.payData.orderId }).then(res => {
        const { statusCode, code_url, msg } = res;
        if(statusCode === 200) {
          this.code_url = code_url;
        }else {
          this.errorMsg= msg
        }
      })
    }
  },
  mounted() {},
  destroyed() {},
};
</script>

<style>
</style>