<template>
  <div class="new-alert card">
    <div class="n-a-header">
      <span>新建地址</span>
      <a href="javascript:;" class="x_line-close" @click="$emit('close')"
        ><i></i
      ></a>
    </div>
    <div class="n-a-body bb1">
      <div class="flex">
        <span class="required">收货人姓名：</span>
        <input type="text" v-model="name" placeholder="请输入收货人姓名" />
      </div>
      <div class="flex">
        <span class="required">手机号码：</span>
        <input type="tel" v-model="tel" placeholder="请输入手机号码" />
      </div>
      <div class="flex">
        <span class="required">地址信息：</span>
        <el-cascader
          v-model="addressValue"
          :options="options"
          @change="handleChange">
        </el-cascader>
      </div>
      <div class="flex">
        <span class="required">详细地址：</span>
        <input
          type="text"
          v-model="address"
          placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"
        />
      </div>
      <div class="flex">
        <span>邮政编码：</span>
        <input type="text" v-model="postcode" placeholder="请填写邮政编码" />
      </div>
    </div>
    <div class="n-a-footer">
      <a href="javascript:;" class="bg-w-btn mr-8" @click="$emit('close')">
        取 消
      </a>
      <a href="javascript:;" class="bg-r-btn" @click="newAdd">确 定</a>
    </div>
  </div>
</template>

<script>
import { options } from '../assets/location.js';
import { addAddress, saveAddress } from '@/http/api';
export default {
  name: "newAdd",
  props: ["addressData"],
  // props: {
  //   data: {
  //     type: Object,
  //     default() {
  //       return {
  //         //收货人姓名
  //         name: "",
  //         //手机号
  //         tel: "",
  //         //选择的省
  //         province: null,
  //         //选择的市
  //         city: null,
  //         //选择的区
  //         country: null,
  //         //详细地址
  //         address: "",
  //         //邮政编码
  //         postcode: "",
  //         //新建还是修改地址 0是新建 1是修改
  //         addType: 0,
  //         //选择修改的地址索引
  //         addId: 0,
  //       };
  //     },
  //   },
  // },
  data() {
    return {
      //详细地址
      address: '',
      //邮政编码
      postcode: '',
      //新建还是修改地址 0是新建 1是修改
      addType: 0,
      //选择修改的地址索引
      addId: '',
      //收货人姓名
      name: '',
      //手机号
      tel: '',
      //选择的省
      province: '',
      //选择的市
      city: '',
      //选择的区
      country: '',


      // 地址级联选择
      addressValue: null,
      options: []
    };
  },
  created() {
    this.options = options;
  },
  computed: {
  },
  methods: {
    // 新增地址 && 修改地址
    newAdd() {
      if (!this.name) {
        //检查是否输入姓名
        this.$message.warning("请输入姓名");
      }else if(this.addressValue == null) {
        this.$message.warning("请选择地址信息");
      }else if (!this.address) {
        //是否输入详细地址
        this.$message.warning("请输入详细地址");
      } else if (this.testTel(this.tel)) {
        //以上信息全输入了 并且通过手机号格式测试
        switch (this.addType) {
          //新增地址
          case 0: {
            this.addAddress();
            this.$emit("add-suc");
            this.$emit("close");
            break;
          }
          //修改地址
          case 1: {
            this.saveAddress();
            this.$emit("add-suc");
            this.$emit("close");
            break;
          }
        }
      }
    },
    // 监听选择地址
    handleChange() {
      this.province = this.addressValue[0];
      this.city = this.addressValue[1];
      this.country = this.addressValue[2];
    },
    //----------获取数据 &api-------------
    // 新增收货地址
    addAddress() {
      let params = {
        name: this.name,
        phone: this.tel,
        province: this.province,
        city: this.city, 
        district: this.country,
        detail: this.address,
        code: this.postcode,
      }
      addAddress(params).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('添加成功');
        }else {
          this.$message.error('添加失败');
        }
      })
    },
    // 保存地址
    saveAddress() {
      let params = {
        addressId: this.addId,
        name: this.name,
        phone: this.tel,
        province: this.province,
        city: this.city, 
        district: this.country,
        detail: this.address,
        code: this.postcode,
      }
      saveAddress(params).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
        }else {
          this.$message.error(msg);
        }
      })
    },
  },
  watch: {
    addressData: {
      handler(value) {
        this.addType = value.addType;
        if(this.addressData.addType == 0) {
          this.name = '';
          this.tel = '';
          this.province = '';
          this.city = '';
          this.country = '';
          this.address = '';
          this.postcode = '';
          this.addId = '';
          this.addressValue = null
        }else {
          this.name = value.name;
          this.tel = value.phone;
          this.province = value.province;
          this.city = value.city;
          this.country = value.district;
          this.address = value.detail;
          this.postcode = value.code;
          this.addId = value.hashid;
          this.addressValue = [value.province, value.city, value.district];
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.new-alert {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  background-color: #fff;
  z-index: 2001;
  font-size: 16px;
}
.n-a-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}
.n-a-header span {
  font-weight: bold;
}
.n-a-body {
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}
.n-a-body .flex {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}
.n-a-body span {
  width: 100px;
  text-align: right;
}
.n-a-body .required::before {
  content: "*";
  color: #f5222d;
  margin-right: 4px;
}
.n-a-body input,
.n-a-body .select-box {
  width: 346px;
  height: 34px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
}
.n-a-body input {
  border: 1px solid #d9d9d9;
  padding: 0 12px;
}
.n-a-footer {
  text-align: right;
  padding: 12px 0;
  padding-right: 16px;
}
.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-box .select {
  flex-shrink: 0;
  width: 110px;
}
.select-box .select:not(:last-child) {
  margin-right: 8px;
}
.n-a-footer a.bg-r-btn,
.n-a-footer a.bg-w-btn {
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
}
.new-alert .el-select-dropdown{
  z-index: 2002!important;
}
</style>