<template>
  <div class="after-sale-item">
    <c-nav :nav="navList"></c-nav>
    <div class="card mb-20 flex f-a-c card-0" v-if="orderDetail">
      <div class="left flex f-a-c">
        <p class="fs-12 mb-20">售后订单号：{{ orderDetail.sn}}</p>
        <p>{{orderDetail.status_name}}</p>
      </div>
      <ul class="process-status flex">
        <li
          v-for="item in processList"
          :key="item.id"
          :class="item.state ? 'active' : ''"
        >
          <p class="title">{{ item.name }}</p>
        </li>

        <div
          v-for="item in processList"
          :key="`${item.id}line`"
          :class="['line', item.state ? 'active' : '']"
        ></div>

      </ul>
    </div>
    <div class="card p-20 mb-20" v-if="orderDetail">
      <div class="flex" style="justify-content: space-between;">
        <span class="bold fs-14">售后单信息</span>
        <div class="cancelApyBtn fs-12 bg_red t-center" v-if="orderDetail.status === 1" @click="handleCanelOrder">撤销申请</div>
        <div class="fs-12 fillBtn t-center" @click="handleFillNo" v-if="orderDetail.status === 2">{{trackNo === '' ? '填写快递信息' : '编辑快递信息'}}</div>
      </div>
      <div class="mt-20 dis_block">
        <div class="backReason fs-14">
          <span>售后原因：{{orderDetail.reason}}</span>
          <span class="mt-16">退货方式：快递寄回</span>
          <span class="mt-16">售后方式：退货退款</span>
          <span class="mt-16">退款方式：{{orderDetail.type === 1 ? '仅退款' : '退款,退货'}}</span>
        </div>
      </div>
      <div class="dis_block fs-14 mt-20" style="margin-left:118px; vertical-align:top;">
        <div>售后说明：{{orderDetail.describe}}</div>
        <div class="mt-16">
          相关图片：
          <img 
          v-for="(item, index) in orderDetail.image" :key="'img'+index" 
          :src="item" alt="" class="mr-16" style="width: 88px; height: 88px;">
        </div>
      </div>
      <div class="fs-14 transpostMsg mt-16">
        <div>退货地址：
          {{orderDetail.merchandiser ? orderDetail.merchandiser.name : ''}}-
          {{orderDetail.merchandiser ? orderDetail.merchandiser.phone : ''}}-
          {{orderDetail.merchandiser ? orderDetail.merchandiser.province : ''}}
          {{orderDetail.merchandiser ? orderDetail.merchandiser.city : ''}}
          {{orderDetail.merchandiser ? orderDetail.merchandiser.county : ''}}
          {{orderDetail.merchandiser ? orderDetail.merchandiser.detail : ''}}
          </div>
        <div class="mt-16">快递单号：{{orderDetail.delivery ? orderDetail.delivery.mail_no : ''}}</div>
      </div>
    </div>
    <div class="card p-20 fs-14" v-if="orderDetail">
      <div class=" bold">商品信息</div>
      <div class="goodsBox mt-20">
        <div class="header flex gray6">
          <span style="width: 60%;">商品</span>
          <span>规格</span>
          <span>数量</span>
          <span>单价</span>
          <span>小计</span>
        </div>
        <div class="goodsBody flex" v-for="item in orderDetail.warehouse[0].products" :key="item.id" >
          <div style="width: 60%;">
            <img :src="item.image + '?imageMogr2/thumbnail/64x'" class="ver_mid pointer" alt="" style="width:64px; height:64px;" @click="toDetailPage(item.hashid)">
            <a href="javascript:;" class="pointer" @click="toDetailPage(item.hashid)">{{item.full_name}}</a>
          </div>
          <div class="flex" style="align-items: center; flex:1;">
            <div v-for="col in item.colourArr" :key="col.id"  style="flex:1;">
              <div v-for="size in col.sizeArr" :key="size.id" class="flex sizeBox">
                <span>{{col.name}}-{{size.name}}</span>
                <span>{{size.number}}</span>
                <span>￥{{item.price}}</span>
                <span>￥{{item.total_price}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="priceBox t-right mt-20">
        <div>商品总数：{{orderDetail.numbers}}件</div>
        <div class="mt-10">商品金额：￥{{orderDetail.amount}}</div>
        <div class="mt-10">优惠券抵扣：￥{{orderDetail.coupon_amount}}</div>
        <div class="mt-10">运费：￥{{orderDetail.freight}}</div>
        <div class="mt-10">手续费：￥{{orderDetail.service_charge}}</div>
        <div class="mt-10 col_f52">退款总计：<span class="fs-18 bold">￥{{orderDetail.order_amount}}</span></div>
      </div>
    </div>

    <div class="mask" v-show="isFill">
      <div class="pop">
        <div class="pop_top flex">
          <span class="fs-16 col_85">填写快递信息</span>
          <span class="fs-12 col_45 pointer" @click="colsePop">X</span>
        </div>
        <div class="pop_body fs-14 t-center">
          <div class="selAddress">
            <span class="col_85"><span class="col_f52">*</span>快递公司：</span>
            <el-select v-model="expressId" placeholder="请选择快递公司">
              <el-option
                v-for="item in expressList"
                :key="item.id"
                :label="item.company"
                :value="item.hashid">
              </el-option>
            </el-select>
          </div>
          <div class="mt-24">
            <span class="col_85"><span class="col_f52">*</span>快递单号：</span>
            <input type="text" v-model="mailNo">
          </div>
        </div>
        <div class="pop_bottom fs-14 flex">
          <div class="pop_btn" @click="colsePop">取消</div>
          <div class="pop_btn" @click="handelSumbit">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reOrderDetail, revoke, saveDelivery } from '@/http/api';
export default {
  name: "afterSaleItem",
  data() {
    return {
      processList: [
        {
          name: "提交申请",
          state: false,
          id: 1,
        },
        {
          name: "平台审核",
          state: true,
          id: 2,
        },
        {
          name: "待寄回/寄回中",
          state: false,
          id: 3,
        },
        {
          name: "平台收货",
          state: false,
          id: 4,
        },
        {
          name: "完成",
          state: false,
          id: 5,
        },
      ],
      navList: [
        {
          path: `/user/after-sale`,
          text: "售后工作台",
        },
        {
          path: `${this.$route.path}`,
          text: "售后详情",
        },
      ],
      process: [
        {
          name: "提交申请",
          time: "2020-11-25 21:41:04",
          status: "创建订单",
          child: [
            {
              time: "21:42:04",
              status: "准备出库",
            },
          ],
        },
        {
          name: "审核环节",
          time: "2020-11-25 21:41:04",
          status: "已揽收",
        },
        {
          name: "处理环节",
          time: "2020-11-26 18:00:58",
          status: "快件离开[广州市]",
          child: [
            {
              time: "18:51:31",
              status: "已到达[杭州市]",
            },
          ],
        },
        {
          name: "原物返回",
          time: "",
          status: "",
        },
      ],

      state: 0,
      orderDetail: null,
      orderId: '',
      isFill: false,
      trackNo: '',
      mailNo: '', // 快递单号
      expressList: null, // 快递公司列表
      expressId: ''
    };
  },
  created() {
    let id = this.$route.params.pathMatch;
    this.orderId = id;
    this.reOrderDetail(this.orderId);
  },
  methods: {
    // 撤销申请
    handleCanelOrder() {
      this.$confirm('是否撤销申请','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.revoke(this.orderId);
      }).catch(() => {})
    },
    // 填写快递单号
    handleFillNo() {
      this.$store.commit('changeMask');
      this.isFill = true;
      this.mailNo = this.trackNo;
    },
    // 关闭弹窗
    colsePop() {
      this.$store.commit('changeMask');
      this.isFill = false;
      this.mailNo = this.trackNo;
      this.expressId = '';
    },
    // (快递单号)确认
    handelSumbit() {
      this.saveDelivery();
    },
    // 跳转到详情
    toDetailPage(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
    //----------获取数据 &api--------------
    // 获取订单详情
    reOrderDetail(id) {
      reOrderDetail({orderId: id}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          console.log(res);
          this.orderDetail = data;
          this.trackNo = data.delivery ? data.delivery.mail_no : '';
          this.mailNo = this.trackNo;
          // this.expressId = res.expressList[0].hashid;
          this.expressList = res.expressList;
          console.log(this.expressList);
          if(data.status === 1 || data.status === 3) {
            this.state = 1;
          }else if(data.status === 2) {
            this.state = 2;
          }else if(data.status === 4) {
            this.state = 3;
          }else if(data.status === 5 || data.status === 6) {
            this.state = 4
          }
          this.processList.forEach((item, index) => {
            if(index <= this.state) {
              item.state = true;
            }else {
              item.state = false;
            }
          });
          
        }
      })
    },
    // 撤销售后申请
    revoke(id) {
      revoke({orderId: id}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('撤销成功');
          this.reOrderDetail(this.orderId);
        }else {
          this.$message.error('撤销失败');
        }
      })
    },
    // 保存寄回快递单号
    saveDelivery() {
      let params = {
        orderId: this.orderId,
        mailNo: this.mailNo,
        expressId: this.expressId
      }
      saveDelivery(params).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('保存成功');
          this.isFill = false;
          this.mailNo = '';
          this.expressId = '';
          this.$store.commit('changeMask');
          this.reOrderDetail(this.orderId);
        }else {
          this.$message.error('保存失败');
        }
      })
    }
  }
};
</script>

<style>
.cancelApyBtn {
  width: 80px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}
.backReason span {
  display: block;
}
.goodsBox {
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}
.goodsBox .header {
  padding: 11px 16px;
  background: #F8F8F8;
}
.goodsBox .header span:nth-child(n+2) {
  flex: 1;
  text-align: center;
}
.goodsBody {
  padding: 16px;
}
.sizeBox span{
  flex: 1;
  text-align: center;
}
.transpostMsg {
  padding-top: 16px;
  border-top: 1px solid #EEEEEE;
}
.fillBtn {
  color: #fff;
  width: 104px;
  height: 24px;
  line-height: 24px;
  background: #74C041;
  cursor: pointer;
}
.mask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, .5); */
  /* justify-content: center; */
  /* align-items: center; */
}
.pop {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 400px;
  width: 640px;
  background: #fff;
}
.pop_top {
  border-bottom: 1px solid #F0F0F0;
  padding: 16px 24px;
  justify-content: space-between;
}
.pop_body {
  padding: 24px;
  border-bottom: 1px solid #F0F0F0;
}
.pop_body input {
  width: 346px;
  height: 32px;
  padding: 6px 8px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}
.pop_bottom {
  justify-content: flex-end;
  padding: 12px 24px;
}
.pop_btn {
  width: 65px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
.pop_btn:nth-child(1) {
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.65);
}
.pop_btn:nth-child(2) {
  margin-left: 8px;
  background: #F5222D;
  color: #fff;
}
.selAddress .el-select .el-input .el-select__caret {
  line-height: 20px;
}
</style>