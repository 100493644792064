<template>
  <div class="rankPage" :class="{'pb-80': typeAct}">
    <cNav :nav="navList" />
    <div class="salesRank mt-16" v-show="salesAct">
      <div class="c-box sales">
        <div class="title flex">
          <div class="titleName">
            <span class="bold">销量榜</span>
            SALES LIST
          </div>
          <div class="line">
            <span class="allRank fs-14 ver_mid" v-if="typeAct" @click="seeAll">查看全部榜单</span>
            <span class="gray9 fs-18" v-else @click="seeMore(1)">查看更多 ></span>
          </div>
        </div>
        <div class="typeList flex mt-20" v-show="typeAct">
          <div class="typeItem fs-14 mr-16" :class="{'TypeActive': typeActive === -1}" @click="handleSelCate(-1, 1)">全部</div>
          <div v-for="item in cateList" :key="item.id" 
          class="typeItem fs-14 mr-16" 
          :class="{'TypeActive': typeActive === item.id}"
          @click="handleSelCate(item.id, 1)"
          >
            {{item.name}}
          </div>
        </div>
        <div class="content flex">
          <!-- <div class="conItem bg_white mt-20 flex" v-for="(item ,index) in salesRank1" :key="item.id" @click="toGoodDetail(item.hashid)">
            <div style="position: relative;">
              <div class="rankIcon">
                <img src="../../images/rank-1.png" alt="" v-if="index === 0">
                <img src="../../images/rank-2.png" alt="" v-else-if="index === 1">
                <img src="../../images/rank-3.png" alt="" v-else-if="index === 2">
                <span class="fs-14 bold" :class="{'col_r1': index === 0, 'col_r2': index === 1, 'col_r3': index === 2}">
                  {{index+1}}
                </span>
              </div>
              <img :src="item.image" alt="" class="img1">
            </div>
            <div class="vis_mid ml-16" style="width: 100%;">
              <div class="fs-14 bold full_name">{{item.full_name}}</div>
              <div class="color fs-12 mt-16 gray9">{{item.colourNul}}色</div>
              <div class="flex mt-16" style="justify-content: space-between;">
                <span class="bold fs-18 col_f52">￥{{item.price}}</span>
                <span @click="handleStar($event, item)">
                  <img src="../../images/star-fill.png" alt="" v-if="item.isCollection === 1" style="width:16px;" class="ver_mid">
                  <img src="../../images/starEnpty.png" alt="" v-else style="width:16px;" class="ver_mid">
                  <span class="gray9 fs-12 ver_mid" v-if="item.isCollection === 2"> 收藏</span>
                <span class="gray9 fs-12 ver_mid" v-else> 已收藏</span>
                </span>
              </div>
            </div>
          </div> -->
          <div class="conItem bg_white mt-20 t-c" v-for="(item ,index) in salesRank1" :key="item.id" @click="toGoodDetail(item.hashid)">
            <div class="rankIcon1">
                <img src="../../images/rank-1.png" alt="" v-if="index === 0">
                <img src="../../images/rank-2.png" alt="" v-else-if="index === 1">
                <img src="../../images/rank-3.png" alt="" v-else-if="index === 2">
                <span class="fs-24 bold" :class="{'col_r1': index === 0, 'col_r2': index === 1, 'col_r3': index === 2}">
                  {{index+1}}
                </span>
            </div>
            <el-image :src="item.image + '?imageMogr2/thumbnail/200x'" alt="" class="img1 mt-16" lazy></el-image>
            <div class="rankbg-y flex bold fs-24 f-a-c">
              <span style="width: 70%;" class="white">全站销量总榜</span>
              <span class="black" style="width: 30%;">第{{index+1}}名</span>
            </div>
            <div class="t-left" style="padding: 14px 16px 16px;">
              <div class="fs-16 black bold">{{item.full_name}}</div>
              <div class="color fs-12 mt-12 gray9">{{item.colourNul}}色</div>
              <div class="flex f-j-s mt-8" style="width: 100%;">
                <span class="fs-18 bold col_f52">￥{{item.price}}</span>
                <span @click="handleStar($event, item)">
                    <img src="../../images/star-fill.png" alt="" v-if="item.isCollection === 1" style="width:16px;" class="ver_mid">
                    <img src="../../images/starEnpty.png" alt="" v-else style="width:16px;" class="ver_mid">
                    <span class="gray9 fs-12 ver_mid" v-if="item.isCollection === 2"> 收藏</span>
                  <span class="gray9 fs-12 ver_mid" v-else> 已收藏</span>
                </span>
              </div>
            </div>
            
            
          </div>
        </div>
        <div class="content flex mt-20">
          <div class="conItem2 bg_white mb-20" v-for="(item, index) in salesRank2" :key="item.id" @click="toGoodDetail(item.hashid)">
            <div style="position: relative;">
              <div style="position: relative;">
                <div class="rankIcon">
                  <img src="../../images/rank-nor.png" alt="">
                  <span class="fs-14 bold col_nor" >
                    {{index+4}}
                  </span>
                </div>
                <el-image :src="item.image + '?imageMogr2/thumbnail/188x'" alt="" class="img2" lazy></el-image>
              </div>
              <span class="tag2 gray9 fs-12">{{item.colourNul}}色</span>
            </div>
            <div class="fs-14 mt-16 full_name" >{{item.full_name}}</div>
            <div class="flex mt-8" style="justify-content: space-between;">
              <span class="bold fs-18 col_f52">￥{{item.price}}</span>
              <span @click="handleStar($event, item)">
                <img src="../../images/star-fill.png" alt="" v-if="item.isCollection === 1" style="width:16px;" class="ver_mid">
                <img src="../../images/starEnpty.png" alt="" v-else style="width:16px;" class="ver_mid">
                <span class="gray9 fs-12 ver_mid" v-if="item.isCollection === 2"> 收藏</span>
                <span class="gray9 fs-12 ver_mid" v-else> 已收藏</span>
              </span>
            </div>
          </div>
        </div>
        <div class="t-c">
          <div class="more fs-14 ver_mid" @click="seeMore(1)" v-show="!typeAct && salesRank1.length">查看更多</div>
        </div>
      </div>
    </div>
    <div class="salesRank" :class="{'mt-40': salesAct, 'mt-16': !salesAct}" v-show="browseAct">
      <div class="c-box hot">
        <div class="title flex">
          <div class="titleName">
            <span class="bold">热度榜</span>
            HOT LIST
          </div>
          <div class="line">
            <span class="allRank fs-14 ver_mid" v-if="typeAct" @click="seeAll">查看全部榜单</span>
            <span class="gray9 fs-18" v-else @click="seeMore(2)">查看更多 ></span>
          </div>
        </div>
        <div class="typeList flex mt-20" v-show="typeAct">
          <div class="typeItem fs-14 mr-16" :class="{'TypeActive': typeActive === -1}" @click="handleSelCate(-1, 2)">全部</div>
          <div v-for="item in cateList" :key="item.id" 
          class="typeItem fs-14 mr-16" 
          :class="{'TypeActive': typeActive === item.id}"
          @click="handleSelCate(item.id, 2)"
          >
            {{item.name}}
          </div>
        </div>
        <div class="content flex">
          <div class="conItem bg_white mt-20 t-c" v-for="(item ,index) in browseRank1" :key="item.id" @click="toGoodDetail(item.hashid)">
            <div class="rankIcon1">
                <img src="../../images/rank-1.png" alt="" v-if="index === 0">
                <img src="../../images/rank-2.png" alt="" v-else-if="index === 1">
                <img src="../../images/rank-3.png" alt="" v-else-if="index === 2">
                <span class="fs-24 bold" :class="{'col_r1': index === 0, 'col_r2': index === 1, 'col_r3': index === 2}">
                  {{index+1}}
                </span>
            </div>
            <el-image :src="item.image + '?imageMogr2/thumbnail/200x'" alt="" class="img1 mt-16" lazy></el-image>
            <div class="rankbg-r flex bold fs-24 f-a-c">
              <span style="width: 70%;" class="white">全站热度总榜</span>
              <span class="black" style="width: 30%;">第{{index+1}}名</span>
            </div>
            <div class="t-left" style="padding: 14px 16px 16px;">
              <div class="fs-16 black bold">{{item.full_name}}</div>
              <div class="color fs-12 mt-12 gray9">{{item.colourNul}}色</div>
              <div class="flex f-j-s mt-8" style="width: 100%;">
                <span class="fs-18 bold col_f52">￥{{item.price}}</span>
                <span @click="handleStar($event, item)">
                    <img src="../../images/star-fill.png" alt="" v-if="item.isCollection === 1" style="width:16px;" class="ver_mid">
                    <img src="../../images/starEnpty.png" alt="" v-else style="width:16px;" class="ver_mid">
                    <span class="gray9 fs-12 ver_mid" v-if="item.isCollection === 2"> 收藏</span>
                  <span class="gray9 fs-12 ver_mid" v-else> 已收藏</span>
                </span>
              </div>
            </div>
            
            
          </div>
        </div>
        <div class="content flex mt-20">
          <div class="conItem2 bg_white mb-20" v-for="(item, index) in browseRank2" :key="item.id" @click="toGoodDetail(item.hashid)">
            <div style="position: relative;">
              <div style="position: relative;">
                <div class="rankIcon">
                  <img src="../../images/rank-nor.png" alt="">
                  <span class="fs-14 bold col_nor" >
                    {{index+4}}
                  </span>
                </div>
                <el-image :src="item.image + '?imageMogr2/thumbnail/188x'" alt="" class="img2" lazy></el-image>
              </div>
              <span class="tag2 gray9 fs-12">{{item.colourNul}}色</span>
            </div>
            <div class="fs-14 mt-16 full_name">{{item.full_name}}</div>
            <div class="flex mt-8" style="justify-content: space-between;">
              <span class="bold fs-18 col_f52">￥{{item.price}}</span>
              <span @click="handleStar($event, item)">
                <img src="../../images/star-fill.png" alt="" v-if="item.isCollection === 1" style="width:16px;" class="ver_mid">
                <img src="../../images/starEnpty.png" alt="" v-else style="width:16px;" class="ver_mid">
                <span class="gray9 fs-12 ver_mid" v-if="item.isCollection === 2"> 收藏</span>
                <span class="gray9 fs-12 ver_mid" v-else> 已收藏</span>
              </span>
            </div>
          </div>
        </div>
        <div class="t-c">
          <div class="more fs-14 ver_mid" @click="seeMore(2)" v-show="!typeAct && browseRank1.length">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productCat, salesRank, browseRank, collection, delProduct } from '@/http/api'
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          path: "rank",
          text: "排行榜",
        },
      ],
      
      typeList: [
        {id: 1, name: '全部' },
        {id: 2, name: 'T恤' },
        {id: 3, name: '卫衣' },
        {id: 4, name: '风衣马甲' }
      ],

      typeAct: false,
      salesAct: true,
      browseAct: true,

      typeActive: -1,
      salesRank1: [],
      salesRank2: [],
      browseRank1: [],
      browseRank2: [],
      categoryIdArr: [],
      cateList: [],
      collectionIdArr: [], //已收藏产品id
      saleFlag: 1,
      browFlag: 1
    }
  },
  created() {
    this.getSalesRank(this.saleFlag);
    this.getBrowseRank(this.browFlag);
  },
  methods: {
    // 查看更多
    seeMore(index) {
      scrollTo(0,0);
      this.typeActive = -1;
      if(index === 1) {
        this.browseAct = false;
        this.salesAct = true;
        this.typeAct = true;
        this.getCate();
        this.saleFlag = 2;
        this.getSalesRank(this.saleFlag);
      }else {
        this.salesAct = false;
        this.browseAct = true;
        this.typeAct = true;
        this.getCate();
        this.browFlag = 2
        this.getBrowseRank(this.browFlag);
      }
    },
    // 查看全部榜单
    seeAll() {
      scrollTo(0,0);
      this.browseAct = true;
      this.salesAct = true;
      this.typeAct = false;
      this.categoryIdArr = [];
      this.saleFlag = 1;
      this.browFlag = 1;
      this.getSalesRank(this.saleFlag);
      this.getBrowseRank(this.browFlag);
    },
    // 选择分类
    handleSelCate(id, type) {
      this.typeActive = id;
      this.categoryIdArr = [];
      if(id !== -1) {
        this.categoryIdArr.push(id);
      }
      if(type === 1) {
        this.getSalesRank(2);
      }else {
        this.getBrowseRank(2);
      }
    },
    // 跳转商品详情
    toGoodDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
    // 收藏商品
    handleStar(e, item) {
      e.stopPropagation();
      if(item.isCollection === 1) {
        this.collectionIdArr = [];
        this.collectionIdArr.push(item.collectionId)
        this.delProduct();
      }else {
        this.collection(item.hashid);
      }
    },
    // --------------------------获取数据------------------
    // 获取分类
    getCate() {
      productCat().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.cateList = data.productCat[0].children
        }
      })
    },
    // 销量排行榜
    getSalesRank(flag) {
      salesRank({
        categoryIdArr: this.categoryIdArr
      }).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          let resList = JSON.parse(JSON.stringify(data.productList.data));
          this.salesRank1 = resList.splice(0, 3);
          if(flag === 1) {
            this.salesRank2 = resList.splice(0, 5);
          }else {
            this.salesRank2 = resList.splice(0);
          }
        }
      })
    },
    // 热度排行榜
    getBrowseRank(flag) {
      browseRank({
        categoryIdArr: this.categoryIdArr
      }).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          let resList = JSON.parse(JSON.stringify(data.productList.data));
          this.browseRank1 = resList.splice(0, 3);
          if(flag === 1) {
            this.browseRank2 = resList.splice(0, 5);
          }else {
            this.browseRank2 = resList.splice(0);
          }
        }
      })
    },
    // 收藏
    collection(id) {
      collection({ productId: id }).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('收藏成功');
          this.$emit('changeCollection');
          this.$store.commit('changeCollectNumber' , 1);
          this.getSalesRank(this.saleFlag);
          this.getBrowseRank(this.browFlag);
        }else {
          this.$message.error('收藏失败');
        }
      })
    },
    delProduct() {
      delProduct({collectionIdArr: this.collectionIdArr}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('已取消收藏');
          this.$emit('changeCollection');
          this.$store.commit('changeCollectNumber' , -1);
          this.getSalesRank(this.saleFlag);
          this.getBrowseRank(this.browFlag);
        }else {
          this.$message.error('取消收藏失败');
        }
      })
    }
  }
}
</script>

<style scoped>

.rankPage {
  background: #f5f5f5;
}

.rankPage .titleName {
  padding: 11px 16px;
  border-radius: 4px 4px 0px 0px;
  color: #fff;
  font-size: 20px;
}
.sales .titleName {
  background: #FFD500;
  color: #000;
}
.hot .titleName {
  background: #F5222D;
}
.rankPage .line {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  border-bottom: 4px solid #000;
}
.line span {
  cursor: pointer;
  font-weight: normal;
}
.conItem:nth-child(1),
.conItem:nth-child(2),
.conItem:nth-child(3) {
  width: 380px;
  
}
.conItem:nth-child(3) {
  margin-right: 0;
}
.conItem2:nth-child(5n) {
  margin-right: 0;
}
.conItem,
.conItem2 {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}
.conItem:hover,
.conItem2:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
.conItem:hover .img1 {
  transform: translateY(-6px);
}
.conItem2:hover .img2 {
  transform: translateY(-6px);
}
.img1 {
  transition: all 0.3s;
  width: 200px;
  height: 200px;
}
.rankbg-r {
  height: 48px;
  background: url('../../images/rank-r.png');
  background-size: 100% 100%;
}
.rankbg-y {
  height: 48px;
  background: url('../../images/rank-y.png');
  background-size: 100% 100%;
}
.color {
  display: inline-block;
  padding: 4px;
  border: 1px solid #999999;
  border-radius: 4px;
  background: #fff;
}
.content {
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.img2 {
  transition: all 0.3s;
  width: 188px;
  height: 188px;
}
.conItem2 {
  width: 220px;
  padding: 16px;
}
.more {
  background: #000;
  padding: 14px 72px;
  margin: 40px 0;
  color: #fff;
  cursor: pointer;
}
.allRank {
  background: #000;
  padding: 8px 24px;
  color: #fff;
  cursor: pointer;
}
.tag2 {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4px;
  border: 1px solid #999999;
  border-radius: 4px;
  background: #fff;
}
.typeList {
  flex-wrap: wrap;
}
.typeItem {
  padding: 16px 40px;
  border: 1px solid #DDDDDD;
  color: #000;
  border-radius: 35px;
  cursor: pointer;
  height: 51px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.TypeActive {
  color: #F5222D;
  border-color: #FF0000;
}
.full_name{
  height: 38px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.rankIcon1 {
  z-index: 99;
  position: absolute;
  left: 16px;
  top: 16px;
  width: 51px;
  height: 64px;
}
.rankIcon {
  z-index: 99;
  position: absolute;
  left: -8px;
  top: -8px;
  width: 25px;
  height: 32px;
}
.rankIcon1 span, 
.rankIcon span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -64%);
}
.col_r1 {
  color: #FFEF9A;
}
.col_r2 {
  color: #E1EDF5;
}
.col_r3 {
  color: #F8C6AD;
}
.col_nor {
  color: #FFFFFF;
}

</style>
