<template>
  <div class="my">
    <c-nav :nav="navList"></c-nav>
    <ul class="flex card">
      <li>
        <div class="img-box mb-8">
          <img
            :src="userInfo.headimgurl"
            alt="头像"
            v-if="userInfo.headimgurl"
          />
        </div>
        <p class="mb-8">{{ userInfo.name }}</p>
        <router-link to="my-add">我的收货地址</router-link>
      </li>
      <li v-for="(value, index) in userData" :key="index" class="flex1" style="position: relative;">
        <router-link :to="value.path">
          <p class="mb-8">{{ value.num }}</p>
          <p>{{ value.title }}</p>
        </router-link>
        <div class="col_f52 rechargeBtn fs-12" v-if="index === 2" @click="toRecharge">充值</div>
      </li>
    </ul>
    <div class="flex">
      <div class="left card">
        <p>我的订单</p>
        <ul class="flex">
          <li v-for="(value, index) in myBtn" :key="index">
            <router-link :to="value.path">
              <i v-if="value.sum">{{ value.sum }}</i>
              <img :src="value.src" :alt="value.title" />
              <p>{{ value.title }}</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="right card">
        <p>系统通知</p>
        <ul>
          <li v-for="(value, index) in notice" :key="index">
            <span>{{ value.content }}</span>
            <span>{{ }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="table card">
      <div class="header">
        <span>我的订单</span>
        <div class="right">
          <a href="javascript:;" 
          v-for="item in timeList" :key="item.id"
          :class="{'active': timeActive === item.id}"
          @click="handlechange(item.id)"
          >{{item.title}}</a>
        </div>
      </div>
      <div id="my-table" ref="myTable" v-show="tableX.length"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import obligation from "../../images/obligation.png";
import packaging from "../../images/packaging.png";
import transportation from "../../images/transportation.png";
import completed from "../../images/completed.png";
import afterSales from "../../images/after-sales.png";

import { myInfo } from '@/http/api'
export default {
  name: "my",
  data() {
    return {
      navList: [
        {
          path: "my",
          text: "我的工作台",
        },
      ],
      userData: [
        {
            path: "my-coupon",
            title: "我的优惠券",
            num: 0,
          },
          {
            path: "my-star",
            title: "我的收藏",
            num: 0,
          },
          {
            path: "my-flow",
            title: "我的余额",
            num: 0,
          },
      ],
      myBtn: [
        {
          src: obligation,
          title: "待付款",
          sum: "",
          path: "follow?query=1",
        },
        {
          src: packaging,
          title: "待发货",
          sum: "",
          path: "follow?query=2",
        },
        {
          src: transportation,
          title: "待收货",
          sum: "",
          path: "follow?query=3",
        },
        {
          src: completed,
          title: "已签收",
          sum: "",
          path: "follow?query=4",
        },
        {
          src: afterSales,
          title: "退换/售后",
          sum: "",
          path: "after-sale",
        },
      ],
      timeList:[
        { id: 1, title: '本周' },
        { id: 2, title: '本月' },
        { id: 3, title: '全年' },
      ],
      notice: [],
      userInfo: {},
      tableX: [],
      tableY1: [],
      tableY2: [],
      timeActive: 1,
      myTable: null
    };
  },
  computed: {
  },
  methods: {
    //生成table
    createTable() {
      const _this = this;
      console.log(11);
      var option = {
        tooltip: {
          trigger: "axis", //坐标轴指示器，坐标轴触发有效，
          type: "line",
          textStyle: {
            color: "rgba(0, 0, 0, 0.65)",
          },
          backgroundColor: "#fff",
          borderColor: "rgba(0, 0, 0, 0.15)",
          borderWidth: 1,
        },
        legend: {
          data: ["下单数量", "下单金额"],
        },
        //X轴
        xAxis: {
          data: _this.tableX,
          axisLabel: {
            show: true,
            textStyle: { fontSize: "12", color: "rgba(0, 0, 0, 0.65)" },
            shadowOffsetY: 12,
          },
          boundaryGap: false,
          nameGap: 15,
        },
        //Y轴
        yAxis: {
          axisLine: {
            lineStyle: {
              opacity: 0,
            },
          },
          axisLabel: {
            show: true,
            textStyle: { fontSize: "12", color: "rgba(0, 0, 0, 0.65)" },
          },
          min: 0,
        },
        series: [
          {
            name: "下单数量",
            type: "line",
            data: _this.tableY1,
            symbol: "circle",
            color: "#1890FF",
          },
          {
            name: "下单金额",
            type: "line",
            data: _this.tableY2,
            symbol: "circle",
            color: "#2FC25B",
          },
        ],
      };
      this.myTable.setOption(option);
    },
    // 切换echart
    handlechange(id) {
      this.timeActive = id;
      this.getMyInfo(id);
      // this.createTable();
    },
    // 充值
    toRecharge() {
      this.$router.push('my-flow/recharge');
    },
    //-------------获取数据 &api----------
    // 获取用户数据
    getMyInfo(dateType) {
      let params = {};
      if(dateType) {
       params = {dateType: dateType + ''}
      }
      myInfo(params).then(res => {
        // console.log(res);
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.userInfo = data.user;
          this.notice = data.notice;
          this.userData[0].num = data.couponNum;
          this.userData[1].num = data.collectionNum;
          this.userData[2].num = data.user.surplus_money;
          this.myBtn[0].sum = data.noPayNum;
          this.myBtn[1].sum = data.noSendNum;
          this.myBtn[2].sum = data.noReceivedNum;
          this.myBtn[3].sum = data.signNum;
          this.myBtn[4].sum = data.saleNum;
          this.tableX = data.statisticsData ? data.statisticsData.show : [];
          this.tableY1 = data.statisticsData ? data.statisticsData.data.numbers : [];
          this.tableY2 = data.statisticsData ? data.statisticsData.data.amount: [];
        }
        this.createTable();
        // if(data.statisticsData) {
          
        // }
      })
    },
  },
  created() {
    
    
  },
  mounted() {
    this.getMyInfo();
    this.myTable = echarts.init(this.$refs.myTable);
    // this.createTable();
  },
};
</script>

<style>
.my > ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 20%;
  height: 160px;
  color: #333333;
}
.my > ul li:first-child {
  background: #db1e28;
  color: #fff;
}
.my > ul li .img-box {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  overflow: hidden;
}
.my > ul li:first-child p:nth-child(2) {
  font-size: 16px;
  line-height: 21px;
}
.my > ul li:first-child a {
  font-size: 12px;
  line-height: 16px;
}
.my > ul li p:first-child {
  font-size: 24px;
  line-height: 31px;
}
.my > ul li p:nth-child(2) {
  font-size: 16px;
  line-height: 21px;
}
.my .flex:nth-child(3) {
  margin: 20px 0;
}
.my .flex:nth-child(3) .left {
  width: 580px;
}
.my .flex:nth-child(3) div > p,
.my .table .header {
  padding: 20px 0;
  padding-left: 20px;
  border: 1px solid #eeeeee;
}
.my .flex:nth-child(3) .left img {
  width: auto;
  height: 32px;
}
.my .flex:nth-child(3) .left li {
  width: 20%;
  height: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.cardBtn a i,
.my .flex:nth-child(3) .left li i {
  position: absolute;
  width: max-content;
  height: 21px;
  line-height: 19px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #fa5151;
  border: 1px solid #ffffff;
  border-radius: 16px;
  padding: 0 4px;
}
.my .flex:nth-child(3) .left li i {
  top: 20px;
  right: 20px;
}
.my .flex:nth-child(3) .right {
  width: 380px;
  height: 175px;
  overflow: hidden;
}
.my .flex:nth-child(3) .right li {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my .flex:nth-child(3) .right li span:first-child {
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.my .flex:nth-child(3) .right li span:nth-child(2) {
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}
.my .table {
  min-height: 417px;
}
.my .table .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}
.my .table .header a {
  margin-left: 24px;
}
.my .table .header a.active {
  color: #db1e28;
}
#my-table {
  width: 980px;
  height: 354px;
  padding-top: 16px;
}
.cardBtn {
  border: 1px solid #eee;
}
.cardBtn a {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  font-weight: bold;
  margin-right: 40px;
  border-bottom: 2px solid;
  border-color: transparent;
  padding-bottom: 4px;
  transition: all 0.3s;
}
.cardBtn a i {
  top: -8px;
  left: calc(100% - 4px);
}
.cardBtn a:hover,
.cardBtn a.active {
  border-color: #f5222d;
  color: #f5222d;
}
.my .rechargeBtn {
  position: absolute;
  bottom: 20px;
  width: 48px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #F5222D;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}
</style>