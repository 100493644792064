<template>
  <div class="form">
    <p class="title">注册</p>
    <slot name="tel"></slot>
    <slot name="code"></slot>
    <div class="checkbox">
      <input
        type="checkbox"
        name="isRead"
        id="isRead"
        class="mr-8"
        v-model="isRead"
      />
      <label for="isRead" class="mr-8" :class="{ x_completed: isRead }">
        <i></i>
      </label>
      <span class="read" @click="changeShow">
        已阅读并同意注册条约和退货条约
      </span>
    </div>
    <button @click="reg">注册</button>
    <router-link to="/login" class="back">使用已有账号登录</router-link>
    <transition name="alert">
      <div class="alert" v-show="isShow">
        <p class="alert-title">注册协议</p>
        <div class="content">
          <div class="chapter">
            <p class="tips">提示条款</p>
            <p class="line">
              <span class="article">
                本协议由XX有限公司（以下简称“我司”）与您（以下简称“您”或“用户”）共同签署，您在申请成为印爆网（www.XXX.com）平台（以下简称“本平台”）注册用户或使用本平台的任何服务前，请
              </span>
              <span class="focus">
                您务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的条款、法律适用和争议解决条款或其他以粗体或下划线标识的条款。
              </span>
              <span class="article">
                如您对本协议有任何疑问，可向本平台客服咨询。
              </span>
            </p>
            <p class="line">
              <span class="article">
                本协议由XX有限公司（以下简称“我司”）与您（以下简称“您”或“用户”）共同签署，您在申请成为印爆网（www.XXX.com）平台（以下简称“本平台”）注册用户或使用本平台的任何服务前，请
              </span>
              <span class="focus">
                您务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的条款、法律适用和争议解决条款或其他以粗体或下划线标识的条款。
              </span>
              <span class="article">
                如您对本协议有任何疑问，可向本平台客服咨询。
              </span>
            </p>
          </div>
          <div class="chapter">
            <p class="chapter-name">第一条 账户注册、维护与使用</p>
            <p class="line">
              <span class="chapter-num">1.1</span>
              <span class="focus">
                在您注册并使用本平台服务之前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，您理解本平台无法对您是否符合本款约定进行任何形式或实质的审查与确认。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
              </span>
            </p>
            <p class="line">
              <span class="chapter-num">1.2</span>
              <span class="article">
                当您按照注册页面提示填写信息、阅读并同意本协议完成全部注册程序后，确保所填写的注册资料是真实、准确、完整、合法有效，您可获得本平台账户并成为我司的用户。
              </span>
            </p>
          </div>
        </div>
        <div class="alert-footer">
          <a href="javascript:;" class="close" @click="changeShow">关闭</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { auth } from '@/http/api'
export default {
  name: "reg",
  props: {
    //手机号
    tel: String,
    //验证码
    code: String,
  },
  data() {
    return {
      //昵称
      nickName: "",
      //密码
      password: "",
      //确认密码
      again_password: "",
      //是否同意条约
      isRead: false,
      //条约显示状态
      isShow: false,
    };
  },
  created() {
    this.$emit('initData');
  },
  methods: {
    //显示条约
    changeShow() {
      this.$store.commit("changeMask");
      this.isShow = !this.isShow;
    },
    //注册
    async reg() {
      //检查用户是否完整的输入信息
      if (this.testTel(this.tel) == false) {
        //nothing
      } else if (this.code == "") {
        this.$message.warning('请输入验证码');
      } else if (this.isRead == false) {
        this.$message.warning('尚未同意注册条约和退货条约');
      } else {
        // 已输入所有信息 发送请求
        let params = {
          phone: this.tel,
          code: this.code,
          type: 1,
          nick_name: this.nickName,
        }
        auth(params).then(res => {
          const { statusCode, msg, data} = res;
          if(statusCode === 200) {
            this.$message.success(msg);
            this.$store.commit('saveToken', data.token);
            // this.$store.commit('saveWxInfo', this.info);
            this.$router.push('/');
            this.$store.commit("changeLogin", true);
            this.$store.commit("saveTel", this.tel);
          }else {
            return this.$message.error(msg);
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
  },
};
</script>

<style>
.login .checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#isRead {
  display: none;
}
#isRead + label {
  width: 14px;
  height: 14px;
  border: 1px solid #555;
  border-radius: 2px;
}
#isRead + label.x_completed {
  border: none;
}
#isRead + label i {
  transform: translateY(-2px);
}
.login .read {
  color: #f5222d;
  font-size: 14px;
  cursor: pointer;
}
.login .alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 558px;
  height: 755px;
  text-align: left;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 20px 0;
  z-index: 3001;
  background: #fff;
}
.alert-enter-active,
.alert-leave-active {
  transition: all 0.3s ease;
}
.alert-enter,
.alert-leave-to {
  opacity: 0;
}
.alert-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.alert .content {
  flex: 1;
  line-height: 24px;
  overflow-y: scroll;
  padding: 0 20px;
}
.chapter {
  margin-bottom: 40px;
}
.chapter .tips {
  text-indent: 0;
  font-weight: bold;
}
.chapter .chapter-name,
.chapter .line {
  margin-bottom: 10px;
}
.chapter .line {
  text-indent: 20px;
  word-break: break-all;
  text-align: justify;
  letter-spacing: 3px;
}
.chapter .chapter-num {
  margin-right: 8px;
}
.focus {
  font-weight: bold;
  text-decoration: underline;
}
.alert-footer {
  padding: 15px;
  border-top: 1px solid #ddd;
}
.alert .close {
  float: right;
  /* display: inline-block; */
  width: 60px;
  height: 30px;
  text-align: center;
  color: #333;
  line-height: 28px;
  font-size: 14px;
  border: 1px solid;
  border-color: #ddd;
  border-radius: 4px;
  background: #fff;
  transition: all 0.3s;
}
.alert .close:hover {
  color: #f5222d;
  border-color: #f5222d;
}
</style>