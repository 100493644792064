<template>
  <div class="pay c-box mb-80">
    <div class="flex f-a-c mb-24">
      <router-link to="/" class="logo-box mr-24">
        <img src="../../images/LOGO.png" alt="印爆网" id="logo" />
      </router-link>
      <h2>支付中心</h2>
    </div>
    <div class="card">
      <div class="flex f-a-c mb-24">
        <div class="left">
          <span class="pay-order-num">订单号：{{ order.sn }}</span>
          <br />
          <span class="fs-14 c-6">请您在</span>
          <span class="c-red fs-14">{{ minutes }}分{{ seconds }}秒</span>
          <span class="fs-14 c-6">内完成支付，否则订单会被自动取消</span>
        </div>
        <div class="right">
          <span class="mr-8 fs-12">应付金额</span>
          <span class="c-red mr-4">{{ payData.isRecharge ? order.amount : order.order_amount }}</span>
          <span class="fs-12">元</span>
        </div>
      </div>
      <div
        v-for="(value, index) in payType"
        class="pay-type flex f-a-c"
        :class="{ active: value.active }"
        :key="index"
      >
        <input
          type="checkbox"
          :name="value.icon"
          :id="value.icon"
          :class="{ active: value.active }"
        />
        <label
          :for="value.icon"
          @click="changePay(value.id)"
          class="w_line-check_red"
        ></label>
        <span class="mr-40 flex f-a-c" :class="value.icon">
          <i></i>{{ value.type }}
        </span>
        <span class="fs-12 c-9" v-if="value.id == 0">
          账户余额：{{ user.surplus_money }}元
        </span>
        <div class="right" v-show="value.active">
          <span class="mr-8">支付</span>
          <span class="fs-18 c-red mr-4">{{ payData.isRecharge ? order.amount : order.order_amount }}</span>
          <span>元</span>
        </div>
      </div>
      <router-view @overpay="overPay"></router-view>
    </div>
    <!-- <div class="new-alert" v-show="passwordShow">
      <div class="n-a-header">
        <span>支付密码</span>
        <a href="javascript:;" class="w_line-close" @click="changeShow">
          <i></i>
        </a>
      </div>
      <div class="n-a-body bb1">
        <div class="flex">
          <span class="required">支付密码：</span>
          <input
            type="password"
            v-model="payPassword"
            placeholder="请输入支付密码"
          />
        </div>
      </div>
      <div class="n-a-footer">
        <a href="javascript:;" class="bg-w-btn mr-8" @click="changeShow">
          取 消
        </a>
        <a href="javascript:;" class="bg-r-btn" @click="overpay">确 定</a>
      </div>
    </div> -->
 
  </div>
</template>

<script>
import router from "../../router/index";
import { getPayIndex, balancePay, rechargeIndex, queryPayStatus, queryRechargeStatus, queryZfbPayStatus, queryZfbRechargeStatus } from '@/http/api';
import { mapState } from 'vuex'
export default {
  name: "pay",
  data() {
    return {
      payType: [
        {
          id: 0,
          type: "余额支付",
          icon: "x_balance",
          active: true,
        },
        {
          id: 1,
          type: "支付宝支付",
          icon: "x_alipay",
          active: false,
        },
        {
          id: 2,
          type: "微信支付",
          icon: "x_wechatpay",
          active: false,
        },
      ],
      //余额
      over: "",
      //计时器
      timer: null,
      //支付倒计时
      payTime: "",
      //支付密码
      payPassword: "",
      //支付密码弹框显示状态
      // passwordShow: false,

      orderId: '', //订单id
      order: {}, //订单数据
      user: {}, // 用户数据
      stateTimer: null, // 微信支付支付状态定时器
      zfbStateTimer: null, // 支付宝支付状态定时器
      flag: 1,
      zfbFlag: 1
    };
  },
  methods: {
    //改变支付方式
    changePay(id) {
      this.payType.forEach(item => {
        if(item.id === id) {
          item.active = true;
        }else {
          item.active = false;
        }
      });
      switch (id) {
        case 1:
          this.$router.push('alipay');
          if(this.zfbFlag === 1) {
            clearInterval(this.zfbStateTimer);
            if(this.payData.isRecharge) {
              this.zfbStateTimer = setInterval(this.queryZfbRechargeStatus, 2000);
            }else {
              this.zfbStateTimer = setInterval(this.queryZfbPayStatus, 2000);
            }
            this.zfbFlag = 2;
          }
          break;
        case 2:
          this.$router.push('wechatpay');
          if(this.flag === 1) {
            clearInterval(this.stateTimer);
            if(this.payData.isRecharge) {
              this.stateTimer = setInterval(this.queryRechargeStatus, 2000);
            }else {
              this.stateTimer = setInterval(this.queryPayStatus, 2000);
            }
            this.flag = 2;
          }
          break;
        case 0:
          this.$router.push('over-pay');
      }

    },
    //支付倒计时
    payCountDown() {
      if (this.payTime > 0) {
        --this.payTime;
      } else if (this.payTime <= 0 && this.payTime != "") {
        this.$message.warning('支付时间结束');
        // this.payTime = 20 * 60;
        router.push("/");
      }
    },
    //-------------- 获取数据 &api------------
    // 获取支付页面数据
    getPayIndex() {
      getPayIndex({orderId: this.payData.orderId}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.order = data.order;
          this.user = data.user;
          this.calculation(this.order.end_pay_at);
        }
      })
    },
    // 获取充值支付页面数据
    rechargeIndex() {
      rechargeIndex({orderId: this.payData.orderId}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.order = data.order;
          this.user = data.user;
          this.calculation(this.order.end_pay_at);
        }
      })
    },
    //余额支付
    overPay() {
      balancePay({orderId: this.payData.orderId}).then(res => {
        const { statusCode, msg, orderId } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          let data = {
            orderId: orderId
          }
          this.$store.commit("savePaySucData", data);
          this.$store.commit("clearPayData");
          this.$router.push("/pay-success");
        }else {
          this.$message.error(msg);
        }
      })
      
    },
    // 查询微信支付状态
    queryPayStatus() {
      queryPayStatus({orderId: this.payData.orderId}).then(res => {
        const { statusCode, msg, type, orderId } = res;
        if(statusCode === 200) {
          if(type === 1) {
            this.$message.success(msg);
            clearInterval(this.stateTimer);
             let data = {
              orderId: orderId,
            }
              this.$store.commit("savePaySucData", data);
              this.$store.commit("clearPayData");
              this.$router.push("/pay-success");
          }else {
            return;
          }
        }else {
          return;
        }
      })
    },
    // 查询充值订单支付状态
    queryRechargeStatus() {
      queryRechargeStatus({orderId: this.payData.orderId}).then(res => {
        const { statusCode, msg, type, orderId } = res;
        if(statusCode === 200) {
          if(type === 1) {
            this.$message.success(msg);
            clearInterval(this.zfbStateTimer);
            clearInterval(this.stateTimer);
             let data = {
              orderId: orderId,
              isRecharge: true
            }
              this.$store.commit("savePaySucData", data);
              this.$store.commit("clearPayData");
              this.$router.push("/pay-success");
          }else {
            return;
          }
        }else {
          return;
        }
      })
    },
    // 查询订单支付宝支付状态
    queryZfbPayStatus() {
      queryZfbPayStatus({orderId: this.payData.orderId}).then(res => {
        const { statusCode, msg, type, orderId } = res;
        if(statusCode === 200) {
          if(type === 1) {
            this.$message.success(msg);
            clearInterval(this.stateTimer);
            clearInterval(this.zfbStateTimer);
            let data = {
              orderId: orderId,
            }
            this.$store.commit("savePaySucData", data);
            this.$store.commit("clearPayData");
            this.$router.push("/pay-success");
          }else return;
        }else return;
      })
    },
    // 查询充值支付宝支付状态
    queryZfbRechargeStatus() {
      queryZfbRechargeStatus({orderId: this.payData.orderId}).then(res => {
        const { statusCode, msg, type, orderId } = res;
        if(statusCode === 200) {
          if(type === 1) {
            this.$message.success(msg);
            clearInterval(this.zfbStateTimer);
            clearInterval(this.stateTimer);
             let data = {
              orderId: orderId,
              isRecharge: true
            }
              this.$store.commit("savePaySucData", data);
              this.$store.commit("clearPayData");
              this.$router.push("/pay-success");
          }else {
            return;
          }
        }
      })
    },
    // 计算倒计时
    calculation(endData) {
      let time  = (new Date(endData).getTime() - new Date().getTime()) / 1000;
      this.payTime = time;
    }
  },
  computed: {
    ...mapState(["payData"]),
    //倒计时分
    minutes() {
      return Math.floor(this.payTime / 60);
    },
    //倒计时秒
    seconds() {
      return Math.floor(this.payTime % 60);
    },
  },
  created() {
    // this.orderId = this.$route.query.orderId;
    // 获取订单数据
    if(this.payData.isRecharge) {
      this.payType.splice(0,1);
      this.rechargeIndex();
    }else {
      this.getPayIndex();
    }
    
  },
  mounted() {
    //隐藏头部
    this.$store.commit("changeSearch");

    for (let i = 0; i < this.payType.length; i++) {
        this.payType[i].active = false;
      }
    //支付方式
    if(this.payType.length ===3) {
      switch (this.$route.path) {
        case "/pay/alipay":
          this.payType[1].active = true;
          break;
        case "/pay/wechatpay":
          this.payType[2].active = true;
          break;
        default:
          this.payType[0].active = true;
      }
    }else {
      switch (this.$route.path) {
        case "/pay/alipay":
          this.payType[0].active = true;
          break;
        case "/pay/wechatpay":
          this.payType[1].active = true;
          break;
      }
    }
    //倒计时
    this.timer = setInterval(this.payCountDown, 1000);
    if(this.payData.isRecharge) {
      this.stateTimer = setInterval(this.queryRechargeStatus, 2000);
    }else {
      return;
      // this.stateTimer = setInterval(this.queryPayStatus, 2000);
    }
  },
  //显示header，清除定时器
  destroyed() {
    this.$store.commit("changeSearch");
    clearInterval(this.timer);
    clearInterval(this.stateTimer);
    clearInterval(this.zfbStateTimer);
  },
};
</script>

<style>
.pay {
  padding-top:36px;
}
.pay.c-box,
.pay-suc.c-box {
  width: 990px;
  margin-top: 32px;
}
.pay .card {
  height: 800px;
  padding: 24px;
}
.pay-order-num {
  display: inline-block;
  line-height: 24px;
  font-size: 18px;
  margin-bottom: 10px;
}
.pay .right .c-red {
  font-weight: bold;
  font-size: 22px;
}
.pay-type {
  padding: 0 16px;
  height: 64px;
  transition: all 0.3s;
  border-bottom: 1px solid #eeeeee;
}
.pay-type:hover,
.pay-type.active {
  border-bottom: 1px solid #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}
.pay-type label {
  margin-right: 32px;
}
.pay-type i {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  transform: translateY(0);
}
.pay-type input[type="checkbox"] {
  display: none;
}
.w_line-check_red {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #999;
  background-color: #fff;
}
.active + .w_line-check_red {
  border: none;
  background: url("../../images/checkbox-selected+normal.png") no-repeat;
  background-size: contain;
}
.pay-type:nth-last-child(2) {
  margin-bottom: 32px;
}
.wechatpay,
.alipay {
  justify-content: center;
}
.pay-QRcode-box {
  width: 280px;
  height: 280px;
  border: 1px solid #dddddd;
  margin-bottom: 16px;
  padding: 20px;
}
.pay-QRcode-box img {
  width: 240px;
}
.pay .x_scanning {
  width: 280px;
  height: 62px;
  background: #5cdbd3;
  color: #fff;
  justify-content: center;
}
.pay .x_scanning i {
  width: 36px;
  height: 36px;
  transform: translateY(0);
}
.pay .x_scanning span {
  width: 142px;
  line-height: 21px;
  margin-left: 12px;
}
.pay-right {
  width: 330px;
  height: 422px;
  border: 1px dashed #333;
  margin-left: 32px;
}
.pay-right img {
  width: 100%;
}
.pay .new-alert {
  width: 320px;
}
.pay .n-a-body span {
  width: auto;
}
.pay .n-a-body input {
  width: 124px;
}
.payBtn {
  width: 216px;
  height: 48px;
  line-height: 48px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}
</style>