<template>
  <div class="form">
    <p class="title">忘记密码</p>
    <slot name="tel"></slot>
    <slot name="code"></slot>
    <div class="input-box">
      <i class="iconfont mr-8">&#xe675;</i>
      <input type="password" v-model="password" placeholder="请输入新密码" />
    </div>
    <div class="input-box">
      <i class="iconfont mr-8">&#xe675;</i>
      <input type="password" v-model="again_password" placeholder="确认密码" />
    </div>
    <button @click="seekPassword">重置</button>
    <router-link to="/login" class="back">返回登录</router-link>
  </div>
</template>

<script>
export default {
  name: "forgetpwd",
  props: {
    //手机号
    tel: String,
    //验证码
    code: String,
  },
  data() {
    return {
      //密码
      password: "",
      //确认密码
      again_password: "",
    };
  },
  methods: {
    //提交重置密码
    seekPassword() {
      //检查信息是否填写
      if (this.testTel(this.tel) == false) {
        //nothing
      } else if (this.code == "") {
        alert("请输入验证码");
      } else if (this.password == "") {
        alert("请输入新密码");
      } else if (this.again_password == "") {
        alert("请确认密码");
      } else {
        this.axios({
          url: "home/public/seek_password",
          method: "POST",
          data: {
            phone: this.tel,
            phone_code: this.code,
            password: this.password,
            again_password: this.again_password,
          },
        }).then((res) => {
          const data = res.data;
          console.log(res);
          alert(data.msg);
          if (data.code == 0) {
            this.password = "";
            this.again_password = "";
            this.$router.push("/login");
          }
        });
      }
    },
  },
};
</script>

<style>
.login .title,
.get-code,
.login .back {
  font-size: 14px;
}
.login .title {
  color: #999;
  text-align: left;
  margin: 20px 0;
}
.has-get-code {
  width: 102px;
  height: 37px;
  
}
.get-code,
.has-get-code {
  color: #fff;
  line-height: 37px;
  border: 1px solid;
  border-color: #ddd;
  background: #fff;
  transition: all 0.3s;
  background: #F5222D;
  border-radius: 19px;
}
.get-code:hover {
  color: #fff;
  border-color: #f5222d;
}
.login .back {
  color: #f5222d;
  float: right;
}
</style>