<template>
  <div>
    <div class="bindlogin" v-if="type === 2">
      <img :src="info.headimgurl" alt="" class="avatar">
      <p class="nick_name">{{info.nickname}}</p>
      <p>您的微信号尚未注册/绑定印爆网账号，请先绑定:</p>
      <div class="input_item">
        <span class="label">手机号</span>
        <div class="inputs">
          <!-- <i class="iconfont mr-8">&#xe66d;</i> -->
          <input type="tel" placeholder="请输入手机号" v-model="phone"/>
        </div>
      </div>
      <div class="input_item">
        <span class="label">验证码</span>
        <div class="inputs">
          <!-- <i class="iconfont mr-8">&#xe661;</i> -->
          <input type="text" placeholder="请输入手机验证码" class="codeInput" v-model="phoneCode"/>
          <div class=" timer" v-if="hasGetCode">{{time}}</div>
          <div class="codeBox" @click="sendCode" v-else>
            获取验证码
          </div>
        </div>
        
      </div>
      <div class="submitBtn" @click="bindtoLogin">绑定登录</div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { sendCode, auth, getToken, getUserInfo } from '@/http/api'
export default {
  data() {
     return{
       phone: '',
       phoneCode: '',
      // 是否发送验证码
      hasGetCode: false,
      // 倒计时
      time: 60,
      // 计数器
      timer: null,
      // wx授权code
      code: '',
      // 用户信息
      info: {},
      // 判断是否绑定
      type: 0
     }
  },
  created () {
    this.$store.commit("changeHeader");
    this.$store.commit("changeFooter");
    console.log(this.$route.query);
  },
  mounted() {
    this.code = this.$route.query.code ? this.$route.query.code : '';
    this.getWxInfo();
  },
  watch: {
    //监听是否发送过验证码
    hasGetCode: function (newValue) {
      //如果已发送则开启倒计时
      if (newValue) {
        this.timer = setInterval(() => {
          this.time--;
          //倒计时结束才能再次获取验证码
          if (this.time == 0) {
            clearInterval(this.timer);
            this.hasGetCode = false;
            this.time = 60;
          }
        }, 1000);
      }
    },
  },
  methods: {
    async getWxInfo() {
      if(this.code) {
        let res = await getToken({code: this.code});
        const { statusCode } = res;
        const { type } = res.data;
        
        if(statusCode === 200) {
          this.type = type;
          if(type === 1) {
            this.$store.commit('saveToken', res.data.token);
            getUserInfo().then(res => {
              const { statusCode, data } = res;
              if (statusCode === 200) {
                this.$store.commit("savesurplusMoney", data.user.surplus_money);
                this.$store.commit("saveTel", data.user.phone);
              }
            })
            this.$router.go(-2);
            this.$store.commit("changeHeader");
            this.$store.commit("changeFooter");
            this.$store.commit("changeLogin", true);
          }else {
            this.info = res.data.data;
          }
        }
      }
    },
    // 发送验证码
    sendCode() {
      if (this.testTel(this.phone)) {
        sendCode({phone: this.phone}).then(res => {
          const { statusCode, msg } = res;
          if(statusCode === 200) {
            this.hasGetCode = true;
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        })
      }
    },
    async bindtoLogin() {
      if(this.phone == '') {
        this.$message.warning('请输入手机号');
      }else if(this.phoneCode == '') {
        this.$message.warning('请输入验证码');
      }else {
        // 请求登录
        let params ={
          phone: this.phone,
          code: this.phoneCode,
          type: 1,
          nick_name: this.info.nick_name,
          headimgurl: this.info.headimgurl,
          openid: this.info.openid,
          unionid: this.info.unionid,
          sex: this.info.sex,
          country: this.info.country,
          province: this.info.province,
          city: this.info.city
        }
        let res = await auth(params);
        const { statusCode, data, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.$store.commit('saveToken', data.token);
          this.$store.commit('saveWxInfo', this.info);
          getUserInfo().then(res => {
            const { statusCode, data } = res;
              if (statusCode === 200) {
                this.$store.commit("savesurplusMoney", data.user.surplus_money);
                this.$store.commit("saveTel", data.user.phone);
              }
          })
          this.$router.go(-2);
          this.$store.commit("changeHeader");
          this.$store.commit("changeFooter");
          this.$store.commit("changeLogin", true);
        }else {
          this.$message.error(msg);
        }
      }
    }
  }
}
</script>

<style scoped>
.bindlogin {
  width: 460px;    
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  text-align: center;
  margin: 200px auto;
  padding: 20px;
  background: #fff;
}
.avatar {
  width: 80px;
  height: 80px;
}
.nick_name {
  margin: 20px 0;
  font-weight: 700;
}
.input_item {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  margin-top: 20px;
}
.input_item input {
  height: 34px;
  flex: 1;
  padding: 0 25px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border: 1px solid #ccc;
  font-size: 14px;
  border-radius: 4px;
}
.input_item .iconfont {
  position: absolute;
  left: 75px;
  top: 50%;
  transform: translateY(-50%);
}
.input_item .inputs {
  display: flex;
  flex: 1;
}
.label {
  font-weight: 700;
  margin-right: 20px;
}
.input_item .codeInput {
  border-radius: 4px 0 0 4px;
}
.codeBox,
.timer {
  padding: 0 15px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border-left: 0;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}
.codeBox:hover {
  background: #eee;
}
.timer:hover {
  cursor: not-allowed;
}
.submitBtn {
  display: inline-block;
  width: 88px;
  padding: 0 15px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  font-size: 14px;
  margin:  20px auto;
  cursor: pointer;
}
.submitBtn:hover {
  background: #337ab7;
  color: #fff;
}
</style>