<template>
  <div class="afterSaleDefault">
    <c-nav :nav="navList"></c-nav>
    <div class="card">
      <div class="input-box p-20 flex f-a-c fs-14" style="flex-wrap: wrap; justify-content: space-between;">
        <div>
          <span class="t_name mb-10">订单号：</span>
          <input type="text" v-model="search" placeholder="请输入订单编号" class="mr-8" />
        </div>
        <div class="ml-32">
          <span class="t_name mb-10">商品名称：</span>
          <input type="text" v-model="product" placeholder="请输入商品名称" class="mr-8" />
        </div>
        <div class="ml-32">
          <span class="t_name mb-10">订单状态：</span>
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div>
          <span class="t_name mb-10">下单时间：</span>
          <div class="select dis_block" style="vertical-align: middle;">
              <a href="javascript:;" class="header w_down" v-show="!isTime">
                请选择下单时间<i></i>
              </a>
              <a href="javascript:;" class="header w_down" v-show="isTime">
                {{ startTime }}~{{ endTime }}
              </a>
              <Calendar
                v-model="example.value"
                :range="example.range"
                :lang="example.lang"
                :firstDayOfWeek="example.firstDayOfWeek"
                :input-class="example.inputClass"
                :position="example.position"
                :disabled-start-date="example.disabledStartDate"
                :text-format="example.textFormat"
                :date-format="example.dateFormat"
                :disabled-end-date="example.disabledEndDate"
                @choose="isTime = true"
                @handleClear="isTime = false"
              />
            </div>
        </div>
        <a href="javascript:;" class="bg-r-btn mb-10 mt-10" @click="searchFn">查询</a>
      </div>
    </div>
    <div class="table card mb-20">
      <div class="header flex">
        <span class="goods" >商品</span>
        <!-- <span class="unit-price" style="width: 11%;">单价(元)</span> -->
        <span class="num" style="width: 8%;">数量(件)</span>
        <span class="sum" style="width: 12%;">售后状态</span>
        <span class="status" style="width: 12%;">处理方式</span>
        <span class="other" style="width: 14%;">退款金额</span>
        <span class="action" style="width: 7%;">操作</span>
      </div>
      <ul class="order-list">
        <li v-for="(value, index) in order_list" :key="index" class="mb-20">
          <div class="header flex f-a-c" style="justify-content: space-between;">
            <div style="width: 55%;">
              <span class="mr-19" >订单号：{{ value.sn }}</span>
              <span class="mr-24 gray9">{{ value.created_at }}</span>
            </div>
            
            <!-- <div style="width: 15%; text-align: right;"><span class="iconfont m_delete-fill"></span></div> -->
          </div>
          <div class="body" v-for="orderItem in value.warehouse" :key="orderItem.id">
            <div style="padding: 10px; border-bottom: 1px solid #EEEEEE;">
              <div class="fs-12">
                <span class="">
                  <i class="iconfont m_home"></i>
                  <span class="ml-8">{{orderItem.name}}</span>
                </span>
                <span class="ml-24">
                  <i class="iconfont m_zhanghao"></i>
                  <span class="ml-8">{{orderItem.admin.code}}</span>
                </span>
                <span class="ml-24" v-if="orderItem.type === 1">
                  
                  <i class="iconfont m_local-fill"></i>
                  <span class="ml-8">自提</span>
                </span>
                <span class="ml-24 deliveryItem" v-if="orderItem.type !== 1">
                  <span class="triangle_down" v-show="orderItem.delivery"></span>
                  <span class="tips bg_white" v-show="orderItem.delivery">
                    <span v-show="orderItem.type === 2">快递寄付</span>
                    <span v-show="orderItem.type === 3">快递到付</span>
                    <span class="ml-4">{{orderItem.delivery ? orderItem.delivery.mail_no : ''}}</span>
                    <span>{{orderItem.delivery ? orderItem.delivery.express_name : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.name : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.phone : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.province : ''}} {{orderItem.receiving ? orderItem.receiving.city :''}}
                      {{orderItem.receiving ? orderItem.receiving.district : ''}} {{orderItem.receiving ? orderItem.receiving.detail : ''}}
                    </span>
                  </span>
                  <i class="iconfont m_truck"></i>
                  <span class="ml-8">{{orderItem.receiving ? orderItem.receiving.name : ''}}</span>
                </span>
                <span class="col_f52 bold " style="float: right;"
                 v-show="value.status === 7 || value.status === 5">
                  <span v-show="orderItem.asles_day_num > 0">
                    可售后时间：{{orderItem.asles_day_num}}
                  </span>
                </span>
              </div>
            </div>
            <div class="flex">
              <div class="order-goods-list">
                <ul>
                  <li
                    class="flex  goodItem"
                    v-for="(goods, index) in orderItem.products"
                    :key="index"
                  >
                    <a
                      :to="'/house/' + goods.hashid"
                      class="order-img-box"
                      href="javascript:;"
                      @click="toDetailPage(goods.hashid)"
                    >
                      <img
                        :src="goods.image + '?imageMogr2/thumbnail/64x'"
                        :alt="goods.full_name"
                      />
                    </a>
                    <div>
                      <a
                        :to="'/house/' + goods.hashid"
                        class="title mb-8"
                        href="javascript:;"
                       @click="toDetailPage(goods.hashid)"
                      >
                        {{ goods.full_name }}
                      </a>
                      
                    </div>
                    <p class="ml-24 gray9">
                        <span v-for="color in goods.colourArr" :key="color.id" class="block" style="text-align:left;">
                          <span class="mr-8">
                            {{ color.name }}:
                            <span v-for="size in color.sizeArr" :key="size.id"> {{size.name}}-{{size.number}};</span>
                          </span>
                        </span>
                      </p>
                    <!-- <span>{{ goods.total_price }}</span> -->
                    <span>{{ goods.number }}</span>
                  </li>
                </ul>
               
              </div>
               
              <div class="order-status p-20 flex1">
                <p>{{ value.status_name }}</p>
              </div>
               <div class="rightMsg p-20 flex1">
                <p>退货退款</p>
              </div>
              <div class="rightMsg p-20 flex1 bold">
                <p>￥{{value.total_amount}}</p>
              </div>
              <div class="rightMsg p-20 flex1">
                <router-link :to="'after-sale/' + value.hashid" class="block">
                  查看详情
                </router-link>
                <!-- <a href="javascript:;" class="active" @click="toSaleDetail(value.hashid)">查看详情</a> -->
                <a href="javascript:;" v-if="value.status === 1" class="block mt-8 col_f52" @click="handleCanelOrder(value.hashid)">撤销申请</a>
                <!-- <a href="javascript:;" v-if="value.status === 1">撤销申请</a> -->
              </div>
            </div>
            <div class="resonBox fs-14 col_85">
              <div>退货原因：{{value.reason}}</div>
              <div class="mt-16">退货地址：
                <span v-if="value.merchandiser">
                  {{value.merchandiser.province}}{{value.merchandiser.city}}
                  {{value.merchandiser.county}}{{value.merchandiser.detail}}
                </span>
                <span v-else>--</span>
              </div>
              <div class="mt-16">退货说明：{{value.describe}}</div>
            </div>
          </div>
        </li>
      </ul>
      <p v-show="order_list && order_list.length == 0" class="p-20">暂时没有哦~</p>
    </div>
    <div class="card flex f-a-c p-16-20 mt-20">
      <!-- <input type="checkbox" name="all" id="all" />
      <label for="all">全选</label> -->
      <!-- 分页导航 -->
      <div class="pagination right flex" v-if="refund_count !== 0">
        <a
          href="javascript:;"
          class="w_turn-left flex f-a-c"
          @click="changePages('-')"
          ><i></i
        ></a>
        <a
          v-for="i in refund_count"
          :key="i"
          href="javascript:;"
          @click="changePages(i)"
          :class="[parseInt(pageNum) === parseInt(i) ? 'active' : '']"
          >{{ i }}</a
        >
        <a
          href="javascript:;"
          class="w_turn-right flex f-a-c"
          @click="changePages('+')"
          ><i></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "../../../components/calendar";
import { afterOrderList, revoke } from '@/http/api';
export default {
  name: "afterSaleDefault",
  components: {
    Calendar
  },
  data() {
    return {
      active: 1,
      // tagList: [
      //   {
      //     id: 1,
      //     name: "换货记录",
      //     num: 1,
      //   },
      //   {
      //     id: 2,
      //     name: "退款记录",
      //     num: 2,
      //   },
      // ],
      refund_count: 1, // 分页,售后信息总数
      pageNum: 1,
      navList: [
        {
          path: `/user/after-sale`,
          text: "售后工作台",
        },
      ],
      // exchangeRecord: [],

      //日期选择器
      example: {
        inputClass: "exampleDatePicker",
        lang: "zh-CN",
        position: "left",
        range: true,
        value: [new Date(), new Date()],
        firstDayOfWeek: "sunday",
      },
      //是否筛选时间
      isTime: false,
      order_list: [],
      status: null,
      search: '',
      product: '',
      // 订单状态列表
      statusList: [
        { id: 0, value: '已撤销'},
        { id: 1, value: '待审核'},
        { id: 2, value: '待寄回'},
        { id: 3, value: '已驳回'},
        { id: 4, value: '待签收'},
        { id: 5, value: '已拒收'},
        { id: 6, value: '已完成'},
      ]
    };
  },
  computed: {
    //开始时间
    startTime() {
      let str = "";
      if (this.example.value[0] != "" && this.isTime) {
        str = this.example.value[0].toLocaleDateString().split("/").join("-");
      }
      return str;
    },
    //结束时间
    endTime() {
      let str = "";
      if (this.example.value[1] != "" && this.isTime) {
        str = this.example.value[1].toLocaleDateString().split("/").join("-");
      }
      return str;
    },
  },
  created() {
    this.afterOrderList();
  },
  methods: {
    tagClick(id) {
      this.active = id;
    },
    searchFn() {
      this.afterOrderList();
    },
    // 查看详情
    toSaleDetail(id) {
      this.$router.push({path:'after-sale',query: {orderId: id}});
    },
    // 撤销申请
    handleCanelOrder(id) {
      this.$confirm('是否撤销申请','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.revoke(id)
      }).catch(() => {})
    },
    // 跳转到商品详情
    toDetailPage(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
    //---------------------获取数据 &api----------------------
    afterOrderList() {
      let params = {
        status: this.status,
        search: this.search,
        product: this.product,
        time: this.startTime ? this.startTime + ' - '+ this.endTime : ''
      }
      afterOrderList(params).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.order_list = data.data;
        }
      })
    },
    // 撤销申请
    revoke(id) {
      revoke({orderId: id}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('撤销成功');
          this.afterOrderList();
        }else {
          this.$message.error('撤销失败');
        }
      })
    },
  },
  mounted() {
  },
};
</script>

<style>
.input-box .el-input__icon {
  line-height: 32px;
}
/* .input-box >div {
  margin-bottom: 20px;
} */
.afterSaleDefault .input-box .t_name {
  width: 80px;
  display: inline-block;
}

.afterSaleDefault .table {
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
}
.afterSaleDefault .table span {
  display: inline-block;
}
.afterSaleDefault .table a.x_lujing52 {
  opacity: 0.25;
}
.afterSaleDefault .table > .header {
  padding: 12px 0;
  padding-left: 48px;
  background: #f8f8f8;
  border-bottom: 1px solid #ededed;
  color: #999999;
  
}
.table > .header .goods {
  flex: 2;
}


.afterSaleDefault .order-list .header {
  padding: 8px 12px;
  background: #f5f5f5;
  color: #333333;
}
.afterSaleDefault .order-list .header span:nth-child(3) {
  color: #999;
}

.afterSaleDefault .order-list .body {
  border: 1px solid #EEEEEE;
  /* border-top: 0; */
}
.afterSaleDefault .order-list .body:nth-child(1) {
  border-top: 0;
}
.afterSaleDefault .order-list .body > p {
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
  color: #666666;
}
.afterSaleDefault .order-goods-list {
  width: 520px;
  border-right: 1px solid #eeeeee;
}
.afterSaleDefault .order-goods-list li {
  height: 120px;
  border-bottom: 1px solid #eee;
  /* padding-right: 20px; */
}
.afterSaleDefault .order-img-box {
  flex-shrink: 0;
  width: 76px;
  height: 76px;
  background: #eeeeee;
  margin-right: 12px;
}
.order-img-box img {
  width: 100%;
}
.afterSaleDefault td > div,
.afterSaleDefault .order-goods-list li > div {
  width: 120px;
}
.afterSaleDefault td > div .title,
.afterSaleDefault .order-goods-list li > div .title {
  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.afterSaleDefault td > div p:nth-child(2),
.afterSaleDefault .order-goods-list li > div p:nth-child(2) {
  color: #999;
}
.afterSaleDefault .order-goods-list li span {
  text-align: right;
  flex: 1;
}
.afterSaleDefault .order-goods-list > p {
  padding: 12px 0;
  text-align: center;
  color: #999;
}
.afterSaleDefault .flex1 {
  text-align: center;
}
.afterSaleDefault .order-sum p:nth-child(2) {
  color: #999999;
}
.afterSaleDefault .rightMsg,
.afterSaleDefault .order-status{
  border-bottom: 1px solid #EEEEEE;
}
/* .afterSaleDefault .order-status {
  border-left: 1px solid #EEEEEE;
} */
.afterSaleDefault .order-status p {
  color: #74c041;
}
/* .afterSaleDefault .other {
  border-left: 1px solid #EEEEEE;
} */

.goodItem {
  padding: 18px;
}
.m_delete-fill {
  font-size: 12px;
  color: #AAAAAA;
  cursor: pointer;
}
.deliveryItem {
  position: relative;
  cursor: pointer;
}
.deliveryItem .tips {
  z-index: 9999;
  display: none!important;
  position: absolute;
  left: -75px;
  top: -144px;
  width: 250px;
  height: 130px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #ccc;
}
.triangle_down {
  display: none!important;
  position: absolute;
  z-index: 10000;
  background: #fff;
  left: 0;
  top: -20px;
  width: 15px;
  height: 15px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  transform: rotate(45deg);
}
.deliveryItem:hover .tips,
.deliveryItem:hover .triangle_down{
  display: block!important;
}
.resonBox {
  padding: 16px;
}
.afterSaleDefault a:hover {
  color: #f5222d;
}
</style>