<template>
  <div class="my-coupon">
    <c-nav :nav="navList"></c-nav>
    <div class="card">
      <div class="flex p-20 bb1" style="justify-content: space-between; align-items: center;">
        <div class=" ">我的优惠券</div>
        <div class="flex" style="align-items: center;">
          <div class="dis_block col_f52 getCoupBtn t-center fs-14 pointer" @click="toCouponPage">获取更多优惠券</div>
          <img src="../../images/warnIcon.png" alt="" class="ml-16">
          <span class="gray9 fs-14 ml-4 pointer" @click="showExplain">优惠券说明</span>
        </div>
      </div>
      <div class="flex f-a-c p-20">
        <span>优惠券类型：</span>
        <div class="select mr-40" :class="typeShow && 'active'">
          <a href="javascript:;" class="header w_down" @click="changeTypeShow">
            {{ choosedType.name }}<i></i>
          </a>
          <div class="option" :style="{ height: typeShow ? '150px' : '0px' }">
            <a
              href="javascript:;"
              v-for="value in type"
              :key="value.id"
              @click="chooseType(value.id)"
            >
              {{ value.name }}
            </a>
          </div>
        </div>
        <span>优惠券状态：</span>
        <div class="select mr-40" :class="statusShow && 'active'">
          <a
            href="javascript:;"
            class="header w_down"
            @click="changeStatusShow"
          >
            {{ choosedStatus.name }}<i></i>
          </a>
          <div class="option" :style="{ height: statusShow ? '120px' : '0px' }">
            <a
              href="javascript:;"
              v-for="value in status"
              :key="value.id"
              @click="chooseStatus(value.id)"
            >
              {{ value.name }}
            </a>
          </div>
        </div>
      </div>
      <div class="flex p-20" style="width:1000px; padding-bottom:0;">
        <mycoupon-items
          v-for="(value,index) in couponList"
          :content="value"
          :key="'coup'+index"
          v-show="couponList.length > 0"
          class="mr-20 mb-20"
        >
        </mycoupon-items>
        <p v-show="couponList.length == 0">暂时没有哦~</p>
      </div>
      <div class="flex p-20" v-show="couponList.length" style="justify-content: flex-end;">
        <pages
          :pageCount="page_count"
          :nowPage="nowPagination"
          @change-page="changePage"
        >
          <a href="javascript:;" class="ml-20">{{ goods_page_count }}条/页</a>
          <span>
            跳至
            <input
              type="text"
              name="to-pages"
              v-model="pagesTo"
              @keyup.enter="changePages(pagesTo)"
            />页
          </span>
        </pages>
      </div>
      
    </div>
    <!-- 弹框 -->
    <div class="coupBox bg_white" v-show="coupShow">
      <div class="coupTop flex">
        <span class="fs-16">优惠券说明</span>
        <span class="col_45 pointer" @click="showExplain">X</span>
      </div>
      <div class="coupBody gray6 fs-14">
        <div>优惠券类型分别为现金券，折扣券，减免券，运费券。</div>
        <div class="mt-16">现金券：满足使用条件后，可以抵扣部分金额；</div>
        <div class="mt-16">折扣券：满足使用条件后 可以在订单原价的基础上享受折扣；</div>
        <div class="mt-16">减免券：满足使用条件后 可以减免相应金额；</div>
        <div class="mt-16">运费券：满足使用条件后 可以减免相应运费；</div>
      </div>
      <div class="coupBom flex">
        <div class="col_65 fs-14 coupBtn pointer" @click="showExplain">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import { myCoupon } from '@/http/api'
import mycouponItems from "../../components/myCouponItems";
import pages from "../../components/pages";
export default {
  name: "myCoupon",
  components: {
    mycouponItems,
    pages
  },
  data() {
    return {
      navList: [
        {
          path: `/user/my-coupon`,
          text: "我的优惠券",
        },
      ],
      //优惠券列表
      couponList: [],
      //选择优惠券类型
      type: [
        {
          id: 0,
          name: "全部类型",
          active: true,
        },
        {
          id: 1,
          name: "满减券",
          active: false,
        },
        {
          id: 2,
          name: "现金券",
          active: false,
        },
        {
          id: 3,
          name: "运费券",
          active: false,
        },
        {
          id: 4,
          name: "折扣券",
          active: false,
        },
      ],
      //显示类型
      typeShow: false,
      //选择优惠券状态
      status: [
        {
          id: -1,
          name: "全部类型",
          active: true,
        },
        {
          id: 0,
          name: "已过期/失效",
          active: false,
        },
        {
          id: 1,
          name: "待使用",
          active: false,
        },
        {
          id: 2,
          name: "已使用",
          active: false,
        },
      ],
      //显示状态
      statusShow: false,
      // 优惠券说明显示
      coupShow: false,

      //当前页
      nowPagination: 0,
      //跳转指定分页
      pagesTo: "",
      //分页总页数
      page_count: 0,
      //每页条数
      goods_page_count: 0,
    };
  },
  computed: {
    //选择的优惠券类型
    choosedType() {
      let obj = {};
      for (const iterator of this.type) {
        if (iterator.active) obj = iterator;
      }
      return obj;
    },
    //选择的优惠券状态
    choosedStatus() {
      let obj = {};
      for (const iterator of this.status) {
        if (iterator.active) obj = iterator;
      }
      return obj;
    },
  },
  methods: {
    // 获取优惠券数据
    async getMyCouponList(page) {
      let params ={
        type: this.choosedType.id == 0 ? '' : this.choosedType.id,
        status: this.choosedStatus.id == -1 ? '' : this.choosedStatus.id,
        page: page,
        limit: 8
      }
      let res = await myCoupon(params);
      const { statusCode, data } = res;
      if(statusCode === 200) {
        this.couponList = data.data;
        this.nowPagination = data.current_page;
        this.page_count = data.last_page;
        this.goods_page_count = data.per_page;
      }
    },
    
    //改变显示类型
    changeTypeShow() {
      this.statusShow = false;
      this.typeShow = !this.typeShow;
    },
    //选择类型
    chooseType(id) {
      for (const iterator of this.type) {
        iterator.active = false;
      }
      this.type[id].active = true;
      this.changeTypeShow();
      this.getMyCouponList();
    },
    //改变显示状态
    changeStatusShow() {
      this.typeShow = false;
      this.statusShow = !this.statusShow;
    },
    //选择状态
    chooseStatus(id) {
      for (const iterator of this.status) {
        iterator.active = false;
      }
      this.status[id + 1].active = true;
      this.changeStatusShow();
      this.getMyCouponList();
    },
    // 优惠券说明
    showExplain() {
      this.$store.commit('changeMask');
      this.coupShow = !this.coupShow;
    },
    // 前往优惠券页面
    toCouponPage() {
      this.$router.push('/coupon');
    },

    // 切换分页
    changePage(page) {
      if(this.nowPagination === page) {
        return;
      }
      this.nowPagination = page;
      window.scrollTo(0, 0);
      this.getMyCouponList(this.nowPagination);
    },
    // 跳转至
    changePages(i) {
      i = parseInt(i);
      if(this.nowPagination === i) {
        return;
      }
      if (i > this.page_count || i <= 0) {
        return this.$message.error('你输入的分页不存在！');
      }
      this.nowPagination = i;
      this.getMyCouponList(this.nowPagination);
      window.scrollTo(0, 0);
      this.pagesTo = "";
    },
  },
  created() {
    this.getMyCouponList();
  },
};
</script>

<style>
.getCoupBtn {
  width: 130px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #F5222D;
  border-radius: 2px;
}
.coupBox {
  z-index: 9999;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 300px;
  border-radius: 2px;
}
.coupBody {
  padding: 24px 48px;
  border: 1px solid #F0F0F0;
}
.coupTop {
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid #F0F0F0;
}
.coupBom {
  justify-content: flex-end;
  padding: 16px 24px;
}
.coupBtn {
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 2px;
  padding: 6px 12px;
}
</style>