import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import cNav from "./components/cNav";
import axios from "../public/libs/axios";
import store from "./store";
import './plugins/element.js';
import './assets/iconfont.css'

//全局注册组件
Vue.component("cNav", cNav);

//全局引用axios
Vue.prototype.axios = axios;
//默认发送凭证
// axios.defaults.withCredentials = true;

//验证手机号
function testTel(tel) {
  if (tel == "") {
    this.$message.warning('请输入手机号');
    return false;
  } else if (!/^1[3456789]\d{9}$/.test(tel)) {
    this.$message.warning('请输入正确的手机号');
    return false;
  } else {
    return true;
  }
}
Vue.prototype.testTel = testTel;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
