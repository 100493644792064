<template>
  <div class="follwDefault">
    <c-nav :nav="navList"></c-nav>
    <div class="card">
      <div class="flex cardBtn p-20" >
        <a
          href="javascript:;"
          v-for="(value, index) in cardBtn"
          :class="{ active: typeActive == index }"
          :key="index"
          @click="changeType(index)"
        >
          {{ value.name }}<i v-if="value.num">{{ value.num }}</i>
        </a>
      </div>
      <div class="search-box" style="padding: 20px 10px 0px;">
        <div class="flex">
          <div class="input-box" style="padding: 0 10px;">
            <label for="order-num">订单号：</label>
            <input type="text" v-model="orderNum" placeholder="请输入订单号" />
          </div>
          <div class="input-box">
            <label for="goods-name">商品名称：</label>
            <input
              type="text"
              v-model="goodsName"
              placeholder="请输入商品名称"
            />
          </div>
          <div class="input-box">
            <span>下单时间：</span>
            <div class="select">
              <a href="javascript:;" class="header w_down" v-show="!isTime">
                请选择下单时间<i></i>
              </a>
              <a href="javascript:;" class="header w_down" v-show="isTime">
                {{ startTime }}~{{ endTime }}
              </a>
              <Calendar
                v-model="example.value"
                :range="example.range"
                :lang="example.lang"
                :firstDayOfWeek="example.firstDayOfWeek"
                :input-class="example.inputClass"
                :position="example.position"
                :disabled-start-date="example.disabledStartDate"
                :text-format="example.textFormat"
                :date-format="example.dateFormat"
                :disabled-end-date="example.disabledEndDate"
                @choose="isTime = true"
                @handleClear="isTime = false"
              />
            </div>
          </div>
          <a href="javascript:;" class="bg-r-btn" @click="search">查询</a>
        </div>
        <div class="flex mt-16 bg_e2 col_005">
          <span class="mr-46">总订单数：{{ orderSum }}</span>
          <span>总金额：{{ sumMoney }}元</span>
        </div>
      </div>
    </div>
    <div class="table card mb-20">
      <div class="header flex mb-10">
        <span class="goods" >货品</span>
        <span class="unit-price" style="width: 17%;">小计</span>
        <span style="width: 10%;">合计</span>
        <span class="status" style="width: 12%;">订单状态</span>
        <span class="other" style="width: 9%;">其他</span>
      </div>
      <ul class="order-list">
        <li v-for="(value, index) in orderList" :key="index" class="mb-20">
          <div class="header flex f-a-c" style="justify-content: space-between;">
            <div style="width: 55%;">
              <span class="mr-19" >订单号：{{ value.sn }}</span>
              <span class="mr-24 gray9">{{ value.created_at }}</span>
            </div>
            <div class="bold c-3 fs-12" style="width: 50%;">
              总计：{{ value.order_amount }}
              <span class="c-red fs-12">(含优惠金额{{value.coupon_amount}}元)</span>
            </div>
            <!-- <div style="width: 15%; text-align: right;"><span class="iconfont m_delete-fill"></span></div> -->
          </div>
          <div class="body" v-for="orderItem in value.warehouse" :key="orderItem.id">
            <div style="padding: 10px; border-bottom: 1px solid #EEEEEE;">
              <div class="fs-12">
                <span class="">
                  <i class="iconfont w_warehouse2"></i>
                  <span class="ml-8">{{orderItem.name}}</span>
                </span>
                <span class="ml-24">
                  <i class="iconfont w_supplier"></i>
                  <span class="ml-8">{{orderItem.admin.code}}</span>
                </span>
                <span class="ml-24 deliveryItem" v-if="orderItem.type === 1">
                  <i class="iconfont w_local"></i>
                  <span class="ml-8">自提</span>
                  <span class="triangle_down" v-show="value.pick_point"></span>
                  <span class="tips bg_white" v-show="value.pick_point">
                    <span>自提</span>
                    <span class="block">{{value.pick_point ? value.pick_point.phone : ''}}</span>
                    <span class="block">{{value.pick_point ? value.pick_point.province : ''}} {{value.pick_point ? value.pick_point.city :''}}
                      {{value.pick_point ? value.pick_point.district : ''}} {{value.pick_point ? value.pick_point.detail : ''}}
                    </span>
                  </span>
                </span>
                <span class="ml-24 deliveryItem" v-if="orderItem.type !== 1">
                  <span class="triangle_down" v-show="orderItem.receiving"></span>
                  <span class="tips bg_white" v-show="orderItem.receiving">
                    <span v-show="orderItem.type === 2">快递寄付</span>
                    <span v-show="orderItem.type === 3">快递到付</span>
                    <span class="ml-4">{{orderItem.delivery ? orderItem.delivery.mail_no : ''}}</span>
                    <span>{{orderItem.delivery ? orderItem.delivery.express_name : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.name : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.phone : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.province : ''}} {{orderItem.receiving ? orderItem.receiving.city :''}}
                      {{orderItem.receiving ? orderItem.receiving.district : ''}} {{orderItem.receiving ? orderItem.receiving.detail : ''}}
                    </span>
                  </span>
                  <i class="iconfont w_transportation"></i>
                  <span class="ml-8">{{orderItem.receiving ? orderItem.receiving.name : ''}}</span>
                </span>
                <span class="col_f52 bold " style="float: right;"
                 v-show="value.status === 7 || value.status === 5">
                  <span v-show="orderItem.asles_day_num > 0">
                    可售后时间：{{orderItem.asles_day_num}}天
                  </span>
                </span>
              </div>
            </div>
            <div class="flex" >
              <div class="order-goods-list">
                <ul>
                  <li class="flex goodItem" v-for="(goods, gIndex) in orderItem.products" :key="gIndex">
                    <router-link
                     :to="'follow/' + orderItem.orderHashid"
                      class="order-img-box"
                    >
                      <img
                        :src="goods.image + '?imageMogr2/thumbnail/76x'"
                        :alt="goods.full_name"
                      />
                    </router-link>
                    <div>
                      <router-link
                       :to="'follow/' + orderItem.orderHashid"
                        class="title mb-8"
                      >
                        {{ goods.full_name }}
                      </router-link>
                    </div>
                    <p class="ml-24 gray9" style="flex: 1;">
                        <span v-for="color in goods.colourArr" :key="color.id" class="block" style="text-align:left;">
                          <span class="" style="width: 60%;">
                            {{ color.name }}:
                            <span v-for="size in color.sizeArr" :key="size.id"> {{size.name}}-{{size.number}};</span>
                          </span>
                          <span style="width:40%;" class="t-center gray3">
                            {{color.number}}件，共{{color.total_price}}元
                          </span>
                        </span>
                    </p>
                  </li>
                </ul>
                <!-- <p>共{{ orderItem.type }}种产品</p> -->
              </div>
              <div class="order-sum p-20 t-c rightPart">
                <p class="bold fs-14">{{orderItem.order_amount}}元</p>
                <p>含运费{{ orderItem.freight }}</p>
                <p class="black fs-12">共{{orderItem.number}}件</p>
              </div>
              <div class="order-status p-20 rightPart">
                <p class="mb-16" v-if="orderItem.status === 0">已删除/已过期</p>
                <p class="mb-16" v-else>{{ orderItem.status_name }}</p>
                <!-- <router-link :to="'follow/' + orderItem.orderHashid" class="href">
                  订单详情
                </router-link> -->
                <div>
                  <a href="javascript:;" v-if="value.status == 1" @click="handleCancelOrder(orderItem.orderHashid)">取消订单</a>
                </div>
                
              </div>
              <div class="other p-20 rightPart">
                <a class="block" href="javascript:;" v-if="value.status == 1" @click="toPay(value.hashid)">
                  去付款
                </a>
                <a href="javascript:;" v-if="value.status == 3 || value.status == 4" @click="confirm(orderItem.orderHashid)">
                  确认收货
                </a>
                <a  href="javascript:;" class="block col_f52" v-if="value.status == 5" @click="applySale(orderItem.orderHashid)" >
                  申请售后
                </a>
                <a href="javascript:;" class="block" @click="buyAgain(orderItem.hashid, index)" v-if="value.status != 1">
                  再次购买
                </a>
                
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-show="orderList.length == 0" class="p-20">暂时没有哦~</p>
    </div>
    <div class="card flex f-a-c p-16-20">
      <!-- <pages
        :pageCount="page_count"
        :nowPage="page"
        @change-page="getDocumentary"
      ></pages> -->
      <pages
        :pageCount="page_count"
        :nowPage="nowPagination"
        @change-page="changePage"
      >
        <a href="javascript:;" class="ml-20">{{ goods_page_count }}条/页</a>
        <span>
          跳至
          <input
            type="text"
            name="to-pages"
            v-model="pagesTo"
            @keyup.enter="changePages(pagesTo)"
          />页
        </span>
      </pages>
    </div>
  </div>
</template>

<script>
import Calendar from "../../../components/calendar";
import pages from "../../../components/pages";

import { documentary, addCart, confirmReceipt, delOrder } from '@/http/api';

export default {
  name: "followDefault",
  props: {
    query: {
      type: String,
    },
  },
  components: {
    Calendar,
    pages,
  },
  data() {
    return {
      navList: [
        {
          path: `/user/follow`,
          text: "跟单工作台",
        },
      ],
      //订单种类列表
      cardBtn: [
        {
          name: "全部订单",
          num: "",
        },
        {
          name: "待付款",
          num: "",
        },
        {
          name: "配货中",
          num: "",
        },
        {
          name: "待收货/自提",
          num: "",
        },
        {
          name: "已完成",
          num: "",
        },
        {
          name: '已删除/已过期',
          num: ''
        }
      ],
      //订单种类类型 对应订单种类列表
      type: '',
      typeActive: 0,
      //订单列表
      orderList: [],
      //总订单数
      orderSum: 0,
      //总金额
      sumMoney: 0,
      //日期选择器
      example: {
        inputClass: "exampleDatePicker",
        lang: "zh-CN",
        position: "left",
        range: true,
        value: [new Date(), new Date()],
        firstDayOfWeek: "sunday",
      },
      //是否筛选时间
      isTime: false,
      //搜索的订单号
      orderNum: "",
      //搜索的商品名称
      goodsName: "",


      //当前页
      page: 0,
      //切换的分页
      toPage: 1,

     
      //当前页
      nowPagination: 0,
      //跳转指定分页
      pagesTo: "",
      //分页总页数
      page_count: 0,
      //每页条数
      goods_page_count: 0,
    };
  },
  computed: {
    //开始时间
    startTime() {
      let str = "";
      if (this.example.value[0] != "" && this.isTime) {
        str = this.example.value[0].toLocaleDateString().split("/").join("-");
      }
      return str;
    },
    //结束时间
    endTime() {
      let str = "";
      if (this.example.value[1] != "" && this.isTime) {
        str = this.example.value[1].toLocaleDateString().split("/").join("-");
      }
      return str;
    },
    //用户账号
    // tel() {
    //   return this.$store.state.tel;
    // },
  },
  methods: {
    
    //切换订单种类类型
    changeType(type) {
      this.typeActive = type;
      if(type == 0) {
        this.type = ''
      }else if(type == 5) {
        this.type = 0;
      }else {
        this.type = type;
      }
      this.isTime = false;
      this.getDocumentary();
    },
    //点击查询
    search() {
      // this.type = -1;
      this.getDocumentary();
    },
    // 去付款
    toPay(orderId) {
      let data = {
        orderId: orderId,
      }
      this.$store.commit('savePayData', data);
      this.$router.push('/pay');
    },
    // 再次购买
    buyAgain(hashid, index) {
      let arr = [];
      this.orderList[index].warehouse.forEach(item => {
          if(item.hashid === hashid) {
            item.products.forEach(pro => {
              arr.push({
                productId: pro.hashid,
                number: pro.number,
                sku_id: pro.sku_id
              })
            })
          }
      });
      console.log(arr);
      this.addCart(hashid, arr);
    },
    // 申请售后
    applySale(orderId) {
      this.$router.push({path: 'follow/applySale', query: {orderId: orderId}});
    },
    // 确认收货
    confirm(orderId) {
      this.$confirm('是否确认收货','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmReceipt(orderId);
      }).catch(() => {})
    },
    // 取消订单
    handleCancelOrder(id) {
      this.$confirm('是否取消该订单','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delOrder(id);
      }).catch(() => {
      })
    },
    // 切换分页
    changePage(page) {
      if(this.nowPagination === page) {
        return;
      }
      this.nowPagination = page;
      window.scrollTo(0, 0);
      this.getDocumentary(this.nowPagination);
    },
    // 跳转至
    changePages(i) {
      i = parseInt(i);
      if(this.nowPagination === i) {
        return;
      }
      if (i > this.page_count || i <= 0) {
        return this.$message.error('你输入的分页不存在！');
      }
      this.nowPagination = i;
      this.getDocumentary(this.nowPagination);
      window.scrollTo(0, 0);
      this.pagesTo = "";
    },
    //--------------------------获取数据-----------------
    //获取数据
    getDocumentary(page) {
      let params = {
        status: this.type,
        search: this.orderNum,
        product: this.goodsName,
        time: this.startTime ? this.startTime + ' - ' + this.endTime : '',
        page: page,
        limit: 15,
      };
      console.log(params);
      documentary(params).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          console.log(res);
          this.orderList = data.data;
          this.sumMoney = res.totalAmount;
          this.orderSum = data.total;

          this.cardBtn[0].num = res.num;
          this.cardBtn[1].num = res.num1;
          this.cardBtn[2].num = res.num2;
          this.cardBtn[3].num = res.num3;
          this.cardBtn[4].num = res.num4;
          this.cardBtn[5].num = res.num5;

          this.nowPagination = data.current_page;
          this.page_count = data.last_page;
          this.goods_page_count = data.per_page;
        }
      })
    },
    // 加入购物车
    addCart(id, list) {
      let parms = {
        productData: list,
        warehouseId: id
      }
      addCart(parms).then(res => {
        const { statusCode } = res;
        if(statusCode	== 200) {
          this.$router.push('/shopping-cart');
        }
      })
    },
    // 确认收货
    confirmReceipt(orderId) {
      confirmReceipt({orderId: orderId}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('确认收货成功');
          this.getDocumentary();
        }else {
          this.$message.error('确认收货失败')
        }
      })
    },
    // 取消订单
    delOrder(id) {
      delOrder({orderId: id}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('取消成功');
          this.getDocumentary(this.nowPagination);
        }else {
          this.$message.error('取消失败');
        }
      })
    },
  },
  created() {
    if (this.query){
      this.type = this.query;
      this.typeActive = this.query;
    }

    this.getDocumentary(1);
    // this.getTypeNum();
  },
};
</script>

<style>
.follow .search-box .input-box {
  display: flex;
  align-items: center;
  margin-right: 22px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.follow .search-box .input-box .input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #bfbfbf;
  cursor: pointer;
}
.follow .search-box .input-box .input i {
  width: 10px;
  height: 10px;
  transform: translateY(0);
  opacity: 0.25;
}
.follow .search-box .input-box .input,
.follow .search-box .input-box input {
  width: 200px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 5px 12px;
}
.follow .search-box .flex:last-child {
  background: #f5f5f5;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  padding: 10px 20px;
}
.follow .table {
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
}
.follow .table span {
  display: inline-block;
}
.follow .table a.x_lujing52 {
  opacity: 0.25;
}
.follow .table > .header {
  padding: 12px 0;
  padding-left: 48px;
  background: #f8f8f8;
  border-bottom: 1px solid #ededed;
  color: #999999;
  
}
.table > .header .goods {
  flex: 2;
}


.follow .order-list .header {
  padding: 8px 12px;
  background: #f5f5f5;
  color: #333333;
}
.follow .order-list .header span:nth-child(3) {
  color: #999;
}

.follow .order-list .body {
  border: 1px solid #EEEEEE;
  border-top: 0;
}
.follow .order-list .body > p {
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
  color: #666666;
}
.follow .order-goods-list {
  /* width: 520px; */
  flex: 1;
  border-right: 1px solid #eeeeee;
}
/* .follow .order-goods-list li {
  height: 120px;
  border-bottom: 1px solid #eee;
  padding-right: 20px;
} */
.follow .order-img-box {
  flex-shrink: 0;
  width: 76px;
  height: 76px;
  background: #eeeeee;
  margin-right: 12px;
}
.order-img-box img {
  width: 100%;
}
.follow td > div,
.follow .order-goods-list li > div {
  width: 120px;
}
.follow td > div .title,
.follow .order-goods-list li > div .title {
  width: 120px;
  max-height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.follow td > div p:nth-child(2),
.follow .order-goods-list li > div p:nth-child(2) {
  color: #999;
}
/* .follow .order-goods-list li span {
  text-align: right;
  flex: 1;
} */
.follow .order-goods-list > p {
  padding: 12px 0;
  text-align: center;
  color: #999;
}
.follow .flex1 {
  text-align: center;
}
.follow .order-sum p:nth-child(2) {
  color: #999999;
}
.follow .order-status {
  border-left: 1px solid #EEEEEE;
}
.follow .order-status p {
  color: #74c041;
}
.follow .other {
  border-left: 1px solid #EEEEEE;
}
.follow .other a:nth-child(n+2) {
  margin-top: 16px;
}
.goodItem {
  padding: 0 40px;
}
.goodItem:nth-child(n+2) {
  border-top: 1px solid #EEEEEE;
}
.m_delete-fill {
  font-size: 12px;
  color: #AAAAAA;
  cursor: pointer;
}
.deliveryItem {
  position: relative;
  cursor: pointer;
}
.deliveryItem .tips {
  display: none!important;
  position: absolute;
  left: -75px;
  top: -144px;
  width: 250px;
  height: 130px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #ccc;
}
.triangle_down {
  display: none!important;
  position: absolute;
  z-index: 1;
  background: #fff;
  left: 0;
  top: -20px;
  width: 15px;
  height: 15px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  transform: rotate(45deg);
}
.deliveryItem:hover .tips,
.deliveryItem:hover .triangle_down{
  display: block!important;
}

.rightPart {
  width: 107px;
  text-align: center;
}
.w_warehouse2,
.w_supplier {
  color: #1890FF;
}
.w_local,
.w_transportation {
  color: #F5222D;
}
.href:hover {
  color: #f5222d;
}
.follwDefault a:hover{
  color: #f5222d;
}
.goodItem {
  padding: 18px;
}
</style>