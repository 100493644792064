<template>
  <div class="shopping-cart fs-12">
    <div class="c-box">
      <p class="c-red">全部商品</p>
      <div class="table sc-table mb-20">
        <div class="header flex">
          <span class="flex f-a-c">
            <input
              type="checkbox"
              id="sc-all"
              class="mr-8"
              v-model="selectAll"
            />
            <label
              class="w_line-check_red"
              :class="{ x_completed: selectAll }"
              @click="handleSelAll"
            >
              <i></i>
            </label>
            <label  @click="handleSelAll">全选</label>
          </span>
          <span style="margin-left: 80px;">产品</span>
          <span class="t-center" style="width: 20%;">数量</span>
          <span class="t-center" style="width: 22%;">单价(元)</span>
          <span class="t-right">金额(元)</span>
        </div>
        <div class="no-order" v-show="orderList.length == 0">
          购物车里还没有商品，
          <router-link to="/house" class="c-red"> 去逛逛 </router-link>
        </div>
        <div
          class="body mb-16"
          v-for="(value, index) in orderList"
          :key="index"
        >
          <div class="flex">
            <span class="warehouse-box flex f-a-c" @click="chooseHouse(index)">
              <input
                type="checkbox"
                :id="'warehouse' + value.id"
                class="mr-8"
                v-model="value.active"
              />
              <label
                
                class="w_line-check_red"
                :class="{ x_completed: value.active }"
              >
                <i></i>
              </label>
              <label >
                {{ value.name }}
              </label>
            </span>
            <a
              href="javascript:;"
              class="right x_lujing52"
              @click="deletHouse(index)"
            >
              <i></i>
            </a>
          </div>
          <div
            class="flex main"
            v-for="(goods, goodsIndex) in value.productData"
            :key="goodsIndex"
          >
            <div class="flex sc-img-box mr-8">
              <input
                type="checkbox"
                class="mr-8"
                :id="'goods' + value.id + goods.id"
                v-model="goods.active"
              />
              <label
                
                class="w_line-check_red"
                :class="{ x_completed: goods.active }"
                @click="chooseGoods(index, goodsIndex)"
              >
                <i></i>
              </label>
              <router-link :to="'/house/' + goods.hashid" class="img-box">
                <img
                  :src="goods.image"
                  :alt="goods.full_name"
                  v-if="goods.image"
                />
              </router-link>
            </div>
            <div class="order-att flex1">
              <p class="title mb-24">{{ goods.full_name }}</p>
              <div
                class="oa-for"
                v-for="(att, attIndex) in goods.colourData"
                :key="attIndex"
              >
                <div class="flex ml-40">
                  <span class="sc-color">颜色：{{ att.name }}</span>
                  <div class="info-box">
                    <div
                      class="info"
                      v-for="(size, sizeIndex) in att.skuData"
                      :key="sizeIndex"
                    >
                      <div class="sc-size-box">
                        <div class="sc-size flex f-a-c">
                          <input
                            type="checkbox"
                            :id="
                              'sc-size-' +
                              value.id +
                              goods.id +
                              att.id +
                              size.size.id
                            "
                            v-model="size.active"
                          />
                          <label
                            
                            class="w_line-check_red"
                            :class="{ x_completed: size.active }"
                            @click="
                              changeSize(index, goodsIndex, attIndex, sizeIndex)
                            "
                          >
                            <i></i>
                          </label>
                          <label
                            
                            @click="
                              changeSize(index, goodsIndex, attIndex, sizeIndex)
                            "
                          >
                            尺码：{{ size.size.name }}
                          </label>
                        </div>
                      </div>
                      <div class="sc-num-box">
                        <div class="flex">
                          <a
                            href="javascript:;"
                            class="x_reduce"
                            @click="
                              changeNum(
                                index,
                                goodsIndex,
                                attIndex,
                                sizeIndex,
                                0
                              )
                            "
                          >
                            <i></i>
                          </a>
                          <input type="text" id="n1" v-model="size.numbers" maxlength="5"
                          @input="() =>{if(size.numbers.length>5)size.numbers=size.numbers.slice(0,5)}"
                          @blur="handleChangeNum(index, goodsIndex, attIndex, sizeIndex)"/>
                          <a
                            href="javascript:;"
                            class="x_add"
                            @click="
                              changeNum(
                                index,
                                goodsIndex,
                                attIndex,
                                sizeIndex,
                                1
                              )
                            "
                          >
                            <i></i>
                          </a>
                        </div>
                      </div>
                      <div class="sc-unit-price">
                        <p>{{ size.price }}</p>
                      </div>
                      <div class="sc-sum-box">
                        <p class="c-red">
                          {{ (size.numbers * size.price).toFixed(2) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-footer flex f-a-c">
        <div class="flex f-a-c">
          <input
            type="checkbox"
            id="sc-all1"
            class="mr-8"
            v-model="selectAll"
          />
          <label
            class="w_line-check_red"
            :class="{ x_completed: selectAll }"
            @click="handleSelAll"
          >
            <i></i>
          </label>
          <label @click="handleSelAll">全选</label>
          <a href="javascript:;" class="ml-40" @click="deletAll">删除全部</a>
        </div>
        <div class="right flex f-a-c">
          <span>产品种类：</span>
          <span class="fs-16">{{ goodsSortSum }}</span>
          <span class="mr-16">种</span>
          <span>数量总计：</span>
          <span class="fs-16">{{ choosedNum }}</span>
          <span class="mr-24">件</span>
          <span>产品金额总计(不包含运费)：</span>
          <span class="c-red">{{ payMoney }}</span>
          <span>元</span>
          <a href="javascript:;" class="bg-r-btn" @click="toSettle">结算</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCartList, updateCartNum, delCartOrder, getCartNum } from '@/http/api';
export default {
  name: "shoppingCart",
  data() {
    return {
      //商品列表 预载数据
      orderList: [],
      //全选
      selectAll: false,
      // 删除订单id数组
      delOrderIdList: []
    };
  },
  computed: {
    //产品种类
    goodsSortSum() {
      let sum = 0;
      for (const item of this.orderList) {
        for (const goods of item.productData) {
          if (goods.active) sum += 1;
        }
      }
      return sum;
    },
    //服务器url
    path() {
      return this.$store.state.serverPath;
    },
    //选择的商品数量总计
    choosedNum() {
      let num = 0;
      for (const item of this.orderList) {
        for (const goods of item.productData) {
          for (const att of goods.colourData) {
            for (const size of att.skuData) {
              if (size.active) num += +size.numbers;
            }
          }
        }
      }
      return num;
    },
    //金额总计
    payMoney() {
      let num = 0;
      for (const item of this.orderList) {
        for (const goods of item.productData) {
          for (const att of goods.colourData) {
            for (const size of att.skuData) {
              if (size.active) num += +size.numbers * +size.price;
            }
          }
        }
      }
      return num.toFixed(2);
    },
    //跳至结算界面需要发送的数据
    settleData() {
      let orderData = [];
      for (const item of this.orderList) {
        let obj = {
          productData: [],
          warehouseId: item.hashid
        };
        for (const goods of item.productData) {
            let goodObj = {
              productId: goods.hashid,
              colourArr: []
            }
          for (const att of goods.colourData) {
            let attObj = {
              colourId: att.hashid,
              sizeArr: []
            }
            for (const size of att.skuData) {
              if (size.active) {
                attObj.sizeArr.push({
                  sizeId: size.size.hashid,
                  number: size.numbers
                })
              }
            }
            if(attObj.sizeArr.length) {
              goodObj.colourArr.push(attObj);
            }
            
            // console.log(goodObj);
          }
          if(goodObj.colourArr.length) {
            obj.productData.push(goodObj);
            console.log(obj);
          } 
        }
        if(obj.productData.length) {
          orderData.push(obj);
        }
      }
      return orderData;
    },
  },
  methods: {
    // 监听失去焦点
    handleChangeNum(index, goodsIndex, attIndex, sizeIndex) {
      let carDataId = this.orderList[index].productData[goodsIndex].colourData[attIndex]
        .skuData[sizeIndex].cartDataId;
      let num = this.orderList[index].productData[goodsIndex].colourData[
        attIndex].skuData[sizeIndex].numbers;
      if(isNaN(num)) {
        this.$message.error('请输入正确的数量!');
        this.orderList[index].productData[goodsIndex].colourData[
        attIndex].skuData[sizeIndex].numbers = 1;
        num = 1;
        this.updateCartNum(carDataId, num);
      }else {
        this.updateCartNum(carDataId, num);
      }
    },
    // 增删商品
    changeNum(index, goodsIndex, attIndex, sizeIndex, type) {
      let num = this.orderList[index].productData[goodsIndex].colourData[attIndex]
      .skuData[sizeIndex].numbers;
      //减
      if (type == 0 && num > 1) {
        this.orderList[index].productData[goodsIndex].colourData[attIndex]
          .skuData[sizeIndex].numbers--;
          
      } else if (type == 1) {
        this.orderList[index].productData[goodsIndex].colourData[attIndex]
          .skuData[sizeIndex].numbers++;
      }
      let carDataId = this.orderList[index].productData[goodsIndex].colourData[attIndex]
          .skuData[sizeIndex].cartDataId;
      let numbers = this.orderList[index].productData[goodsIndex].colourData[attIndex]
          .skuData[sizeIndex].numbers;
      this.updateCartNum(carDataId, numbers);
    },
    //改变数据
    newData(list) {
      let arr = [];
      for (const item of list) {
        item.active = false;
        for (const goods of item.productData) {
          goods.active = false;
          for (const att of goods.colourData) {
            for (const size of att.colourData) {
              size.active = false;
            }
          }
        }
      }
      for (const iterator of list) {
        arr.push({
          ...iterator,
        });
      }
      return arr;
    },
    // 全选
    handleSelAll() {
      this.selectAll =!this.selectAll;
      this.delOrderIdList = [];
      this.orderList.forEach(item => {
        item.active = this.selectAll;
        item.productData.forEach(pro => {
          pro.active = this.selectAll;
          pro.colourData.forEach(col => {
            col.skuData.forEach(size => {
              size.active = this.selectAll;
              if(this.selectAll) {
                this.delOrderIdList.push(size.cartDataId);
              }else {
                this.delOrderIdList = [];
              }
            })
          })
        })
      })
      // console.log(this.delOrderIdList);
    },
    //选择仓库商品
    chooseHouse(index) {
      this.orderList[index].active = !this.orderList[index].active;
      let newActive = this.orderList[index].active;
      // 操作商品&尺码选择
        this.orderList[index].productData.forEach(item => {
          item.active = newActive;
          item.colourData.forEach(col => {
            col.skuData.forEach(size => {
              size.active = newActive;
              this.handelDelIdList(newActive, size.cartDataId);
            })
          });
          this.checkSelAll();
        });
        // console.log(this.delOrderIdList);
    },
    //选择商品
    chooseGoods(index, goodsIndex) {
      this.orderList[index].productData[goodsIndex].active = !this.orderList[index].productData[goodsIndex].active;
      let newActive = this.orderList[index].productData[goodsIndex].active;
      // 操作尺码选择
      this.orderList[index].productData[goodsIndex].colourData.forEach(item => {
          item.skuData.forEach(size => {
            size.active = newActive;
            // 需要删除的id数据
            this.handelDelIdList(newActive, size.cartDataId);
          })
      });
     // console.log(this.delOrderIdList);
      // 判断商品是否全选
      this.checkProIsAll(index);
      this.checkSelAll();
    },
    //选择尺码
    changeSize(index, goodsIndex, attIndex, sizeIndex) {
      //点击选择的是哪个尺码列表
      this.orderList[index].productData[goodsIndex].colourData[
        attIndex
      ].skuData[sizeIndex].active = !this.orderList[index].productData[goodsIndex].colourData[
        attIndex
      ].skuData[sizeIndex].active;

      //判断尺码是否全选
      let selSizeList = this.orderList[index].productData[goodsIndex].colourData[
        attIndex
      ].skuData.filter(item => {return item.active == true});
      console.log(selSizeList);
      if(selSizeList.length === this.orderList[index].productData[goodsIndex].colourData[attIndex].skuData.length) {
        this.orderList[index].productData[goodsIndex].colourData[attIndex].active = true;
      }else {
        this.orderList[index].productData[goodsIndex].colourData[attIndex].active = false;
      }
      let selColList =  this.orderList[index].productData[goodsIndex].colourData.filter(item => {return item.active});
      if(selColList.length === this.orderList[index].productData[goodsIndex].colourData.length) {
        this.orderList[index].productData[goodsIndex].active = true;
      }else {
        this.orderList[index].productData[goodsIndex].active = false;
      }

      // 需要删除的id数据
      let active = this.orderList[index].productData[goodsIndex].colourData[
        attIndex
      ].skuData[sizeIndex].active;
      let dataId = this.orderList[index].productData[goodsIndex].colourData[
        attIndex
      ].skuData[sizeIndex].cartDataId;
      this.handelDelIdList(active, dataId);
      // 判断商品是否全选
      this.checkProIsAll(index);
      this.checkSelAll();
    },
    //删除购物车商品
    deletHouse() {
      if(this.delOrderIdList.length === 0) {
        return this.$message.error('请选择需要删除的商品');
      }else {
        this.delCartOrder(this.delOrderIdList);
      }
      
    },
    //全部删除
    deletAll() {
      this.orderList.forEach(item => {
       item.productData.forEach(pro => {
          pro.colourData.forEach(col => {
            col.skuData.forEach(size => {
              this.delOrderIdList.push(size.cartDataId);
            })
          })
        })
      })
      let arr = Array.from(new Set(this.delOrderIdList));
      this.$confirm('是否删除全部商品','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delCartOrder(arr);
      }).catch(() => {
        this.delOrderIdList = [];
      })
    },
    //跳至结算
    toSettle() {
      // console.log(this.orderList);
      console.log(this.settleData);
      if (this.settleData.length == 0) {
        this.$message.warning("请选择商品");
      } else {
        console.log(this.settleData);
        this.$store.commit("saveSettleData", this.settleData);
        this.$store.commit("saveSettleType", 2);
        this.$router.push("/settle");
      }
    },
    //------------获取数据&api--------------
    // 获取购物车列表
    getCartList() {
      getCartList().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.getCartNum();
          let resList = JSON.parse(JSON.stringify(data.data));
          resList.forEach(item => {
            item.active = false;
            item.productData.forEach(product => {
              product.active = false;
              product.colourData.forEach(col => {
                col.active = false;
                col.skuData.forEach(sku => {
                  sku.active = false;
                })
              })
            })
          });
          this.orderList = resList;
          // this.goodsSortSum = data.productTypeNum;
          // this.choosedNum = data.productNum;
          // this.payMoney = data.totalAmount;
        }
      })
    },
    // 修改购物车数量
    updateCartNum(cartDataId, number) {
      updateCartNum({cartDataId: cartDataId, number: number}).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          return;
          // this.$message.success(msg);
        }else {
          this.$message.error(msg);
        }
      })
    },
    // 删除购物车订单
    delCartOrder(orderIdList) {
      delCartOrder({cartDataIdArr: orderIdList}).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.delOrderIdList = [];
          this.getCartList();
        }else {
          this.$message.error(msg);
        }
      })
    },
    // 获取购物车数量
    getCartNum() {
      getCartNum().then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$store.commit('saveCartNumber', res.orderCartNum);
          }
      });
    },
    // 判断商品是否全选
    checkProIsAll(index) {
      let selProList = this.orderList[index].productData.filter(item => {return item.active});
      if(selProList.length === this.orderList[index].productData.length) {
        this.orderList[index].active = true;
      }else {
        this.orderList[index].active = false;
      }
    },
    // 判断是否全选
    checkSelAll() {
      let selList = this.orderList.filter(item => {return item.active});
      if(selList.length === this.orderList.length) {
        this.selectAll = true;
      }else {
        this.selectAll = false;
      }
    },
    // 删除操作 &购物车数据id数组
    handelDelIdList(flag, delId) {
      if(flag) {
        if(this.delOrderIdList.indexOf(delId) > -1) {
          return;
        }else {
          this.delOrderIdList.push(delId);
        }
      }else {
        if(this.delOrderIdList.indexOf(delId) > -1) {
          this.delOrderIdList.splice(this.delOrderIdList.indexOf(delId), 1);
        }else {
          return;
        }
      }
    }
  },
  watch: {
    //监听点击全选按钮
    // selectAll: function (newQuestion) {
    //   for (const item of this.orderList) {
    //     item.active = newQuestion;
    //     for (const goods of item.productData) {
    //       goods.active = newQuestion;
    //       for (const att of goods.colourData) {
    //         for (const size of att.skuData) {
    //           size.active = newQuestion;
    //         }
    //       }
    //     }
    //   }
    // },
  },
  created() {
    this.getCartList();
  },
};
</script>

<style>
/* 购物车 */
.shopping-cart {
  background: #fff;
  padding-top: 8px;
  padding-bottom: 80px;
}
.shopping-cart .c-box > p {
  font-size: 16px;
  margin: 16px 0;
}
.shopping-cart .header {
  height: 42px;
  line-height: 40px;
  border: 1px solid #e9e9e9;
  background: #f3f3f3;
  padding-left: 16px;
  padding-right: 24px;
  margin-bottom: 16px;
}
.shopping-cart .header span:nth-child(2) {
  flex: 3;
}
.shopping-cart .body > .flex:first-child {
  padding: 0 24px;
  margin-bottom: 10px;
}
.shopping-cart .x_lujing52 {
  opacity: 0.25;
}
.sc-table .body {
  border: 1px solid #f1f1f1;
  padding: 16px 0;
  border-radius: 2px;
}
.shopping-cart .main {
  padding: 16px 24px;
}
.shopping-cart .main .img-box {
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
}
.shopping-cart .sc-color {
  /* margin-right: 190px; */
  width:26%;
}
.shopping-cart input[type="checkbox"] {
  display: none;
}
.shopping-cart .w_line-check_red {
  width: 14px;
  height: 14px;
  line-height: 14px;
  margin-right: 8px;
  overflow: hidden;
}
.shopping-cart .x_completed {
  border: none;
}
.shopping-cart .x_completed i {
  transform: translateY(0);
}
.sc-size-box {
  width: 264px;
}
.sc-size {
  margin-bottom: 32px;
}
.sc-size-box input {
  margin-right: 8px;
}
.sc-num-box .flex {
  margin-bottom: 22px;
}
.sc-num-box input {
  width: 58px;
  border-right: none;
  border-left: none;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  text-align: center;
  padding: 0 6px;
}
.sc-sum-box {
  flex: 1;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
}
.sc-unit-price p,
.sc-sum-box p {
  margin-bottom: 28px;
}
.sc-unit-price {
  display: inline-block;
  text-align: right;
  width: 180px;
}

.shopping-cart .info-box {
  flex: 1;
}
.shopping-cart .info {
  display: flex;
}

.no-order {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-size: 24px;
}

.sc-footer {
  height: 56px;
  background: #eae9e7;
  padding-left: 16px;
  overflow: hidden;
}
.sc-footer .right {
  height: 100%;
}
.sc-footer .fs-16 {
  transform: translateY(-2px);
  margin-right: 4px;
}
.sc-footer .c-red {
  font-weight: bold;
  font-size: 22px;
  margin-right: 4px;
  transform: translateY(-4px);
}
.sc-footer .bg-r-btn {
  width: 150px;
  height: 56px;
  padding: 0;
  line-height: 56px;
  text-align: center;
  margin-left: 24px;
}

/*Chrome 下去除type ==  "number"  时 的右侧图标*/
#n1::-webkit-outer-spin-button,
#n1::-webkit-inner-spin-button{
  -webkit-appearance: none !important;
  margin: 0;
}

/*火狐 下去除type ==  "number"  时 的右侧图标*/
input[type="number"]{-moz-appearance:textfield;}

</style>