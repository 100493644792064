<template>
  <div class="goods-box" @click="toItem(data.hashid)">
    <i v-show="data.colourNul > 0">{{ data.colourNul }}色</i>
    <div class="img-box" :class="{ haveI: data.image }">
      <img
        :src="data.image +'?imageMogr2/thumbnail/160x'"
        :alt="data.full_name"
        v-if="data.image"
      />
    </div>
    <p class="goods-title mb-8">{{ data.full_name }}</p>
    <p class="price c-red">￥{{ data.price }}</p>
  </div>
</template>

<script>
export default {
  name: "goodsBox",
  props: {
    data: Object,
  },
  computed: {
    //服务器地址
    serverPath() {
      // return this.$store.state.serverPath;
      return '';
    },
  },
  methods: {
    //跳转至商品详情页
    toItem(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push('/house/' + id);
    },
  },
};
</script>

<style>
/* goods-box */
.goods-box {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 240px;
  background: #ffffff;
  padding: 18px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.goods-box i {
  position: absolute;
  top: 4px;
  left: 4px;
  width: max-content;
  height: 18px;
  line-height: 16px;
  font-size: 12px;
  color: #fa5151;
  text-align: center;
  border-radius: 6px;
  padding: 0 4px;
  border: 1px solid #fa5151;
  background: #fff;
  z-index: 998;
}
.goods-box .img-box {
  width: 160px;
  height: 160px;
  border: 1px solid #707070;
  margin: 0 auto;
  margin-bottom: 8px;
}
.goods-box .img-box.haveI {
  border: none;
}
.goods-box:hover .img-box {
  transform: translateY(-6px);
}
.goods-box .goods-title {
  max-width: 176px;
  height: 38px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.goods-box .price {
  font-size: 18px;
  transform: translateX(-4px);
}
.t-left {
  text-align: left;
  color: #0000FF;
  font-size: 14px;
}
</style>