<template>
  <div class="main">
    <div class="login-nav-box" :class="{ isWechat: isWechat }">
      <span
        class="login-nav"
        :class="{ active: !isWechat }"
        @click="changeNav(0)"
      >
        短信登录
      </span>
      <span
        class="login-nav"
        :class="{ active: isWechat }"
        @click="changeNav(1)"
      >
        微信登录
      </span>
    </div>
    <div class="form" v-show="!isWechat">
      <!-- <div class="input-box">
        <i class="iconfont mr-8">&#xe66d;</i>
        <input
          type="tel"
          v-model="tel"
          placeholder="请输入手机号"
          autocomplete
        />
      </div> -->
      <slot name="tel"></slot>
      <!-- <div class="input-box">
        <i class="iconfont mr-8">&#xe675;</i>
        <input type="password" v-model="password" placeholder="请输入密码" />
      </div> -->
      <slot name="code"></slot>
      <!-- <div class="code-box">
        <div class="input-box">
          <i class="iconfont mr-8">&#xe661;</i>
          <input type="text" v-model="code" placeholder="请输入验证码" />
        </div>
        <div class="code" @click="getCode">
          <img :src="codeImg" alt="验证码" v-if="codeImg" />
        </div>
      </div> -->
      <div class="fs-14 flex mt-20" style="justify-content: space-between;">
        <div>
          <el-checkbox v-model="agreeCheck"><span class="gray9">阅读并同意</span></el-checkbox><span class="col_f52 pointer">《用户协议》</span>
        </div>
        <div>
          <el-checkbox v-model="freeCheck"><span class="gray9">7天内自动登录</span></el-checkbox>
        </div>
      </div>
      
      <button @click="login" class="fs-14 mt-32">登录</button>
      <div class="link-box">
        <!-- <router-link to="/login/forgetpwd" class="link">忘记密码</router-link> -->
        <div></div>
        <router-link to="/login/reg" class="link">立即注册</router-link>
      </div>
    </div>
    <div class="wx-login" v-show="isWechat">
      <div class="QRcode-box" id="QRcode">
        <img src="" alt="QRcode" />
      </div>
      <div class="black">微信授权登录</div>
      <div class="fs-14 gray9 mt-24">请使用微信扫码授权登录/注册，注册代表同意 <span class="col_f52 pointer">《用户协议》</span></div>
    </div>
    <div class="fs-14 bottomText" style="color: #BBBBBB;">Copyright 2022 广州苏铁信息技术有限公司 粤ICP备16017870号</div>
  </div>
</template>

<script type="text/javascript" src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { auth } from '@/http/api'
export default {
  name: "loginDefault",
  props: {
    //手机号
    tel: String,
    //验证码
    code: String,
  },
  data() {
    return {
      //是否显示微信登录
      isWechat: false,
      agreeCheck: false,
      freeCheck: false,
    };
  },
  created() {
    this.$emit('initData');
  },
  mounted() {
    this.codeLogin();
  },
  
  methods: {
    //点击切换登录类型
    changeNav(e) {
      if (e == 0) {
        this.isWechat = false;
      } else {
        this.isWechat = true;
      }
    },
    
    //登录
    async login() {
      //检查用户是否完整的输入信息
      if(!this.agreeCheck) {
        this.$message.warning('请勾选用户协议');
      }
      else if (this.testTel(this.tel) == false) {
      } else if (this.password == "") {
        this.$message.warning('请输入密码');
      } else if (this.code == "") {
        this.$message.warning('请输入验证码');
      } else {
        let params = {
            phone: this.tel, 
            code: this.code, 
            type: 2
        }
        let res = await auth(params);
         const { statusCode, data, msg } = res;
          if(statusCode === 200) {
            this.$message.success(msg);
            this.$store.commit('saveToken', data.token);
            
            // this.$store.commit('saveWxInfo', this.info);
            // this.$router.replace('/');
            this.$router.go(-1);
            this.$store.commit("changeLogin", true);
            this.$store.commit("saveTel", this.tel);
          }else {
            this.$message.error(msg);
          }
        
      }
    },
    // 二维码登录
    codeLogin() {
      var obj = new WxLogin
        ({
          id:"QRcode",//div的id
          appid: "wx9fedf2701e4dfd11",
          scope: "snsapi_login",//写死
          redirect_uri:encodeURI("https://yinbaole.com/loginSuccess"), // 跳转位置
          state: "login",
          style: "black",//二维码黑白风格
          href: "https://b.xuerenguan.com/book_yimeima_logo_style/scan_login_style.css" // 二维码样式(需要https) 
        });
    }
  },
  
};
</script>

<style>
.login .main {
  position: relative;
}
.login .main,
.wx-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .main,
.wx-login,
.login .form {
  width: 400px;
  margin: auto;
}
.login-nav-box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid;
  border-bottom-color: #fff;
}
.login-nav-box,
.login .input-box,
.login button,
.QRcode-box {
  margin-bottom: 20px;
}
.login-nav-box::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0%;
  display: inline-block;
  width: 50%;
  height: 2px;
  background: #f5222d;
  transition: all 0.3s;
}
.login-nav-box.isWechat::after {
  left: 50%;
}
.login-nav {
  flex: 1;
  padding: 20px 0;
  cursor: pointer;
}
.login-nav.active {
  color: #f5222d;
}
.login .input-box {
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0 8px;
}
.login .input-box,
.login button {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #CCCCCC;
}
.login input,
.login button {
  border: none;
}
.login input {
  flex: 1;
}
.code-box,
.link-box {
  display: flex;
  justify-content: space-between;
}
.code-box .input-box {
  flex: 1;
   border: 0;
   border-bottom: 1px solid #CCCCCC;
   border-radius: 0;
   margin-right: 8px;
}
.code-box input {
  width: 100%;
}
.code,
.get-code {
  width: 102px;
  height: 37px;
  background: #eee;
}
.code img {
  width: 100%;
  height: 100%;
}
.login button {
  width: 100%;
  height: 48px;
  line-height: 48px;
  color: #fff;
  background: #F5222D;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}
.login button:hover {
  background: #fd1c23;
}
.link-box {
  color: #f5222d;
}
.link-box,
.wx-login {
  font-size: 14px;
}
.QRcode-box {
  width: 322.5px;
  /* height: 302.5px; */
  /* background: #ddd; */
  /* padding-top: 25px; */
  overflow: hidden;
}
.QRcode-box img {
  width: 100%;
}
.QRcode-box iframe {
  width: 100%;
  height: 230px;
}

.wx-login {
  line-height: 24px;
  color: #666;
}
.bottomText {
  width: 410px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -48px;
}
/* input[type=checkbox] {
  display: inline-block;  
  vertical-align: middle;  
  width: 20px;  
  height: 20px;
  -webkit-appearance: none;
  border: 1px solid #CCCCCC;
} */
</style>