<template>
  <div class="news">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "news",
  data() {
    return {
      
    }
  },
  
  
};
</script>

<style scoped>

</style>