<template>
  <div class="setting">
    <c-nav :nav="navList"></c-nav>
    <p class="card p-20 mb-20">账号设置</p>
    <div class="card flex">
      <div class="left">
        <a
          href="javascript:;"
          v-for="(value, index) in settingNav"
          :class="{ active: value.active }"
          @click="changeNav(index)"
          :key="index"
        >
          {{ value.name }}
        </a>
      </div>
      <div class="set-right" v-show="isEdit">
        <p class="mb-24">{{ title }}</p>
        <div class="flex" v-show="settingNav[0].active">
          <div class="r-left">
            <p class="mb-12">昵称</p>
            <input
              type="text"
              v-model="userInfo.name"
              placeholder="请输入昵称"
              class="mb-24"
            />
            <p class="mb-12">认证公司</p>
            <p class="mb-24" v-show="is_authentication == 0">
              <span>未认证</span><a href="javascript:;">立即认证</a>
            </p>
            <p class="mb-24" v-show="is_authentication == 1">
              <span>已认证</span><a href="javascript:;">修改</a>
            </p>
            <p class="mb-12">邮箱</p>
            <input
              type="email"
              v-model="userInfo.mailbox"
              placeholder="请输入邮箱"
              class="mb-24"
            />
            <p class="mb-12">QQ</p>
            <input
              type="text"
              v-model="userInfo.qq"
              placeholder="请输入QQ"
              class="mb-24"
            />
            <br />
            <a href="javascript:;" class="bg-r-btn" @click="uploadInfo">
              确认更新信息
            </a>
          </div>
          <div class="r-right">
            <p class="mb-12">头像</p>
            <div class="img-box">
              <img :src="userInfo.headimgurl" alt="avatar" v-if="userInfo.headimgurl" />
            </div>
            <div class="t-center">
              <el-upload action="https://yinbaole.com/admin/uploadQiniu"
              :on-success="handleSuccess"
              >
                <el-button type="upload"><i class="el-icon-upload2"></i> 点击上传</el-button>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="safe-right" v-show="settingNav[1].active">
          <!-- <div class="flex f-a-c bb1" v-show="is_login_pass == 0">
            <div class="safe-left">
              <p>账号密码</p>
              <p>未设置</p>
            </div>
            <a href="javascript:;" class="right" @click="changePAWShow">
              去设置
            </a>
          </div> -->
          <!-- <div class="flex f-a-c bb1" v-show="is_login_pass == 1">
            <div class="safe-left">
              <p>账号密码</p>
              <p>已设置</p>
            </div>
            <a href="javascript:;" class="right" @click="changePAWShow">
              修改
            </a>
          </div> -->
          <!-- <div class="flex f-a-c bb1" v-show="is_pay_pass == 0">
            <div class="safe-left">
              <p>支付密码</p>
              <p>未设置</p>
            </div>
            <a
              href="javascript:;"
              class="right"
              @click="changePayPAWShow(true)"
            >
              去设置
            </a>
          </div> -->
          <!-- <div class="flex f-a-c bb1" v-show="is_pay_pass == 1">
            <div class="safe-left">
              <p>支付密码</p>
              <p>已设置</p>
            </div>
            <a
              href="javascript:;"
              class="right"
              @click="changePayPAWShow(true)"
            >
              修改
            </a>
          </div> -->
          <div class="flex f-a-c bb1">
            <div class="safe-left">
              <p>绑定手机</p>
              <p>已绑定手机：{{ userInfo.phone }}</p>
            </div>
            <a href="javascript:;" class="right" @click="changePhoneShow(true)">
              修改
            </a>
          </div>
          <div class="flex f-a-c bb1" v-show="userInfo.unionid == null">
            <div class="safe-left">
              <p>绑定微信</p>
              <p>未设置</p>
            </div>
            <a href="javascript:;" class="right" @click="bindWechat">去绑定</a>
          </div>
          <div class="flex f-a-c bb1" v-show="userInfo.unionid">
            <div class="safe-left">
              <p>绑定微信</p>
              <p>已设置</p>
            </div>
            <a href="javascript:;" class="right" @click="handleRelieve">解绑</a>
          </div>
        </div>
      </div>
      <!-- <div class="settingBox col_85" v-show="passwordShow">
        <div class="fs-20">修改密码</div>
        <div class="mt-24">
          <div class="fs-14">旧密码</div>
          <div class="passInput mt-12">
            <input v-model="password" :type="pass ? 'password' : 'text'" placeholder="请输入旧密码">
            <i class="iconfont m_eye" v-show="pass" @click="changeInput(1)"></i>
            <i class="iconfont m_eye-close" v-show="!pass" @click="changeInput(1)"></i>
          </div>
        </div>
        <div class="mt-24">
          <div class="fs-14">新密码</div>
          <div class="passInput mt-12">
            <input v-model="newPassword" :type="newPass ? 'password' : 'text'" placeholder="请输入新密码">
            <i class="iconfont m_eye" v-show="newPass" @click="changeInput(2)"></i>
            <i class="iconfont m_eye-close" v-show="!newPass" @click="changeInput(2)"></i>
          </div>
          
        </div>
        <div class="flex mt-24 fs-14">
          <div class="saveBtn bg_red white" @click="savePassword">确认修改</div>
          <div class="backBtn col_65 ml-8" @click="goBack">返回</div>
        </div>
      </div> -->
      <div class="settingBox col_85" v-show="phoneShow">
        <div class="fs-20">修改绑定手机</div>
        <div class="mt-24 phoneBox">
          <div class="fs-14">新手机号</div>
          <input class="mt-12" v-model="newPhone" type="text" placeholder="请输入新手机号">
        </div>
        <div class="mt-24 phoneBox">
          <div class="fs-14">验证码</div>
          <div class="flex" style="align-items: center;">
            <input class="mt-12" v-model="code" type="text" placeholder="请输入验证码" style="width: 200px;">
            <div class=" ml-16 fs-14 mt-12" v-if="hasGetCode">{{time}}秒后重新发送</div>
            <div class="sendCodeBtn ml-16 fs-14 mt-12" @click="sendPhonecode" v-else>发送验证码</div>
          </div>
            
        </div>
        <div class="flex mt-24 fs-14">
          <div class="saveBtn bg_red white" @click="handleUpdate">确认修改</div>
          <div class="backBtn col_65 ml-8" @click="goBack">返回</div>
        </div>
      </div>
    </div>
    <div class="codeOutBox" v-show="hasQRcode">
      <div id="bindQRcode"></div>
      <div class="mt-8 t-center">
        <img src="../../images/wx.png" alt="">
        <span class="gray6 fs-14 ml-8">微信扫一扫授权</span>
      </div>
      <div class="closeBtn mt-16 t-center pointer" @click="handleCloseCode">关闭</div>
      <!-- <img src="" alt=""> -->
    </div>
    <div class="relieveBox" v-show="isRelieve">
      <span class="reClose pointer" @click="closeRelieveBox">X</span>
      <div class="col_85 fs-16">确定解除绑定微信吗？</div>
      <div class="flex mt-24 fs-14 relieveBtn" style="justify-content: space-between;">
        <span class="bg_red white" @click="submitRelieve">确定</span>
        <span class="col_65 ml-16" @click="closeRelieveBox">取消</span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { getUserInfo, editInfo, upload, editPassword, sendCode, editPhone, unbound } from '@/http/api';
export default {
  name: "setting",
  data() {
    return {
      navList: [
        {
          path: `/user/setting`,
          text: "账号设置",
        },
      ],
      settingNav: [
        {
          name: "基本设置",
          active: true,
        },
        {
          name: "安全设置",
          active: false,
        },
      ],
      title: "基本设置",
      
      //是否已认证公司(0否,1是)
      is_authentication: 0,
      
      //设置密码弹窗显示状态
      passwordShow: false,
      //修改手机号弹窗显示状态
      phoneShow: false,

      // 用户信息
      userInfo: {},
      isEdit: true,
      pass: true,
      newPass: true,
      //--------修改密码------
      //原密码
      password: "",
      //新密码
      newPassword: "",
      // --------修改绑定手机号------
      // 验证码
      code: '',
      // 新手机号
      newPhone: '',
      //获取过短信验证码了吗
      hasGetCode: false,
      //获取过就倒计时
      time: 60,
      hasQRcode: false,
      isRelieve: false
    };
  },
  computed: {
    path() {
      return this.$store.state.serverPath;
    },
  },
  methods: {
    //----------------获取数据------------
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          console.log(res);
          this.userInfo = data.user;
        }
      })
    },
    // 修改基本信息
    editInfo() {
      let params = {
        name: this.userInfo.name,
        headimgurl: this.userInfo.headimgurl,
        mailbox: this.userInfo.mailbox,
        qq: this.userInfo.qq
      }
      editInfo(params).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.getUserInfo();
        }else {
          this.$message.error(msg);
        }
      })
    },
    // 上传头像
    upload(file) {
      upload({dir: file}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.userInfo.headimgurl = res.src;
        }
      })
    },
    // 修改密码
    editPassword() {
      editPassword({
        oldPassword: this.password,
        newPassword: this.newPassword
        }).then(res => {
          const { statusCode } = res;
          if(statusCode === 200) {
            this.$message.success('修改成功');
          }else {
            this.$message.error('修改失败');
          }
      })
    },
    // 修改手机号
    editPhone() {
      editPhone({phone: this.newPhone, code: this.code}).thn(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('修改成功');
        }else {
          this.$message.error('修改失败');
        }
      })
    },
    //-----------------------------------
    //初始化输入数据
    start() {
      this.password = "";
      this.newPassword = "";
      this.payPassword = "";
      this.agaPayPAW = "";
      this.payCode = "";
      this.payPhoneCode = "";
      this.newPhone = '';
      this.code = '';
    },
    //点击切换设置
    changeNav(i) {
      this.phoneShow = false;
      this.isEdit = true;
      for (let n = 0; n < this.settingNav.length; n++) {
        this.settingNav[n].active = false;
      }
      this.settingNav[i].active = true;
      this.title = this.settingNav[i].name;
    },
    // 上传头像成功
    handleSuccess(file) {
      console.log(file);
      this.userInfo.headimgurl = file.data.src;
    },
    //更新信息
    uploadInfo() {
      //检查输入的邮箱格式是否正确
      let test = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (this.userInfo.name == "") {
        //是否输入昵称
        this.$message.warning("请输入昵称");
      } else if (this.userInfo.headimgurl == "") {
        //是否上传头像
        this.$message.warning("请上传头像");
      } else if (!this.userInfo.mailbox) {
        //是否输入邮箱
        this.$message.warning("请输入邮箱");
      } else if (!test.test(this.userInfo.mailbox)) {
        //输入邮箱格式是否正确
        this.$message.warning("邮箱格式错误");
      } else {
        this.editInfo();
      }
    },
    // 切换修改密码框状态
    changeInput(index) {
      if(index === 1) {
        this.pass = !this.pass;
      }else {
        this.newPass = !this.newPass;
      }
    },
    //显示修改密码弹框
    changePAWShow() {
      this.passwordShow = true;
      this.isEdit = false;
    },
    // 返回
    goBack() {
      this.phoneShow = false;
      this.passwordShow = false;
      this.isEdit = true;
      this.start();
    },
    // 确认修改密码
    savePassword() {
      if(this.password === '') {
        return this.$message.warning('请输入旧密码');
      }else if(this.newPassword === '') {
        return this.$message.warning('请输入新密码');
      }else {
        this.editPassword();
      }
    },
    // 获取手机验证码
    sendPhonecode() {
      this.hasGetCode = true;
      if(this.testTel(this.newPhone)) {
        sendCode({phone: this.newPhone}).then(res => {
          const { statusCode, msg } = res;
          if(statusCode === 200) {
            this.$message.success(msg);
            
          }else {
            this.$message.error(msg);
          }
        })
      }
    },
    // 确认修改
    handleUpdate() {
      if(this.newPhone === '') {
        return this.$message.warning('请输入新手机号');
      }else if(this.code === '') {
        return this.$message.warning('请输入验证码');
      }else {
        this.editPhone();
        this.getUserInfo();
        this.phoneShow = false;
        this.isEdit = true;
      }
    },
    //修改绑定手机号
    changePhoneShow() {
      this.phoneShow = true;
      this.isEdit = false;
      this.start();
    },
    // 绑定微信
    bindWechat() {
      this.$store.commit('changeMask');
      this.hasQRcode = true;
      var obj = new WxLogin
        ({
          id:"bindQRcode",//div的id
          appid: "wx9fedf2701e4dfd11",
          scope: "snsapi_login",//写死
          redirect_uri:encodeURI("https://yinbaole.com/wechat"), // 跳转位置
          state: "login",
          style: "black",//二维码黑白风格
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7CndpZHRoOiAyMjBweCFpbXBvcnRhbnQ7CmJvcmRlcjogbm9uZSFpbXBvcnRhbnQ7Cm1hcmdpbi10b3A6MCFpbXBvcnRhbnQ7Cn0KLmltcG93ZXJCb3ggLnRpdGxlewpkaXNwbGF5Om5vbmUhaW1wb3J0YW50Owp9" // 二维码样式(需要https) 
        });
    },
    handleCloseCode() {
      this.hasQRcode = false;
      this.$store.commit('changeMask');
    },
    // 显示解绑微信弹框
    handleRelieve() {
      this.$store.commit('changeMask');
      this.isRelieve = true;
    },
    closeRelieveBox() {
      this.$store.commit('changeMask');
      this.isRelieve = false;
    },
    // 解绑微信
    submitRelieve() {
      unbound().then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.$store.commit('changeMask');
          this.isRelieve = false;
          this.getUserInfo();
        }else {
          this.$message.error(msg);
        }
      })
    }
  },
  created() {
    //获取数据
    this.getUserInfo();
  },
  watch: {
    //监听是否发送过验证码
    hasGetCode: function (newValue) {
      //如果已发送则开启倒计时
      if (newValue) {
        this.timer = setInterval(() => {
          this.time--;
          //倒计时结束才能再次获取验证码
          if (this.time == 0) {
            clearInterval(this.timer);
            this.hasGetCode = false;
            this.time = 60;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style>
.new-alert .get-code,
.new-alert .has-get-code,
.new-alert .code {
  width: 94px;
  text-align: center;
}
.new-alert .code-box input {
  width: 244px;
  margin-right: 8px;
}
.el-upload-list {
  display: none;
}

.settingBox {
  padding-left: 40px;
}
.passInput {
  position: relative;
}
.passInput input{
  width: 310px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  padding: 0 30px 0 12px;
  box-sizing: border-box;
}
.passInput .iconfont {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #BBBBBB;
}
.saveBtn,
.backBtn {
  padding: 6px 16px;
  cursor: pointer;
  border-radius: 2px;
}
.backBtn {
  border: 1px solid #D9D9D9;
}
.sendCodeBtn {
  padding: 6px 12px;
  background: #000;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
}
.phoneBox input {
  width: 310px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  padding: 0 30px 0 12px;
  box-sizing: border-box;
}
.codeOutBox {
    z-index: 9999;
  position: absolute;
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
}
#bindQRcode {
  width: 220px;
  height: 225px;
}
.codeOutBox .closeBtn {
  padding: 6px 80px;
  border-radius: 18px;
  border: 1px solid #DDDDDD;
}
#bindQRcode iframe {
  width: 100%;
  height: 100%;
}
#bindQRcode iframe .wrp_code {
  width: 100%;
}
.relieveBox {
  z-index: 9999;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translateX(-50%);
  padding: 32px 40px 24px;
  background: #fff;
}
.relieveBtn span {
  width: 65px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}
.relieveBtn span:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.reClose {
  position: absolute;
  right: 11px;
  top: 11px;
}
</style>