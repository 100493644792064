<template>
  <div class="alipay flex">
    <div class="pay-left">
      <div class="pay-QRcode-box">
        <img :src="code_url" alt="pay-QRcode" v-if="code_url"/>
      </div>
      <div class="x_scanning flex f-a-c">
        <i></i>
        <span>请使用支付宝扫一扫扫描二维码支付</span>
      </div>
    </div>
    <div class="pay-right">
      <img src="../../images/zfbScan.png" alt="" />
    </div>
  </div>
</template>

<script>
import { zfbPay, zfbRechargePay } from '@/http/api';
import { mapState } from 'vuex';
export default {
  name: "alipay",
  data() {
    return {
      code_url: '',
      errorMsg: ''
    };
  },
  created() {
    if(this.payData.isRecharge) {
      this.zfbRechargePay();
    }else {
      this.zfbPay();
    }
  },
  computed: {
    ...mapState(["payData"])
  },
  methods: {
    //----------------获取数据 &api----------------
    // 调起支付宝支付
    zfbPay() {
      zfbPay({ orderId: this.payData.orderId }).then(res => {
        const { statusCode, code_url, msg } = res;
        if(statusCode === 200) {
          this.code_url = code_url;
        }else {
          this.errorMsg= msg
        }
      })
    },
    // 调起充值支付宝支付
    zfbRechargePay() {
      zfbRechargePay({orderId:this.payData.orderId}).then(res => {
        const { statusCode, code_url, msg } = res;
        if(statusCode === 200) {
          if(statusCode === 200) {
          this.code_url = code_url;
          }else {
            this.errorMsg = msg
          }
        }
      })
    }
  },
  mounted() {},
  destroyed() {},
};
</script>

<style>
</style>