<template>
  <div id="app" v-cloak>
    <sideNav />
    <myHeader v-show="showHeader" />
    <router-view  class="minHeight " :class="{'pt-194': showHeader && showSearch}"/>
    <myFooter v-show="showFooter" />
    <transition name="mask">
      <div id="mask" v-show="showMask">
        
      </div>
    </transition>
  </div>
</template>

<script>
import myHeader from "./components/myHeader";
import myFooter from "./components/myFooter";
import sideNav from "./components/sideNav";
import { mapState } from 'vuex'
import { getCartNum, getUserInfo } from '@/http/api'
export default {
  name: "app",
  components: {
    myHeader,
    myFooter,
    sideNav
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["showHeader","showSearch"]),
    //header显示
    showHeader() {
      return this.$store.state.showHeader;
    },
    //footer显示
    showFooter() {
      return this.$store.state.showFooter;
    },
    //遮罩显示
    showMask() {
      return this.$store.state.showMask;
    },
  },
  methods: {},
  created() {
    let token = window.localStorage.getItem('token');
    if (token) {
      this.$store.commit("changeLogin", true);
      getUserInfo().then(res => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          this.$store.commit("savesurplusMoney", data.user.surplus_money);
          this.$store.commit("saveTel", data.user.phone);
        }
      })
    }
    getCartNum().then(res => {
      const { statusCode } = res;
      if(statusCode === 200) {
        this.$store.commit('saveCartNumber', res.orderCartNum);
        this.$store.commit('saveCollectNumber', res.collectionNum);
        this.$store.commit('saveCoupNumber', res.couponNum);
      }
    }); 
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  box-sizing: border-box;
}
.minHeight {
  min-height: 500px;
}
.pt-194 {
  padding-top: 194px!important;
}
body {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  background: #f5f5f5;
  color: #333;
  overflow-x: hidden;
  position: relative;
}
a {
  color: inherit;
  text-decoration: none;
}
img {
  vertical-align: text-top;
}
.ver_top {
  vertical-align: top;
}
.ver_mid {
  vertical-align: middle;
}
.t-r {
  text-align: right;
}
.t-c {
  text-align: center;
}
*::placeholder {
  color: #bfbfbf;
}
.flex {
  display: flex;
}
.f-a-c {
  align-items: center;
}
.f-j-c {
  justify-content: center;
}
.f-j-s {
  justify-content: space-between;
}
.flex .right {
  margin-left: auto;
}
.card {
  background: #fff;
}
#mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.mask-enter-active,
.mask-leave-active {
  transition: all 0.3s ease;
}
.mask-enter,
.mask-leave-to {
  opacity: 0;
}
/* 居中盒子 */
.c-box {
  width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}
@media (max-width: 1180px) {
  #app {
    width: 1180px;
    margin: 0 auto;
  }
}

@font-face {
  font-family: "iconfont"; /* project id 2041244 */
  src: url("//at.alicdn.com/t/font_2041244_vs8jxwnffb.eot");
  src: url("//at.alicdn.com/t/font_2041244_vs8jxwnffb.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_2041244_vs8jxwnffb.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_2041244_vs8jxwnffb.woff") format("woff"),
    url("//at.alicdn.com/t/font_2041244_vs8jxwnffb.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_2041244_vs8jxwnffb.svg#iconfont") format("svg");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

/* 红色字体 */
.c-red {
  color: #f5222d !important;
}
.c-3 {
  color: #333 !important;
}
.c-9 {
  color: #999 !important;
}
.c-6 {
  color: #666 !important;
}
.fw-bold {
  font-weight: bold !important;
  
}

/* 白底按钮 */
.bg-w-btn,
.bg-r-btn {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s;
  border-radius: 2px;
}
.bg-w-btn {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.65);
}
.bg-w-btn:hover {
  opacity: 1;
}

/* 红底按钮 */
.bg-r-btn {
  background: #ed1c24;
  border: 1px solid #ed1c24;
  color: #fff;
}
.bg-r-btn:hover {
  background: #fd1c23;
  border: 1px solid #fd1c23;
}

/* 不换行 */
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* 图片上传 */
.file {
  width: 107px;
  height: 32px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.14901960784313725);
  opacity: 1;
  border-radius: 2px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.file i {
  margin-right: 4px;
  transform: translateY(0px);
}
.file input {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5222d;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  opacity: 0;
}

/* 边框 */
.bb1 {
  border-bottom: 1px solid #eee;
}
.bb1-d {
  border-bottom: 1px dashed #eee;
}

/*margin*/
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-7 {
  margin-top: 7px;
}
.mt-8,
.my-8 {
  margin-top: 8px;
}
.mb-8,
.my-8 {
  margin-bottom: 8px;
}
.mt-9 {
  margin-top: 9px;
}
.mt-10,
.my-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-80 {
  margin-top: 80px;
}
.mb-2 {
  margin-bottom: 2px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-19 {
  margin-right: 19px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-4 {
  margin-right: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-68 {
  margin-left: 68px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-16,
.mx-16 {
  margin-left: 16px;
}
.mr-16,
.mx-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-46 {
  margin-right: 46px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-10,
.my-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-44 {
  margin-bottom: 44px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-80 {
  margin-bottom: 80px;
}
/*padding*/
.pt-4 {
  padding-top: 4px;
}
.pt-14 {
  padding-top: 14px;
}
.pt-24 {
  padding-top: 24px;
}
.pr-0 {
  padding-right: 0 !important;
}
.p-24 {
  padding: 24px;
}
.p-24-32 {
  padding: 24px 32px;
}
.p-16-20 {
  padding: 16px 20px;
}
.p-20 {
  padding: 20px;
}
.p-16 {
  padding: 16px;
}
.pb-80 {
  padding-bottom: 80px;
}

.t-center {
  text-align: center;
}
.t-left {
  text-align: left;
}
.t-right {
  text-align: right;
}
.relative {
  position: relative;
}
.flex1 {
  flex: 1;
}

/* 字体大小 */
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}
.fs-36 {
  font-size: 36px;
}

.size32 {
  font-size: 32px;
}
.bold {
  font-weight: bold;
}
.col_eee {
  color: #EEEEEE;
}
.white {
  color: #fff!important;
}
.black {
  color: #000;
}
.col_f52 {
  color: #F5222D;
}
.col_fff {
  color: rgba(255, 255, 255, 0.45);
}
.col_45 {
  color: rgba(0, 0, 0, 0.45);
}
.col_65 {
  color: rgba(0, 0, 0, 0.65);
}
.col_85 {
  color: rgba(0, 0, 0, 0.85);
}
.col_03 {
  color: #030303;
}
.col_005 {
  color: #005EA7!important;
}
.gray3 {
  color: #333;
}
.gray9 {
  color: #999999;
}
.col_46 {
  color: #4675D4;
}

.bg_white {
  background: #fff;
}
.bg_black {
  background: #000!important;
}
.bg_f5 {
  background: #F5F5F5;
}
.bg_f8 {
  background: #F8F8F8;
}
.bg_f9 {
  background: #f9f9f9;
}
.bg_red {
  background: #F5222D!important;
}
.bg_f9f {
  background: #F9FBFF;
}
.bg_e2 {
  background: #E2EDF8!important;
}
.ver_mid {
  display: inline-block;
  vertical-align: middle;
}
.pointer {
  cursor: pointer;
}
.dis_block {
  display: inline-block;
}
.block {
  display: block!important;;
}
/* 按钮盒子 */
.btn-inline a {
  display: inline-block;
  width: 116px;
  height: 43px;
  background: #f9f9f9;
  border: 1px solid #dddddd;
  opacity: 1;
  border-radius: 2px;
  font-size: 14px;
  line-height: 43px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  transition: all 0.3s;
}
.btn-inline a:hover {
  background: #fff;
}
.btn-inline i {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: contain;
  background-position: center;
  transform: translateY(2px);
  margin-right: 5px;
}
.i-shopping i {
  background: url("images/shopping.png") no-repeat;
}
.i-star i {
  background: url("images/star.png") no-repeat;
}

/* 图片盒子 */
.img-box {
  transition: all 0.3s;
}
.goods-box .img-box:hover {
  transform: translateY(-6px);
}
.img-box img {
  width: 100%;
  height: 100%;
}

/* 下拉框 */
.select {
  position: relative;
  width: 200px;
  height: 34px;
  line-height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  z-index: 999;
}
.select a,
.select .option > span {
  display: flex;
  width: 100%;
  padding: 0 12px;
  transition: all 0.3s;
}
.select .header {
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.25);
}
.select .header span {
  max-width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select .header i {
  opacity: 0.25;
  transform: rotate(0deg);
  transition: all 0.3s;
}
.select.active .header i {
  transform: rotate(180deg);
}
.select .option {
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  max-height: 296px;
  margin-top: 1px;
  transition: all 0.3s;
  overflow-y: scroll;
  background-color: #fff;
}
.select .option a,
.select .option > span {
  border: 1px solid;
  border-color: #d9d9d9;
  margin-top: -1px;
  text-align: left;
}
.select .option a,
.select .option > span {
  line-height: 22px;
  padding: 5px 12px;
}
.select .option a:hover {
  background-color: #ed1c24;
  color: #fff;
  border-color: #ed1c24;
}

/* 图标 */
i {
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-style: normal;
  width: 14px;
  height: 14px;
}
.x_fill-sort i {
  background-image: url("./images/fill-sort.png");
}
.x_sort-fill i {
  background-image: url("./images/sort-fill.png");
}
.x_sort-down-fill i {
  background-image: url("./images/sort-down-fill.png");
}
.x_sort-up-fill i {
  background-image: url("./images/sort-up-fill.png");
}
.w_turn-right i {
  background-image: url("./images/turn right.png");
}
.w_turn-left i {
  background-image: url("./images/turn left.png");
}
.w_down i {
  background-image: url("./images/down.png");
}
.x_completed i {
  background-image: url("./images/completed.png");
}
.w_line-upload i {
  background-image: url("./images/line-upload.png");
}
.w_up i {
  background-image: url("./images/up.png");
}
.x_fill-star-selected i {
  background-image: url("./images/fill-star-selected.png");
}
.x_reduce i {
  background-image: url("./images/reduce.png");
}
.x_add i {
  background-image: url("./images/add.png");
}
.x_line-close i {
  background-image: url("./images/line-close.png");
}
.x_lujing52 i {
  background-image: url("./images/delete.png");
}
.x_balance i {
  background-image: url("./images/balance.png");
}
.x_alipay i {
  background-image: url("./images/alipay.png");
}
.x_wechatpay i {
  background-image: url("./images/wechat\ pay.png");
}
.x_scanning i {
  background-image: url("./images/scanning.png");
}
.w_line-check_white i {
  background-image: url("./images/line-check-white.png");
}
.w_star-selected i {
  background-image: url("./images/star-fill.png");
}
.w_checkbox-selectednormal i {
  background-image: url("./images/checkbox-selected+normal.png");
}

.el-select-dropdown{
  z-index: 1001!important;
}
</style>
