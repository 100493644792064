<template>
  <div class="my-flow">
    <c-nav :nav="navList"></c-nav>
    <div class="card flex f-a-c mb-20 flow-header p-20">
      <div>
        <p class="mb-8">{{ myOver }}</p>
        <p>我的余额</p>
        <a href="javascript:;" class="bg-w-btn" @click="toRecharge">充值</a>
      </div>
      <!-- <div>
        <p class="mb-8">{{ giftMoney.toFixed(2) }}</p>
        <p>赠送金</p>
      </div> -->
    </div>
    <div class="card mb-20">
      <p class="p-20 bb1">资金流水</p>
      <div class="flex f-a-c p-20 fs-14">
        <span>交易类型：</span>
        <div class="select mr-40">
          <a
            href="javascript:;"
            class="header w_down"
            @click="changeType"
            @blur="isChoose = false"
          >
            {{ typeText }}<i></i>
          </a>
          <div class="option" :style="{ height: typeHeight }">
            <a
              href="javascript:;"
              v-for="value in typeList"
              :key="value.id"
              @focus="chooseType(value.id)"
            >
              {{ value.name }}
            </a>
          </div>
        </div>
        <span>时间范围：</span>
        <div class="select mr-40">
          <a href="javascript:;" class="header w_down" v-show="!isTime">
            请选择时间范围<i></i>
          </a>
          <a href="javascript:;" class="header w_down" v-show="isTime">
            {{ startTime }}~{{ endTime }}
          </a>
          <Calendar
            v-model="example.value"
            :range="example.range"
            :lang="example.lang"
            :firstDayOfWeek="example.firstDayOfWeek"
            :input-class="example.inputClass"
            :position="example.position"
            :disabled-start-date="example.disabledStartDate"
            :text-format="example.textFormat"
            :date-format="example.dateFormat"
            :disabled-end-date="example.disabledEndDate"
            @choose="isTime = true"
            @handleClear="isTime = false"
          />
        </div>
        <a href="javascript:;" class="bg-r-btn mr-16" @click="clear">
          取消筛选
        </a>
        <a href="javascript:;" class="bg-r-btn" @click="getWallet(nowPagination)">查询</a>
      </div>
      <div class="p-20 table-box">
        <table>
          <thead>
            <th><p>时间</p></th>
            <th><p>订单号</p></th>
            <th><p>金额</p></th>
            <th><p>类型</p></th>
            <th><p>详情</p></th>
          </thead>
          <tbody>
            <tr class="bb1" v-for="(value, index) in flowList" :key="index">
              <td>{{ value.created_at }}</td>
              <td>{{value.sn}}</td>
              <td>{{ value.fee_type === 1 ? '+' + value.fee : '-' + value.fee}}</td>
              <td>{{ flowType[value.type] }}</td>
              <td>{{ value.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card flex f-a-c p-16-20">
      <!-- <pages
        :pageCount="page_count"
        :nowPage="page"
        @change-page="getWallet"
      ></pages> -->
      <pages
        :pageCount="page_count"
        :nowPage="nowPagination"
        @change-page="changePage"
      >
        <a href="javascript:;" class="ml-20">{{ goods_page_count }}条/页</a>
        <span>
          跳至
          <input
            type="text"
            name="to-pages"
            v-model="pagesTo"
            @keyup.enter="changePages(pagesTo)"
          />页
        </span>
      </pages>
    </div>
  </div>
</template>

<script>
import Calendar from "../../../components/calendar";
import pages from "../../../components/pages";
import { wallet, myInfo } from '@/http/api';
import { mapState } from 'vuex'
export default {
  name: "myFlow",
  components: {
    Calendar,
    pages,
  },
  data() {
    return {
      navList: [
        {
          path: `/user/my-flow`,
          text: "资金流水",
        },
      ],
      //余额
      myOver: 0,
      //赠送金
      giftMoney: 0,
      //流水数据
      flowList: [],
      flowType:["未知","正常下单","充值","商品退款"],
      //日期选择器
      example: {
        inputClass: "exampleDatePicker",
        lang: "zh-CN",
        position: "center",
        range: true,
        value: [new Date(), new Date()],
        firstDayOfWeek: "sunday",
      },
      //是否筛选时间
      isTime: false,
      //交易类型列表
      typeList: [
        {
          id: '',
          name: "全部类型"
        },
        {
          id: 0,
          name: "未知",
        },
        {
          id: 1,
          name: "正常下单",
        },
        {
          id: 2,
          name: "充值",
        },
        {
          id: 3,
          name: "商品退款",
        },
      ],
      //是否选择选择类型
      isChoose: false,
      //交易类型
      type: "",

      //当前页
      page: 0,

      //切换的分页
      toPage: 1,

      //当前页
      nowPagination: 0,
      //跳转指定分页
      pagesTo: "",
      //分页总页数
      page_count: 0,
      //每页条数
      goods_page_count: 0,
    };
  },
  computed: {
    ...mapState(["surplusMoney"]),
    //选择类型 高度
    typeHeight() {
      let num = 0;
      if (this.isChoose && this.typeList.length > 0) {
        num = this.typeList.length * 34;
      } else if (this.isChoose && this.typeList.length == 0) {
        num = 34;
      }
      return num + "px";
    },
    //选择类型文本
    typeText() {
      let str = "";
      if (this.type === "") {
        str = "全部类型";
      } else {
        for (const iterator of this.typeList) {
          if (iterator.id == this.type) str = iterator.name;
        }
      }
      return str;
    },
    //开始时间
    startTime() {
      let str = "";
      if (this.example.value[0] != "" && this.isTime) {
        str = this.example.value[0].toLocaleDateString().split("/").join("-");
      }
      return str;
    },
    //结束时间
    endTime() {
      let str = "";
      if (this.example.value[1] != "" && this.isTime) {
        str = this.example.value[1].toLocaleDateString().split("/").join("-");
      }
      return str;
    },
  },
  methods: {
    //改变类型展开状态
    changeType() {
      this.isChoose = !this.isChoose;
    },
    //选择类型
    chooseType(id) {
      this.type = id;
      console.log(id);
      // this.getWallet();
    },
    //取消筛选
    clear() {
      this.type = "";
      this.isTime = false;
      // this.getData();
    },
    // 充值
    toRecharge() {
      this.$router.push('my-flow/recharge');
    },

    // 切换分页
    changePage(page) {
      if(this.nowPagination === page) {
        return;
      }
      this.nowPagination = page;
      // window.scrollTo(0, 0);
      this.getWallet(this.nowPagination);
    },
    // 跳转至
    changePages(i) {
      i = parseInt(i);
      if(this.nowPagination === i) {
        return;
      }
      if (i > this.page_count || i <= 0) {
        return this.$message.error('你输入的分页不存在！');
      }
      this.nowPagination = i;
      this.getWallet(this.nowPagination);
      // window.scrollTo(0, 0);
      this.pagesTo = "";
    },
    //---------------------获取数据---------------
    // 获取资金流水
    getWallet(page) {
      let params = {
        type: this.type,
        time: this.startTime ? this.startTime + ' - ' + this.endTime : '',
        page: page,
        limit: 6,
      }
      wallet(params).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.flowList = data.data;
          this.nowPagination = data.current_page;
          this.page_count = data.last_page;
          this.goods_page_count = data.per_page;
        }
      })
    },
    // 获取余额
    getMoney() {
      myInfo().then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.myOver = data.user.surplus_money;
        }
      })
    },
  },
  created() {
    // 获取流水数据
    this.getWallet(1);
    this.getMoney();
  },
};
</script>

<style>
.my-flow .select {
  width: 220px;
}
.my-flow .table-box {
  padding-bottom: 0;
}
</style>