<template>
  <div class="applyAfterSale">
    <c-nav :nav="navList"></c-nav>
    <div class="applyCotent bg_white fs-14">
      <div class="applyItem">订单号：{{reOrderdata ? reOrderdata.sn : ''}}</div>
      <div class="applyItem">下单时间：{{reOrderdata ? reOrderdata.created_at : ''}}</div>
      <div class="applyItem">
        <span class="Applytitle">
          <span class="required">*</span>
          售后原因：
        </span>
        <el-select v-model="reason" placeholder="请选择售后原因">
           <el-option
            v-for="item in reasonOP"
            :key="item.id"
            :label="item.label"
            :value="item.label">
          </el-option>
        </el-select>
      </div>
      <div class="applyItem">
        <span class="Applytitle">
          <span class="required">*</span>
          售后方式：
        </span>
        <a class="selItem t-center active" href="javascript:;">退货退款</a>
      </div>
      <div class="applyItem">
        <span class="Applytitle">供应商：</span>
        {{reOrderdata ? reOrderdata.admin.code : ''}}
      </div>
      <div class="applyItem">
        <span class="Applytitle" style="ver_mid">
          <span class="required">*</span>
          售后商品
        </span>
        <div class="flex dis_block proMsg">
          <div class="flex bg_f8">
            <span style="width:30%;">商品名称</span>
            <span>颜色</span>
            <span>尺码</span>
            <span>单价(元)</span>
            <span>数量(件)</span>
            <span>退货数量(件)</span>
            <span>折扣金额</span>
            <span>退货总价</span>
          </div>
          <div class="flex" v-for="item in products" :key="item.id" style="padding: 12px 16px 0 16px;">
            <div style="width: 30%;">
              {{item.full_name}}
            </div>
            <div style="flex-direction: column; flex: 1;">
              <div v-for="spec in item.spec" :key="spec.id" class="specItem mb-12">
                <div>{{spec.colour}}</div>
                <div>{{spec.size}}</div>
                <div>{{spec.price}}</div>
                <div>{{spec.number}}</div>
                <div>
                  <el-input-number size="mini" v-model="spec.backNum" :min="0" :max="spec.number"></el-input-number>
                </div>
                <div>￥{{spec.discount_price}}</div>
                <div>￥{{(spec.backNum * spec.price).toFixed(2)}}</div>
              </div>
            </div>
          </div>
          <div class="bottom bold flex" style="justify-content: flex-end;">
            <span>合计</span>
            <span>{{total}}</span>
            <span>￥0.00</span>
            <span class="col_f52">￥{{totalPrice}}</span>
          </div>
        </div>
      </div>
      <div class="applyItem">
        <span class="Applytitle">
          <span class="required">*</span>
          退货方式：
        </span>
        <a class="selItem t-center active" href="javascript:;">快递寄回</a>
      </div>
      <div class="applyItem">
        <span class="Applytitle">退款方式：</span>
         退到账户余额
      </div>
      <div class="applyItem">
        <span class="Applytitle" style="vertical-align: top;">
          <span class="required">*</span>
          售后说明：
        </span>
        <textarea class="textarea" placeholder="请填写售后说明" v-model="explain"></textarea>
      </div>
      <div class="applyItem">
        <span class="Applytitle">相关图片: </span>
        <div class="dis_block upImgbox">
          <el-upload
            action="https://yinbaole.com/admin/uploadQiniu"
            :on-success="handleUpSuccess"
            :show-file-list=false
            list-type="picture-card"
            v-show="image.length < 5"
            >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div style="position: relative;" class="dis_block" v-for="(item,index) in image" :key="'img'+index">
            <i class="iconfont m_text-delete" @click="deleteUpImg(index)"></i>
            <img :src="item" alt=""  class="uploadImg"> 
          </div>
        </div>
        <div class="col_45 mt-8" style="margin-left: 80px;">(每个商品最多可上传5张图片，图片大小请限制在5M以内)</div>
      </div>
      
    </div>
    <div class="applyBottom fs-12 bg_white t-right">
        <span class="gray6">退款金额总计(不包含运费)：
          <span class="fs-22 bold col_f52">{{totalPrice}}</span>
          <span class="col_f52">元</span>
        </span>
        <span class="submitBtn bg_red fs-16 t-center ml-15" @click="handleSubmit">确认提交</span>
      </div>
  </div>
</template>

<script>
import { reOrderIndex, createReOrder } from '@/http/api';
// import { mapState } from 'vuex'
export default {
  data() {
    return {
      navList: [
        {
          path: `/user/follow`,
          text: "跟单工作台",
        },
        {
          path: '',
          text: '申请售后'
        }
      ],
      reason: '',
      reasonOP:[
        {id: 1, label: '商品破损/配错货'},
        {id: 2, label: '少发/漏发'},
        {id: 3, label: '不合适/不喜欢/看样板'},
        {id: 4, label: '退运费'},
        {id: 5, label: '缺货导致退款'},
      ],
      products: [],
      orderId: '',
      reOrderdata: null,
      explain: '', // 售后说明
      image: [], // 图片
    }
  },
  computed: {
    // ...mapState(["tel"]),
    total() {
      let num = 0;
      this.products.forEach(item => {
        item.spec.forEach(spec => {
          num += spec.backNum;
        })
      })
      return num;
    },
    totalPrice() {
      let price = 0;
      this.products.forEach(item => {
          item.spec.forEach(spec => {
            price += spec.backNum * spec.price;
          })
        })
      return price.toFixed(2);
    },
    // 提交数据
    selectPro() {
      let arr = []
      this.products.forEach(item => {
        item.spec.forEach(sp => {
          if(sp.backNum > 0) {
            let obj = {
              productId: sp.hashid,
              number: sp.backNum,
            }
            arr.push(obj);
          }
        })
      })
      return arr;
    }
  },
  created() {
    // console.log(this.$route.query.orderId);
    this.orderId = this.$route.query.orderId;
    this.reOrderIndex(this.orderId);
  },
  methods: {
    // 上传图片成功
    handleUpSuccess(file) {
      console.log(file);
      this.image.push(file.data.src);
    },
    // 删除图片
    deleteUpImg(index) {
      this.image.splice(index, 1);
    },
    // 确认提交
    handleSubmit() {
      if(this.reason === '') {
        this.$message.warning('请选择售后原因!');
      }else if(this.selectPro.length === 0) {
        this.$message.warning('请选择售后商品');
      }else if(this.explain === '') {
        this.$message.warning('请填写售后说明');
      }else {
        // console.log(this.selectPro);
        this.createReOrder();
      }
    },
    // 获取售后订单数据
    reOrderIndex(orderId) {
      reOrderIndex({orderId: orderId}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          let list  = JSON.parse(JSON.stringify(data.products));
          list.forEach(item => {
            item.spec.forEach(sp => {
              sp.backNum = 0;
            })
          })
          this.reOrderdata = data;
          this.products = list;
        }
      })
    },
    // 创建售后订单
    createReOrder() {
      let params = {
        productData: this.selectPro,
        orderId: this.orderId,
        reason: this.reason,
        describe: this.explain,
        image: this.image
      }
      createReOrder(params).then(res => {
        const { statusCode, msg } = res;
        if(statusCode === 200) {
          this.$message.success(msg);
          this.$router.replace(`/user/after-sale`);
        }else {
          this.$message.error(msg);
        }
      })
    }
  }
}
</script>

<style>
.applyCotent {
  padding: 22px 24px 35px 24px;
}
.applyItem {
  margin-top: 32px;
}
.applyItem:nth-child(1) {
  margin: 0;
}
.selItem {
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #F5222D;
  border-radius: 2px;
}
.applyItem .active::before,
.applyItem .active::after {
  content: "";
  display: inline-block;
  position: absolute;
}
.applyItem .active::before {
  width: 24px;
  height: 24px;
  background: #f5222d;
  transform: rotate(45deg);
  bottom: -12px;
  right: -12px;
}
.applyItem .active::after {
  width: 12px;
  height: 12px;
  background: url("../../../images/line-check-white.png") no-repeat;
  background-size: contain;
  background-position: center;
  bottom: -1px;
  right: -1px;
}
.Applytitle {
  position: relative;
  display: inline-block;
  width: 80px;
}
.Applytitle .required {
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
}
.proMsg {
  width: calc(100% - 80px);
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}
.proMsg .bg_f8 {
  padding: 11px 16px;
}
.bg_f8 span:nth-child(n+2) {
  text-align: center;
  flex: 1;
}
.specItem {
  display: flex;
}
.specItem div {
  flex: 1;
  text-align: center;
}
.proMsg .bottom {
  border-top: 1px solid #D9D9D9;
  padding: 16px;
}
.proMsg .bottom span {
  width: 10%;
  text-align: center;
}
.textarea {
  width: 400px;
  height: 90px;
  padding: 8px 12px;
  resize: none;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  font-size: 14px;
}
.applyBottom {
  border-top: 1px solid #DDDDDD;
  padding: 24px;
}
.applyBottom .submitBtn {
  display: inline-block;
  width: 160px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
}
.uploadImg {
  margin-left: 16px;
  width: 104px;
  height: 104px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
}
.upImgbox div {
  display: inline-block;
}
.upImgbox .m_text-delete {
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: -5px;
  color: #333;
}

.el-input-number--mini {
  width: 94px!important;
}
.el-upload--picture-card i {
  width: 28px;
}
.el-upload--picture-card {
  width: 104px!important;
  height: 104px!important;
  line-height: 118px!important;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 104px!important;
  height: 104px!important;
}
.applyItem .el-input--suffix .el-input__inner {
  height: 32px!important;
}
.applyItem .el-input__icon {
  line-height: 32px!important;
}
.el-upload-list--picture-card {
  display: none!important;
}
</style>