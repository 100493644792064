<template>
  <div class="goodsItem" v-if="good" @click="toDetail(good.hashid)">
    <span class="colorTag fs-12 gray9">{{good.colourNul}}色</span>
    <div class="imgBox">
      <el-image lazy :src="good.image + '?imageMogr2/thumbnail/180x'" alt=""></el-image>
      <div class="rank flex fs-12" v-if="good.salesRanking <= good.browseRanking">
        <span>{{good.cat ? good.cat.name : ''}}销量排行榜</span>
        <span class="bg_yellow">第{{good.salesRanking}}名</span>
      </div>
      <div class="rank flex fs-12" v-else>
        <span>{{good.cat ? good.cat.name : ''}}热度排行榜</span>
        <span class="bg_red">第{{good.browseRanking}}名</span>
      </div>
    </div>
    <div class="textBox">
      <p class="fs-14 col_85" v-html="good.full_name"></p>
      <div class="flex mt-8" style="align-items: center; justify-content: space-between; ">
        <span class="fs-18 bold c-red">￥{{good.price}}</span>
        <span class="starBox fs-12 gray9 pointer" @click="star($event)">
          <img class="pointer" src="../images/star-fill.png" alt="" v-if="good.isCollection === 1">
          <img class="pointer" src="../images/starEnpty.png" alt="" v-else >
          <span class="ver_mid">{{good.isCollection === 1 ? ' 已收藏' : ' 收藏'}}</span>
        </span>
      </div>
      <div class="flex fs-12 gray9 mt-8" style="justify-content: space-between;">
        <span>复购率:{{(good.repurchaseRate * 100).toFixed(0)}}%</span>
        <span>售后率:{{(good.afterSalesRate * 100).toFixed(0)}}%</span>
      </div>
      <div class="mt-12">
        <div class="mb-8 mr-8 asTag col_f52 fs-12" 
        v-for="item in good.label_data" :key="item.id" 
        >{{item.name}}</div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { collection, delProduct } from '@/http/api';
export default {
  props: ["good"],
  data() {
    return {
      collectionIdArr: []
    }
  },
  created() {
    if(this.good.collectionId) {
      this.collectionIdArr.push(this.good.collectionId);
    }
  },
  watch: {
    good: function() {
      if(this.good.collectionId) {
        this.collectionIdArr.push(this.good.collectionId);
      }else {
        this.collectionIdArr = [];
      }
    }
  },
  mounted() {
 
  },
  methods: {
    // 跳转到详情
    toDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
    // 收藏
    star(e) {
      e.stopPropagation();
      if(this.good.isCollection === 1) {
        this.delProduct();
      }else {
        this.collection();
      }
    },
    //----------获取数据 &api-------------------
    // 收藏
    collection() {
      collection({ productId: this.good.hashid }).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('收藏成功');
          this.$emit('changeCollection');
          this.$store.commit('changeCollectNumber' , 1);
        }else {
          this.$message.error('收藏失败');
        }
      })
    },
    delProduct() {
      delProduct({collectionIdArr: this.collectionIdArr}).then(res => {
        const { statusCode } = res;
        if(statusCode === 200) {
          this.$message.success('已取消收藏');
          this.$emit('changeCollection');
          this.$store.commit('changeCollectNumber' , -1);
        }else {
          this.$message.error('取消收藏失败');
        }
      })
    }
  }
}
</script>

<style>
.colorTag {
  z-index: 99;
  position: absolute;
  left: 16px;
  top: 16px;
  border: 1px solid #999999;
  border-radius: 4px;
  padding: 4px;
  background: #fff;
}
.goodsItem {
  position: relative;
  width: 220px;
  /* height: 326px; */
  background: #fff;
}
.goodsItem:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
.goodsItem:hover .imgBox img{
 transform: translateY(-6px);
}
.goodsItem .imgBox img {
  transition: all 0.3s;
}
.goodsItem .imgBox {
  
  position: relative;
  padding: 20px 20px 16px;
  width: 100%;
}
.imgBox img {
  width: 180px;
  height: 180px;
}
.textBox {
  padding: 0 16px 16px;
}
.textBox p {
  min-height: 38px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.starBox img {
  width: 10px;
  height: 10px;
  vertical-align: middle;
}
.rank {
  position: absolute;
  left: 16px;
  bottom: 20px;
}
.rank span {
  padding: 4px 8px;
}
.rank span:nth-child(1) {
  background: #000;
  color: #fff;
}
.bg_yellow {
  background: #FFD500;
}
.bg_red {
  background: #F5222D;
  color: #fff;
}
.asTag {
  display: inline-block;
  padding: 4px 6px;
  text-align: center;
  border: 1px solid #F5222D;
  border-radius: 12px;
}
</style>