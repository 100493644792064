<template>
  <div class="goods">
    <cNav :nav="navList" />
    <div class="flex c-box mt-10" id="title" style="align-items: self-start; overflow: inherit;">
      <img :src="searchImg" alt="" v-show="searchImg" style="width: 200px;"/>
      <div class="ml-32" style="flex: 1;">
        <div class=" sort-box sort-list">
          <div class="flex">
          <span class="sort-title">{{cat1_list.name}}</span>
          <ul class="flex" style="border-bottom: none">
            <li :class="{'c-red': catActive === -1}" @click="handleSelAll(cat1_list.id, 1)"><a href="javascript:;">全部</a></li>
            <li
              v-for="(fType,index) in cat1_list.children" :key="fType.id"
              :class="fType.id === catActive && 'c-red'"
              @click="handleSelCat(fType.id, fType.hashid, index)"
            >
              <a href="javascript:;" v-if="fType.cat_name">
                {{ fType.cat_name }}
              </a>
              <a href="javascript:;" v-else>{{ fType.name }}</a>
            </li>
          </ul>
        </div>
        <ul class="cat2 sort-list" v-show="cat2_list.length && cat2Show">
          <li
            v-for="(fType) in cat2_list" :key="fType.id"
            :class="fType.id === cat2Active && 'c-red'"
            @click="handleSelCat2(fType.id, fType.hashid)"
          >{{fType.active}}
            <a href="javascript:;">{{ fType.name }}</a>
          </li>
        </ul>
        </div>
        <div class=" flex sort-list" >
          <span class="sort-title">{{fabric.name}}</span>
          <ul class="flex" >
            <li :class="{'c-red': fabricActive === -1}" @click="handleSelAll(fabric.id, 2)"><a href="javascript:;">全部</a></li>
            <li
              v-for="(fType) in fabric.children" :key="fType.id"
              :class="fType.id === fabricActive && 'c-red'"
              @click="handleSelFabric(fType.id, fType.parent_id)"
            >
              <a href="javascript:;">{{ fType.name }}</a>
            </li>
          </ul>
        </div>
        <div class=" flex sort-list">
          <span class="sort-title">{{color.name}}</span>
          <ul class="flex">
            <li :class="{'c-red': colorActive === -1}" @click="handleSelAll(color.id, 3)"><a href="javascript:;">全部</a></li>
            <li
              v-for="(fType) in color.children" :key="fType.id"
              :class="fType.id === colorActive && 'c-red'"
              @click="handleSelColor(fType.id, fType.parent_id)"
            >
              <a href="javascript:;" v-if="fType.color_name">
                {{ fType.color_name }}
              </a>
              <a href="javascript:;" v-else>{{ fType.name }}</a>
            </li>
          </ul>
        </div>
        <div class=" flex sort-list">
          <span class="sort-title">{{apply.name}}</span>
          <ul class="flex">
            <li :class="{'c-red': applyActive === -1}" @click="handleSelAll(apply.id, 4)"><a href="javascript:;">全部</a></li>
            <li
              v-for="(fType) in apply.children" :key="fType.id"
              :class="fType.id === applyActive && 'c-red'"
              @click="handleSelApply(fType.id, fType.parent_id)"
            >
              <a href="javascript:;">{{ fType.name }}</a>
            </li>
          </ul>
        </div>
        <div class=" flex sort-btn" v-show="!searchImg">
          <a
            href="javascript:;"
            :class="{'active': isPriceSort === 0}"
            @click="changeSort(0)"
          >
            默认排序
          </a>
          <a
            href="javascript:;"
            :class="[
              isPriceSort === 1 && 'active',
            ]"
            @click="changeSort(1)"
          >
            新品
            
          </a>
          <a
            href="javascript:;"
            :class="[
              isPriceSort === 2 && 'active',
              is_price == null && 'x_sort-fill',
              is_price == 2 && 'x_sort-down-fill',
              is_price == 1 && 'x_sort-up-fill',
            ]"
            @click="changeSort(2)"
          >
            价格
            <i></i>
          </a>
          <span class="price flex">
            <span class="fs-12">￥</span>
            <input class="fs-12" type="text" v-model="priceArr[0]" />
          </span>
          <span style="margin-left: 5px">-</span>
          <span class="price flex">
            <span class="fs-12">￥</span>
            <input class="fs-12" type="text" v-model="priceArr[1]" />
          </span>
          <span class="bg-r-btn" @click="handleConfirm">确定</span>
          <span class="right flex">
            <span>{{ nowPagination }}/{{ page_count }}</span>
            <a
              href="javascript:;"
              class="w_turn-left"
              :class="nowPagination == 1 || 'active'"
              @click="topChangePage(0,npage)"
            >
              <i></i>
            </a>
            <a
              href="javascript:;"
              class="w_turn-right"
              :class="nowPagination == page_count || 'active'"
              @click="topChangePage(1,npage)"
            >
              <i></i>
            </a>
          </span>
        </div>
        <div class="flex sort-check-box" v-show="!searchImg">
          <div class="flex f-a-c" style="width:92%; flex-wrap:wrap;">
            <span
            class="sort-checkbox flex f-a-c"
            v-for="(value, index) in search_tag"
            :key="index"
            @click="chooseSearch(value.id)"
            >
              <a
                href="javascript:;"
                class="flex"
                :class="{ x_completed: value.active }"
              >
                <i></i>
                {{ value.name }}
              </a>
            </span>
          </div>
          <div
            class="right flex1"
          >
            <a class="w_down" href="javascript:;" @mouseenter="handleShowArea" @mouseleave="handleHidArea">
              <span v-if="selArea">{{selArea}}</span>
              <span v-else>所有地区</span>
              <i></i>
            </a>
            <transition name="area" >
              <div class="area-box" v-show="areaShow" @mouseenter="handleShowArea" @mouseleave="handleHidArea">
                <div class="area-header">
                  <a href="javascript:;" :class="areaActive === -1 && 'c-red'" @click="chooseArea(-1)">全部省份</a>
                </div>
                <ul class="area">
                  <li
                    class="area-item"
                    v-for="(value, index) in area_list"
                    :key="index"
                    :class="areaActive === index && 'c-red'"
                    @click="chooseArea(index)"
                  >
                    <a href="javascript:;" v-if="value.name">
                      {{ value.name }}
                    </a>
                    <!-- <a href="javascript:;" v-else>{{ value.name }}</a> -->
                  </li>
                </ul>
              </div>
            </transition>
        </div>
          
        </div>
        
      </div>
    </div>
    <div class="c-box" id="goodList">
        <ul class="flex goods-list" id="inner">
          <li v-for="value in productList" :key="value.id" class="pointer">
            <goods-item :good="value" @changeCollection='changeCollection'></goods-item>
            <!-- <goods-box :data="value"></goods-box> -->
          </li>
          
        </ul>
        <p class="p-20 t-c" v-show="productList && productList.length == 0">暂时没有哦~</p>
      </div>
    
    <pages
      :pageCount="page_count"
      :nowPage="nowPagination"
      @change-page="changePage"
    >
      <a href="javascript:;">{{ goods_page_count }}条/页</a>
      <span>
        跳至
        <input
          type="text"
          name="to-pages"
          v-model="pagesTo"
          @keyup.enter="changePages(pagesTo)"
        />页
      </span>
    </pages>
  </div>
</template>

<script>
import goodsItem from "../../components/goodsItem"
import pages from "../../components/pages";
import { productCat, productList, imageSearch } from '@/http/api';
import { province } from '../../assets/province.js';
import { mapState } from 'vuex'

export default {
  name: "houseDefault",
  components: {
    // goodsBox,
    goodsItem,
    pages,
  },
  props: {
    //传入的一级分类ID
    cat_id1: String,
    //传入的商品标签名
    search_tag_props: String,
  },
  data() {
    return {
      //导航
      navList: [
        {
          id: 1,
          path: "house",
          text: "服饰仓库",
        },
      ],
      //一级分类 类别
      cat1_list: [],
      //二级分类 类别
      cat2_list: [],
      //二级分类显示状态
      cat2Show: false,
      //面料
      fabric: [],
      //颜色
      color: [],
      //性别
      apply: [],
      //商品标签
      search_tag: [],
      //地区
      area_list: [],
      //地区列表显示状态
      areaShow: false,
      //是否按价格排序
      isPriceSort: 0,
      //价格排序(true为降序false为升序)
      sort_by_status: true,
      //价格排序(1为降序2为升序)
      sort_by: "",
      //按价格筛选
      price: ["", ""],
      //当前页
      nowPagination: 0,
      npage: 0,
      //跳转指定分页
      pagesTo: "",
      //分页总页数
      page_count: 0,
      //每页条数
      goods_page_count: 0,

      // 分类数据
      typeList: [],
      // 分类id数组
      categoryIdArr: [],
      // 已选择的tag id数组
      labelIdArr: [],
      //------------类别高亮--------------
      // 类别1
      catActive: -1,
      // 类别2
      cat2Active: -1,
      // 面料
      fabricActive: -1,
      // 颜色
      colorActive: -1,
      // 性别
      applyActive: -1,
      // 地区
      areaActive: -1,
      

      // 类别2所属类别
      belogId: -1,

      // 分类id对象
      categoryIdObj: {},
      // 价格区间
      priceArr: [],
      // 商品列表数据
      productList: [],
      // 发货地
      selArea: '',
      // 是否新品
      is_new: null,
      // 价格升降序
      is_price: null,
      // 搜索关键词
      key: '',
      productCat: [],
      // 类别数据id
      catId: '',
      // 领标id
      leadingLabelId: '',

      cid: null,
      pid: null,
      isPrice: false, //是否升降序
      loading: null
    };
  },
  computed: {
    ...mapState(["searchImg", "sFlag", "keyword", "labelId"])
  },
  watch: {
    sFlag: {
      handler: function() {
        this.key = this.keyword;
        console.log(this.keyword);
        this.getGoodsList(1);
      },
      deep: true
    },
    searchImg: function(newVal) {
      if(newVal != '') {
        this.imageSearch(1);
      }
    }
  },
  methods: {
    // 选择全部
    handleSelAll(id, type) {
      switch (type) {
        case 1:
          this.catActive = -1;
          this.cat2Show = false;
          this.catId = '';
          break;
        case 2:
          this.fabricActive = -1;
          break;
        case 3:
          this.colorActive = -1;
          break;
        case 4:
          this.applyActive = -1;
          break;
      }
      let resId = this.categoryIdObj[id];
      console.log(this.categoryIdArr.indexOf(resId));
      if(resId) {
        this.categoryIdArr.splice(this.categoryIdArr.indexOf(resId), 1);
        this.categoryIdObj[id] = null;
      }
      this.searchImg ? this.imageSearch(1) : this.getGoodsList(1);
    },
    // 选择类别
    handleSelCat(id, hashid, index) {
      this.belogId = index;
      this.catActive = id;
      this.cat2Active = -1;
      this.cat2Show = true;
      this.cat1_list.children.forEach(item => {
        if(item.id === id) {
          this.cat2_list = item.children;
        }else {
          return;
        }
      });
      this.catId = hashid;
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 选择类别2
    handleSelCat2(id, hashid) {
      this.cat2Active = id;
      this.catId = hashid;

      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 选择面料
    handleSelFabric(id, pId) {
      this.fabricActive = id;
      this.setCategoryIdArr(id, pId);
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 选择颜色
    handleSelColor(id, pId) {
      this.colorActive = id;
      this.setCategoryIdArr(id, pId);
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 选择性别
    handleSelApply(id, pId) {
      this.applyActive = id;
      this.setCategoryIdArr(id, pId);
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 选择发货地
    chooseArea(index) {
      this.areaActive = index;
      if(index !== -1) {
        this.selArea = this.area_list[index].name;
      }else {
        this.selArea = '';
      }
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 排序方式
    changeSort(type) {
      this.isPriceSort = type
      switch(parseInt(type)) {
        case 0: 
          this.is_new = null;
          this.is_price = null;
          break;
        case 1:
          this.is_new = 1;
          this.is_price = null;
          break;
        case 2:
          this.is_new = null;
          this.isPrice = !this.isPrice;
          this.is_price = this.isPrice? 2 : 1;
          break;
      }
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 选择标签
    chooseSearch(id) {
      this.labelIdArr = [];
      this.search_tag.forEach(item => {
        if(item.id === id) {
          item.active = !item.active;
        }
        if(item.active) {
          this.labelIdArr.push(item.id);
        }else {
          if(this.labelIdArr.indexOf(item.id) > -1) {
            this.labelIdArr.splice(this.labelIdArr.indexOf(item.id), 1);
          }else return;
        }
      });
      this.searchImg ? this.imageSearch(this.nowPagination) : this.getGoodsList(this.nowPagination);
    },
    // 收藏后刷新
    changeCollection() {
      if(this.searchImg) {
        this.imageSearch(this.nowPagination);
      }else {
        this.getGoodsList(this.nowPagination);
      }
      
    },
    //---------------------获取数据 &api------------------
    //获取分类数据
    getproductCat() {
      productCat().then(res => {
        const loading = this.openLoading('#title');
        const { statusCode } = res;
        const {productCat, productLabel} = res.data;
        if(statusCode === 200) {
          loading.close();
          productCat.forEach(item => {
            item.active = false;
            item.children.forEach(item2 => {
              item2.active = false;
            });
          })
          this.productCat = productCat;
          this.cat1_list = productCat[0];
          this.fabric = productCat[1];
          this.color = productCat[2];
          this.apply =productCat[3];
          let newLabelList = JSON.parse(JSON.stringify(productLabel));
          newLabelList.forEach(item => {
            item.active = false;
          })
          this.search_tag = newLabelList;
          if(this.cid) {
            this.handlejumpLoc();
          }
          console.log(this.searchImg);
          if(this.searchImg) {
            this.imageSearch(1);
          }
          if(!this.searchImg) {
            this.getGoodsList(1);
          }
        }else {
          loading.close();
          this.$message.error('暂无数据');
        }
      })
    },
    // 获取产品列表数据
    getGoodsList(page) {
      const loading = this.openLoading('#goodList');
      let newPriceArr = this.setPriceArr();
      let params = {
          catId: this.catId,
          search: this.key,
          leadingLabelId: this.leadingLabelId,
          categoryIdArr: this.categoryIdArr,
          is_new: this.is_new === 1 ? 1 : '',
          is_price: this.is_price,
          labelIdArr: this.labelIdArr,
          priceArr: newPriceArr,
          province: this.selArea,
          page: page,
          limit: 50,
        }
      productList(params).then(res => {
        const { statusCode } = res;
        const { productList } = res.data;
        if(statusCode === 200 && productList.data) {
          loading.close();
          // console.log(this.productList);
          this.productList = productList.data;
          this.npage = productList.current_page;
          this.nowPagination = productList.current_page;
          this.page_count = productList.last_page;
          this.goods_page_count = productList.per_page;
        }else {
          loading.close();
        }
      }).catch(() => {
        loading.close();
        this.$message.error('搜索失败')
      })
    },
    // 根据上传图片获取数据
    imageSearch(page) {
      const loading = this.openLoading('#goodList');
      let newPriceArr = this.setPriceArr();
      let params = {
        image: this.searchImg,
        search: this.key,
        catId: this.catId,
        leadingLabelId: this.leadingLabelId,
        categoryIdArr: this.categoryIdArr,
        labelIdArr: this.labelIdArr,
        is_new: this.is_new === 1 ? 1 : '',
        is_price: this.is_price,
        priceArr: newPriceArr,
        province: this.selArea,
        page: page,
        limit: 50
      }
      imageSearch(params).then(res => {
          const { statusCode, data } = res;
        if(statusCode === 200) {
          loading.close();
          if(data.data.length !== 0) {
            this.productList = data.data;
            this.nowPagination = data.current_page;
            this.page_count = data.last_page;
            this.goods_page_count = data.per_page;
          }else {
            this.productList = [];
          }
          console.log(this.productList);
        }else {
          loading.close();
        }
      }).catch(() => {
        loading.close();
        this.$message.error('搜索失败')
      })
    },
    // 添加分类id到查询数组
    setCategoryIdArr(id, pId) {
      if(this.categoryIdObj[pId]) {
        let resId = this.categoryIdObj[pId];
        if(this.categoryIdArr.indexOf(resId) > -1) {
            this.categoryIdArr.splice(this.categoryIdArr.indexOf(resId), 1);
            this.categoryIdArr.push(id);
            this.categoryIdObj[pId] = id;
        }
      }else {
        this.categoryIdObj[pId] = id;
        this.categoryIdArr.push(id);
      }
    },
    // 价格区间处理
    setPriceArr() {
      let newPriceArr = [];
      if(!this.priceArr[0] && !this.priceArr[1]) {
        this.priceArr = [];
        newPriceArr = [];
      }else if(!this.priceArr[0] && this.priceArr[1]) {
        // this.priceArr[0] = 0;
        newPriceArr[0] = 0;
        newPriceArr[1] = this.priceArr[1];
      }else if(this.priceArr[0]  && !this.priceArr[1]){
        newPriceArr[0] = this.priceArr[0];
        newPriceArr[1] = 99999;
      }else {
        newPriceArr = this.priceArr;
      }
      return newPriceArr;
    },
    // 确认按钮事件
    handleConfirm() {
      this.getGoodsList(1);
    },
    // 发货地显示
    handleShowArea() {
      this.areaShow = true;
    },
    handleHidArea() {
      this.areaShow = false;
    },
    /*--------分页操作--------------*/
    // 跳转至
    changePages(i) {
      i = parseInt(i);
      if(this.nowPagination === i) {
        return;
      }
      if (i > this.page_count || i <= 0) {
        return this.$message.error('你输入的分页不存在！');
      }
      this.nowPagination = i;
      this.getGoodsList(this.nowPagination);
      window.scrollTo(0, 0);
      this.pagesTo = "";
    },
    // 切换分页
    changePage(page) {
      if(this.nowPagination === page) {
        return;
      }
      // this.nowPagination = page;
      window.scrollTo(0, 0);
      this.getGoodsList(page);
    },
    topChangePage(type, page) {
      let pages = page;
      if(type == 0) {
        if(--pages >= 1) {
          this.getGoodsList(pages);
        }else return;
      }else {
        if(++pages <= this.page_count) {
          this.getGoodsList(pages);
        }else return;
      }
      
    },
    // 从首页跳转当前页
    handlejumpLoc() {
      let activeIndex;
      this.productCat.forEach((item, index) => {
        if(item.id == this.pid){
          activeIndex = index;
        }
      });
      if(activeIndex === 0) {
        this.catActive = this.cid;
      }else if(activeIndex === 1) {
        this.fabricActive = this.cid;
      }else if(activeIndex === 2) {
        this.colorActive = this.cid;
      }else {
        this.applyActive = this.cid;
      }
      console.log(this.cid, this.pid,this.catId);
      if(this.catId == '') {
        this.setCategoryIdArr(this.cid, this.pid);
      }
      this.cid = null;
      this.pid = null;
    },
    beforeunloadFn() {
      location.reload();
    }
  },
  created() {
    if(this.keyword) {
      this.key = this.keyword;
    }
    // if(this.searchImg) {
    //   this.imageSearch(1);
    //   return;
    // }
    if(this.labelId) {
      this.leadingLabelId = this.labelId;
    }
    //获取商品数据
    this.getproductCat();
    if(this.$route.query.catId) {
      this.catId = this.$route.query.catId;
    }
    this.cid = parseInt(this.$route.query.cat_id);
    this.pid = parseInt(this.$route.query.par_id);
  },
  mounted() {
    this.area_list = province;
    // window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  destroyed() {
    this.$store.commit('saveSearchImg', '');
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  }
};
</script>

<style>
.sort-list ul {
  flex-wrap: wrap;
}
.sort-box,
.sort-list {
  border-bottom: 1px solid #dddddd;
}
.sort-list li,
.sort-title {
  padding: 0 12px;
}
.sort-list li {
  padding-bottom: 16px;
}
.sort-title {
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.45);
}
.sort-list a:hover {
  color: #f5222d;
}
.sort-list .c-red {
  position: relative;
}

.cat2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 1034px;
  min-height: 44px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}
.cat2 li:first-child {
  color: #333;
}
.sort-list,
.cat2 {
  padding-top: 16px;
}
.sort-btn {
  height: 40px;
  line-height: 40px;
  background: #f5f5f5;
  border: 1px solid #dddddd;
  margin-top: -1px;
  align-items: center;
}
.sort-btn > a {
  padding: 0 20px;
  border-right: 1px solid #dddddd;
  transition: all 0.3s;
  height: 38px;
  line-height: 38px;
}
.sort-btn a.active {
  color: #f5222d;
  background: #fff;
}
.sort-btn > a:hover {
  color: #f5222d;
  background: #fff;
}
.sort-btn > a i {
  margin-left: 4px;
  transform: translateY(2px);
}
.sort-btn .bg-r-btn {
  margin-left: 8px;
  width: 50px;
  height: 24px;
  line-height: 22px;
  font-size: 14px;
}
.sort-btn span.x_fill-sort {
  margin-left: 20px;
  margin-right: 2px;
}
.sort-btn .price {
  width: 64px;
  height: 26px;
  line-height: 24px;
  background: #ffffff;
  border: 1px solid #dddddd;
  margin-left: 5px;
}
.sort-btn .price span {
  color: #bbb;
  line-height: 22px;
  margin-left: 4px;
}
.sort-btn .price input {
  width: 90%;
  padding: 0 5px;
  border: none;
}
.sort-btn .right {
  margin-left: auto;
  margin-right: 20px;
  align-items: center;
}
.sort-btn .right a {
  display: inline-block;
  width: 32px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  opacity: 0.5;
  margin-left: 8px;
  transition: all 0.3s;
}
.sort-btn .right a.active {
  opacity: 1;
}
.sort-check-box {
  background: #f9f9f9;
  border: 1px solid #dddddd;
  margin-top: -1px;
  padding: 8px 20px 0;
  overflow: unset;
}
.sort-check-box .sort-checkbox {
  margin-right: 24px;
  margin-bottom: 8px;
}
.sort-check-box .sort-checkbox input {
  display: none;
}
.sort-check-box .sort-checkbox a,
.sort-check-box .right {
  height: 20px;
  line-height: 20px;
}
.sort-check-box .right {
  position: relative;
}
.sort-check-box .sort-checkbox a i {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.sort-check-box .sort-checkbox a.x_completed i {
  border: none;
  opacity: 0.85;
}
.sort-check-box .right .w_down i,
.sort-check-box .sort-checkbox a i {
  transform: translateY(2px);
}
.sort-check-box .right .w_down i {
  margin-left: 8px;
  opacity: 0.25;
}
.area-box {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 500px;
  padding: 14px;
  padding-bottom: 0;
  border: 1px solid #ddd;
  background: #fff;
  z-index: 1000;
}
.area-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 14px;
  margin-bottom: 14px;
}
.area-header span {
  cursor: pointer;
}
.area {
  display: flex;
  flex-wrap: wrap;
}
.area-item {
  width: 20%;
  text-align: center;
  margin-bottom: 14px;
}
.area-item a:hover {
  color: #f5222d;
}
.area-enter-active,
.area-leave-active {
  transition: opacity 0.3s;
}
.area-enter,
.area-leave-to {
  opacity: 0;
}
.goods-list {
  flex-wrap: wrap;
  width: 1180px;
  padding: 0 5px;
}
.goods-list li {
  width: 220px;
  /* height: 302px; */
  /* margin-top: 20px; */
  margin: 20px 7px;
  border: 1px solid rgba(0, 0, 0, 0);
}
/* .goods-list li:hover {
  height: 300px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
} */
/* .goods-list .goods-box {
  width: 100%;
  height: auto;
} */
.pagination {
  margin: 40px auto;
}
.pagination a {
  padding: 0 8px;
  min-width: 32px;
  line-height: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
}
.pagination .w_turn-left i,
.pagination .w_turn-right i {
  width: 12px;
  height: 12px;
  opacity: 0.65;
}
.pagination .w_turn-right {
  margin-right: 16px;
}
.pagination input {
  width: 48px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin: 0 8px;
}
.pagination .active {
  background: #f5222d;
  color: #fff;
  border: none;
}
.house .pagination {
  justify-content: center;
}
.allProvince {
  border-bottom: 1px solid #DDDDDD;
}
#inner {
  min-height: 200px;
}
</style>