<template>
  <div class="home">
    <main>
      <div class="swiper-box mb-24">
        <div
          class="swiper"
          :style="{
            left: left + 'px',
            transition: transition ? 'all .6s' : 'none',
          }"
        >
          <a
            href="javascript:;"
            v-for="(value, index) in swiper"
            :key="index"
            @click="swiperTo(value.admin.code)"
          >
            <img :src="value.image"  v-if="swiper" style="height:360px;"/>
          </a>
        </div>
        <ol class="point-box">
          <li
            class="point"
            :class="{ active: value.active }"
            v-for="(value, index) in swiper"
            @click="changeSwiper(index)"
            :key="index"
          ></li>
        </ol>
      </div>
      <div class="mt-40 flex c-box catelist">
        <a href="javascript:;" class="block mb-40" style="width:264px; height:160px;" 
        v-for="item in category" :key="item.id" @click="seeByCate(item.id, item.parent_id, item.hashid)">
          <img :src="item.image" alt="" class="cateImg">
        </a>
      </div>
      <div
        class="c-box shopping mb-44"
        v-for="(value, index) in productList"
        :key="index"
      >
        <div class="title flex">
          <div class="titleName"><span class="bold">{{value.title}}</span> {{value.english}}</div>
          <div class="tag">
            <div>
              <span class="tagItem pointer"
              v-for="(item, index) in value.catData" :key="'tag' + index"
              @click="seeMore(item.id ,item.parent_id, item.hashid)"
              >{{item.name}}</span>
            </div>
            <span class="gray9 fs-18" @click="seeByCate(value.cat.id, value.cat.parent_id, value.catData[0].hashid)">查看更多 ></span>
          </div>    
        </div>
        <div class="flex mt-8">
          <div class="left-img img-box">
            <img :src="value.image" alt="name" v-if="value.image" />
          </div>
          <ul>
            <!-- <goods-item></goods-item> -->
            <li v-for="(value, index) in value.productData" :key="index">
              <goods-box :data="value"></goods-box>
            </li>
          </ul>
        </div>
      </div>
      <div class="home-news c-box mb-80">
        <div class="newtitle flex">
          <div class="titleName"><span class="bold">新闻咨询</span> NEWS</div>
          <div class="tag">
            <div></div>
            <span class="gray9 fs-18" @click="moreNews">查看更多 ></span>
          </div>    
        </div>
        <ul class="flex mt-40">
          <li v-for="(value, index) in newData" class="flex" :key="index" @click="toNewsDetail(value.hashid)">
            <div class="left mr-25">
              <p class="date mb-10 bold">{{ value.month_day }}</p>
              <p class="years">{{ value.year }}</p>
            </div>
            <a href="javascript:;" style="width: calc(100% - 92px);" class="ml-25">
              <p class="title mb-10 ellipsis flex">{{ value.title }}</p>
              <p class="content ellipsis">{{ value.abstract }}</p>
            </a>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import goodsBox from "../components/goodsBox.vue";
// import goodsItem from "../components/goodsItem.vue"
import { getIndexData } from '@/http/api';
export default {
  name: "home",
  components: {
    goodsBox,
    // goodsItem
  },
  data() {
    return {
      //产品类型
      category: [
        
      ],
      
      //轮播图偏移量
      left: 0,
      //是否开启动画
      transition: true,
      //现在展示的轮播图索引
      index: 0,
      //轮播定时器
      timer: null,
      //保存宽度
      width: 0,

      swiper: [], //轮播图
      catData: [],
      productList:[],//服装类型
      newData: [], //新闻
    };
  },
  
  computed: {
  },
  
  methods: {
    //轮播
    start() {
      //获取屏幕宽度
      this.width = window.innerWidth;
      //开启定时器
      this.timer = setInterval(() => {
        if (this.swiper == undefined || this.swiper.length == 0) {
          return;
        }
        //轮播索引++
        this.index++;
        //如果索引大于轮播图数组长度 则重置轮播
        if (this.index < this.swiper.length) {
          this.left = -(this.index * this.width);
        } else {
          this.index = 0;
          this.left = 0;
        }
        //给point样式
        for (const iterator of this.swiper) {
          iterator.active = false;
        }
        this.swiper[this.index].active = true;
      }, 5000);
    },
    //点击切换图片
    changeSwiper(e) {
      //清除定时器
      clearInterval(this.timer);
      //给point样式
      for (const iterator of this.swiper) {
        iterator.active = false;
      }
      this.swiper[e].active = true;
      //改变轮播索引 并播放至对应图片
      this.index = e;
      this.left = -(this.index * this.width);
      //重新开始轮播
      this.start();
    },
    //点击轮播跳转
    swiperTo(code) {
      this.$store.commit('saveKeyword', code);
      this.$router.push(`/house`);
    },
    //点击分类跳转
    catTo(id) {
      this.$router.push("/house?cat_id1=" + id);
    },
    // 根据catId 跳转
    seeByCate(cat_id, par_id, id) {
      this.$router.push(`/house?cat_id=${cat_id}&par_id=${par_id}&catId=${id}`);
    },
    // 点击查看更多
    seeMore(cat_id, par_id, id) {
      console.log(cat_id, par_id,id);
      if(par_id != 1) {
        this.$router.push(`/house?cat_id=${cat_id}&par_id=${par_id}&catId=`);
      }else {
        this.$router.push(`/house?cat_id=${cat_id}&par_id=${par_id}&catId=${id}`);
      }
    },
    // 查看更多新闻
    moreNews() {
      this.$router.push('/news');
    },
    //  跳转新闻详情
    toNewsDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/news/newsDetail?newsId=" + id,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({path: '/news/newsDetail', query:{newsId: id}});
    },
    // 点击分类跳转图片
    handleToHouse(id) {
      console.log(id);
    },
    //-----------------获取数据------------------
    // 获取首页数据
    getData() {
      getIndexData({}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.swiper = data.bannerData; //轮播图
          this.category = data.catData; // 分类
          this.productList = data.productList; //商品列表
          this.newData = data.newData; //新闻
        }
      })
    },
  },
  created: function () {
    this.getData();
  },
  mounted: function () {
    //启动图片轮播
    this.start();
    //监听窗口变化
    window.onresize = () => {
      //获取屏幕宽度
      this.width = window.innerWidth;
    };
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
};
</script>
<style>
.home {
  background: #fff;
  padding-bottom: 20px;
}
.swiper-box {
  width: 100%;
  overflow: hidden;
}
.swiper-box,
.swiper {
  position: relative;
}
.swiper,
.point-box {
  display: flex;
}
.swiper a {
  flex-shrink: 0;
  width: 100vw;
}
.swiper img {
  width: 100%;
}
.point-box {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}
.point {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  cursor: pointer;
}
.point.active {
  background-color: rgba(255, 255, 255, 1);
}
.point:last-child {
  margin-right: 0;
}
.headerGoodsList {
  justify-content: space-between;
  text-align: center;
  overflow: visible;
}
.headerGoodsList li {
  cursor: pointer;
}
.headerGoodsList .img-box {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.headerGoodsList p {
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.shopping {
  overflow: hidden;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-bottom: 4px solid #000;
}
.tagItem {
  background: #000;
  border-radius: 4px;
  padding: 3px 12px 4px;
  color: #fff;
  margin-left: 24px;
}
.tag .gray9 {
  cursor: pointer;
  font-weight: normal;
}
.title .titleName,
.newtitle .titleName{
  background: #F5222D;
  padding: 11px 16px;
  border-radius: 4px 4px 0px 0px;
  color: #fff;
  font-size: 20px;
}

.shopping .left-img.img-box {
  width: 260px;
  height: 504px;
  background: #c1c1c1;
  flex-shrink: 0;
}
.shopping ul {
  /* height: 634px; */
  /* overflow: hidden; */
  padding-left: 16px;
  display: flex;
  flex-wrap: wrap;
}
.shopping li {
  display: inline-block;
  width: 220px;
  height: 240px;
  /* margin-left: 20px; */
  /* margin-bottom: 20px; */
}
.catelist {
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-news h3 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 35px;
  color: #333;
  font-weight: 500;
}
.home-news ul {
  flex-wrap: wrap;
  color: #333;
}
.home-news li {
  align-items: flex-start;
  justify-content: start;
  width: 45%;
  margin-bottom: 30px;
  transition: all 0.3s;
  padding: 10px;
  margin: 0 2.5%;
}
.home-news li:hover {
  background: #fff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}
.home-news .date {
  font-size: 23px;
}
.home-news .years {
  font-size: 14px;
  color: #999;
}
.home-news .title {
  font-size: 18px;
  font-weight: bold;
  align-items: flex-start;
  justify-content: flex-start;
  color: #333;
}
.home-news .title::before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../images/hot.gif") no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 10px;
}
.home-news .content {
  font-size: 14px;
  color: #666;
  width: 100%;
}
.cateImg {
  width: 100%;
  height: 100%;
}
</style>