<template>
  <div class="followItem">
    <c-nav :nav="navList"></c-nav>
    <div class="card mb-20 flex f-a-c card-0">
      <div class="left flex f-a-c">
        <p class="fs-12 mb-20">订单号：{{ orderDetail.sn }}</p>
        <p>{{ orderDetail.status_name }}</p>
      </div>
      <ul class="process-status flex">
        <li :class="{ active: orderDetail.status >= 1 }">
          <p class="title">待付款</p>
        </li>
        <li :class="{ active: orderDetail.status >= 2 }">
          <p class="title">配货中</p>
        </li>
        <li :class="{ active: orderDetail.status >= 3 }">
          <p class="title">待自提</p>
        </li>
        <li :class="{ active: orderDetail.status >= 4 }">
          <p class="title">待收货</p>
        </li>
        <li :class="{ active: orderDetail.status == 7 || orderDetail.status == 5}">
          <p class="title">已完成</p>
        </li>
        <div class="line" :class="{ active: orderDetail.status >= 2 }"></div>
        <div class="line" :class="{ active: orderDetail.status >= 3 }"></div>
        <div class="line" :class="{ active: orderDetail.status >= 4 }"></div>
        <div class="line" :class="{ active: orderDetail.status == 7 || orderDetail.status == 5}"></div>
      </ul>
    </div>
    <div class="card flex fs-12 mb-20" >
      
      <ul class="courier-status" style="height: 240px; overflow: auto;">
        <li>
          <div
            class="child flex"
            v-for="(item, index) in orderDetail.record" :key="item.id"
            style="justify-content: center;"
          >
            <span>{{ item.created_at }}</span>
            <span style="position: relative;">
              <i class="statusLine" v-show="index > 0"></i>
              <i class="statusCircle"></i>
              {{item.remark}}
            </span>
          </div>
        </li>
      </ul>
    </div>
    <ul class="card mb-20 flex fs-14">
      <li class="p-20 flex1">
        <p class="bold mb-20">收货人信息</p>
        <p>
          <span>收货人：</span>
          <span>{{ orderDetail.receiving ? orderDetail.receiving.name : ''}}</span>
        </p>
        <p>
          <span>地址：</span>
          <span>
            {{ orderDetail.receiving ? orderDetail.receiving.province : ''}}
            {{ orderDetail.receiving ? orderDetail.receiving.city : ''}}
            {{ orderDetail.receiving ? orderDetail.receiving.district : ''}}
            {{ orderDetail.receiving ? orderDetail.receiving.detail : ''}}
          </span>
        </p>
        <p>
          <span>手机号码：</span>
          <span>{{ orderDetail.receiving ? orderDetail.receiving.phone : ''}}</span>
        </p>
        <p>
          <span>备注：</span>
          <span>{{ orderDetail.remarks ? orderDetail.remarks : ''}}</span>
        </p>
      </li>
      <li class="p-20 flex1">
        <p class="bold mb-20">配送信息</p>
        <p>
          <span>配送方式：</span>
          <span>{{ orderDetail.type === 1 ? '上门自提' : '快递配送' }}</span>
        </p>
        <p>
          <span>包裹重量：</span>
          <span>{{orderDetail.weight}}</span>
        </p>
        <p>
          <span>{{ orderDetail.type === 1 ? '提货地址：' : '快递公司：'}}</span>
          <span v-if="orderDetail.type === 1 && orderDetail.pick_point">
            {{orderDetail.pick_point.province}}{{orderDetail.pick_point.city}}
            {{orderDetail.pick_point.county}}{{orderDetail.pick_point.detail}}
          </span>
          <span v-else>{{orderDetail.delivery ? orderDetail.delivery.express_name : ''}}</span>
        </p>
        <p>
          <span>{{ orderDetail.type === 1 ? '自提码：' : '快递单号：'}}</span>
          <span v-if="orderDetail.type === 1">{{ orderDetail.code ? orderDetail.code : ''}}</span>
          <span v-else>{{orderDetail.delivery ? orderDetail.delivery.mail_no : ''}}</span>
        </p>
      </li>
      <li class="p-20 flex1">
        <p class="bold mb-20">付款信息</p>
        <div v-show="orderDetail.status > 1">
          <p>
            <span>付款方式：</span>
            <span>{{ orderDetail.pay_type }}</span>
          </p>
          <p>
            <span>支付金额：</span>
            <span>￥{{ orderDetail.order_amount ? orderDetail.order_amount.toFixed(2) : ''}}</span>
          </p>
          <p>
            <span>付款时间：</span>
            <span>{{ orderDetail.pay_at }}</span>
          </p>
        </div>
        
      </li>
    </ul>
    <div class="table card mb-20">
      <div class="header flex">
        <span class="goods" >货品</span>
        <span class="unit-price" style="width: 16%;">小计</span>
        <span style="width: 10%;">合计</span>
        <span class="status" style="width: 13%;">订单状态</span>
        <span class="other" style="width: 8%;">其他</span>
      </div>
      <ul class="order-list">
        <li class="mb-20">
          <div class="body" v-for="orderItem in orderDetail.warehouse" :key="orderItem.id">
            <div style="padding: 10px; border-bottom: 1px solid #EEEEEE;">
              <div class="fs-12">
                <span class="">
                  <i class="iconfont m_home"></i>
                  <span class="ml-8">{{orderItem.name}}</span>
                </span>
                <span class="ml-24">
                  <i class="iconfont m_zhanghao"></i>
                  <span class="ml-8">{{orderItem.admin.code}}</span>
                </span>
                <span class="ml-24" v-if="orderItem.type === 1">
                  
                  <i class="iconfont m_local-fill"></i>
                  <span class="ml-8">自提</span>
                </span>
                <span class="ml-24 deliveryItem" v-if="orderItem.type !== 1">
                  <!-- <span class="triangle_down" v-show="orderItem.receiving"></span> -->
                  <!-- <span class="tips bg_white" v-show="orderItem.receiving">
                    <span v-show="orderItem.type === 2">快递寄付</span>
                    <span v-show="orderItem.type === 3">快递到付</span>
                    <span class="ml-4">{{orderItem.delivery ? orderItem.delivery.mail_no : ''}}</span>
                    <span>{{orderItem.delivery ? orderItem.delivery.express_name : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.name : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.phone : ''}}</span>
                    <span class="block">{{orderItem.receiving ? orderItem.receiving.province : ''}} {{orderItem.receiving ? orderItem.receiving.city :''}}
                      {{orderItem.receiving ? orderItem.receiving.district : ''}} {{orderItem.receiving ? orderItem.receiving.detail : ''}}
                    </span>
                  </span> -->
                  <i class="iconfont m_truck"></i>
                  <span class="ml-8">{{orderItem.receiving ? orderItem.receiving.name : ''}}</span>
                </span>
                <span class="col_f52 bold " style="float: right;"
                 v-show="orderDetail.status === 7 || orderDetail.status === 5">
                  <span v-show="orderItem.asles_day_num > 0">
                    可售后时间：{{orderItem.asles_day_num}}
                  </span>
                </span>
              </div>
            </div>
            <div class="flex" >
              <div class="order-goods-list">
                <ul>
                  <li
                    class="flex goodItem"
                    v-for="(goods, index) in orderItem.products" :key="index"
                  >
                    <div
                      class="order-img-box"
                      @click="toDetailPage(goods.hashid)"
                    >
                      <img
                        :src="goods.image + '?imageMogr2/thumbnail/76x'"
                        :alt="goods.full_name"
                      />
                    </div>
                    <div>
                      <div
                        class="title mb-8"
                        @click="toDetailPage(goods.hashid)"
                      >
                        {{ goods.full_name }}
                      </div>
                    </div>
                     <p class="ml-24 gray9" style="flex: 1;">
                        <span v-for="color in goods.colourArr" :key="color.id" class="block" style="text-align:left;">
                          <span class="" style="width: 60%;">
                            {{ color.name }}:
                            <span v-for="size in color.sizeArr" :key="size.id"> {{size.name}}-{{size.number}};</span>
                          </span>
                          <span style="width:40%;" class="t-center gray3">
                            {{color.number}}件，共{{color.total_price}}元
                          </span>
                        </span>
                    </p>
                  </li>
                </ul>
                <!-- <p>共{{ orderItem.type }}种产品</p> -->
              </div>
              <div class="order-sum p-20 rightPart t-c">
                <p class="bold fs-14">{{ orderDetail.total_amount.toFixed(2) }}</p>
                <p>含运费{{ orderDetail.freight }}</p>
                <p class="black fs-12">共{{orderItem.number}}件</p>
              </div>
              <div class="order-status p-20 rightPart">
                <p>{{ orderDetail.status_name }}</p>
              </div>
              <div class="other p-20 rightPart">
                <a href="javascript:;" v-if="orderDetail.status == 1" @click="toPay(orderDetail.hashid)">
                  去付款
                </a>
                <a href="javascript:;" v-if="orderDetail.status == 5 || orderDetail.status == 7" @click="buyAgain(orderItem.hashid, )">
                  再次购买
                </a>
                <a
                  href="javascript:;"
                  v-if="orderDetail.status == 3 || orderDetail.status == 4"
                >
                  确认收货
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-show="orderDetail.warehouse && orderDetail.warehouse.length == 0" class="p-20">暂时没有哦~</p>
      <div class="t-right fs-14" style="padding:0 10px 10px 0;">
        <p class="mb-10">
          <span>商品总额：</span>
          <span>￥{{ orderDetail.amount }}</span>
        </p>
        <p class="mb-10">
          <span>优惠金额：</span>
          <span>￥{{ orderDetail.coupon_amount }}</span>
        </p>
        <p class="mb-10">
          <span>运费：</span>
          <span>￥{{ orderDetail.freight }}</span>
        </p>
        <p class="c-red">
          <span>实付款：</span>
          <span class="fs-18 bold">￥{{ orderDetail.order_amount }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { orderDetails, addCart } from '@/http/api';
export default {
  name: "followItem",
  data() {
    return {
      navList: [
        {
          path: `/user/follow`,
          text: "跟单工作台",
        },
        {
          path: `${this.$route.path}`,
          text: "订单详情",
        },
      ],
      //收货人姓名
      name: "",
      //收货人地址
      add: "",
      //收货人手机号码
      tel: "",
      //备注
      remark: "",
      //配送方式
      sendType: "",
      //付款方式
      payType: "",
      //付款时间
      payTime: "",
      //订单号
      orderNum: "",
      //订单商品列表
      goodsList: [],
      //商品总金额
      goodsSum: "",
      //优惠金额
      coupon_money: "",
      //邮费
      postSum: "",
      //实际支付总金额
      paySum: "",
      //订单状态
      orderStatus: "",
      //快递情况
      sendStatus: [],

      orderDetail: {}
    };
  },
  computed: {
  },
  methods: {
    // 再次购买
    buyAgain(hashid) {
      let arr = [];
      this.orderDetail.warehouse.forEach(item => {
        if(item.hashid === hashid) {
          item.products.forEach(pro => {
            arr.push({
                productId: pro.hashid,
                number: pro.number,
                sku_id: pro.sku_id
              })
          })
        }
      });
      this.addCart(hashid, arr);
    },
    // 获取订单详情
    orderDetails(orderId) {
      orderDetails({orderId: orderId}).then(res => {
        const { statusCode, data } = res;
        if(statusCode === 200) {
          this.orderDetail = data;
        }
      });
    },
    // 加入购物车
    addCart(id, list) {
      let parms = {
        productData: list,
        warehouseId: id
      }
      addCart(parms).then(res => {
        const { statusCode } = res;
        if(statusCode	== 200) {
          this.$router.push('/shopping-cart');
        }
      })
    },
    // 去付款
    toPay(orderId) {
      let data = {
        orderId: orderId,
      }
      this.$store.commit('savePayData', data);
      this.$router.push('/pay');
    },
    // 去详情页
    // 跳转到详情
    toDetailPage(id) {
      let routeUrl = this.$router.resolve({
        path: "/house/" + id,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  created() {
    //订单ID
    let id = this.$router.currentRoute.params.pathMatch;
    this.orderDetails(id);
  },
};
</script>

<style>
.followItem > .card:nth-child(3) .left {
  width: 274px;
  padding: 20px;
  border-right: 1px solid #eee;
}
.followItem > .card:nth-child(3) .left .img-box {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 1px dashed #888;
  margin-right: 10px;
}
.followItem .courier-status {
  padding: 20px;
}
.followItem .courier-status span:first-child {
  width: 20%;
}
.followItem > ul li.flex1 {
  text-align: left;
  border-right: 1px solid #eee;
}
/* .followItem > ul li.flex1 p:first-child {
  margin-bottom: 20px;
} */
.followItem > ul li.flex1 p {
  display: flex;
  margin-bottom: 10px;
}
.followItem > ul li.flex1 p span {
  display: inline-block;
  width: 70%;
}
.followItem > ul li.flex1 p span:first-child {
  width: 70px;
}
.followItem .table {
  overflow: hidden;
}
/* .followItem .order-goods-list {
  width: 80%;
  margin-right: 0;
} */
.followItem .order-sum {
  padding-left: 10px;
}
.followItem .order-list > li {
  padding: 0;
}
.followItem .table > .header .goods {
  flex: 2;
}
/* .followItem .order-goods-list li span {
  text-align: center;
} */
.followItem li li > span:last-child {
  transform: translateX(14px);
}
.followItem .order-goods-list li:last-child {
  border: none;
}
/* .followItem .order-goods-list li > div {
  margin-right: 30px;
} */
.followItem .order-sum p:first-child {
  position: relative;
  /* top: 50%; */
  /* transform: translateY(-9px); */
}
.followItem table {
  table-layer: fixed;
  margin-bottom: 20px;
}
.followItem td:first-child,
.followItem th:first-child {
  width: 275px;
  padding-left: 20px;
}
.followItem tr:first-child td:last-child,
.followItem th:last-child {
  border-left: 1px solid #eee;
}
.followItem td[rowspan="0"],
.followItem tr {
  border-bottom: 1px solid #eee;
}
.followItem td:first-child {
  display: flex;
}
.followItem td:first-child div {
  text-align: left;
}
.follow td:last-child {
  font-weight: bold;
}
.followItem .order-box {
  padding-bottom: 20px;
  font-size: 14px;
}
.followItem .order-box > p {
  text-align: right;
  padding-right: 20px;
}
.followItem .order-box > p.c-red span:last-child {
  font-size: 18px;
  font-weight: bold;
}
.statusCircle {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  background: #c2c2c2;
  border-radius: 50%;
  left: -26px;
  top: 7px;
  z-index: 100;
}
.statusLine {
  display: block;
  position: absolute;
  height: 37px;
  top: -30px;
  left: -25px;
  width: 1px;
  background: #e9e9e9;
  transform: translateX(1px);
  z-index: 99;
}
.followItem a:hover {
  color: #f5222d;
}
.followItem .order-img-box {
  flex-shrink: 0;
  width: 76px!important;
  height: 76px;
  background: #eeeeee;
  margin-right: 12px;
}

</style>