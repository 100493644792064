<template>
  <div class="c-nav">
    <div class="c-box flex">
      <router-link to="/">首页</router-link>
      <router-link
        v-for="value in nav"
        :to="value.path"
        class="flex"
        :key="value.id"
      >
        <i></i>
        <span>{{ value.text }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "cNav",
  props: {
    nav: Array,
  },
  created() {
  }
};
</script>

<style>
/* 导航组件 */
.c-nav {
  background: #f5f5f5;
}
.c-nav .c-box {
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 12px;
}
.c-nav span {
  /* max-width: 134px; */
  white-space: nowrap;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}
.c-nav a:last-child {
  color: #333333;
}
.c-nav a i {
  width: 12px;
  height: 12px;
  background: url("../images/arrow.png") no-repeat;
  margin: 0 5px;
}
</style>