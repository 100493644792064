import Axios from "axios";
// import { reject, resolve } from "core-js/fn/promise";
// 引入qs库
import qs from "qs";


// 判断开发环境是本地还是线上 true是本地 false是线上
// 保存url 如果是本地 则url前缀为/api 如果是线上 则url前缀为线上api地址
let url = process.env.NODE_ENV == "development" ? "/api" : "https://yinbaole.com/api/pc";
const axios = Axios.create({
  // 以application / x-www-form-urlencoded格式发送数据
  headers: {
    "content-type": "application/x-www-form-urlencoded",
  },
  // 带cookie请求
  withCredentials: true,
});
// 给每个url添加默认前缀
axios.defaults.baseURL = url;
let data = {};
// 处理axios请求
export default function (path, params, method = "GET", headerType = "json") {
  // post请求
  if (method === 'post') {
    if (headerType === "json") {
      axios.defaults.headers["Content-Type"] = 'application/json;charset=UTF-8';
      data = params;
    } else {
      axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded;charset=UTF-8';
      data = qs.stringify(params);
    }
  }

  // get请求
  if (method === 'get') {
    data = qs.stringify(params);
    path = path + '?' + data;
    data = {};
  }

  // 请求拦截
  axios.interceptors.request.use((config) => {
    let token = window.localStorage.getItem('token');
    if (token) {
      config.headers['token'] = token;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  // 响应拦截
  axios.interceptors.response.use(response => {
    if (response.headers.token) {
      window.localStorage.setItem('token', response.headers.token);
    }
    if (response.data.statusCode === 401) {
      this.$message.error('登录过期，请重新登录!');
    }
    return response;
  }, error => {
    return Promise.resolve(error.response);
  });

  // 发送请求
  return new Promise(() => {
    axios({
      method,
      url: path,
      data: params,
      timeout: 2000,
    }).then(res => {
      return(res.data);
    }).catch(err => {
      return(err);
    })
  })
}

// 请求拦截
// axios.interceptors.request.use((config) => {
//   // 如果发送的数据本身就是FormData格式的数据 那么就不需要用qs模块进行转换
//   if (typeof config.data == "object" && config.data.__proto__.toString() == "[object FormData]") return config;
//   if (config.method == "post") {
//     // 如果是post请求 使用qs模块转换发送的数据
//     config.data = qs.stringify(config.data);
//   }
//   return config;
// });

// 响应拦截
// axios.interceptors.response.use(res => {
//   return res;
// }, err => {
//   return Promise.reject(err);
// });

// export default axios;
